/*
 * @Descripttion:
 * @version:
 * @Author: sujun
 * @email: 1373842098@qq.com
 * @Date: 2022-01-10 09:32:13
 * @LastEditors: linxi
 * @LastEditTime: 2024-12-25 14:44:11
 */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Button, Col, Form, Input, Row, Select, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import {
  DateRangeType,
  DateSelect as DateSelectOne,
  getDateRange,
  RecentTime, RecentTimePotion,
} from '../../../compnents/date/TimeSelect'
import { replaceMakeDetailParams, SupplierListResponse } from '../../../services/OReplaceMake'
import moment from 'moment'
import styles from './order.module.css'
import { getSupplierList } from '../OReplaceMakeSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../../app/store'

export const Query = (props: {
  onValueChange: (queries: replaceMakeDetailParams) => void
  onExport: () => void
}) => {
  const [form] = Form.useForm()

  const dispatch = useDispatch<RootDispatch>()

  const [head, tail] = getDateRange(DateRangeType.Today)

  const [supplierOptions, setSupplierOptions] = useState<
    Array<SupplierListResponse>
  >([])

  const recipeStatusOptions = [
    {
      value: -1,
      label: '全部',
    },
    {
      value: 0,
      label: '未上传',
    },
    {
      value: 1,
      label: '已上传',
    },
    {
      value: 3,
      label: '已取消',
    },
  ]

  const machiningTypeOptions = [
    {
      value: -1,
      label: '全部',
    },
    {
      value: 0,
      label: '委外代煎颗粒剂',
    },
    {
      value: 1,
      label: '委外代煎袋装液体',
    },
    {
      value: 2,
      label: '委外外配药材',
    },
    {
      value: 3,
      label: '委外膏方代煎',
    },
  ]

  useEffect(() => {
    dispatch(getSupplierList())
      .then(unwrapResult)
      .then((res: Array<SupplierListResponse>) => {
        setSupplierOptions(res)
      })
  }, [])

  return (
    <Form
      form={form}
      name='control-hooks'
      autoComplete='off'
      onFinish={(v) => {        
        const time = {
          pullTimeHead: null,
          pullTimeTail: null,
          startTime: v?.pullTimeHead
            ? moment(v.pullTimeHead).format('YYYY-MM-DD HH:mm:ss')
            :  moment(head).format('YYYY-MM-DD HH:mm:ss'),
          endTime: v?.pullTimeTail
            ? moment(v.pullTimeTail).format('YYYY-MM-DD HH:mm:ss')
            :  moment(tail).format('YYYY-MM-DD HH:mm:ss'),
        }
        props.onValueChange({ ...v, ...time })
      }}
      style={{ paddingBottom: '20px' }}
    >
      <Row>
        <Space style={{ flex: 1 }}>
          <Form.Item name='keyWord' noStyle>
            <Input
              size='middle'
              placeholder='药品名称/助记码'
              // onChange={form.submit}
              allowClear
              style={{ width: 180 }}
              prefix={<SearchOutlined />}
            />
          </Form.Item>
          <Form.Item name='supplierId' noStyle>
            <Select allowClear placeholder='代煎饮片厂' onChange={form.submit} style={{ width: 120 }}>
              {supplierOptions.length &&
                supplierOptions?.map((c) => (
                  <Select.Option key={c.name} value={c.id}>
                    {c.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item name='machiningType' noStyle>
            <Select
              allowClear
              placeholder='代煎类别'
              onChange={form.submit}
              dropdownClassName={styles.select}
              style={{ width: 120 }}
            >
              {machiningTypeOptions.map((c) => (
                <Select.Option key={c.label} value={c.value}>
                  代煎类别-{c.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name='recipeStatus' noStyle>
            <Select
              allowClear
              placeholder='上传状态'
              onChange={form.submit}
              dropdownClassName={styles.select}
              style={{ width: 120 }}
            >
              {recipeStatusOptions.map((c) => (
                <Select.Option key={c.label} value={c.value}>
                  上传状态-{c.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item noStyle>
            <DateSelectOne
              width={340}
              showTime={true}
              placeholder='上传时间'
              options={RecentTimePotion}
              initialValue={DateRangeType.Today}
              style={{ width: 120 }}
              labelPrefix=''
              onChange={form.submit}
              namePrefix='pullTime'
            />
          </Form.Item>
          <Button htmlType='submit' type='primary'>
            查询
          </Button>
        </Space>
        <Space>
          <Button type='primary' onClick={() => props.onExport()}>
            导出
          </Button>
        </Space>
      </Row>
    </Form>
  )
}
