/*
 * @Descripttion:
 * @version:
 * @Author: suiyue
 * @Date: 2021-05-19 14:33:03
 * @LastEditors: linxi
 * @LastEditTime: 2024-12-25 12:02:19
 */
import { CloseCircleOutlined, SettingOutlined } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Divider,
  Dropdown,
  Input,
  InputProps,
  notification,
  Row,
  Tag,
} from 'antd'
import React, {
  ReactElement,
  useContext,
  useState,
  useRef,
  useEffect,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectEdition } from '../../../app/applicationSlice'
import { RootDispatch } from '../../../app/store'
import { entry, getEntryData } from '../../../models/ToothHome'
import { ThemeContext } from '../../../theme/ThemeContext'
import { SetXianBingEntryModal } from './addOrEditEnteyModal'
import styles from './TcmCases.module.css'
import {
  defaulTallergenListAsync,
  defaultMainComplaintListAsync,
  defaultMaiXiangListAsync,
  defaultSheXiangListAsync,
  selectGuoMingData,
  selectJiaZuData,
  selectJiWangData,
  selectMaixiangData,
  selectShexiangData,
  selectZhuSuData,
  setGuoMingData,
  setJiaZuData,
  setJiWangData,
  setZhuSuData,
  setShexiangData,
  setMaixiangData,
  defaultMainComplaintListKQAsync,
  selectGeRenData,
  setGeRenData,
  defaultXianBingListAsync,
  setXianbingData,
  saveXianbingEntry,
  selectXianbingData,
  selectTiGeJianChaData,
  setTiGeJianChaData,
} from './tcmCasesSlice'

type action =
  | 'zhusu'
  | 'guoming'
  | 'xianbing'
  | 'jiwang'
  | 'jiazu'
  | 'jieya'
  | 'jiuzheng'
  | 'jiuzhenyuanyin'
  | 'maixiang'
  | 'shexiang'
  | 'geren'
  | 'hunyu'
  | 'yuejin'
  | 'tigejiancha'
interface RecipeItemInputProps {
  action: action
  value?: any
  style?: React.CSSProperties
  onChange?: (value: string) => void
  onBlur?: () => void
  medicalRecord?: any
  placeholder?: any
}

export const FastInput = ({
  action,
  value,
  style,
  onChange,
  onBlur,
  medicalRecord,
  placeholder,
}: RecipeItemInputProps &
  Pick<InputProps, 'onBlur' | 'onPressEnter'>): ReactElement => {
  const inputRef = useRef<any>()

  const theme = useContext(ThemeContext)

  const dispatch = useDispatch<RootDispatch>()

  const [isVisible, setIsVisible] = useState(false)

  const [isModalVisible, setIsModalVisible] = useState(false)

  const hisVersion = useSelector(selectEdition) //his版本 0通用 1口腔

  const { CheckableTag } = Tag

  const [selectedTags, setSelectedTags] = useState<any>([])

  const [inputValue, setInputValue] = useState<any>()

  const [currentFlagNumber, setCurrentFlagNumber] = useState<any>()

  const [currentTag, setCurrentTag] = useState('')

  const zhuSuData = useSelector(selectZhuSuData)

  const maixiangData: any = useSelector(selectMaixiangData)

  const shexiangData: any = useSelector(selectShexiangData)

  const guoMingData = useSelector(selectGuoMingData)

  const jiWangData = useSelector(selectJiWangData)

  const jiaZuData = useSelector(selectJiaZuData)

  const xianBingData = useSelector(selectXianbingData)

  const tigejianchaData = useSelector(selectTiGeJianChaData)

  const geRenData = useSelector(selectGeRenData)

  const [symptomList, setSymptomList] = useState<any>([])

  const [periodlist, setPeriodlist] = useState<any>([])

  const [degreeList, setDegreeList] = useState<any>([])

  const [xianBingList, setXianBingList] = useState<any>([])

  const [selectionStartIndex, setSelectionStartIndex] = useState<any>()

  const [selectionEndIndex, setSelectionEndIndex] = useState<any>()

  useEffect(() => {
    if (isVisible) {
      const defaultMainComplaintListMainAsync =
        hisVersion == 1 || medicalRecord == 3
          ? defaultMainComplaintListKQAsync
          : defaultMainComplaintListAsync
      setCurrentTag('')
      setSelectedTags([])
      !getObject(zhuSuData) &&
        dispatch(defaultMainComplaintListMainAsync())
          .then(unwrapResult)
          .then((res: any) => {
            if (hisVersion == 1 || medicalRecord == 3) {
              const sort = [
                'toothPosition',
                'toothType',
                'symptom',
                'times',
                'requirement',
                'referral',
                'followUp',
              ]
              dispatch(
                setZhuSuData({
                  symptomList: sort?.map((v) => res[v]),
                  // periodlist: res.times,
                })
              )
            } else {
              const sort = ['one', 'two', 'three', 'four']
              dispatch(
                setZhuSuData({
                  symptomList: sort?.map((v) => res[v]),
                  periodlist: res.times,
                })
              )
            }
          })
      !getObject(maixiangData) &&
        dispatch(defaultMaiXiangListAsync())
          .then(unwrapResult)
          .then((res: any) => {
            const sort = ['one', 'two', 'three', 'four']
            dispatch(
              setMaixiangData({
                symptomList: sort?.map((v) => res[v]),
              })
            )
          })
      !getObject(shexiangData) &&
        dispatch(defaultSheXiangListAsync())
          .then(unwrapResult)
          .then((res: any) => {
            const sort = ['one', 'two', 'three', 'four', 'five', 'six']
            dispatch(
              setShexiangData({
                symptomList: sort?.map((v) => res[v]),
              })
            )
          })
      !getObject(guoMingData) &&
        dispatch(defaulTallergenListAsync('1'))
          .then(unwrapResult)
          .then((res: any) => {
            dispatch(
              setGuoMingData({
                symptomList: [res.allergen] || [[]],
                periodlist: res.allergenReaction,
                degreeList: res.allergenDegree,
              })
            )
          })
      !getObject(jiWangData) &&
        dispatch(defaulTallergenListAsync('2'))
          .then(unwrapResult)
          .then((res: any) => {
            const sort = ['pastMedicalHistoryCase', 'pastMedicalHistory']
            dispatch(
              setJiWangData({
                symptomList: sort.map((v) => res[v]),
              })
            )
          })
      !getObject(jiaZuData) &&
        dispatch(defaulTallergenListAsync('3'))
          .then(unwrapResult)
          .then((res: any) => {
            const sort = ['familyHistoryDeny', 'familyHistory']
            dispatch(
              setJiaZuData({
                symptomList: sort.map((v) => res[v]),
              })
            )
          })
      !getObject(geRenData) &&
        dispatch(defaulTallergenListAsync('4'))
          .then(unwrapResult)
          .then((res: any) => {
            const sort = ['personalHistoryDeny', 'personalHistory']
            dispatch(
              setGeRenData({
                symptomList: sort.map((v) => res[v]),
              })
            )
          })
      !getObject(tigejianchaData) &&
        dispatch(defaulTallergenListAsync('5'))
          .then(unwrapResult)
          .then((res: any) => {
            const sort = ['physicalHaveCase', 'physicalNoCase']
            dispatch(
              setTiGeJianChaData({
                symptomList: sort.map((v) => res[v]),
              })
            )
          })
      !getObject(xianBingData) && getXianBingList()
    } else {
      dispatch(setZhuSuData({}))
    }
  }, [isVisible, medicalRecord, hisVersion])

  const getXianBingList = () => {
    dispatch(defaultXianBingListAsync(1))
      .then(unwrapResult)
      .then((res: any) => {
        if (res?.length) {
          const data = res?.map((v: any) => v?.name)
          dispatch(
            setXianbingData({
              symptomList: [data],
            })
          )
        } else {
          dispatch(
            setXianbingData({
              symptomList: [['无']],
            })
          )
        }
      })
      .catch(() => {
        return
      })
  }

  useEffect(() => {
    if (isModalVisible) {
      dispatch(defaultXianBingListAsync(1))
        .then(unwrapResult)
        .then((res: any) => {
          if (res?.length) {
            setXianBingList(res)
          }
        })
        .catch(() => {
          return
        })
    } else {
      setXianBingList([])
    }
  }, [isModalVisible])

  useEffect(() => {
    if (action == 'zhusu' && getObject(zhuSuData)) {
      setSymptomList(zhuSuData.symptomList)
      setPeriodlist(zhuSuData.periodlist)
    }
    if (action == 'guoming' && getObject(guoMingData)) {
      setSymptomList(guoMingData.symptomList)
      setPeriodlist(guoMingData.periodlist)
      setDegreeList(guoMingData.degreeList)
    }
    if (action == 'xianbing' && getObject(xianBingData)) {
      setSymptomList(xianBingData.symptomList)
    }
    if (action == 'tigejiancha' && getObject(tigejianchaData)) {
      setSymptomList(tigejianchaData.symptomList)
    }
    if (action == 'maixiang' && getObject(maixiangData)) {
      setSymptomList(maixiangData.symptomList)
    }
    if (action == 'shexiang' && getObject(shexiangData)) {
      setSymptomList(shexiangData.symptomList)
    }
    if (action == 'jiwang' && getObject(jiWangData)) {
      setSymptomList(jiWangData.symptomList)
    }
    if (action == 'jiazu' && getObject(jiaZuData)) {
      setSymptomList(jiaZuData.symptomList)
    }
    if (action == 'geren') {
      setSymptomList(geRenData.symptomList)
    }
    if (action == 'hunyu') {
      setSymptomList([['未婚', '已婚']])
    }
    if (action == 'yuejin') {
      setSymptomList([
        ['否认月经期'],
        [
          '闭经',
          '有痛经史',
          '既往月经规律',
          '不规律',
          '量少',
          '量中',
          '量多',
          '色红',
          '暗红',
          '无血块',
          '痛经',
          '闭经',
        ],
      ])
    }
    if (action == 'jieya') {
      setSymptomList([getEntryData(entry.JYXG)])
    }
    if (action == 'jiuzheng') {
      setSymptomList([getEntryData(entry.JZJL)])
    }
    if (action == 'jiuzhenyuanyin') {
      setSymptomList([getEntryData(entry.JZYY)])
    }
  }, [
    action,
    zhuSuData,
    xianBingData,
    tigejianchaData,
    maixiangData,
    shexiangData,
    guoMingData,
    jiWangData,
    jiaZuData,
    geRenData,
  ])

  const getObject = (obj: any) => {
    return !!Object.keys(obj).length
  }

  const overlay = (
    <div className={styles.chiefComplaintOverlay}>
      <div
        style={{
          padding: '0 15px',
        }}
      >
        {action == 'guoming' && <div className={styles.title}>过敏源</div>}

        {symptomList?.map((el: any, i: any) => (
          <div
            key={i}
            style={{
              marginBottom: 10,
            }}
          >
            {action == 'zhusu' && (hisVersion == 1 || medicalRecord == 3) && (
              <div className={styles.title}>
                {i == 0
                  ? '牙位'
                  : i == 2
                  ? '症状'
                  : i == 3
                  ? '时间'
                  : i == 4
                  ? '要求'
                  : i == 5
                  ? '转诊'
                  : i == 6
                  ? '复诊'
                  : ''}
              </div>
            )}
            {el?.map((tag: any) => (
              <>
                {hisVersion == 1 || medicalRecord == 3 ? (
                  <>
                    {i != 3 && (
                      <CheckableTag
                        key={tag}
                        checked={selectedTags.indexOf(tag) > -1}
                        onChange={(checked) => {
                          handleChangeKQ(tag, checked, i)
                        }}
                      >
                        {tag}
                      </CheckableTag>
                    )}
                  </>
                ) : (
                  <>
                    {
                      <CheckableTag
                        key={tag}
                        checked={selectedTags.indexOf(tag) > -1}
                        onChange={(checked) => {
                          handleChange(tag, checked)
                        }}
                      >
                        {tag}
                      </CheckableTag>
                    }
                  </>
                )}
              </>
            ))}

            {i == 3 && (hisVersion == 1 || medicalRecord == 3) && (
              <Row
                style={{
                  margin: '10px 0',
                }}
              >
                {el?.map((t: any, index: number) => (
                  <Tag
                    key={i}
                    style={{
                      cursor: 'pointer',
                      marginBottom: 10,
                    }}
                    onClick={() => {
                      let str = ''
                      if (
                        selectionStartIndex == 0 ||
                        selectionStartIndex >= value?.length
                      ) {
                        str = value
                          ? value +
                            (currentFlagNumber == 5 ||
                            currentFlagNumber == 6 ||
                            currentFlagNumber == 0
                              ? '，'
                              : ' ') +
                            t
                          : t
                      } else {
                        const text =
                          value?.substring(0, selectionStartIndex) +
                          ((currentFlagNumber == 5 ||
                            currentFlagNumber == 6 ||
                            currentFlagNumber == 0) &&
                          value[selectionStartIndex] != '，'
                            ? '，'
                            : ' ') +
                          t +
                          ' '
                        str = text + value?.substring(selectionEndIndex)

                        const length = text?.length

                        setSelectionStartIndex(length)
                        setSelectionEndIndex(length)
                      }

                      onChange && onChange(str?.replace(/\s+/g, ' '))
                    }}
                  >
                    {t}
                  </Tag>
                ))}
              </Row>
            )}

            <Divider />
          </div>
        ))}
        {action == 'guoming' && <div className={styles.title}>过敏反应</div>}
        <Row
          style={{
            margin: '10px 0',
          }}
        >
          {periodlist?.map((t: any, i: number) => (
            <Tag
              key={i}
              style={{
                cursor: 'pointer',
                marginBottom: 10,
              }}
              onClick={() => {
                let str = ''
                if (
                  selectionStartIndex == 0 ||
                  selectionStartIndex >= value?.length
                ) {
                  str = value ? value + ' ' + t : t
                } else {
                  const text =
                    value?.substring(0, selectionStartIndex) +
                    (value[selectionStartIndex - 1] == ' ' ? '' : ' ') +
                    t +
                    ' '
                  str = text + value?.substring(selectionEndIndex)

                  const length = text?.length

                  setSelectionStartIndex(length)
                  setSelectionEndIndex(length)
                }

                if (
                  (selectedTags.length || inputValue) &&
                  action != 'guoming'
                ) {
                  onChange && onChange(str?.replace(/\s+/g, ' '))
                }
                if (action == 'guoming') {
                  onChange && onChange(str?.replace(/\s+/g, ' '))
                }
              }}
            >
              {' '}
              {t}
            </Tag>
          ))}
        </Row>
        {action == 'guoming' && (
          <>
            <Divider />
            <div className={styles.title}>过敏程度</div>
          </>
        )}
        <Row
          style={{
            margin: '10px 0',
          }}
        >
          {degreeList?.map((t: any, i: number) => (
            <Tag
              key={i}
              style={{
                cursor: 'pointer',
                marginBottom: 10,
              }}
              onClick={() => {
                let str = ''
                if (
                  selectionStartIndex == 0 ||
                  selectionStartIndex >= value?.length
                ) {
                  str = value ? value + ' ' + t : t
                } else {
                  const text =
                    value?.substring(0, selectionStartIndex) +
                    (value[selectionStartIndex - 1] == ' ' ? '' : ' ') +
                    t +
                    ' '
                  str = text + value?.substring(selectionEndIndex)
                  const length = text?.length

                  setSelectionStartIndex(length)
                  setSelectionEndIndex(length)
                }
                if (
                  (selectedTags.length || inputValue) &&
                  action != 'guoming'
                ) {
                  onChange && onChange(str?.replace(/\s+/g, ' '))
                }
                if (action == 'guoming') {
                  onChange && onChange(str?.replace(/\s+/g, ' '))
                }
              }}
            >
              {t}
            </Tag>
          ))}
        </Row>
      </div>
      <Row
        justify='center'
        align='middle'
        style={{
          backgroundColor: '#F7F7F7',
          padding: '10px 0',
          color: theme.c1,
          cursor: 'pointer',
          position: 'relative',
        }}
        onClick={() => {
          setIsVisible(false)
        }}
      >
        <div>
          <CloseCircleOutlined />
          &ensp; 关 闭
        </div>
        {action == 'xianbing' ? (
          <div
            onClick={() => {
              setIsModalVisible(true)
            }}
          >
            <SettingOutlined
              style={{
                position: 'absolute',
                right: '20px',
                bottom: '14px',
              }}
            />
          </div>
        ) : (
          <></>
        )}
      </Row>
    </div>
  )

  const handleChange = (tag: string, checked: boolean) => {
    if (!selectedTags.some((v: any) => v.name == tag)) {
      setCurrentTag(tag)
      setSelectedTags([tag])
      const content: any = value
      let chiefComplaint = ''
      if (selectionStartIndex == 0 || selectionStartIndex >= content?.length) {
        chiefComplaint = content ? content + '，' + tag : tag
      } else {
        const keywords = [
          '偶尔',
          '1天',
          '2天',
          '3天',
          '4天',
          '5天',
          '1周',
          '2周',
          '3周',
          '1个月',
          '2个月',
          '3个月',
          '半年',
          '1年',
        ]

        const str = content?.substring(
          Number(selectionStartIndex),
          Number(selectionStartIndex + 3)
        )
        const containsKeyword = keywords.some((keyword) =>
          str.includes(keyword)
        )

        const text =
          content?.substring(0, selectionStartIndex) +
          (content[selectionStartIndex - 1] == '，' ? '' : '，') +
          tag +
          (content[selectionStartIndex] == '，' || containsKeyword ? ' ' : '，')
        chiefComplaint = text + content?.substring(selectionEndIndex)

        const length = text?.length

        setSelectionStartIndex(length)
        setSelectionEndIndex(length)
      }
      onChange && onChange(chiefComplaint?.replace(/\s+/g, ' '))
    }
  }

  const handleChangeKQ = (tag: string, checked: boolean, i: number) => {
    if (!selectedTags.some((v: any) => v.name == tag)) {
      setCurrentTag(tag)
      setSelectedTags([tag])
      setCurrentFlagNumber(i)

      const content: any = value
      let chiefComplaint = ''
      if (selectionStartIndex == 0 || selectionStartIndex >= content?.length) {
        chiefComplaint = content
          ? content +
            (i == 5 || i == 6 || (i == 0 && content) ? ' ，' : '  ') +
            tag +
            (i == 5 || i == 6 ? '  ' : '')
          : tag
        console.log(i, chiefComplaint, 'hhhhhhhhh')
      } else {
        const keywords = [
          '偶尔',
          '1天',
          '2天',
          '3天',
          '4天',
          '5天',
          '1周',
          '2周',
          '3周',
          '1个月',
          '2个月',
          '3个月',
          '半年',
          '1年',
        ]

        const str = content?.substring(
          Number(selectionStartIndex),
          Number(selectionStartIndex + 3)
        )
        const containsKeyword = keywords.some((keyword) =>
          str.includes(keyword)
        )

        const text =
          content?.substring(0, selectionStartIndex) +
          (content[selectionStartIndex - 1] == '，'
            ? ''
            : i == 5 || i == 6 || (i == 0 && content)
            ? '，'
            : '') +
          tag +
          ' '

        chiefComplaint = text + content?.substring(selectionEndIndex)

        const length = text?.length

        console.log(text,length,"texttexttext")
        setSelectionStartIndex(length)
        setSelectionEndIndex(length)
      }
      onChange && onChange(chiefComplaint?.replace(/\s+/g, ' '))
    }
  }

  return (
    <>
      <Dropdown
        overlay={overlay}
        visible={isVisible}
        trigger={['click']}
        overlayStyle={{
          width: 400,
          boxShadow:
            ' 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)',
        }}
        placement='bottomCenter'
        align={{
          overflow: {
            adjustX: false,
            adjustY: false,
          },
        }}
        className={styles.chiefComplaintContainer}
        getPopupContainer={(triggerNode: any) => triggerNode.parentNode}
        onVisibleChange={(visible) => setIsVisible(visible)}
      >
        <Input.TextArea
          ref={inputRef}
          autoSize
          placeholder={placeholder ? placeholder : '请输入内容'}
          value={value}
          style={style}
          onClick={(e: any) => {
            const startPos = e.target.selectionStart // 获取鼠标点击时的光标位置
            const endPos = e.target.selectionStart // 获取鼠标点击时的光标位置

            setSelectionStartIndex(startPos)
            setSelectionEndIndex(endPos)

            if (!e.target.value) {
              setIsVisible(true)
            }
            if (action == 'zhusu') {
              setInputValue(e.target.value)
            } else {
              setInputValue('')
            }
          }}
          onChange={(e) => {
            if (action == 'zhusu') {
              setInputValue(e.target.value)
            } else {
              setInputValue('')
            }
            onChange && onChange(e.target.value)
            setIsVisible(false)
          }}
          onBlur={() => {
            onBlur && onBlur()
          }}
          // onKeyDown={(event: xianbingDataany) => {
          //   if (event.keyCode === 17) {
          //     setIsVisible(!isVisible);
          //   }
          //   if (event.keyCode === 13) {
          //     onChange(event.target.value);
          //   }
          // }}
        />
      </Dropdown>
      <SetXianBingEntryModal
        visible={isModalVisible}
        xianbingDataList={xianBingList}
        onCancel={() => {
          setIsModalVisible(false)
          setXianBingList([])
        }}
        onOk={(data: any) => {
          const params = data.map((item: any, index: any) => {
            return {
              id: item.id,
              name: item.name,
              sort: index + 1,
              type: 1,
            }
          })
          dispatch(saveXianbingEntry(params))
            .then(unwrapResult)
            .then((res) => {
              notification.success({
                message: '操作成功',
              })
              setIsModalVisible(false)
              getXianBingList()
            })
        }}
        onRefresh={() => {
          getXianBingList()
        }}
      />
    </>
  )
}
