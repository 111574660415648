import { unwrapResult } from '@reduxjs/toolkit'
import { Col, Form, Input, Modal, notification, Select } from 'antd'
import React, { useState, useEffect, useRef, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { getAllDoctorList } from '../A-toothModule/toothHome/toothHomeSlice'
import { getDoctorsTimeStepAsync } from '../registration/register/registerSlice'
import { getAppointmentScheduleTimeAsync, getScheduleDeactivatedAsync } from './appointmentSelice'

interface TemporarySuspensionModalProps {
  visible: boolean
  scheduleDate?: any
  onOk: () => void
  onCancel: () => void
  refreshList: () => void
}
export const TemporarySuspensionModal = ({
  visible,
  scheduleDate,
  onOk,
  onCancel,
  refreshList,
}: TemporarySuspensionModalProps) => {
  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const { Option } = Select

  const { TextArea } = Input

  const [doctorList, setDoctorList] = useState<any>([]) //  医生数据

  const [modalBtnLoading, setModalBtnLoading] = useState(false) //  弹窗确定按钮 loading

  const [startTimeList, setStartTimeList] = useState([]) //预约时间-开始

  const [endTimeList, setEndTimeList] = useState<any>() //预约时间-结束

  const getAllDoctor = (id?: string) => {
    //获取医生数据
    dispatch(
      getAllDoctorList({
        size: 1000,
        state: 1,
        stationCategory: 1,
      })
    )
      .then(unwrapResult)
      .then(({ records }) => {
        setDoctorList(
          records
            .filter((v: any) => v.kssxbz != 1)
            .filter((v: any) => v.state === 1)
        ) // 筛选已注销科室的医生
      })
  }

  useEffect(() => {
    if (visible) {
      getAllDoctor()
    }
  }, [visible])

  return (
    <Modal
      title='添加停诊'
      width={500}
      visible={visible}
      onCancel={() => {
        onCancel()
        form.resetFields()
        setModalBtnLoading(false)
      }}
      confirmLoading={modalBtnLoading}
      onOk={() => {
        form.submit()
        setModalBtnLoading(false)
      }}
      centered
    >
      <div>
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          onFinish={(values) => {
            const params = {
              doctorId: values?.doctorId,
              startTime: values?.time?.startTime,
              endTime: values?.time?.endTime,
              deactivativeCause: values?.deactivativeCause,
              scheduleDate: scheduleDate,
            }
            dispatch(getScheduleDeactivatedAsync(params))
              .then(unwrapResult)
              .then((v: any) => {
                notification.success({
                  message: '保存成功',
                })
                onOk()
                refreshList()
                form.resetFields()
                setModalBtnLoading(false)
              })
              .catch(() => {
                // onCancel()
                // form.resetFields()
                setModalBtnLoading(false)
              })
          }}
        >
          <Col span={24}>
            <Form.Item
              name='doctorId'
              label='停诊医生'
              rules={[{ required: true }]}
            >
              <Select
                placeholder='请选择'
                allowClear
                onChange={(e) => {
                  setStartTimeList([])
                  setEndTimeList([])
                  const params = {
                    doctorId: e,
                    scheduleDate: scheduleDate,
                  }
                  dispatch(getAppointmentScheduleTimeAsync(params))
                    .then(unwrapResult)
                    .then((res: any) => {
                      console.log(res, 'res?.[0]')

                      setEndTimeList(res?.[0]?.endTimes)
                      setStartTimeList(res?.[0]?.startTimes)
                    })
                }}
              >
                {doctorList?.map((d: any) => (
                  <Select.Option key={d.id} value={d.id}>
                    {d.realname}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} style={{ height: '56px' }}>
            <Form.Item
              label='停诊时间'
              name='time'
              rules={[{ required: true, message: ' ' }]}
            >
              <Input.Group compact>
                <Form.Item
                  name={['time', 'startTime']}
                  rules={[{ required: true, message: '请选择时间' }]}
                >
                  <Select style={{ width: '134px' }}>
                    {startTimeList?.length &&
                      startTimeList.map((v: any) => (
                        <Option key={v} value={v}>
                          {v}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
                &nbsp;&nbsp; ~ &nbsp;&nbsp;
                <Form.Item
                  name={['time', 'endTime']}
                  rules={[{ required: true, message: '请选择时间' }]}
                >
                  <Select style={{ width: '134px' }}>
                    {endTimeList?.length &&
                      endTimeList?.map((v: any) => (
                        <Option key={v} value={v}>
                          {v}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name='deactivativeCause' label='停诊原因'>
              <TextArea placeholder='请选择' allowClear></TextArea>
            </Form.Item>
          </Col>
        </Form>
      </div>
    </Modal>
  )
}
