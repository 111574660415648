import { Col, Popover, TableColumnType } from 'antd'
import moment from 'moment'
import React, { ReactElement } from 'react'
import { getChrgitmLvName } from '../../../../models/commodity'
import { DateTimeFormatSimple } from '../../../../models/datetime'
import { Material } from '../../../../models/material'
import { ThemeData } from '../../../../theme/ThemeContext'
import styles from './columns.module.css'

export const RecipeItemColumns = (
  theme: ThemeData
): TableColumnType<Material>[] => {
  return [
    {
      title: '药品信息',
      key: 'name',
      className: styles.name,
      render: function MaterialName(_, m): ReactElement {
        return (
          // <Popover
          //   overlayClassName={styles.popverContent}
          //   zIndex={999999}
          //   placement='top'
          //   content={
          //     <>
          //       <div
          //         style={{
          //           color: theme.tc1,
          //           width: 420,
          //           fontSize: '1.125rem',
          //           fontWeight: 'bold',
          //         }}
          //       >
          //         {m.name}
          //       </div>
          //       <div
          //         style={{
          //           color: theme.tc3,
          //           width: 420,
          //           fontSize: '1rem',
          //         }}
          //       >
          //         {m.spec}/{m.packSpec}/{m.accountUnit}/{m.doseCategory}/
          //         {m.tenantSupplierName}
          //       </div>
          //     </>
          //   }
          // >
          <Col style={{ cursor: 'pointer' }}>
            <div
              style={{
                color: theme.tc1,
                width: 420,
                fontSize: '1.125rem',
                fontWeight: 'bold',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {m.name}
            </div>
            <div
              title={
                m.spec +
                '/' +
                m.packSpec +
                '/' +
                m.accountUnit +
                '/' +
                m.doseCategory +
                '/' +
                m.tenantSupplierName
              }
              style={{
                color: theme.tc3,
                width: 420,
                fontSize: '1rem',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {m.spec}/{m.packSpec}/{m.accountUnit}/{m.doseCategory}/
              {m.tenantSupplierName}
            </div>
          </Col>
          // </Popover>
        )
      },
    },
    {
      title: '库房',
      dataIndex: 'storehouseName',
      align: 'center',
    },
    {
      title: '库存',
      key: 'count',
      align: 'center',
      render: (_, m) => (m.count ? `${m.count}${m.accountUnit}` : '/'),
    },
    {
      title: '拆零',
      key: 'split',
      align: 'center',
      render: (_, m) =>
        m.splitTag === 1 && m.splitCount
          ? `${m.splitCount}${m.splitUnit}`
          : '/',
    },
    { title: '单价/元', dataIndex: 'retailPrice', align: 'right' },
    {
      title: '等级',
      dataIndex: 'chrgitmLv',
      align: 'center',
      render: (_, m: any) => (m.chrgitmLv ? getChrgitmLvName(m.chrgitmLv) : ''),
    },
    {
      title: '备注',
      key: 'remark',
      align: 'center',
      dataIndex: 'remark',
      // render: (_, m) => (m.priceLimit > 0 ? `${m.remark} 医保限价：${m.priceLimit}` : `${m.remark}`),
      render: function Remark(_, m): ReactElement {
        return (
          <div
            title={
              `${m.remark}` +
              (m.priceLimit > 0 && m.priceLimit < 99999
                ? ` 医保限价：${m.priceLimit}`
                : '')
            }
            className={styles.remarkStyle}
          >
            {`${m.remark}` +
              (m.priceLimit > 0 && m.priceLimit < 99999
                ? ` 医保限价：${m.priceLimit}`
                : '')}
          </div>
        )
      },
    },
  ]
}

export const RecipeItemColumnsWithBatchNo = (
  theme: ThemeData,
  scene?: string
): TableColumnType<Material>[] => {
  const columns: TableColumnType<Material>[] = [
    {
      title: '药品信息',
      key: 'name',
      className: styles.name,
      render: function MaterialName(_, m): ReactElement {
        return (
          // <Popover
          //   overlayClassName={styles.popverContent}
          //   zIndex={999999}
          //   placement='top'
          //   content={
          //     <>
          //       <div
          //         style={{
          //           width: 420,
          //           color: theme.tc1,
          //           fontSize: '1.125rem',
          //           fontWeight: 'bold',
          //         }}
          //       >
          //         {m.name}
          //       </div>
          //       <div
          //         style={{
          //           width: 420,
          //           color: theme.tc2,
          //           fontSize: '1rem',
          //         }}
          //       >
          //         {m.spec}/{m.packSpec}/{m.accountUnit}/{m.doseCategory}/
          //         {m.tenantSupplierName}
          //       </div>
          //     </>
          //   }
          // >
          <Col style={{ cursor: 'pointer' }}>
            <div
              style={{
                width: 420,
                color: theme.tc1,
                fontSize: '1.125rem',
                fontWeight: 'bold',
                whiteSpace: 'nowrap',
              }}
            >
              {m.name}
            </div>
            <div
              title={
                m.spec +
                '/' +
                m.packSpec +
                '/' +
                m.accountUnit +
                '/' +
                m.doseCategory +
                '/' +
                m.tenantSupplierName
              }
              style={{
                width: 420,
                color: theme.tc2,
                fontSize: '1rem',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {m.spec}/{m.packSpec}/{m.accountUnit}/{m.doseCategory}/
              {m.tenantSupplierName}
            </div>
          </Col>
          // </Popover>
        )
      },
    },
    {
      title: '批次/有效期至',
      key: 'batch',
      render: function Batch(_, m): ReactElement {
        const deadline = moment(m.deadline)
        const duration = moment.duration(deadline.diff(moment()))
        const warning = deadline.isValid() && duration.asDays() <= 90
        const days = duration.asDays()
        return (
          <Col>
            <div
              style={{
                color: theme.tc1,
                fontSize: '1rem',
                whiteSpace: 'nowrap',
              }}
            >
              批次号：{m.batchNo}
            </div>
            <div
              style={{
                color: warning ? theme.e : days <= 180 ? '#027AFF' : theme.tc1,
                fontWeight: warning || days <= 180 ? 'bold' : 'normal',
                fontSize: '1rem',
              }}
            >
              {warning
                ? `有效期: ${Math.floor(duration.asDays())}天`
                : `有效期至：${
                    deadline.isValid()
                      ? deadline.format(DateTimeFormatSimple)
                      : '--'
                  }`}
            </div>
          </Col>
        )
      },
    },
    {
      title: '库房',
      dataIndex: 'storehouseName',
      align: 'center',
    },
    {
      title: '库存',
      key: 'count',
      align: 'center',
      render: (_, m) => (m.count ? `${m.count}${m.accountUnit}` : '/'),
    },
    {
      title: '拆零',
      key: 'split',
      align: 'center',
      render: (_, m) =>
        m.splitTag === 1 && m.splitCount
          ? `${m.splitCount}${m.splitUnit}`
          : '/',
    },
    {
      title: '单价/元',
      dataIndex: 'retailPrice',
      align: 'center',
      key: 'retailPrice',
    },
    {
      title: '进价',
      dataIndex: 'unitPrice',
      align: 'center',
      key: 'unitPrice',
    },
    {
      title: '等级',
      dataIndex: 'chrgitmLv',
      align: 'center',
      render: (_, m: any) => (m.chrgitmLv ? getChrgitmLvName(m.chrgitmLv) : ''),
    },
    {
      title: '备注',
      key: 'remark',
      align: 'center',
      dataIndex: 'remark',
      render: function Remark(_, m): ReactElement {
        return (
          <div
            title={
              `${m.remark}` +
              (m.priceLimit > 0 && m.priceLimit < 99999
                ? ` 医保限价：${m.priceLimit}`
                : '')
            }
            className={styles.remarkStyle}
          >
            {`${m.remark}` +
              (m.priceLimit > 0 && m.priceLimit < 99999
                ? ` 医保限价：${m.priceLimit}`
                : '')}
          </div>
        )
      },
    },
  ]

  //购入退库显示进价
  return scene === 'InputToReturn'
    ? columns.filter((c) => c.key !== 'retailPrice')
    : columns.filter((c) => c.key !== 'unitPrice')
}

export const RecipeItemColumnsSimple = (
  theme: ThemeData
): TableColumnType<Material>[] => {
  return [
    {
      title: '药品信息',
      key: 'name',
      className: styles.name,
      render: function MaterialName(_, m): ReactElement {
        return (
          // <Popover
          //   overlayClassName={styles.popverContent}
          //   zIndex={999999}
          //   placement='top'
          //   content={
          //     <>
          //       <div
          //         style={{
          //           width: 420,
          //           color: theme.tc1,
          //           fontSize: '1.125rem',
          //           fontWeight: 'bold',
          //         }}
          //       >
          //         {m.name}
          //       </div>
          //       <div
          //         style={{
          //           width: 420,
          //           color: theme.tc2,
          //           fontSize: '1rem',
          //         }}
          //       >
          //         {m.spec}/{m.packSpec}/{m.accountUnit}/{m.doseCategory}/
          //         {m.tenantSupplierName}
          //       </div>
          //     </>
          //   }
          // >
          <Col style={{ cursor: 'pointer' }}>
            <div
              style={{
                width: 420,
                color: theme.tc1,
                fontSize: '1.125rem',
                fontWeight: 'bold',
              }}
            >
              {m.name}
            </div>
            <div
              title={
                m.spec +
                '/' +
                m.packSpec +
                (m.model && m.materialsCategory == 1 ? `/${m?.model}` : '') +
                '/' +
                m.accountUnit +
                '/' +
                m.doseCategory +
                '/' +
                m.tenantSupplierName
              }
              style={{
                width: 420,
                color: theme.tc2,
                fontSize: '1rem',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {m.spec}/{m.packSpec}{m.model && m.materialsCategory == 1 ? '/' + m?.model : ''}
              /{m.accountUnit}/{m.doseCategory}/{m.tenantSupplierName}
            </div>
          </Col>
          // </Popover>
        )
      },
    },
    {
      title: '单价/元',
      dataIndex: 'retailPrice',
      align: 'right',
    },
    {
      title: '等级',
      dataIndex: 'chrgitmLv',
      align: 'center',
      render: (_, m: any) => (m.chrgitmLv ? getChrgitmLvName(m.chrgitmLv) : ''),
    },
    {
      title: '备注',
      key: 'remark',
      align: 'center',
      dataIndex: 'remark',
      render: function Remark(_, m): ReactElement {
        return (
          <div
            title={
              `${m.remark}` +
              (m.priceLimit > 0 && m.priceLimit < 99999
                ? ` 医保限价：${m.priceLimit}`
                : '')
            }
            className={styles.remarkStyle}
          >
            {`${m.remark}` +
              (m.priceLimit > 0 && m.priceLimit < 99999
                ? ` 医保限价：${m.priceLimit}`
                : '')}
          </div>
        )
      },
    },
  ]
}

export const RecipeItemColumnsSimpleBatchNo = (
  theme: ThemeData
): TableColumnType<Material>[] => {
  return [
    {
      title: '药品信息',
      key: 'name',
      className: styles.name,
      render: function MaterialName(_, m): ReactElement {
        return (
          <Popover
            overlayClassName={styles.popverContent}
            zIndex={999999}
            placement='top'
            content={
              <>
                <div
                  style={{
                    width: 420,
                    color: theme.tc1,
                    fontSize: '1.125rem',
                    fontWeight: 'bold',
                  }}
                >
                  {m.name}
                </div>
                <div
                  style={{
                    width: 420,
                    color: theme.tc2,
                    fontSize: '1rem',
                  }}
                >
                  {m.spec}/{m.packSpec}/{m.accountUnit}/{m.doseCategory}/
                  {m.tenantSupplierName}
                </div>
              </>
            }
          >
            <Col style={{ cursor: 'pointer' }}>
              <div
                style={{
                  width: 420,
                  color: theme.tc1,
                  fontSize: '1.125rem',
                  fontWeight: 'bold',
                }}
              >
                {m.name}
              </div>
              <div
                style={{
                  width: 420,
                  color: theme.tc2,
                  fontSize: '1rem',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {m.spec}/{m.packSpec}/{m.accountUnit}/{m.doseCategory}/
                {m.tenantSupplierName}
              </div>
            </Col>
          </Popover>
        )
      },
    },
    {
      title: '批号',
      dataIndex: 'batchNo',
      align: 'center',
    },
    {
      title: '单价/元',
      dataIndex: 'retailPrice',
      align: 'right',
    },
    {
      title: '等级',
      dataIndex: 'chrgitmLv',
      align: 'center',
      render: (_, m: any) => (m.chrgitmLv ? getChrgitmLvName(m.chrgitmLv) : ''),
    },
    {
      title: '备注',
      key: 'remark',
      align: 'center',
      dataIndex: 'remark',
      render: function Remark(_, m): ReactElement {
        return (
          <div
            title={
              `${m.remark}` +
              (m.priceLimit > 0 && m.priceLimit < 99999
                ? ` 医保限价：${m.priceLimit}`
                : '')
            }
            className={styles.remarkStyle}
          >
            {`${m.remark}` +
              (m.priceLimit > 0 && m.priceLimit < 99999
                ? ` 医保限价：${m.priceLimit}`
                : '')}
          </div>
        )
      },
    },
  ]
}

export const RecipeItemMoreBatchNumColumns = (
  theme: ThemeData
): TableColumnType<Material>[] => {
  return [
    {
      title: '名称',
      key: 'name',
      className: styles.name,
      render: function MaterialName(_, m): ReactElement {
        return (
          <Popover
            overlayClassName={styles.popverContent}
            zIndex={999999}
            placement='top'
            content={
              <>
                <div
                  style={{
                    width: 420,
                    color: theme.tc1,
                    fontSize: '1.125rem',
                    fontWeight: 'bold',
                  }}
                >
                  {m.name}
                </div>
                <div
                  style={{
                    width: 420,
                    color: theme.tc2,
                    fontSize: '1rem',
                  }}
                >
                  {m.spec}/{m.packSpec}/{m.accountUnit}/{m.doseCategory}/
                  {m.tenantSupplierName}
                </div>
              </>
            }
          >
            <Col style={{ cursor: 'pointer' }}>
              <div
                style={{
                  width: 420,
                  color: theme.tc1,
                  fontSize: '1.125rem',
                  fontWeight: 'bold',
                }}
              >
                {m.name}
              </div>
              <div
                style={{
                  width: 420,
                  color: theme.tc2,
                  fontSize: '1rem',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {m.spec}/{m.packSpec}/{m.accountUnit}/{m.doseCategory}/
                {m.tenantSupplierName}
              </div>
            </Col>
          </Popover>
        )
      },
    },
  ]
}
