/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-14 09:57:36
 * @LastEditors: linxi
 * @LastEditTime: 2024-12-19 16:23:37
 */
import { Form, Input, Modal, Pagination, Row , notification} from 'antd'
import { TableRowSelection } from 'antd/lib/table/interface'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StockfloorParams } from '../../services/stockfloor'
import { DataType } from '../putstorage/putstorage'
import {
  selectCurrent,
  selectTotal,
  selectStockfloorModalData,
  pageStockfloorEdit,
  setCurrent,
  setStockfloorlogoutlistAsync,
  setData,
} from './stockfloorModalSlice'
import { Columns } from './columns'
import styles from './stockfloorModal.module.css'
import { StockfloorModalQuery } from './query'
import { DeliveryItem } from '../delivery/deliverySlice'
import { EditableList } from '../../compnents/list/EditableList'
import { RootDispatch } from '../../app/store'
import { unwrapResult } from '@reduxjs/toolkit'
// import { StockfloorQuery } from "./query";

export const StockfloorModal = (props: {
  visible?: boolean
  onOk: () => void
  onCancel: () => void
}) => {
  const dispatch = useDispatch<RootDispatch>()

  const [size, setSize] = useState(10)

  const data = useSelector(selectStockfloorModalData)

  const Current = useSelector(selectCurrent)

  const Total = useSelector(selectTotal)

  const [Ids, setIds] = useState<any>([])

  const [visible, setVisible] = useState(false)

  const [inventoryAlertNum, setIventoryAlertNum] = useState<any>('')

  useEffect(() => {
    setSize(size ? size : 10)
    dispatch(setCurrent(Current ? Current : 1))
  }, [size, Current])

  function onShowSizeChange(current: any, pageSize: any) {
    setSize(pageSize)
    dispatch(setCurrent(current))
  }
  const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>(
    'checkbox'
  )
  const [queries, setQueries] = useState<StockfloorParams>({
    flag: 0,
  })
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      setIds(selectedRowKeys)
    },
  }

  const handleChange = (prev: any, next: any) => {
    const newdata = {
      ...prev,
      ...next,
      id: prev.id,
    }
    dispatch(
      setData(data.map((v: any) => (v.id === prev.id ? newdata : { ...v })))
    )
  }

  useEffect(() => {
    if (Current && size) {
      dispatch(
        pageStockfloorEdit({
          ...queries,
          current: Current || 1,
          size: size || 10,
        })
      )
    }
  }, [Current, size, queries, props?.visible])

  return (
    <Modal
      footer={null}
      title='批量设置库存下限'
      visible={props.visible}
      okText='确认'
      cancelText='取消'
      className={styles.form}
      destroyOnClose
      onOk={() => {
        props.onOk()
        setIds([])
      }}
      onCancel={() => {
        props.onCancel()
        setIds([])
      }}
    >
      <StockfloorModalQuery
        onValueChange={(v: any) => {
          setQueries(v)
        }}
        onclick={() => {
          if(Ids?.length) {
            setVisible(true)
          } else {
            notification.info({
              message: '请选择要修改的药品！',
            })
          }
          
        }}
      />
      <div className={styles.editTableContent}>
        <EditableList<DeliveryItem>
          style={{
            height: '60%',
            overflowY: 'scroll',
            marginBottom: '50px',
          }}
          dataSource={data}
          rowSelection={
            {
              type: selectionType,
              ...rowSelection,
            } as TableRowSelection<any>
          }
          columns={Columns((text, t) => {
            if (text === 'submit') {
              handleChange(t.prev, t.next)
              if (t.next.inventoryAlert >= 0)
                dispatch(
                  setStockfloorlogoutlistAsync({
                    id: t.prev.id,
                    inventoryAlert: t.next.inventoryAlert,
                  })
                )
                  .then(unwrapResult)
                  .then(() => {
                    dispatch(
                      pageStockfloorEdit({ ...queries, current: Current, size })
                    )
                  })
            }
          }, size * (Current - 1))}
        />
      </div>
      <Row style={{ justifyContent: 'flex-end' }}>
        <Pagination
          current={Current}
          total={Total}
          pageSize={size}
          defaultCurrent={1}
          // pageSizeOptions={['5', '10', '15', '20']}
          showSizeChanger
          showQuickJumper
          onChange={(current, size) => {
            onShowSizeChange(current, size)
          }}
          showTotal={(total) => `共 ${total} 条`}
          style={{
            float: 'right',
          }}
        />
      </Row>

      <Modal
        title='批量修改下限值'
        visible={visible}
        okText='确认'
        cancelText='取消'
        destroyOnClose
        onOk={() => {
          let newData:any = [...data]
          Ids?.forEach((v:any) => {
            newData = newData.map((t: any) => (v === t.id ? {...t, inventoryAlert: inventoryAlertNum} : { ...t }))
            if (inventoryAlertNum >= 0)
                dispatch(
                  setStockfloorlogoutlistAsync({
                    id: v,
                    inventoryAlert: inventoryAlertNum,
                  })
                )
                  .then(unwrapResult)
                  .then(() => {
                    dispatch(
                      pageStockfloorEdit({ ...queries, current: Current, size })
                    )
                  })
          });
          dispatch(setData(newData))
          setVisible(false)
          setIventoryAlertNum('')
        }}
        onCancel={() => {
          setIventoryAlertNum('')
          setVisible(false)
        }}
        width={400}
        mask={false}
      >
        <Form>
          <Form.Item label='库存下限'>
            <Input value={inventoryAlertNum} onChange={(e) => setIventoryAlertNum(e.target.value)} />
          </Form.Item>
        </Form>
      </Modal>

    </Modal>
  )
}
