/* eslint-disable no-control-regex */
/* eslint-disable prefer-const */
/* eslint-disable no-extra-boolean-cast */
import { message, notification } from 'antd'
import axios from 'axios'
import { TextPlain } from '../services/request'
import {CREDENTIAL_KEY} from "../app/applicationSlice";
// import CryptoJS from 'crypto-js'

export const getTimeOut = (code: string) => {
  switch (code) {
    case '2207A':
    case '2207':
    case '1163':
      return 1000 * 60 * 5
    case '2204':
    case '1101' || '1161':
      return 1000 * 10
    case '1162':
      return 1000 * 60
    case '9001':
    case '9002':
    case '2206A' || '2206':
      return 1000 * 15
    case '2203':
      return 1000 * 5
    case '9102':
      return 1000 * 10 * 60
    case 'dzfpOpen':
      return 1000 * 8
    case '7101':
      return 1000 * 60 * 5
    case '1312':
      return 1000 * 60 * 12
    default:
      return 1000 * 15
  }
}

//
// export const getSignature = (aksk: string,ts:string) => {
//   const ak = aksk.split("####")[0];
//   const sk = aksk.split("####")[1];
//
//   const param = "_api_access_key=" + ak + "&_api_name=" + "hssServives" + "&_api_timestamp=" + "1642491303393" + "&_api_version=" + "1.0.0";
//   const sha1 = CryptoJS.HmacSHA1(param,ak);
//   const sig = CryptoJS.enc.Base64.stringify(sha1);
//   return sig;
// }

export const whiteList = ['3301', '3302','3101','3102']

export const success = (str: string, resolve?: any) => {
  setTimeout(() => {
    message.destroy()
    resolve && resolve()
  }, 1000)
  if (str && str != '库存初始化') {
    notification.success({
      message: str + '成功',
      duration: 1,
    })
  }
}

export const noSuccess = (
  res: any,
  data: any,
  reject?: any,
  infno?: string
) => {
  setTimeout(() => {
    if (res.message || res.notification) {
      message.destroy()
    }
    const errMessage =
      data?.netmessage.err_msg !== undefined
        ? data?.netmessage.err_msg
        : data?.netmessage
    reject && reject(errMessage)

    if (!whiteList.find((v) => v === infno)) {
      notification.error({
        message: errMessage,
        duration: timeOut(errMessage),
      })
    }
  }, 1000)
}

// 其他前置机交易服务
export const MbfeService = async (
    tradeType:any,
    res: any,
    callback: (call: any, err?: boolean) => void,
    resolve?: any,
    reject?: any
) => {
  if (res.message) {
    message.loading('正在进行' + res.message, 0)
  }
  if (res.notification) {
    message.loading('正在' + res.notification, 0)
  }
  await axios({
    url: 'http://127.0.0.1:9955',
    proxy: {
      host: 'http://127.0.0.1',
      port: 9955,
    },
    // responseType: 'text',
    headers: {
      'Content-Type': TextPlain,
    },
    withCredentials: false,
    method: 'POST',
    data: JSON.stringify({
      trade_name: tradeType,
      trade_type: 'TRADE',
      trade_args: res.trade_args,
    }),
    timeout: getTimeOut(res.trade_args[0]),
  })
  .then((result: any) => {
    let { data } = result
    if (typeof data === 'string') {
      data = strParse(data, res.trade_args[0])
    }

    if (res.noSuccess) {
      callback(data)
    } else {
      if (data.netcode === '1') {
        if (data.netmessage.infcode) {
          if (Number(data.netmessage.infcode) === 0) {
            // message.destroy()
            //success(res.message || res.notification)
            callback(data)
          } else {
            noSuccess(res, data, reject, res.trade_args[0])
            callback(data, true)
          }
        } else {
          //success(res.message || res.notification)
          resolve && resolve()
          callback(data)
        }
      } else {
        if(data.netmessage?.ybflag==="FATAL"){
          notification.error({
            message: data.netmessage.ybmessage ,
            // message: ' ' + err,
            duration: 3,
          })
        }else{
          noSuccess(res, data, reject, res.trade_args[0])
          callback(data, true)
        }

      }
    }
  })
  .catch((err) => {
    message.destroy()
    callback(err, true)
    reject && reject(err)
    notification.error({
      message: '请检查通讯前置机是否打开!' + err,
      // message: ' ' + err,
      duration: 3,
    })
  })
  .finally(() => {
    message.destroy()
  })
}


// 医保接口服务
export const medicalSendHttp = async (
  res: any,
  callback: (call: any, err?: boolean) => void,
  resolve?: any,
  reject?: any
) => {
  const tradeType = getTradeName();
  if(!tradeType || tradeType == null){
    return;
  }
  const cycleCode = sessionStorage.getItem('cycleCode')
  if (cycleCode || res.trade_args[0] === '9001') {
    if (res.message && res.message !='库存初始化') {
      message.loading('正在进行' + res.message, 0)
    }
    if (res.notification && res.notification !='库存初始化') {
      message.loading('正在' + res.notification, 0)
    }
    const ts = Math.round(new Date().getTime()/1000).toString();

    await axios({
      url: 'http://127.0.0.1:9955',
      proxy: {
        host: 'http://127.0.0.1',
        port: 9955,
      },
      // responseType: 'text',
      headers: {
        'Content-Type': TextPlain,
      },
      withCredentials: false,
      method: 'POST',
      data: JSON.stringify({
        trade_name: tradeType,
        trade_type: 'TRADE',
        trade_args: res.trade_args,
      }),
      timeout: getTimeOut(res.trade_args[0]),
    })
      .then((result: any) => {
        let { data } = result
        if (typeof data === 'string') {
          data = strParse(data, res.trade_args[0])
        }

        if (res.noSuccess) {
          callback(data)
        } else {
          if (data.netcode === '1') {
            if (
              String(data?.netmessage.err_msg).indexOf(
                '根据传来的参数在费用明细表中未查询到任何有效相关的记录'
              ) > 0 ||
              String(data?.netmessage.err_msg).indexOf(
                '异地就医费用明细信息不能为空'
              ) > 0 ||
              String(data?.netmessage.err_msg).indexOf('没有该目录匹配信息') > 0
            ) {
              callback(data)
              return
            }
            if (data.netmessage.infcode) {
              if (Number(data.netmessage.infcode) === 0) {
                // message.destroy()
                success(res.message || res.notification)
                callback(data)
              } else {
                if(res.trade_args[0] == "3301" && (data?.netmessage?.err_msg?.indexOf("请勿插入重复的主键")>=0)){
                  callback(data)
                }
                if(res.trade_args[0]=="2207A" && (data?.netmessage?.err_msg?.indexOf("同一批费用明细信息不可重复结算")>=0 || data?.netmessage?.err_msg?.indexOf("就诊顺序号无法重复")>=0)){
                  const errMessage = "由于网络问题出现单边账，该笔医保结算已经成功，请联系客服人员进行补账或者冲正操作。";
                  notification.error({
                    message: errMessage,
                    duration: timeOut(errMessage),
                  })
                } else if(res.trade_args[0]=="2206A" && (data?.netmessage?.err_msg?.indexOf("就诊顺序号不允许重复")>=0 || data?.netmessage?.err_msg?.indexOf("就诊顺序号无法重复")>=0)){
                  if (location.pathname == '/treatment') {
                    const errMessage = "由于网络问题出现单边账，该笔订单医保已经收费完成。请到【收费】——【待收费】中重新尝试收费，或联系管理员处理。";
                    notification.error({
                      message: errMessage,
                      duration: timeOut(errMessage),
                    })
                  }else{
                    const errMessage = "系统正在自动补账，请等待，若长时间未响应请联系管理员。";
                    notification.info({
                      message: errMessage,
                      duration: timeOut(errMessage),
                    })
                    callback(data,true)

                  }

                }else {
                  if(res.trade_args[0]!="3201"&&res.trade_args[0]!="3202"&&res.trade_args[0]!="3101"&&res.trade_args[0]!="3102"){
                    if(res.trade_args[0]=="4101A" && data?.netmessage?.err_msg?.indexOf("以上传记录且状态为:1")>=0){
                      callback(data, true)
                    }else{
                      noSuccess(res, data, reject, res.trade_args[0])
                      callback(data, true)
                    }
                  }else{
                    noSuccess(res, data, reject, res.trade_args[0])
                    callback(data, true)
                  }
                }


              }
            } else {
              success(res.message || res.notification)
              resolve && resolve()
              callback(data)
            }
          } else {
            noSuccess(res, data, reject, res.trade_args[0])
            callback(data, true)
          }
        }
      })
      .catch((err) => {
        message.destroy()
        callback(err.message, true)
        reject && reject(err)
        notification.error({
          message: '请检查医保前置机是否打开!' + err,
          // message: ' ' + err,
          duration: 3,
        })
      })
      .finally(() => {
        message.destroy()
      })
  } else {
    message.destroy()
    callback({}, true)
    notification.warn({
      message: '请先签到',
      duration: 1.5,
    })
  }
}

// 杭州医保人脸服务
export const medicalFaceSendHttp = async (
  res: any,
  callback: (call: any, err?: boolean, message?: string) => void,
  resolve?: any,
  reject?: any
) => {
  const tradeType = getTradeName();
  if(!tradeType || tradeType == null){
    return;
  }
  const cycleCode = sessionStorage.getItem('cycleCode')
  if (res.message) {
    message.loading('正在进行' + res.message, 0)
  }
  if (res.notification) {
    message.loading('正在' + res.notification, 0)
  }
  await axios({
    url: 'http://127.0.0.1:9955',
    proxy: {
      host: 'http://127.0.0.1',
      port: 9955,
    },
    // responseType: 'text',
    headers: {
      'Content-Type': TextPlain,
    },
    withCredentials: false,
    method: 'POST',
    data: JSON.stringify({
      trade_name: tradeType,
      trade_type: 'TRADE',
      trade_args: res.trade_args,
    }),
    timeout: 180 * 1000,
  })
    .then((result: any) => {
      let { data } = result
      if (typeof data === 'string') {
        data = strParse(data, res.trade_args[0])
      }
      // if (res.noSuccess) {
      //   callback(data)
      // } else {
      if (data.netcode === '1') {
        switch (data.netmessage?.infcode) {
          case 0:
            callback(data, false, '人脸验证不通过，请重新发起结算并验证!')
            break
          case 1:
          case 2:
            callback(data)
            break
          case -1:
            callback(
              data,
              false,
              '人脸验证不通过，入参格式不正确，请联系管理员，点击确定后，本次结算可以继续！'
            )
            break
          case -2:
            callback(
              data,
              false,
              '【实名制】入参为空，请联系管理员，点击确定后，本次结算可以继续！'
            )
            break
          case -3:
            callback(
              data,
              false,
              '【实名制】超时，请联系管理员，点击确定后，本次结算可以继续！'
            )
            break
          case -4:
            callback(
              data,
              false,
              '人脸验证不通过，未装实名制监管客户端软件，请联系管理员，点击确定后，本次结算可以继续！'
            )
            break
          case -9:
            callback(
              data,
              false,
              '人脸识别结果获取失败，请联系管理员，点击确定后，本次结算可以继续！'
            )
            break
          case -10:
            callback(
              data,
              false,
              '【实名制】其他错误，请联系管理员，点击确定后，本次结算可以继续！'
            )
            break
          default:
            callback(
              data,
              false,
              '人脸识别结果获取失败，请联系管理员，点击确定后，本次结算可以继续！'
            )
            break
        }
      } else {
        noSuccess(res, data, reject)
        callback(
          data,
          false,
          '人脸识别结果获取失败，请联系管理员，点击确定后，本次结算可以继续！'
        )
      }
      // }
    })
    .catch((err) => {
      message.destroy()
      callback(err, true)
      reject && reject(err)
      notification.error({
        message: '请检查医保前置机是否打开!' + err,
        duration: 3,
      })
    })
    .finally(() => {
      message.destroy()
    })
}

// 人脸识别医生考勤查询接口
export const doCheckDoctorAttendance = async (
    res: any,
    callback: (call: any, err?: boolean) => void,
    resolve?: any,
    reject?: any
) => {
  const tradeType = getTradeName();
  if(!tradeType || tradeType == null){
    return;
  }
  const cycleCode = sessionStorage.getItem('cycleCode')
  if (res.message) {
    message.loading('正在进行' + res.message, 0)
  }
  if (res.notification) {
    message.loading('正在' + res.notification, 0)
  }
  await axios({
    url: 'http://127.0.0.1:9955',
    proxy: {
      host: 'http://127.0.0.1',
      port: 9955,
    },
    // responseType: 'text',
    headers: {
      'Content-Type': TextPlain,
    },
    withCredentials: false,
    method: 'POST',
    data: JSON.stringify({
      trade_name: tradeType,
      trade_type: 'TRADE',
      trade_args: res.trade_args,
    }),
    timeout: 180 * 1000,
  })
  .then((result: any) => {
    let { data } = result
    if (typeof data === 'string') {
      data = strParse(data, res.trade_args[0])
    }
    if (data.netcode === '1') {
      callback(data)
    } else {
      noSuccess(res, data, reject)
      callback(data, true)
    }
  })
  .catch((err) => {
    message.destroy()
    callback(err, true)
    reject && reject(err)
    notification.error({
      message: '请检查医保前置机是否打开!' + err,
      duration: 3,
    })
  })
  .finally(() => {
    message.destroy()
  })
}


// 丽水医保人脸服务
export const LSMedicalFaceSendHttp = async (
  res: any,
  callback: (call: any, err?: boolean, message?: string) => void,
  resolve?: any,
  reject?: any
) => {
  const tradeType = getTradeName();
  if(!tradeType || tradeType == null){
    return;
  }
  const cycleCode = sessionStorage.getItem('cycleCode')
  if (res.message) {
    message.loading('正在进行' + res.message, 0)
  }
  if (res.notification) {
    message.loading('正在' + res.notification, 0)
  }
  await axios({
    url: 'http://127.0.0.1:9955',
    proxy: {
      host: 'http://127.0.0.1',
      port: 9955,
    },
    // responseType: 'text',
    headers: {
      'Content-Type': TextPlain,
    },
    withCredentials: false,
    method: 'POST',
    data: JSON.stringify({
      trade_name: tradeType,
      trade_type: 'TRADE',
      trade_args: res.trade_args,
    }),
    timeout: 180 * 1000,
  })
    .then((result: any) => {
      let { data } = result
      callback(data, true)
    })
    .catch((err) => {
      message.destroy()
      callback(err, true)
      reject && reject(err)
      notification.error({
        message: '请检查医保前置机是否打开!' + err,
        duration: 3,
      })
    })
    .finally(() => {
      message.destroy()
    })
}
// 衢州医保人脸服务
export const QZMedicalFaceSendHttp = async (
  res: any,
  callback: (call: any, err?: boolean, message?: string) => void,
  resolve?: any,
  reject?: any
) => {
  const tradeType = getTradeName();
  if(!tradeType || tradeType == null){
    return;
  }
  const cycleCode = sessionStorage.getItem('cycleCode')
  if (res.message) {
    message.loading('正在进行' + res.message, 0)
  }
  if (res.notification) {
    message.loading('正在' + res.notification, 0)
  }
  await axios({
    url: 'http://127.0.0.1:9955',
    proxy: {
      host: 'http://127.0.0.1',
      port: 9955,
    },
    // responseType: 'text',
    headers: {
      'Content-Type': TextPlain,
    },
    withCredentials: false,
    method: 'POST',
    data: JSON.stringify({
      trade_name: tradeType,
      trade_type: 'TRADE',
      trade_args: res.trade_args,
    }),
    timeout: 180 * 1000,
  })
    .then((result: any) => {
      let { data } = result
      if (typeof data === 'string') {
        data = strParse(data, res.trade_args[0])
      }
      // if (res.noSuccess) {
      //   callback(data)
      // } else {
      if (data.netcode === '1') {
        switch (data.netmessage?.infcode) {
          case 0:
            callback(data)
            break
          default:
            callback(
              data,
              false,
              data.netmessage?.authMessage || '人脸识别结果获取失败,请重新识别！'
            )
            break
        }
      } else {
        noSuccess(res, data, reject)
        callback(data, true)
      }
      // }
    })
    .catch((err) => {
      message.destroy()
      callback(err, true)
      reject && reject(err)
      notification.error({
        message: '请检查医保前置机是否打开!' + err,
        duration: 3,
      })
    })
    .finally(() => {
      message.destroy()
    })
}


// CA签章服务
export const CWCAService = async (
  res: any,
  callback: (call: any, err?: boolean) => void

) => {
  const loadingMessage = message.loading('请求中...', 0); // 显示 loading 消息
  try {
    const result = await axios({
      url: 'http://127.0.0.1:23623',
      proxy: {
        host: 'http://127.0.0.1',
        port: 23623,
      },
      headers: {
        'Content-Type': 'text/plain', // 确保这里是正确的字符串
      },
      withCredentials: false,
      method: 'POST',
      data: res,
      timeout: 180 * 1000,
    });

    callback(result?.data, false);
  } catch (err) {
    callback(err, true);
  } finally {
    loadingMessage(); // 结束 loading 消息
    message.destroy(); // 销毁消息
  }
}
let _xmlhttp:any;
export const AjaxIO = (json:any) => {
  let _url = 'http://127.0.0.1:23623/';
  if (_xmlhttp == null) {
    if (window.XMLHttpRequest) {
      // code for IE7+, Firefox, Chrome, Opera, Safari
      _xmlhttp = new XMLHttpRequest();
    } else {
      // code for IE6, IE5
      notification.error({
        message: '当前浏览器不支持',
      })
    }
  }
  if ('https:' == document.location.protocol) {
    _url = 'https://127.0.0.1:23624/';
  }

  /*
    _xmlhttp.open('OPTIONS', _url, false);
    _xmlhttp.send();
  */

  _xmlhttp?.open('POST', _url, false); //false-同步
  _xmlhttp?.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
  _xmlhttp?.send('json=' + JSON.stringify(json));

}
export const GetHttpResult = () =>{
  if (_xmlhttp.readyState == 4 && _xmlhttp.status == 200) {
    return eval('(' + _xmlhttp.responseText + ')');
  } else{
    notification.info({ message: 'CA服务通信失败，请联系客服人员为您开通并安装后再试。' });
  }
  return null;
}
type ServiceResult = 'Exception' | 'Fail' | any; // 根据需要定义更具体的类型
export function InvokeCWCAService2  (): ServiceResult  {
  return 'Fail';
}
//加载CA动态库（HTTP）
export function InvokeCWCAService  (json:any): ServiceResult  {
  try {
    AjaxIO(json);
  } catch (e) {
    notification.error({
      message: '发送异常：' + e,
    })
    return 'Exception';
  }
  const ret = GetHttpResult();
  if (ret) {
    return ret;
  }
  return 'Fail';
}

// 报价器服务
export const BaoJiaQi = async (
  res: any,
) => {
  const tradeType = "COMMON";
  if(!tradeType || tradeType == null){
    return;
  }
  await axios({
    url: 'http://127.0.0.1:9955',
    proxy: {
      host: 'http://127.0.0.1',
      port: 9955,
    },
    headers: {
      'Content-Type': TextPlain,
    },
    withCredentials: false,
    method: 'POST',
    data: JSON.stringify({
      trade_name: tradeType,
      trade_type: 'TRADE',
      trade_args: res.trade_args,
    }),
    timeout: 180 * 1000,
  })
}

// 医保前置电子凭证服务
export const readCardEHttp = async (
  res: any,
  callback: (call: any, err?: boolean) => void
) => {
  const tradeType = getTradeName();
  if(!tradeType || tradeType == null){
    return;
  }

  const cycleCode = sessionStorage.getItem('cycleCode')
  if (cycleCode || res.trade_args[0] === '9001') {
    if(tradeType === 'CQ_ZHYB'){
      //重庆地区没有单独的电子凭证前置交易，直接调用1162接口即可（为了兼容性需要，返回一个固定的不会报错的串）
      const data = {netmessage:"0|0|0|0|0|"}
      callback(data);
    }else{
      if(location.pathname == "/registration"||
          location.pathname == "/toothPatient"||
          location.pathname == "/toothHome"){
        res.trade_args[1] = "01101";
      }
      await axios({
        url: 'http://127.0.0.1:9955',
        proxy: {
          host: 'http://127.0.0.1',
          port: 9955,
        },
        responseType: 'text',
        headers: {
          'Content-Type': TextPlain,
        },
        withCredentials: false,
        method: 'POST',
        data: JSON.stringify({
          trade_name: tradeType,
          trade_type: 'DZPZ',
          trade_args: res.trade_args,
        }),
        timeout: 1000 * 60,
      })
          .then((result) => {
            let { data } = result
            if (typeof data === 'string') {
              data = strParse(data)
            }
            if (data.netcode === '1') {
              if (data.netmessage.code) {
                // if (data.netmessage.code >= 0) {
                //   callback(data)
                // } else {
                notification.error({
                  message:
                      data.netmessage.message !== undefined
                          ? data.netmessage.message
                          : data.netmessage,
                })
                // }
              } else {
                callback(data)
              }
            } else {
              callback(data, true)
              notification.error({
                message:
                    data.netmessage.message !== undefined
                        ? data.netmessage.message
                        : data.netmessage,
              })
            }
          })
          .catch((err) => {
            callback(err, true)
            message.destroy()
            notification.error({
              message: '请检查医保前置机是否打开!' + err,
            })
          })
          .finally(() => {
            message.destroy()
          })
    }
  } else {
    callback({}, true)
    notification.warn({
      message: '请先签到',
      duration: 2,
    })
  }
}



// 医保前置实体卡服务
export const readCardSHttp = async (
  res: any,
  callback: (call: any, err?: boolean) => void
) => {
  const tradeType = getTradeName();
  if(!tradeType || tradeType == null){
    return;
  }
  const cycleCode = sessionStorage.getItem('cycleCode')
  if (cycleCode || res.trade_args[0] === '9001') {
    const argsPlus = [];
    if(tradeType === 'JS_ZHYB'){
      const credentialsInfo = JSON.parse(
          localStorage.getItem(CREDENTIAL_KEY) || '{}'
      )
      const insuranceItem = credentialsInfo.insuranceArray[0];
      if(!insuranceItem || insuranceItem.insuranceCode !="JIANGSU"){
        notification.error({
          message: '险种配置不正确，请联系管理员处理!' ,
          // message: err,
        })
        return;
      }
      res.trade_args.length = 0;
      res.trade_args.push(insuranceItem.countryHospitalNb);
      res.trade_args.push(insuranceItem.secretToken);
      res.trade_args.push(insuranceItem.insuranceCuntryCode);
      await axios({
        url: 'http://127.0.0.1:9955',
        proxy: {
          host: 'http://127.0.0.1',
          port: 9955,
        },
        responseType: 'text',
        headers: {
          'Content-Type': TextPlain,
        },
        withCredentials: false,
        method: 'POST',
        data: JSON.stringify({
          trade_name: tradeType,
          trade_type: 'READCARD',
          // trade_args: ['ZJ_NB_315000'],
          trade_args:  res.trade_args,
        }),
        timeout: 6000,
      })
        .then((result) => {
          let { data } = result
          if (typeof data === 'string') {
            data = strParse(data)
          }
          if (data.netcode === '1') {
            if (data.netmessage.indexOf("###")>=0) {
              callback(data)
            } else {
              callback(data, true)
              notification.error({
                message:'读卡失败，读卡器返回的内容为：' + data.netmessage,
              })
            }
          } else {
            callback(data, true)
            notification.error({
              // message:
              //   '请检查医保前置机是否打开!5' +
              //   (data.netmessage.Message !== undefined
              //     ? data.netmessage.Message
              //     : data.Message),
              message:
                  ' ' + data.netmessage.Message !== undefined
                      ? data.netmessage.Message
                      : data.Message,
            })
          }
        })
        .catch((err) => {
          callback(err, true)
          message.destroy()
          notification.error({
            message: '请检查医保前置机是否打开!' + err,
            // message: err,
          })
        })
        .finally(() => {
          message.destroy()
        })
    }else if(tradeType === 'CQ_ZHYB'){
      //重庆地区没有单独的电子凭证前置交易，直接调用1162接口即可（为了兼容性需要，返回一个固定的不会报错的串）
      const data = {netmessage:{"CanOpen":true,"CardIDCode":"0","CardNum":"0","CardType":"3","Gender":"","IDNum":"0","Name":"","PsamTN":"0","Success":true,"flag":"1","ylbxtcqbm":"0"}}
      callback(data);
    }else{
      await axios({
        url: 'http://127.0.0.1:9955',
        proxy: {
          host: 'http://127.0.0.1',
          port: 9955,
        },
        responseType: 'text',
        headers: {
          'Content-Type': TextPlain,
        },
        withCredentials: false,
        method: 'POST',
        data: JSON.stringify({
          trade_name: tradeType,
          trade_type: 'READCARD',
          // trade_args: ['ZJ_NB_315000'],
          trade_args:  res.trade_args,
        }),
        timeout: 6000,
      })
        .then((result) => {
          let { data } = result
          if (typeof data === 'string') {
            data = strParse(data)
          }
          if (data.netcode === '1') {
            if (data.netmessage.Success) {
              callback(data)
            } else {
              callback(data, true)
              notification.error({
                // message:
                //   '请检查医保前置机是否打开!4' +
                //   (data.netmessage.Message !== undefined
                //     ? data.netmessage.Message
                //     : data.Message),
                message:
                    ' ' + data.netmessage.Message !== undefined
                        ? data.netmessage.Message
                        : data.Message,
              })
            }
          } else {
            callback(data, true)
            notification.error({
              // message:
              //   '请检查医保前置机是否打开!5' +
              //   (data.netmessage.Message !== undefined
              //     ? data.netmessage.Message
              //     : data.Message),
              message:
                  ' ' + data.netmessage.Message !== undefined
                      ? data.netmessage.Message
                      : data.Message,
            })
          }
        })
        .catch((err) => {
          callback(err, true)
          message.destroy()
          notification.error({
            message: '请检查医保前置机是否打开!' + err,
            // message: err,
          })
        })
        .finally(() => {
          message.destroy()
        })
    }
  } else {
    callback({}, true)
    notification.warn({
      message: '请先签到',
      duration: 2,
    })
  }
}

// 医保前置机ping状态
export const pingTargeHttp = async (callback: any) => {
  const tradeType = getTradeName();
  if(!tradeType || tradeType == null){
    callback(false)
  }
  await axios({

    url: 'http://127.0.0.1:9955',
    proxy: {
      host: 'http://127.0.0.1',
      port: 9955,
    },
    responseType: 'text',
    headers: {
      'Content-Type': TextPlain,
    },
    withCredentials: false,
    method: 'POST',
    data: JSON.stringify({
      trade_name: tradeType,
      trade_type: 'TRADE',
      trade_args: ['PING'],
    }),
    timeout: 10000,
  })
    .then((result) => {
      const { data } = result
      if (data.netcode === '1') {
        return callback(true)
      } else {
        return callback(false)
      }
    })
    .catch((err) => {
      return callback(false)
    })
}

export const strParse = (str: string, code?: string) => {
  if(!str){
    return "";
  }
  const parseStr = str
    .replace(new RegExp('"\\[', 'g'), '[')
    .replace(new RegExp('\\]"', 'g'), ']')
    .replace(new RegExp('"{', 'g'), '{')
    .replace(new RegExp('}"', 'g'), '}')
    .replace(new RegExp('\r\n', 'g'), '')
    .replace(new RegExp('\n', 'g'), '')
    .replace(/\\"/g, '"')
    
    .replace(/\\"/g, '"')
    .replace('<!DOCTYPE HTML PUBLIC "-//IETF//DTD HTML 2.0//EN"', '<!DOCTYPE HTML PUBLIC -//IETF//DTD HTML 2.0//EN')
      .replace('"white"', 'white')

  if (code === '10000') {
    return eval('(' + str + ')')
  }else if(code ==='dzfpOpen'){
    return JSON.parse(parseStr.replace("\\\"","\""))
  } else {
    let result ;
    try{
      result = JSON.parse(parseStr)
    }catch(e){
      const newParseStr = parseStr.replace(/\\/g, "").replace(new RegExp('\u0014', 'g'), '').replace(new RegExp('', 'g'), '')
        .replace(/:"",/g, ':"空值",').replace(/:""}/g, ':"空值"}')
        .replace(/:""/g, ':"').replace(/"",/g, '",')
        .replace(/:"空值",/g, ':"",').replace(/:"空值"}/g, ':""}')
    
      result = JSON.parse(newParseStr);
    }
    return result;
  }
}

// 延时时间
export const timeOut = (str: string): number => {
  if (str.length) {
    const time = Math.ceil(str.length / 10)
    if (time < 3) {
      return 3
    } else if (time > 8) {
      return 8
    } else {
      return time
    }
  } else {
    return 2.5
  }
}

// 延时时间
export const getTradeName = (): any => {
  const credentialsInfo = JSON.parse(
      localStorage.getItem(CREDENTIAL_KEY) || '{}'
  )

  if(!credentialsInfo || !credentialsInfo.insuranceArray || credentialsInfo.insuranceArray == null || credentialsInfo.insuranceArray.length != 1){
    notification.error({
      message: '医保配置错误，无法进行医保交易，请联系管理员确认或重新登录后再试！',
    })
    return ;
  }
  if( credentialsInfo.insuranceArray[0].insuranceCode === 'ZHEJIANG'){
    return 'ZJ_ZHYB';
  }else if(credentialsInfo.insuranceArray[0].insuranceCode === 'CHONGQING'){
    return 'CQ_ZHYB';
  }else if(credentialsInfo.insuranceArray[0].insuranceCode === 'JIANGSU'){
    return 'JS_ZHYB';
  }else{
    notification.error({
      message: '医保配置错误，未知的险种：'+credentialsInfo.insuranceArray[0].insuranceCode,
    })
    return ;
  }
}