/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2025-01-06 13:43:55
 * @LastEditors: linxi
 * @LastEditTime: 2025-01-06 15:03:10
 */
import { Form, Space, Switch, TableColumnType } from "antd";
import moment from "moment";
import { CategoryName } from "../../../models/category";
import { Dimen } from "../../../models/dimen";

export const ReceivingColumns = (
  onClick: (text: string, id: any) => void,
  startIndex: number
): TableColumnType<any>[] => {
  return [
    {
      title: "序号",
      align: "center",
      key: "no",
      width: Dimen.Num,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
        title: "业务单号",
        align: "center",
        dataIndex: "putNumber",
        width: Dimen.PutNumber,
        key: "putNumber",
        fixed: 'left',
        sorter: (a: any, b: any) => a.putNumber - b.putNumber,
      },
    {
      title: "供应商名称",
      align: "center",
      dataIndex: "supplierName",
      width: Dimen.SupplierName,
      key: "supplierName",
    },
    {
      title: "业务类别",
      align: "center",
      key: "category",
      width: Dimen.Category,
      render: (_, t) => {
        return CategoryName (t.category);
      },
    },
    {
      title: "发生日期",
      align: "center",
      dataIndex: "upAccountTime",
      width: Dimen.Time,
      key: "upAccountTime",
      render: (_, t) => {
        const upAccountTime = (t.upAccountTime).substring(0, 10);
        return upAccountTime;
      },
      sorter: (a: any, b: any) =>
      moment(a.upAccountTime).unix() - moment(b.upAccountTime).unix(),
    },
    {
      title: "业务部门",
      align: "center",
      dataIndex: "departmentName",
      width: Dimen.Department,
      key: "departmentName",
    },
    {
      title: "业务人员",
      align: "center",
      dataIndex: "userName",
      width: Dimen.Name,
      key: "userName",
      
    },
    {
        title: "应付金额(元)",
        align: "right",
        dataIndex: "shouldPayMoney",
        width: Dimen.Price,
        key: "shouldPayMoney",
        sorter: (a: any, b: any) => a.shouldPayMoney - b.shouldPayMoney,
      },
      
  ];
};
