/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-13 10:49:00
 * @LastEditors: linxi
 * @LastEditTime: 2025-01-06 14:23:17
 */
import { unwrapResult } from '@reduxjs/toolkit'
import { Col, notification, Table } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { DateRangeType, getDateRange } from '../../compnents/form/DateSelect'
import { EditableList } from '../../compnents/list/EditableList'
import { NavBar } from '../../compnents/nav/NavBar'
import {
  DateTimeFormat,
  DateTimeFormatSimple,
  DateTimeFormatSimpleOnlyOne,
} from '../../models/datetime'
import { DurgSalesParams } from '../../services/durgSales'
import { DuresalesQuery } from './DuresalesQuery'
import styles from './durgsales.module.css'
import { DurgsalesColumns } from './DurgsalesColumns'
import {
  getDurgsales,
  selectDurgSalesCurrent,
  selectDurgSalesData,
  selectDurgSalesTotal,
  selectmaterialsCategory,
  selectOtctag,
  setDurgSaleCurrent,
  getDurgsalesExoprt,
  selectPageLoading,
  setPageLoading,
} from './DurgsalesSlice'

export const DurgSales = () => {
  const dispatch = useDispatch<RootDispatch>()

  const [size, setSize] = useState(10)

  const data = useSelector(selectDurgSalesData)

  const total = useSelector(selectDurgSalesTotal)

  const current = useSelector(selectDurgSalesCurrent)

  const otc = useSelector(selectOtctag)

  const materialsCategory = useSelector(selectmaterialsCategory)

  const [queries, setQueries] = useState<DurgSalesParams>({
    // timeHead: moment().startOf("day").format(DateTimeFormatSimple),
    // timeTail: moment().endOf("day").format(DateTimeFormatSimple),
  })
  const [cell, setCell] = useState<any>({}) //合计栏

  const pageLoadingRX = useSelector(selectPageLoading)

  const [head, tail] = getDateRange(DateRangeType.Today)

  const [releasingTimeFlag, setReleasingTime] = useState(false)

  useEffect(() => {
    setReleasingTime(false)
  }, [])
  

  useEffect(() => {
    dispatch(setPageLoading(true))
    if (queries?.timeHead && queries?.timeTail)
      dispatch(
        getDurgsales({ ...queries, current, size, materialsCategory, otc })
      )
    setTimeout(() => {
      dispatch(setPageLoading(false))
    }, 3000)
  }, [current, size, queries, materialsCategory, otc])

  useEffect(() => {
    if (data.length == 0) {
      setCell({})
    } else {
      const tempArr = data.filter((item: any) => {
        return item.totalRetailPrice != -1
      })
      setCell(tempArr[0])
    }
  }, [data])
  
  const outCluedExport = () => {
    dispatch(
      getDurgsalesExoprt({
        ...queries,
        timeHead: moment(queries?.timeHead).format(DateTimeFormatSimpleOnlyOne),
        timeTail: moment(queries?.timeTail).format(DateTimeFormatSimpleOnlyOne),
        current,
        size: 10000,
        materialsCategory,
        otc,
        releasingTime: releasingTimeFlag ? '99' : undefined,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        const content = res
        const blob = new Blob([content])
        const fileName = '药品销售记录.xlsx'
        const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL']
        const elink = document.createElement('a')
        if ('download' in elink) {
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = selfURL['createObjectURL'](blob)
          document.body.appendChild(elink)
          // 触发链接
          elink.click()
          selfURL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          navigator.msSaveBlob(blob, fileName)
        }
      })
      .then(() => {
        notification.success({
          message: '导出成功',
          duration: 3,
        })
      })
      .catch((res: any) => {
        return
      })
  }
  return (
    <div className={styles.durgSales}>
      <Col
        style={{
          margin: '10px 20px',
          position: 'relative',
        }}
      >
        <NavBar
          where={['库房流水查询', '药品销售记录']}
          backtrace={{
            name: '库房工作站',
            path: '/storage/station',
            state: { station: '3' },
          }}
          buttons={<></>}
        />
        <div style={{ position: 'absolute', top: 9, right: 20 }}>
          备注：一次最多导出6个月的数据，如果您想导出更多数据，请分次导出。
        </div>
      </Col>
      <div
        style={{
          height: 'calc(100% - 70px)',
          margin: '10px 20px',
          padding: '20px',
          background: '#fff',
          borderRadius: '10px',
          position: 'relative',
        }}
      >
        <DuresalesQuery
          onValueChange={(v) => {
            setQueries({
              ...v,
              timeHead: v?.timeHead
                ? moment(v?.timeHead).format(DateTimeFormat)
                : moment(head).format(DateTimeFormat),
              timeTail: v?.timeTail
                ? moment(v?.timeTail).format(DateTimeFormat)
                : moment(tail).format(DateTimeFormat),
            })
          }}
          outClued={() => outCluedExport()}
          continueOrder={(v:any, type: string) => {
            if(type === 'djdd') {
              setQueries({ ...queries, ...v, showPotion: Number(v) })
            } else {
              setQueries({ ...queries, ...v, isAccurate: Number(v) })
            }
            dispatch(setDurgSaleCurrent(1))
          }}
          alertMessage={() => {
            alert('Service mode is open')
            setReleasingTime(true)
          }}
        />
        <EditableList
          style={{ height: 'calc(100% - 160px)', overflow: 'auto' }}
          loading={pageLoadingRX}
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row className={styles.total}>
                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                <Table.Summary.Cell className={styles.footingTitle} index={1}>
                  合计
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={4}></Table.Summary.Cell>
                <Table.Summary.Cell index={5}></Table.Summary.Cell>
                <Table.Summary.Cell index={6}></Table.Summary.Cell>
                <Table.Summary.Cell index={7}></Table.Summary.Cell>
                <Table.Summary.Cell index={8}></Table.Summary.Cell>
                <Table.Summary.Cell index={9}></Table.Summary.Cell>
                <Table.Summary.Cell index={13}></Table.Summary.Cell>
                <Table.Summary.Cell index={14}></Table.Summary.Cell>
                <Table.Summary.Cell align='center' index={10}>
                  {/* {cell.totalRetailPrice || '-'} */}
                </Table.Summary.Cell>
                <Table.Summary.Cell align='center' index={11}>
                  {cell.totalDrugCount || '-'}
                </Table.Summary.Cell>
                <Table.Summary.Cell align='center' index={12}>
                  {cell.totalDrugAmount || '-'}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={13}></Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
          rowClassName={(r: any) =>
            r.name === '合计' ? styles.total : ''
          }          rowKey={(_, i) =>
            queries
              ? (queries.size || 0) * (queries.current || 0) + (i || 0)
              : 0
          }
          page={{
            current: current,
            size: size,
            total,
            items: data,
          }}
          onChangePage={(current, size) => {
            setSize(size as number)
            dispatch(setDurgSaleCurrent(current))
          }}
          columns={DurgsalesColumns(size * (current - 1))}
          bordered
        />
      </div>
    </div>
  )
}
