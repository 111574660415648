import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { api, sendAsync } from '../../../app/applicationSlice'
import { RootState, RootThunk, RootThunkApi } from '../../../app/store'
import { LoadingState } from '../../../models/common'
import * as R from '../../../models/registration'
import { Registration } from '../../../models/registration'
import * as Tr from '../../../models/treatment'
import { Treatment } from '../../../models/treatment'
import { startTreatment as apiStartTreatment } from '../../../services/registration'
import {
  copyTreatment as apiCopyTreatment,
  CopyTreatmentParams,
  createTreatment as apiCreateTreatment,
  defaulTallergenList,
  defaultMainComplaintKQList,
  defaultMainComplaintList,
  defaultMaiXiangList,
  defaultSheXiangList,
  defaultXianBingList,
  deleteEntryFn,
  deleteEntryFnParams,
  getPatientCourse,
  getTreatment as apiGetTreatment,
  GetTreatmentDetailParams,
  getUrlLink,
  saveXianbingEntryFnParams,
  saveXianbingEntryFn,
  urlLinkParams,
} from '../../../services/treatment'
import { setTreatmentRegistration } from '../treatmentSlice'

interface TcmCaseState {
  registration?: Registration
  treatment?: Treatment
  creatingState: LoadingState
  zhuSuData?: any
  xianbingData?: any
  tigejianchaData?: any
  maiXiangData?: any
  sheXiangData?: any
  guoMingData?: any
  jiWangData?: any
  jiaZuData?: any
  geRenData?: any
}

const initialState = {
  registration: undefined,
  creatingState: LoadingState.Idle,
  zhuSuData: {},
  xianbingData: {},
  tigejianchaData:{},
  maiXiangData: {},
  sheXiangData: {},
  guoMingData: {},
  jiWangData: {},
  jiaZuData: {},
  geRenData: {},
} as TcmCaseState

export const createTreatment = createAsyncThunk<void, Treatment, RootThunkApi>(
  'TcmCase/createTreatmentStatus',
  async (treatment, api) => {
    return sendAsync(apiCreateTreatment(treatment), api).then(() => {
      // const id = api.getState().TcmCase.registration?.id;
      // if (id) {
      //   api.dispatch(getTreatment({ registrationId: id,medicalRecord:1 }));
      // }
    })
  }
)

const TcmCaseSlice = createSlice({
  name: 'TcmCase',
  initialState,
  reducers: {
    setRegistration: (state, action: PayloadAction<Registration>) => {
      state.registration = action.payload
    },
    setTreatment: (state, action: PayloadAction<Treatment | undefined>) => {
      state.treatment = action.payload
    },
    setTreatmentCreatingState: (state, action: PayloadAction<LoadingState>) => {
      state.creatingState = action.payload
    },
    resetTcmCase: (state) => {
      state.registration = undefined
      state.treatment = undefined
      state.creatingState = LoadingState.Idle
    },
    setZhuSuData: (state, action) => {
      state.zhuSuData = action.payload
    },
    setXianbingData: (state, action) => {
      state.xianbingData = action.payload
    },
    setTiGeJianChaData: (state, action) => {
      state.tigejianchaData = action.payload
    },
    setMaixiangData: (state, action) => {
      state.maiXiangData = action.payload
    },
    setShexiangData: (state, action) => {
      state.sheXiangData = action.payload
    },
    setGuoMingData: (state, action) => {
      state.guoMingData = action.payload
    },
    setJiWangData: (state, action) => {
      state.jiWangData = action.payload
    },
    setJiaZuData: (state, action) => {
      state.jiaZuData = action.payload
    },
    setGeRenData: (state, action) => {
      state.geRenData = action.payload
    },
  },
})

export const {
  setTreatmentCreatingState,
  resetTcmCase,
  setTreatment,
  setRegistration,
  setZhuSuData,
  setXianbingData,
  setTiGeJianChaData,
  setMaixiangData,
  setShexiangData,
  setGuoMingData,
  setJiWangData,
  setJiaZuData,
  setGeRenData,
} = TcmCaseSlice.actions

export function getTreatment(params: GetTreatmentDetailParams): RootThunk {
  return api(
    apiGetTreatment(params),
    (data: Record<string, never>, dispatch) => {
      const registration = R.fromJson(data.registration)
      dispatch(setRegistration(registration))
      dispatch(setTreatmentRegistration(registration))
      const treatment =
        JSON.stringify(data.treatment) !== '{}'
          ? Tr.fromJson(data.treatment)
          : undefined
      dispatch(setTreatment(treatment))
    }
  )
}

export function startTreatment(registrationId: string): RootThunk {
  return api(apiStartTreatment(registrationId), () => {
    // Do nothing.
  })
}

export const copyTreatment = createAsyncThunk<
  void,
  CopyTreatmentParams,
  RootThunkApi<void>
>('TcmCase/copyTreatmentStatus', async (params, api) => {
  return sendAsync(apiCopyTreatment(params), api).then(() => {
    // do nothing.
  })
})

export const getUrl = createAsyncThunk<void, urlLinkParams, RootThunkApi>(
  'TcmCase/getUrl',
  async (params, api) => {
    return sendAsync(getUrlLink(params), api)
  }
)

// 病程
export const getPatientCourseAsync = createAsyncThunk<
  void,
  string,
  RootThunkApi
>('TcmCase/getPatientCourseAsync', async (id, api) => {
  return sendAsync(getPatientCourse(id), api)
})

// 常用主诉列表
export const defaultMainComplaintListAsync = createAsyncThunk<
  any,
  void,
  RootThunkApi
>('TcmCase/defaultMainComplaintListAsync', async (_, api) => {
  return sendAsync(defaultMainComplaintList(), api)
})

// 现病列表
export const defaultXianBingListAsync = createAsyncThunk<
  any,
  number,
  RootThunkApi
>('TcmCase/defaultXianBingListAsync', async (type, api) => {
  return sendAsync(defaultXianBingList(type), api)
})

// 口腔常用主诉列表
export const defaultMainComplaintListKQAsync = createAsyncThunk<
  any,
  void,
  RootThunkApi
>('TcmCase/defaultMainComplaintListKQAsync', async (_, api) => {
  return sendAsync(defaultMainComplaintKQList(), api)
})

// 脉象列表
export const defaultMaiXiangListAsync = createAsyncThunk<
  any,
  void,
  RootThunkApi
>('TcmCase/defaultMaiXiangListAsync', async (_, api) => {
  return sendAsync(defaultMaiXiangList(), api)
})

// 脉象列表
export const defaultSheXiangListAsync = createAsyncThunk<
  any,
  void,
  RootThunkApi
>('TcmCase/defaultSheXiangList', async (_, api) => {
  return sendAsync(defaultSheXiangList(), api)
})

export const defaulTallergenListAsync = createAsyncThunk<
  void,
  string,
  RootThunkApi
>('TcmCase/defaulTallergenListAsync', async (flag, api) => {
  return sendAsync(defaulTallergenList(flag), api)
})

// 删除现病
export const deleteEntry = createAsyncThunk<
  void,
  deleteEntryFnParams,
  RootThunkApi<void>
>('/commodity/deleteEntry', async (params, api) => {
  return sendAsync(deleteEntryFn(params), api)
})

// 保存现病
export const saveXianbingEntry = createAsyncThunk<
  void,
  saveXianbingEntryFnParams[],
  RootThunkApi<void>
>('/commodity/saveXianbingEntry', async (params, api) => {
  return sendAsync(saveXianbingEntryFn(params), api)
})

export const selectRegistration = (
  state: RootState
): Registration | undefined => state.TcmCase.registration

export const selectTreatmentT = (state: RootState): Treatment | undefined =>
  state.TcmCase.treatment

export const selectTreatmentCreatingState = (state: RootState): LoadingState =>
  state.TcmCase.creatingState

export const selectZhuSuData = (state: RootState) => state.TcmCase.zhuSuData

export const selectXianbingData = (state: RootState) =>
  state.TcmCase.xianbingData

export const selectTiGeJianChaData = (state: RootState) =>
  state.TcmCase.tigejianchaData
  
export const selectMaixiangData = (state: RootState) =>
  state.TcmCase.maiXiangData

export const selectShexiangData = (state: RootState) =>
  state.TcmCase.sheXiangData

export const selectGuoMingData = (state: RootState) => state.TcmCase.guoMingData

export const selectJiWangData = (state: RootState) => state.TcmCase.jiWangData

export const selectJiaZuData = (state: RootState) => state.TcmCase.jiaZuData

export const selectGeRenData = (state: RootState) => state.TcmCase.geRenData

export default TcmCaseSlice.reducer
