/*
 * @Descripttion:
 * @version:
 * @Author: sujun
 * @email: 1373842098@qq.com
 * @Date: 2022-01-10 09:32:13
 * @LastEditors: linxi
 * @LastEditTime: 2024-12-25 14:51:27
 */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Button, Col, Form, Input, Row, Select, Space } from 'antd'
import React from 'react'
import { SearchOutlined } from '@ant-design/icons'
import {
  DateRangeType,
  DateSelect as DateSelectOne,
  getDateRange,
  RecentTime, RecentTimePotion,
} from '../../../compnents/date/TimeSelect'
import { replaceMakeDetailParams } from '../../../services/OReplaceMake'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { selectInsuranceArray } from '../../../app/applicationSlice'

export const Query = (props: {
  onValueChange: (queries: replaceMakeDetailParams) => void
  onExport: () => void
  print: () => void
}) => {
  const [form] = Form.useForm()

  const [head, tail] = getDateRange(DateRangeType.Today)

  const insuranceArray = useSelector(selectInsuranceArray)

  const recipeStatusOptions = [
    {
      value: -1,
      label: '全部',
    },
    {
      value: 0,
      label: '未上传',
    },
    {
      value: 1,
      label: '已上传',
    },
    {
      value: 3,
      label: '已取消',
    },
  ]

  const machiningTypeOptions = [
    {
      value: -1,
      label: '全部',
    },
    {
      value: 0,
      label: '委外代煎颗粒剂',
    },
    {
      value: 1,
      label: '委外代煎袋装液体',
    },
    {
      value: 2,
      label: '委外外配药材',
    },
    {
      value: 3,
      label: '委外膏方代煎',
    },
  ]

  return (
    <Form
      form={form}
      name='control-hooks'
      autoComplete='off'
      onFinish={(v) => {
        const time = {
          pullTimeHead: null,
          pullTimeTail: null,
          startTime: v?.pullTimeHead
            ? moment(v.pullTimeHead).format('YYYY-MM-DD HH:mm:ss')
            :  moment(head).format('YYYY-MM-DD HH:mm:ss'),
          endTime: v?.pullTimeTail
            ? moment(v.pullTimeTail).format('YYYY-MM-DD HH:mm:ss')
            :moment(tail).format('YYYY-MM-DD HH:mm:ss'),
        }
        props.onValueChange({ ...v, ...time })
      }}
      style={{ paddingBottom: '20px' }}
    >
      <Row>
        <Space style={{ flex: 1 }}>
          <Form.Item name='keyWord' noStyle>
            <Input
              size='middle'
              placeholder='药品名称'
              // onChange={form.submit}
              allowClear
              style={{ width: 200 }}
              prefix={<SearchOutlined />}
            />
          </Form.Item>
          <Form.Item name='machiningType' noStyle>
            <Select
              allowClear
              placeholder='代煎类别'
              onChange={form.submit}
              style={{ width: 220 }}
            >
              {machiningTypeOptions.map((c) => (
                <Select.Option key={c.label} value={c.value}>
                  代煎类别-{c.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {insuranceArray && insuranceArray.length !== 0 && (
            <Form.Item name='insuranceCode' noStyle>
              <Select
                placeholder='险种类型'
                style={{ width: 150 }}
                onChange={(key) => {
                  form.submit()
                }}
              >
                <Select.Option value={''}>全部</Select.Option>
                <Select.Option value={'ZIFEI'}>自费</Select.Option>
                {insuranceArray.map((v) => (
                  <Select.Option value={v.insuranceCode} key={v.id}>
                    {v.insuranceName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item name='recipeStatus' noStyle>
            <Select
              allowClear
              placeholder='上传状态'
              onChange={form.submit}
              style={{ width: 150 }}
            >
              {recipeStatusOptions.map((c) => (
                <Select.Option key={c.label} value={c.value}>
                  上传状态-{c.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item noStyle>
            <DateSelectOne
              placeholder='上传时间'
              options={RecentTimePotion}
              style={{ width: 150 }}
              labelPrefix=''
              onChange={form.submit}
              initialValue={DateRangeType.Today}
              namePrefix='pullTime'
            />
          </Form.Item>
          <Button htmlType='submit' type='primary'>
            查询
          </Button>
        </Space>
        <Space>
          <Button type='primary' onClick={() => props.print()}>
            打印
          </Button>
          <Button type='primary' onClick={() => props.onExport()}>
            导出
          </Button>
        </Space>
      </Row>
    </Form>
  )
}
