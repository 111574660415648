import { Col, Form, Input, Modal, ModalProps, Row, Select } from 'antd'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { DefaultUseMethod } from '../../../models/recipeusemethod'
import { source } from '../../../models/registration'
import { ThemeContext } from '../../../theme/ThemeContext'
import styles from './RecipeListEditor.module.css'
interface UseMethodModalProps {
  value?: string[]
  onChange: (values: string[], formValue?: any) => void
  elseRecipeUseMethod?: any
}

const K1 = ['水煎煮', '泡酒', '打粉', '制丸', '制膏', '装胶囊']

const K2 = ['内服', '外用', '泡脚', '代茶饮']

const K3 = ['温服', '凉服', '热服', '顿服', '慢服', '兑水冲服']

const K4 = ['饭前服', '饭后服', '空腹服', '热服捂汗','无服用要求']

export const UseMethodModal = ({
  value = DefaultUseMethod,
  onChange,
  elseRecipeUseMethod,
  ...others
}: UseMethodModalProps & ModalProps): ReactElement => {
  const theme = useContext(ThemeContext)

  const [selectedValues, setSelectedValues] = useState(value)
  const [fyyqDisplay, setFyyqDisplay] = useState('flex')

  const [form] = Form.useForm()

  const row = {
    gutter: 10,
    style: {
      color: theme.tc1,
      fontSize: '1rem',
    },
  }

  const col = {
    span: 4,
    style: {
      marginBottom: 10,
      lineHeight: '2rem',
      textAlign: 'center',
      fontSize: '1rem',
    } as React.CSSProperties,
  }

  const firstColumnLayout = {
    labelCol: {
      flex: '0 0 7rem',
    },
  }

  const btn = (selected: boolean) => ({
    style: {
      backgroundColor: selected ? theme.c1 : undefined,
      boxSize: 'border',
      border: selected ? undefined : `solid 1px ${theme.wbd}`,
      borderRadius: 2,
      color: selected ? theme.tt : theme.tc1,
      cursor: 'pointer',
    },
  })

  useEffect(() => {
    setSelectedValues(value)
    if (value[1] && value[1] === '外用') {
      setFyyqDisplay('none')
    } else {
      setFyyqDisplay('flex')
    }
  }, [value])

  useEffect(() => {
    if (fyyqDisplay == 'none') {
      const filterList = selectedValues.filter(
        (v) =>
          ![
            '温服',
            '凉服',
            '顿服',
            '慢服',
            '饭前服',
            '饭后服',
            '空腹服',
            '无服用要求',
          ].includes(v)
      )
      if (selectedValues?.length != filterList.length) {
        setSelectedValues(filterList)
      }
    }
  }, [selectedValues, fyyqDisplay])

  useEffect(() => {
    if (!others.visible) {
      form.resetFields()
      setSelectedValues(value)
      if (value[1] && value[1] === '外用') {
        setFyyqDisplay('none')
      } else {
        setFyyqDisplay('flex')
      }
    }else{
      form.setFieldsValue({
        recipeNormalFrequency: elseRecipeUseMethod?.recipeNormalFrequency
          ? elseRecipeUseMethod?.recipeNormalFrequency
          : '每日一帖',
        recipeNormalUsemethod: elseRecipeUseMethod?.recipeNormalUsemethod
          ? elseRecipeUseMethod?.recipeNormalUsemethod
          : '每帖一次',
        recipeNormalDose: elseRecipeUseMethod?.recipeNormalDose
          ? elseRecipeUseMethod?.recipeNormalDose
          : '每次100ml',
        remark: elseRecipeUseMethod?.remark || '',
      })
    }
  }, [others.visible])

  return (
    <Modal
      title='处方用法'
      {...others}
      width={720}
      onOk={() => {
        onChange && onChange(selectedValues, form.getFieldsValue())
      }}
    >
      <Row {...row}>
        <Col {...col}>采用剂型：</Col>
        {K1.map((v, i) => (
          <Col key={v} {...col} offset={i === 5 ? 4 : undefined}>
            <div
              {...btn(v === selectedValues[0])}
              onClick={() => {
                setSelectedValues([
                  v,
                  ...selectedValues.slice(1).filter((v) => v != '无服用要求'),
                ])
              }}
            >
              {v}
            </div>
          </Col>
        ))}
      </Row>
      <Row {...row}>
        <Col {...col}>用药方法：</Col>
        {K2.map((v) => (
          <Col key={v} {...col}>
            <div
              {...btn(v === selectedValues[1])}
              onClick={() => {
                if (v == '外用') {
                  setFyyqDisplay('none')
                } else {
                  setFyyqDisplay('flex')
                }

                setSelectedValues([
                  selectedValues[0],
                  v,
                  ...selectedValues.slice(2).filter((v) => v != '无服用要求'),
                ])
              }}
            >
              {v}
            </div>
          </Col>
        ))}
      </Row>
      <Row {...row} style={{ display: fyyqDisplay }} className={styles.fyyqFirst}>
        <Col {...col}>服用要求：</Col>
        {K3.map((v) => (
          <Col key={v} {...col}>
            <div
              {...btn(v === selectedValues[2])}
              onClick={() => {
                setSelectedValues([
                  ...selectedValues.slice(0, 2),
                  v,
                  ...selectedValues.slice(3).filter((v) => v != '无服用要求'),
                ])
              }}
            >
              {v}
            </div>
          </Col>
        ))}
      </Row>
      <Row {...row} style={{ display: fyyqDisplay }} className={styles.fyyq}>
        {K4.map((v, i) => (
          <Col key={v} {...col} offset={i === 0 ? 4 : undefined}>
            <div
              {...btn(v === selectedValues[3])}
              onClick={() => {
                if (v == '无服用要求') {
                  setSelectedValues(['', '', '', v])
                } else {
                  setSelectedValues([...selectedValues.slice(0, 3), v])
                }
              }}
            >
              {v}
            </div>
          </Col>
        ))}
      </Row>
      <Form form={form}>
        <Row wrap={false} align='middle' {...row} style={{ height: '50px' }}>
          <Col {...col}>处方剂型：</Col>
          <Form.Item name='recipeNormalFrequency' noStyle>
            <Select
              placeholder='请选择'
              allowClear
              style={{ width: '300px', marginRight: '10px' }}
              // suffixIcon={<CaretDownFilled style={{ color: theme.tc3 }} />}
            >
              <Select.Option value={'每日一帖'} key={'每日一帖'}>
                每日一帖
              </Select.Option>
              <Select.Option value={'每日两帖'} key={'每日两帖'}>
                每日两帖
              </Select.Option>
              <Select.Option value={'每日三帖'} key={'每日三帖'}>
                每日三帖
              </Select.Option>
              <Select.Option value={'每日四帖'} key={'每日四帖'}>
                每日四帖
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name='recipeNormalUsemethod' noStyle>
            <Select
              placeholder='请选择'
              allowClear
              style={{ width: '300px', marginRight: '10px' }}
              // suffixIcon={<CaretDownFilled style={{ color: theme.tc3 }} />}
            >
              <Select.Option value={'每帖一次'} key={'每帖一次'}>
                每帖一次
              </Select.Option>
              <Select.Option value={'每帖两次'} key={'每帖两次'}>
                每帖两次
              </Select.Option>
              <Select.Option value={'每帖三次'} key={'每帖三次'}>
                每帖三次
              </Select.Option>
              <Select.Option value={'每帖四次'} key={'每帖四次'}>
                每帖四次
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name='recipeNormalDose' noStyle style={{ flex: 1 }}>
            <Select
              placeholder='请选择'
              allowClear
              style={{ width: '300px', marginRight: '10px' }}
              // suffixIcon={<CaretDownFilled style={{ color: theme.tc3 }} />}
            >
              
              <Select.Option value={'每次50ml'} key={'每次50ml'}>
                每次50ml
              </Select.Option>
              <Select.Option value={'每次100ml'} key={'每次100ml'}>
                每次100ml
              </Select.Option>
              <Select.Option value={'每次120ml'} key={'每次120ml'}>
                每次120ml
              </Select.Option>
              <Select.Option value={'每次130ml'} key={'每次130ml'}>
                每次130ml
              </Select.Option>
              <Select.Option value={'每次140ml'} key={'每次140ml'}>
                每次140ml
              </Select.Option>
              <Select.Option value={'每次150ml'} key={'每次150ml'}>
                每次150ml
              </Select.Option>
              <Select.Option value={'每次160ml'} key={'每次160ml'}>
                每次160ml
              </Select.Option>
              <Select.Option value={'每次170ml'} key={'每次170ml'}>
                每次170ml
              </Select.Option>
              <Select.Option value={'每次180ml'} key={'每次180ml'}>
                每次180ml
              </Select.Option>
              <Select.Option value={'每次200ml'} key={'每次200ml'}>
                每次200ml
              </Select.Option>
            </Select>
          </Form.Item>
        </Row>
        <Row
          wrap={false}
          align='middle'
          {...row}
          style={{ height: '50px', marginBottom: '20px' }}
        >
          <Col {...col}>备注：</Col>
          <Form.Item label='' name='remark' {...firstColumnLayout} noStyle>
            <Input.TextArea
              maxLength={100}
              showCount
              style={{ width: '560px', marginTop: '32px' }}
            />
          </Form.Item>
        </Row>
      </Form>
    </Modal>
  )
}
