import {
  AlipayCircleFilled,
  CreditCardFilled,
  DollarCircleFilled,
  SearchOutlined,
  WechatFilled,
} from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  notification,
  Row,
  Select,
  Space,
  TableColumnType,
  Tooltip,
} from 'antd'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import {
  DateRangeType,
  DateSelect,
  getDateRange,
} from '../../compnents/form/DateSelect'
import { EditableList } from '../../compnents/list/EditableList'
import { traceRoute } from '../../layouts/layoutSlice'
import { Dimen } from '../../models/dimen'
import { getIconImg, getPayMethodName, PayMethod } from '../../models/payment'
import { getRecipeStatusName, RecipeStatus } from '../../models/recipe'
import { Refund } from '../../models/refund'
import { Retail } from '../../models/retail'
import { getGenderName, User } from '../../models/user'
import { GetRetailListParams } from '../../services/retail'
import { ThemeContext } from '../../theme/ThemeContext'
import { getFullUserList } from '../completion/completionSlice'
import {
  getPaymentDelete,
  getPaymentDetail,
  getPaymentDetail2,
  refund as refundPayment,
} from '../payment/list/paymentSlice'
import {
  getRetailListNew,
  selectRetailPage,
  setTreatmentId,
  JuhePremium,
  editName,
} from './retailSlice'
import {
  EditOutlined,
  JuhePay,
  MembersIcon,
  YBIcon,
} from '../../compnents/icons/Icons'
import {
  getPatientInfoAsync,
  registerValue,
  searchMedicalAsync,
} from '../registration/register/registerSlice'
import { getPatientDetail, getTreatmentDetail } from '../patient/patientSlice'
import { PaymentResult } from '../../models/paymentresult'
import {
  selectEdition,
  selectInsuranceArray,
  selectTenantAddress,
  selectTenantAreaCd,
  selectTenantCategory,
  selectTenantName,
  selectUserId,
  selectUserName,
} from '../../app/applicationSlice'
import {
  getTradeName,
  medicalSendHttp,
  readCardEHttp,
  readCardSHttp,
  strParse,
} from '../../utils/MedicalUtils'
import { getTradeCode } from '../../models/material'
import { getPaymentResults } from '../payment/result/paymentResultSlice'
import { cancelSettlementAsync } from '../payment/confirm/paymentConfirmSlice'
import { selectInsuranceItem } from '../../layouts/insuranceSlice'
import moment from 'moment'
import { DateTimeFormatSimpleOnlyOne } from '../../models/datetime'
import { setRecipeEditorTreatment } from '../treatment/editor/recipeListEditorSlice'
import { OralCases } from '../treatment/oralCases/OralCases'
import { RecipeListEditor } from '../treatment/editor/RecipeListEditor'
import { useHistory } from 'react-router-dom'
import {
  setActiveObj,
  updatePatientTimeAsync,
} from '../A-toothModule/patient/patientSlice'

export const RetailList = (props?: {
  goBackParams: (v: any) => void
}): ReactElement => {
  const history = useHistory()

  const hisVersion = useSelector(selectEdition) //his版本 0通用 1口腔

  const insuranceArray = useSelector(selectInsuranceArray)

  const [insuranceItem, setInsuranceItem] = useState<any>()

  const userName = useSelector(selectUserName)

  const userId = useSelector(selectUserId)

  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const [form] = Form.useForm()

  const page = useSelector(selectRetailPage)

  const [timeHead, timeTail] = getDateRange(DateRangeType.Today)

  useEffect(() => {
    if (insuranceArray.length !== 0) {
      setInsuranceItem(insuranceArray[0])
    }
  }, [insuranceArray])

  const [params, setParams] = useState<GetRetailListParams>({
    current: 1,
    payMethod: 0,
    timeHead: timeHead,
    timeTail: timeTail,
  })
  const tenantCategory = useSelector(selectTenantCategory)

  const cycleCode = sessionStorage.getItem('cycleCode')

  const [users, setUsers] = useState<User[]>([])

  const [loading, setLoading] = useState(false)

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  useEffect(() => {
    dispatch(getFullUserList({ current: 1, size: 1000, state: 1 }))
      .then(unwrapResult)
      .then((users) => setUsers(users))
      .catch(() => {
        // do nothing.
      })
  }, [])

  const tenantAddress = useSelector(selectTenantAddress)

  const tenantName = useSelector(selectTenantName)

  const [refund, setRefund] = useState<Refund | undefined>()

  const tenantAreaCd = useSelector(selectTenantAreaCd)

  const [paymentDetailRecordVOS, setPaymentDetailRecordVOS] = useState<any>()

  const [isHavingInsurance, setIsHavingInsurance] = useState(false)

  //0.取最新的偏好设置内容
  const localData: any = JSON.parse(
    localStorage.getItem('preferencesSettingData') || '{}'
  )

  useEffect(() => {
    setIsHavingInsurance(false)
    paymentDetailRecordVOS?.map((v: any) => {
      if (v.payMethod == '99') {
        setIsHavingInsurance(true)
      }
    })
  }, [paymentDetailRecordVOS])

  const getList = () => {
    setPageLoading(true)
    dispatch(getRetailListNew(params)).finally(() => setPageLoading(false))
  }
  useEffect(() => {
    getList()
  }, [params])

  // 表格双击跳转
  const tableDoubleFn = (r: any) => {
    if (r.recipeState === RecipeStatus.ToSettle) {
      dispatch(setTreatmentId(r.id))
      dispatch(getTreatmentDetail(r?.id))
        .then(unwrapResult)
        .then((res: any) => {
          const obj = JSON.parse(localStorage.getItem('localObj') || '{}')
          const objs = {
            ...obj,
            patientName: res?.patient?.name,
            name: res?.patient?.name,
            ageYear: res?.patient?.ageYear > -1 ? res?.patient?.ageYear : '',
            ageMonth: res?.patient?.ageMonth > -1 ? res?.patient?.ageMonth : '',
            birthday: res?.patient?.birthday ? res?.patient?.birthday : '',
            patientSex: res?.patient?.sex || res?.treatment?.patientSex, 
            phone: res?.patient?.phone,
            state: 1,
            patientId: res?.patient?.id,
            treatmentNo: res?.treatment?.id,
            registrationId: res?.treatment?.registrationId,
            disease:res?.treatment?.disease,
            treatmentDepartmentId: res?.treatment?.treatmentDepartmentId?.toString(),
            treatmentDepartmentName: res?.treatment?.treatmentDepartmentName,
            treatmentDoctorId: res?.treatment?.treatmentDoctorId?.toString(),
            treatmentDoctorName: res?.treatment?.treatmentDoctorName,
            insuranceCode: res?.treatment?.insuranceCode,
            treatmentId: res?.treatment?.id,
            ybPersonalNo: r?.ybPersonalNo,
            dnzhye: r?.dnzhye,
            lnzhye: r?.lnzhye,
            companyName: r?.companyName,
            insuranceRegion: r?.insuranceRegion,
            memberTypeStr: r?.memberTypeStr,
            patientInsurance: { ...res?.patientInsurance },
          }
          dispatch(setRecipeEditorTreatment(res?.treatment))
          props?.goBackParams && props?.goBackParams({ ...objs })
          localStorage.setItem('localObj', JSON.stringify(objs))
        })
    } else {
      if (hisVersion == 1) {
        if (r?.patientId && r?.patientId != 0) {
          tableUpdata(r.patientId, r.registrationId, '7')
        } else {
          return
        }
      } else {
        dispatch(
          traceRoute({
            name: '收费',
            path: '/payment/result',
            query: `paymentId=${r.paymentId}&&status=${r.recipeState}`,
            state: {
              name: '快速开单',
              path: '/retail',
              routeName: '快速开单',
            },
          })
        )
      }
    }
  }
  const cancelSettlement = (
    serverType: any,
    paymentId?: string,
    cardType?: string,
    fullCardInfo?: string,
    responseString?: string
  ) => {
    //医保退费
    const insuranceItem = insuranceArray.find(
      (v: any) => v.insuranceCode === refund?.insuranceCode
    )
    dispatch(
      cancelSettlementAsync({
        serverType,
        body: {
          baseData: {
            ...insuranceItem,
            endTime: moment(insuranceItem.endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            cycleCode,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
            responseString: responseString ? responseString : null,
          },
          exp_content: '',
          treatmentId: refund?.treatmentId,
          paymentId,
        },
      })
    )
      .then(unwrapResult)
      .then(async (res) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          await medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceItem.countryHospitalNb,
                insuranceItem.secretToken,
              ],
              notification: '退费',
            },
            (call: any, err) => {
              if (!err) {
                cancelSettlement(
                  'response',
                  paymentId,
                  cardType,
                  fullCardInfo,
                  JSON.stringify(call.netmessage)
                )
                setLoading(false)
              }
            }
          )
        } else {
          if (refund) {
            dispatch(refundPayment(refund.id))
              .then(unwrapResult)
              .then(() => {
                setLoading(false)
                setRefund(undefined)
                setPaymentDetailRecordVOS(undefined)
                getList()
              })
              .catch(() => {
                setLoading(false)
              })
            //更新医保卡数据
            if (cardType == '1') {
              //实体卡
              getPatientInfo(
                '3',
                insuranceItem?.insuranceCode == 'JIANGSU'
                  ? String(fullCardInfo)
                  : JSON.stringify(fullCardInfo),
                'requestString'
              )
            } else if (cardType == '2') {
              //电子凭证
              getPatientInfo('1', String(fullCardInfo), 'requestString')
            }
          }
        }
      })
      .catch((v) => {
        setLoading(false)
      })
  }

  // 获取参保人信息
  const getPatientInfo = (
    cardType: string,
    fullCardInfo: string,
    serverType: any,
    responseString?: string
  ) => {
    dispatch(
      getPatientInfoAsync({
        serverType,
        body: {
          baseData: {
            ...insuranceItem,
            endTime: moment(insuranceItem.endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            cycleCode,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
            responseString: responseString ? responseString : null,
          },
          cardType: String(cardType),
          fullCardInfo,
          otherMap: {},
        },
      })
    )
      .then(unwrapResult)
      .then(async (res) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          await medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceItem.countryHospitalNb,
                insuranceItem.secretToken,
              ],
            },
            (call: any, err) => {
              if (!err) {
                message.loading('正在更新人员信息')
                getPatientInfo(
                  cardType,
                  fullCardInfo,
                  'response',
                  JSON.stringify(call.netmessage)
                )
                // }
              }
            }
          )
        } else {
          message.success('人员信息已更新')
        }
      })
      .catch((ex) => {
        notification.error({
          message: ex.message,
          duration: 3,
        })
      })
  }

  const success = (str: string) => {
    const hide = message.loading(str, 0)
    setTimeout(() => {
      hide()
    }, 3000)
  }

  const doRefund = (cardType?: string, fullCardInfo?: string) => {
    const payMethodList = refund?.payMethod?.toString()?.split(',')
    const isJuHe: any = payMethodList?.find((v: any) => {
      return v == 2
    })
    if (!refund?.treatmentId) {
      return
    }
    if (isJuHe) {
      //是否聚合支付退费
      dispatch(
        JuhePremium({
          paymentId: refund?.id?.toString() || '',
        })
      )
        .then(unwrapResult)
        .then(() => {
          setLoading(false)
          if (refund) {
            if (refund.insuranceCode !== 'ZIFEI') {
              cancelSettlement(
                'requestString',
                refund.id,
                cardType,
                fullCardInfo
              )
            } else {
              if (refund) {
                dispatch(refundPayment(refund.id))
                  .then(unwrapResult)
                  .then(() => {
                    setLoading(false)
                    setRefund(undefined)
                    setPaymentDetailRecordVOS(undefined)
                    getList()
                  })
                  .catch(() => {
                    setLoading(false)
                  })
              }
            }
          }
        })
        .catch((v) => {
          setLoading(false)
        })
    } else {
      if (refund) {
        if (refund.insuranceCode !== 'ZIFEI') {
          cancelSettlement('requestString', refund.id, cardType, fullCardInfo)
        } else {
          if (refund) {
            dispatch(refundPayment(refund.id))
              .then(unwrapResult)
              .then(() => {
                setLoading(false)
                setRefund(undefined)
                setPaymentDetailRecordVOS(undefined)
                getList()
              })
              .catch(() => {
                setLoading(false)
              })
          }
        }
      }
    }
  }

  const validCardInfoBeforeGoRefund = (type: string) => {
    //实体卡读卡校验
    if (type == '1') {
      setLoading(true)
      //部分强制读卡的地区，跳过校验
      const tradeType = getTradeName()
      if (tradeType === 'CQ_ZHYB') {
        doRefund()
        return
      }
      readCardSHttp(
        {
          trade_args: [getTradeCode(tenantAreaCd.slice(0, 4))],
        },
        (data, err) => {
          if (!err) {
            //其他地区需要做二次读卡校验
            const Name =
              tradeType === 'JS_ZHYB'
                ? data?.netmessage
                    ?.split('|')[4]
                    ?.replace('\r', '')
                    .replace('\t', '')
                    .replace(' ', '')
                    .trim()
                : data?.netmessage?.Name
            if (Name != refund?.patientName) {
              Modal.error({
                title: '提示',
                content:
                  `退费信息与卡片信息不一致，请确认是否插错卡，就诊人：` +
                  refund?.patientName +
                  '，卡片姓名：' +
                  Name,
                okText: '确认',
              })
              setLoading(false)
            } else {
              doRefund(type, data?.netmessage)
            }
          }
        }
      )
      //电子凭证读卡校验
    } else if (type == '2') {
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
      }, 3000)
      readCardEHttp(
        {
          trade_args: [
            insuranceItem?.countryHospitalNb,
            '01301',
            '',
            userId,
            userName,
            '',
            '',
          ],
        },
        (data, err) => {
          if (!err) {
            const readName = data?.netmessage?.split('|')[0]
            if (readName != refund?.patientName) {
              Modal.error({
                title: '提示',
                content:
                  `退费信息与卡片信息不一致，请确认是否插错卡，就诊人：` +
                  refund?.patientName +
                  '，电子凭证姓名：' +
                  readName,
                okText: '确认',
              })
              setLoading(false)
            } else {
              doRefund(type, data?.netmessage)
            }
          }
        }
      )
      //自费直接调用退费
    } else if (type == '3') {
      doRefund()
      //自费直接调用退费
    } else if (type == '0') {
      doRefund()
    } else {
      Modal.error({
        title: '错误',
        content: `未知的退费类型` + type + `请联系客服人员处理`,
        okText: '确认',
      })
    }
  }

  const tableUpdata = (id: string, registrationId: string, num: string) => {
    dispatch(updatePatientTimeAsync(id))
      .then(unwrapResult)
      .then(() => {
        // history.push({
        //   pathname: '/toothPatient',
        // })
        dispatch(
          traceRoute({
            name: '患者管理',
            path: '/toothPatient',
          })
        )
        dispatch(
          setActiveObj({
            tabsActiveKey: '3',
            patientId: id,
            registrationId:
              Number(registrationId) > 0 ? registrationId : undefined,
            rightTabsActiveKey: num,
          })
        )
      })
  }

  return (
    <Col
      style={{
        backgroundColor: theme.pn,
        borderRadius: 10,
        padding: '0px 15px',
        marginTop: 10,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        height: 'calc(100% - 10px)',
      }}
    >
      <Form
        form={form}
        autoComplete='off'
        onFinish={(values) => {
          setParams({
            ...params,
            ...values,
            current: 1,
            timeHead: values?.timeHead ? values?.timeHead : timeHead,
            timeTail: values?.timeTail ? values?.timeTail : timeTail,
          })
        }}
      >
        <Row style={{ height: '5rem' }} align='middle' justify='space-between'>
          <Space>
            <Form.Item noStyle name='param'>
              <Input
                placeholder='请输入患者姓名'
                prefix={<SearchOutlined />}
                style={{ width: '19.75rem' }}
                onChange={form.submit}
              />
            </Form.Item>
            <Form.Item noStyle name='state'>
              <Select
                style={{ width: '11.25rem' }}
                placeholder='结算状态-全部'
                allowClear
                onChange={form.submit}
              >
                {[
                  RecipeStatus.ToSettle,
                  RecipeStatus.Settled,
                  RecipeStatus.Returned,
                ].map((s) => (
                  <Select.Option key={s} value={s}>
                    结算状态-{getRecipeStatusName(s)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item noStyle name='operateUser'>
              <Select
                placeholder='请选择操作员'
                allowClear
                style={{ width: '11.25rem' }}
                onChange={form.submit}
                showSearch
                filterOption={(input, option) => {
                  return option?.className
                    ? ((option?.className as unknown) as string)
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    : false
                }}
              >
                {users.map((u) => (
                  <Select.Option
                    className={u.mnemonicCode}
                    key={u.id}
                    value={u.id}
                  >
                    {u.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <DateSelect
              placeholder='操作时间'
              namePrefix='time'
              labelPrefix=''
              style={{ width: '8.5rem' }}
              onChange={form.submit}
              allowClear={false}
              initialValue={DateRangeType.Today}
            />
            <Button type='primary' onClick={form.submit}>
              查询
            </Button>
          </Space>
        </Row>
      </Form>
      <EditableList<Retail>
        rowKey={(v, i) => `${i}`}
        style={{ flex: 1 }}
        loading={pageLoading}
        page={page}
        columns={[
          {
            title: '序号',
            key: 'no',
            align: 'center',
            width: Dimen.Num,
            render: (_1, _2, i) => `${(page.current - 1) * page.size + i + 1}`,
          },
          {
            title: '患者姓名',
            dataIndex: 'patientName',
            align: 'center',
            width: Dimen.Name,
            editable: true,
            rules: [
              {
                required: true,
                message: '请输入患者姓名',
              },
            ],
            onSave: (prev, next) => {
              dispatch(
                editName({
                  name: next.patientName,
                  treatmentId: prev.id,
                })
              )
                .then(unwrapResult)
                .then((res) => {
                  setParams({
                    ...params,
                  })
                })
            },
            render: function element(_, t) {
              return t.patientName == '零售患者' ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {t.patientName}
                  <EditOutlined
                    mode='small'
                    style={{
                      marginLeft: 5,
                      fill: '#2092c9',
                    }}
                  />
                </div>
              ) : (
                t.patientName
              )
            },
          },
          {
            title: '性别',
            key: 'gender',
            align: 'center',
            width: Dimen.Sex,
            render: (_, r) => getGenderName(r.patientSex),
          },
          {
            title: '最近操作员',
            dataIndex: 'operateUser',
            width: Dimen.OperateUser,
            align: 'center',
          },
          {
            title: '操作时间',
            dataIndex: 'operateTime',
            width: Dimen.Time,
            align: 'center',
          },
          {
            title: '结算金额',
            dataIndex: 'amount',
            align: 'right',
            width: Dimen.Price,
          },
          {
            title: '结算状态',
            key: 'status',
            align: 'center',
            width: Dimen.State,
            render: (_, r) => getRecipeStatusName(r.recipeState),
          },
          {
            title: '操作',
            key: 'actions',
            align: 'center',
            width: '6rem',
            render: function ShowActions(_, r: any) {
              return r.recipeState === RecipeStatus.ToSettle ? (
                <>
                  <a
                    onClick={() => {
                      dispatch(setTreatmentId(r.id))
                      dispatch(getTreatmentDetail(r?.id))
                        .then(unwrapResult)
                        .then((res: any) => {
                          const obj = JSON.parse(
                            localStorage.getItem('localObj') || '{}'
                          )
                          const objs = {
                            ...obj,
                            patientName: res?.patient?.name,
                            name: res?.patient?.name,
                            ageYear:
                              res?.patient?.ageYear > -1
                                ? res?.patient?.ageYear
                                : '',
                            ageMonth:
                              res?.patient?.ageMonth > -1
                                ? res?.patient?.ageMonth
                                : '',
                            birthday: res?.patient?.birthday
                              ? res?.patient?.birthday
                              : '',
                            patientSex: res?.patient?.sex || res?.treatment?.patientSex, 
                            phone: res?.patient?.phone,
                            state: 1,
                            patientId: res?.patient?.id,
                            disease:res?.treatment?.disease,
                            treatmentNo: res?.treatment?.id,
                            registrationId:res?.treatment?.registrationId,
                            treatmentDepartmentId: res?.treatment?.treatmentDepartmentId?.toString(),
                            treatmentDepartmentName:
                              res?.treatment?.treatmentDepartmentName,
                            treatmentDoctorId: res?.treatment?.treatmentDoctorId?.toString(),
                            treatmentDoctorName:
                              res?.treatment?.treatmentDoctorName,
                            insuranceCode: res?.treatment?.insuranceCode,
                            treatmentId: res?.treatment?.id,
                            ybPersonalNo: r?.ybPersonalNo,
                            // dnzhye:r?.dnzhye,
                            // lnzhye:r?.lnzhye,
                            companyName: r?.companyName,
                            insuranceRegion: r?.insuranceRegion,
                            memberTypeStr: r?.memberTypeStr,
                            patientInsurance: { ...res?.patientInsurance },
                          }
                          dispatch(setRecipeEditorTreatment(res?.treatment))
                          props?.goBackParams &&
                            props?.goBackParams({ ...objs })
                          localStorage.setItem('localObj', JSON.stringify(objs))
                        })
                    }}
                  >
                    继续开单
                  </a>
                  <Button
                    ghost
                    type='link'
                    style={{
                      color: '#FF5D5D',
                    }}
                    onClick={() => {
                      dispatch(getPaymentDelete(r.id))
                        .then(unwrapResult)
                        .then((r) => {
                          notification.success({
                            message: '删除成功',
                          })
                          getList()
                        })
                    }}
                  >
                    删除
                  </Button>
                </>
              ) : (
                <Space>
                  {r.recipeState === RecipeStatus.Settled && (
                    <Button
                      type='primary'
                      size='small'
                      onClick={() => {
                        dispatch(getPaymentDetail2(r.paymentId))
                          .then(unwrapResult)
                          .then((r) => {
                            setPaymentDetailRecordVOS(r?.paymentDetailRecordVOS)
                            setRefund(r?.payment)
                          })
                      }}
                    >
                      退费
                    </Button>
                  )}
                  {r?.patientId == 0 ? (
                    <></>
                  ) : (
                    <a
                      onClick={() => {
                        if (hisVersion == 1) {
                          if (r?.patientId && r?.patientId != 0) {
                            tableUpdata(r.patientId, r.registrationId, '7')
                          } else {
                            return
                          }
                        } else {
                          dispatch(
                            traceRoute({
                              name: '快速开单',
                              path: '/treatment/detail',
                              query: `treatmentId=${r.id}&medicalRecord=${r?.medicalRecord}`,
                              state: {
                                where: ['收费明细', '收费详情'],
                                backtrace: {
                                  name: '快速开单',
                                  path: '/retail',
                                },
                              },
                            })
                          )
                        }
                      }}
                    >
                      编辑信息
                    </a>
                  )}
                </Space>
              )
            },
          },
        ]}
        onChangePage={(current, size) =>
          setParams({ ...params, current, size })
        }
        onRow={(record) => ({
          onDoubleClick: () => tableDoubleFn(record),
        })}
      />
      <Modal
        title='预退费'
        destroyOnClose
        visible={!!refund}
        onCancel={() => {
          setRefund(undefined)
          setPaymentDetailRecordVOS(undefined)
        }}
        confirmLoading={loading}
        footer={null}
      >
        <Row style={{ fontSize: '1.125rem', color: theme.tc1 }}>
          <EditableList
            style={{
              marginTop: 12,
              marginLeft: 20,
              marginRight: 20,
              marginBottom: 20,
              flex: 1,
              overflow: 'auto',
            }}
            pagination={false}
            dataSource={paymentDetailRecordVOS}
            bordered
            size='small'
            columns={
              [
                {
                  title: '退费金额',
                  dataIndex: 'realPayAmount',
                  align: 'center',
                  render: (_, t) => t.realPayAmount.toFixed(2),
                },
                {
                  title: '应退费用',
                  dataIndex: 'realPayAmount',
                  align: 'center',
                  render: (_, t) => t.realPayAmount.toFixed(2),
                },
                {
                  title: '退费方式',
                  dataIndex: 'payMethod',
                  align: 'center',
                  render: function payMethod(_, t) {
                    const flag =
                      t.payMethod == 1 ||
                      t.payMethod == 2 ||
                      t.payMethod == 3 ||
                      t.payMethod == 4 ||
                      t.payMethod == 5 ||
                      t.payMethod == 6 ||
                      t?.payMethod == 99
                    const icons = getIconImg(t?.payMethodICon)

                    return flag ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: 35,
                        }}
                      >
                        {[
                          {
                            icon: DollarCircleFilled,
                            color: '#69A6FD',
                            method: PayMethod.Cash,
                            type: 1,
                          },
                          {
                            icon: DollarCircleFilled,
                            color: '#69A6FD',
                            method: PayMethod.YB,
                            type: 1,
                          },
                          {
                            icon: WechatFilled,
                            color: '#00BC00',
                            method: PayMethod.Weipay,
                            type: 1,
                          },
                          {
                            icon: AlipayCircleFilled,
                            color: '#00A9F2',
                            method: PayMethod.Alipay,
                            type: 1,
                          },
                          {
                            icon: CreditCardFilled,
                            color: '#107C84',
                            method: PayMethod.UnionPay,
                            type: 1,
                          },
                          {
                            icon: DollarCircleFilled,
                            color: '#69A6FD',
                            method: PayMethod.Juhe,
                            type: 0,
                          },
                          {
                            icon: DollarCircleFilled,
                            color: '#69A6FD',
                            method: PayMethod.HY,
                            type: 2,
                          },
                        ].map(({ icon, color, method, type }) => {
                          const Icon = icon
                          return (
                            t.payMethod == method && (
                              <Tooltip
                                key={method}
                                overlay={getPayMethodName(method)}
                              >
                                {t.payMethod == 99 ? (
                                  <YBIcon
                                    style={{
                                      position: 'relative',
                                      top: '6px',
                                      fontSize: '1.5rem',
                                      height: '24px',
                                      width: '24px',
                                      padding: '4px',
                                      color,
                                      borderRadius: '50%',
                                      display: type == 1 ? 'block' : 'none',
                                      background: '#088f52',
                                    }}
                                  />
                                ) : (
                                  <Icon
                                    style={{
                                      position: 'relative',
                                      top: '6px',
                                      fontSize: '1.5rem',
                                      color,
                                      borderRadius: 2,
                                      display: type == 1 ? 'block' : 'none',
                                    }}
                                  />
                                )}

                                <div>
                                  <JuhePay
                                    mode='small'
                                    style={{
                                      display: type == 0 ? 'block' : 'none',
                                      borderRadius: 2,
                                      padding: '4px',
                                      width: '40px',
                                      height: '40px',
                                      fontSize: '30px',
                                      cursor: 'pointer',
                                    }}
                                  />
                                </div>
                                <div>
                                  <MembersIcon
                                    mode='small'
                                    style={{
                                      display: type == 2 ? 'block' : 'none',
                                      borderRadius: 2,
                                      padding: '4px',
                                      width: '35px',
                                      height: '35px',
                                      fontSize: '30px',
                                      cursor: 'pointer',
                                    }}
                                  />
                                </div>
                              </Tooltip>
                            )
                          )
                        })}
                      </div>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: 35,
                        }}
                      >
                        <Tooltip key={t?.id} overlay={t?.payMethodName}>
                          <img
                            src={icons}
                            style={{
                              borderRadius: 2,
                              padding: '4px',
                              width: '32px',
                              height: '32px',
                              fontSize: '30px',
                              cursor: 'pointer',
                            }}
                          />
                        </Tooltip>
                      </div>
                    )
                  },
                },
              ] as TableColumnType<PaymentResult>[]
            }
          />
        </Row>
        <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            style={{
              marginRight: '14px',
            }}
            onClick={() => {
              setRefund(undefined)
              setPaymentDetailRecordVOS(undefined)
            }}
          >
            取消
          </Button>
          {isHavingInsurance ? (
            <>
              <Button
                loading={loading}
                style={{
                  marginRight: '14px',
                }}
                type='primary'
                onClick={() => {
                  setLoading(true)
                  validCardInfoBeforeGoRefund('1')
                }}
              >
                实体卡退费
              </Button>
              {tenantName?.indexOf('测试') >= 0 && (
                <Button
                  loading={loading}
                  style={{
                    marginRight: '14px',
                  }}
                  type='primary'
                  onClick={() => {
                    setLoading(true)
                    validCardInfoBeforeGoRefund('3')
                  }}
                >
                  身份证退费
                </Button>
              )}
              <Button
                loading={loading}
                style={{
                  marginRight: '14px',
                }}
                type='primary'
                onClick={() => {
                  setLoading(true)
                  validCardInfoBeforeGoRefund('2')
                }}
              >
                电子凭证退费
              </Button>
            </>
          ) : (
            <>
              <Button
                loading={loading}
                style={{
                  marginRight: '14px',
                }}
                type='primary'
                onClick={() => {
                  setLoading(true)
                  // setReturnPremiumLoading(true)
                  validCardInfoBeforeGoRefund('0')
                }}
              >
                退费
              </Button>
            </>
          )}
        </Row>
      </Modal>
    </Col>
  )
}
