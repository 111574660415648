/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-09-29 15:43:45
 * @LastEditors: linxi
 * @LastEditTime: 2025-01-03 11:56:12
 */
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Popover,
  Radio,
  Row,
  Select,
  Space,
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { Checks, Settinglable } from '../../models/setting'
import styles from './PrintFrame.module.css'
import {
  getPrintTemplateSettingDetail,
  getPrintTemplateSettingUserList,
  getSaveSettingPrintData,
} from './printSlice'
import QMImg from '../user/setting/img/QM.png'
import { traceRoute } from '../../layouts/layoutSlice'

interface PrintSettingModalProps {
  visible: boolean
  onCancel: () => void
  onOk: () => void
}

export const PrintSettingModal = ({
  visible,
  onCancel,
  onOk,
}: PrintSettingModalProps): ReactElement => {
  const [form] = Form.useForm()

  const layout = {
    labelCol: {
      span: 5,
    },
  }

  const dispatch = useDispatch<RootDispatch>()

  const [detail, setDetail] = useState<any>()

  const [users, setUsers] = useState<any>([])

  const [physicianUsers, setPhysicianUsers] = useState<any>([])

  const [physicianSignType, setPhysicianSignType] = useState(false)

  const [reviewerPharmacistSignType, setReviewerPharmacistSignType] = useState(
    false
  )

  const [pharmacistSignType, setPharmacistSignType] = useState(false)

  const [
    pharmaceuticalReviewerSignType,
    setPharmaceuticalReviewerSignType,
  ] = useState(false)

  const [hasDZQMUsers, setHasDZQMUsers] = useState<any>([])

  const [showPhysicianTypeListFlag, setShowPhysicianTypeListFlag] = useState(
    false
  )

  const [
    showPharmacistTypeListFlag,
    setShowPharmacistTypeListFlag,
  ] = useState<any>(false)

  const [
    showPharmaceuticalReviewerTypeListFlag,
    setShowPharmaceuticalReviewerTypeListFlag,
  ] = useState(false)
  const [
    showReviewerPharmacistTypeListFlag,
    setShowReviewerPharmacistTypeListFlag,
  ] = useState(false)

  const [
    showSignatureTypeListFlag,
    setShowSignatureTypeListFlag,
  ] = useState<any>(false)

  useEffect(() => {
    if (visible) {
      // 获取医师
      dispatch(getPrintTemplateSettingUserList({ stationType: 1, state: 1 }))
        .then(unwrapResult)
        .then((users: any) => {
          setPhysicianUsers(users)
        })
        .catch(() => {
          // do nothing.
        })
      // 获取其他医生
      dispatch(getPrintTemplateSettingUserList({ stationType: 2, state: 1 }))
        .then(unwrapResult)
        .then((users: any) => {
          setUsers(users)
        })
        .catch(() => {
          // do nothing.
        })
      if (showSignatureTypeListFlag) {
        dispatch(
          getPrintTemplateSettingUserList({
            state: 1,
            haveSign: 1,
          })
        )
          .then(unwrapResult)
          .then((users: any) => setHasDZQMUsers(users))
          .catch(() => {
            // do nothing.
          })
      }
    }
  }, [showSignatureTypeListFlag, visible])

  useEffect(() => {
    if (visible) {
      // 获取详情
      dispatch(getPrintTemplateSettingDetail())
        .then(unwrapResult)
        .then((v: any) => {
          if (v?.functionList?.length) {
            const CFJDYZZLValue = v?.functionList?.find(
              (v: any) => v?.type == 39
            )?.value
            const CFJDYZCLValue = v?.functionList?.find(
              (v: any) => v?.type == 40
            )?.value
            const CFJDYCFJEValue = v?.functionList?.find(
              (v: any) => v?.type == 28
            )?.value
            const CFJDYCJHPCHValue = v?.functionList?.find(
              (v: any) => v?.type == 33
            )?.value
            const CFJDYBLHValue = v?.functionList?.find(
              (v: any) => v?.type == 32
            )?.value
            const CFJDYBZValue = v?.functionList?.find(
              (v: any) => v?.type == 35
            )?.value
            const CFJDYZSValue = v?.functionList?.find(
              (v: any) => v?.type == 37
            )?.value
            const CFJDYZZCValue = v?.functionList?.find(
              (v: any) => v?.type == 41
            )?.value
            const CFJDYDAYSValue = v?.functionList?.find(
              (v: any) => v?.type == 53
            )?.value
            const LSDJValue = v?.functionList?.find((v: any) => v?.type == 43)
              ?.value
            const SCCSValue = v?.functionList?.find((v: any) => v?.type == 44)
              ?.value
            const PCHYXQValue = v?.functionList?.find((v: any) => v?.type == 45)
              ?.value
            const DJDWValue = v?.functionList?.find((v: any) => v?.type == 46)
              ?.value
            const FZLWTGBTHValue = v?.functionList?.find(
              (v: any) => v?.type == 47
            )?.value
            const OVERLIMITSIGN = v?.functionList?.find(
              (v: any) => v?.type == 50
            )?.value
            const PRINTCABINENO = v?.functionList?.find(
              (v: any) => v?.type == 52
            )?.value
            const JGLXDHValue = v?.functionList?.find((v: any) => v?.type == 48)
              ?.value
            setTimeout(() => {
              form.setFieldsValue({
                ...v,
                physicianCutId:
                  v?.physicianCutId == 0 || v?.physicianCutId == -1
                    ? undefined
                    : v?.physicianCutId,
                pharmacistCutId:
                  v?.pharmacistCutId == 0 || v?.pharmacistCutId == -1
                    ? undefined
                    : v?.pharmacistCutId,
                reviewerPharmacistCutId:
                  v?.reviewerPharmacistCutId == 0 ||
                  v?.reviewerPharmacistCutId == -1
                    ? undefined
                    : v?.reviewerPharmacistCutId,
                signatureId:
                  v?.signatureId == 0 || v?.signatureId == -1
                    ? undefined
                    : v?.signatureId,
                pharmaceuticalReviewerCutId:
                  v?.pharmaceuticalReviewerCutId == 0 ||
                  v?.pharmaceuticalReviewerCutId == -1
                    ? undefined
                    : v?.pharmaceuticalReviewerCutId,
                [Checks.CFJDYZZL]: CFJDYZZLValue == 'true' ? true : false,
                [Checks.CFJDYZCL]: CFJDYZCLValue == 'true' ? true : false,
                [Checks.CFJDYCFJE]: CFJDYCFJEValue == 'true' ? true : false,
                [Checks.CFJDYCJHPCH]: CFJDYCJHPCHValue == 'true' ? true : false,
                [Checks.CFJDYBLH]: CFJDYBLHValue == 'true' ? true : false,
                [Checks.CFJDYBZ]: CFJDYBZValue == 'true' ? true : false,
                [Checks.CFJDYZS]: CFJDYZSValue == 'true' ? true : false,
                [Checks.CFJDYZZC]: CFJDYZZCValue == 'true' ? true : false,
                [Checks.LSDJ]: LSDJValue == 'true' ? true : false,
                [Checks.SCCS]: SCCSValue == 'true' ? true : false,
                [Checks.PCHYXQ]: PCHYXQValue == 'true' ? true : false,
                [Checks.DJDW]: DJDWValue == 'true' ? true : false,
                [Checks.FZLWTGBTH]: FZLWTGBTHValue == 'true' ? true : false,
                [Checks.JGLXDH]: JGLXDHValue == 'true' ? true : false,
                [Checks.OVERLIMITSIGN]: OVERLIMITSIGN == 'true' ? true : false,
                [Checks.PRINTCABINENO]: PRINTCABINENO == 'true' ? true : false,
                [Checks.CFJDYDAYS]: CFJDYDAYSValue == 'false' ? false : true,
              })
            }, 300)
          } else {
            form.setFieldsValue({
              [Checks.CFJDYZZL]: true,
              [Checks.CFJDYZCL]: true,
            })
          }

          setShowPhysicianTypeListFlag(v?.physicianType == 1 ? true : false)
          setShowPharmacistTypeListFlag(v?.pharmacistType == 1 ? true : false)
          setShowReviewerPharmacistTypeListFlag(
            v?.reviewerPharmacistType == 1 ? true : false
          )
          setShowPharmaceuticalReviewerTypeListFlag(
            v?.pharmaceuticalReviewerType == 1 ? true : false
          )

          setShowSignatureTypeListFlag(v?.signatureType == 1 ? true : false)
          setPhysicianSignType(v?.physicianSignType == 1 ? true : false)
          setReviewerPharmacistSignType(
            v?.reviewerPharmacistSignType == 1 ? true : false
          )
          setPharmacistSignType(v?.pharmacistSignType == 1 ? true : false)
          setPharmaceuticalReviewerSignType(
            v?.pharmaceuticalReviewerSignType == 1 ? true : false
          )
          setDetail(v)
        })
    }
  }, [visible])

  const content = (
    <div className={styles.imgWrap}>
      <img src={QMImg} style={{ width: '100%', height: '100%' }}></img>
    </div>
  )

  return (
    <Modal
      visible={visible}
      title='打印设置'
      width={840}
      onCancel={() => {
        onCancel()
        form.resetFields()
      }}
      footer={null}
      onOk={() => {
        onOk()
        form.resetFields()
      }}
      className={styles.modalContent}
    >
      <Form
        form={form}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        autoComplete='off'
        onFinish={(values: any) => {
          if (values?.physicianType == 1 && !values?.physicianCutId) {
            notification.info({
              message: '请选择固定医师',
            })
            return
          }

          if (values?.pharmacistType == 1 && !values?.pharmacistCutId) {
            notification.info({
              message: '请选择固定配药药师',
            })
            return
          }
          if (
            values?.reviewerPharmacistType == 1 &&
            !values?.reviewerPharmacistCutId
          ) {
            notification.info({
              message: '请选择固定复核药师',
            })
            return
          }
          if (
            values?.pharmaceuticalReviewerType == 1 &&
            !values?.pharmaceuticalReviewerCutId
          ) {
            notification.info({
              message: '请选择固定审方药师',
            })
            return
          }

          const pattern = /^[0-9]*$/
          const f = _.chain(values)
            .pickBy((_, k) => pattern.test(k))
            .mapValues(
              (v, k) =>
                ({
                  type: k,
                  value: v ? v?.toString() : 'false',
                } as Settinglable)
            )
            .values()
            .value()

          dispatch(
            getSaveSettingPrintData({
              id: detail?.id,
              drugTypeSort: values?.drugTypeSort,
              medicalWestPrintQuantity: values?.medicalWestPrintQuantity
                ? values?.medicalWestPrintQuantity
                : 0,
              pharmacistId: values?.pharmacistCutId,
              pharmacistType: values?.pharmacistType,

              physicianId: values?.physicianCutId,
              physicianType: values?.physicianType,

              reviewerPharmacistId: values?.reviewerPharmacistCutId,
              reviewerPharmacistType: values?.reviewerPharmacistType,

              pharmaceuticalReviewerId: values?.pharmaceuticalReviewerCutId,
              pharmaceuticalReviewerType: values?.pharmaceuticalReviewerType,

              signatureType: values?.signatureType,
              signatureId: values?.signatureId,

              functionList: f?.filter((val) => val?.type != ''),
              physicianSignType: physicianSignType ? 1 : 0,
              reviewerPharmacistSignType: reviewerPharmacistSignType ? 1 : 0,
              pharmacistSignType: pharmacistSignType ? 1 : 0,
              pharmaceuticalReviewerSignType: pharmaceuticalReviewerSignType
                ? 1
                : 0,
            })
          )
            .then(unwrapResult)
            .then((v) => {
              notification.success({
                message: '保存成功',
              })
              onOk()
              form.resetFields()
            })
        }}
      >
        <div
          style={{
            borderBottom: '1px solid  rgba(187, 187, 187, 0.27)',
          }}
        >
          <div className={styles.title}>处方笺署名</div>
          <Row>
            <Col span={12}>
              <Form.Item name='physicianType' label='医师'>
                <Select
                  placeholder='医师'
                  style={{ width: '200px' }}
                  onChange={(v) => {
                    if (v == 1) {
                      setShowPhysicianTypeListFlag(true)
                    } else {
                      setShowPhysicianTypeListFlag(false)
                    }
                  }}
                >
                  <Select.Option key={0} value={0}>
                    跟随就诊医生
                  </Select.Option>
                  <Select.Option key={1} value={1}>
                    固定医生
                  </Select.Option>
                  <Select.Option key={2} value={2}>
                    不打印
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            {showPhysicianTypeListFlag && (
              <Col span={6}>
                <Form.Item name='physicianCutId' label=''>
                  <Select placeholder='请选择医生' style={{ width: '200px' }}>
                    {physicianUsers?.map((u: any) => (
                      <Select.Option key={Number(u.id)} value={Number(u.id)}>
                        {u.realname}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {detail?.openDoctorSign && (
              <Col span={5}>
                <Checkbox
                  checked={physicianSignType}
                  style={{ marginLeft: '30px' }}
                  onChange={(v) => {
                    setPhysicianSignType(v?.target.checked)
                  }}
                >
                  使用电子签名
                </Checkbox>
              </Col>
            )}
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item name='pharmacistType' label='配药药师'>
                <Select
                  placeholder='配药药师'
                  style={{ width: '200px' }}
                  onChange={(v) => {
                    if (v == 1) {
                      setShowPharmacistTypeListFlag(true)
                    } else {
                      setShowPharmacistTypeListFlag(false)
                    }
                  }}
                >
                  <Select.Option key={0} value={0}>
                    跟随就诊医生
                  </Select.Option>
                  <Select.Option key={1} value={1}>
                    固定医生
                  </Select.Option>
                  <Select.Option key={2} value={2}>
                    不打印
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            {showPharmacistTypeListFlag && (
              <Col span={6}>
                <Form.Item name='pharmacistCutId' label=''>
                  <Select placeholder='请选择医生' style={{ width: '200px' }}>
                    {users?.map((u: any) => (
                      <Select.Option key={Number(u.id)} value={Number(u.id)}>
                        {u.realname}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {detail?.openDoctorSign && (
              <Col span={5}>
                <Checkbox
                  checked={pharmacistSignType}
                  style={{ marginLeft: '30px' }}
                  onChange={(v) => {
                    setPharmacistSignType(v?.target.checked)
                  }}
                >
                  使用电子签名
                </Checkbox>
              </Col>
            )}
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item name='reviewerPharmacistType' label='复核药师'>
                <Select
                  placeholder='复核药师'
                  style={{ width: '200px' }}
                  onChange={(v) => {
                    if (v == 1) {
                      setShowReviewerPharmacistTypeListFlag(true)
                    } else {
                      setShowReviewerPharmacistTypeListFlag(false)
                    }
                  }}
                >
                  <Select.Option key={0} value={0}>
                    跟随就诊医生
                  </Select.Option>
                  <Select.Option key={1} value={1}>
                    固定医生
                  </Select.Option>
                  <Select.Option key={2} value={2}>
                    不打印
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            {showReviewerPharmacistTypeListFlag && (
              <Col span={6}>
                <Form.Item name='reviewerPharmacistCutId' label=''>
                  <Select placeholder='请选择医生' style={{ width: '200px' }}>
                    {users?.map((u: any) => (
                      <Select.Option key={Number(u.id)} value={Number(u.id)}>
                        {u.realname}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {detail?.openDoctorSign && (
              <Col span={5}>
                <Checkbox
                  checked={reviewerPharmacistSignType}
                  style={{ marginLeft: '30px' }}
                  onChange={(v) => {
                    setReviewerPharmacistSignType(v?.target.checked)
                  }}
                >
                  使用电子签名
                </Checkbox>
              </Col>
            )}
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item name='pharmaceuticalReviewerType' label='审方药师'>
                <Select
                  placeholder='审方药师'
                  style={{ width: '200px' }}
                  onChange={(v) => {
                    if (v == 1) {
                      setShowPharmaceuticalReviewerTypeListFlag(true)
                    } else {
                      setShowPharmaceuticalReviewerTypeListFlag(false)
                    }
                  }}
                >
                  <Select.Option key={0} value={0}>
                    跟随就诊医生
                  </Select.Option>
                  <Select.Option key={1} value={1}>
                    固定医生
                  </Select.Option>
                  <Select.Option key={2} value={2}>
                    不打印
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            {showPharmaceuticalReviewerTypeListFlag && (
              <Col span={6}>
                <Form.Item name='pharmaceuticalReviewerCutId' label=''>
                  <Select placeholder='请选择医生' style={{ width: '200px' }}>
                    {users?.map((u: any) => (
                      <Select.Option key={Number(u.id)} value={Number(u.id)}>
                        {u.realname}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {detail?.openDoctorSign && (
              <Col span={5}>
                <Checkbox
                  checked={pharmaceuticalReviewerSignType}
                  style={{ marginLeft: '30px' }}
                  onChange={(v) => {
                    setPharmaceuticalReviewerSignType(v?.target.checked)
                  }}
                >
                  使用电子签名
                </Checkbox>
              </Col>
            )}
          </Row>
        </div>

        <div className={styles.contentTitleMain}>
          <div className={styles.dzqmTitle}>
            <span style={{ fontWeight: 'bold', marginRight: '4px' }}>
              电子签名
            </span>
            <span style={{ fontSize: '14px' }}>
              电子签名将打印在 处方笺/诊断书/执行单等票据中，
              <Popover content={content} placement='bottom' title='签名'>
                <a style={{ borderBottom: '1px solid  #598dff' }}>图例</a>
              </Popover>
            </span>
          </div>
          {detail?.openDoctorSign ? (
            <Row>
              <Col span={12}>
                <Form.Item name='signatureType' label='选择签名'>
                  <Select
                    placeholder='选择签名'
                    style={{ width: '200px' }}
                    onChange={(v: any) => {
                      if (v == 1) {
                        setShowSignatureTypeListFlag(true)
                      } else {
                        setShowSignatureTypeListFlag(false)
                      }
                    }}
                  >
                    <Select.Option key={0} value={0}>
                      就诊医生签名
                    </Select.Option>
                    <Select.Option key={1} value={1}>
                      固定医生签名
                    </Select.Option>
                    <Select.Option key={2} value={2}>
                      不签名
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              {/* 选择固定医生签名后，则展示“医生姓名”选框，展示目前有电子签名的医生 */}
              {showSignatureTypeListFlag && (
                <Col span={12}>
                  <Form.Item name='signatureId' label=''>
                    <Select placeholder='请选择医生' style={{ width: '200px' }}>
                      {hasDZQMUsers?.map((u: any) => (
                        <Select.Option key={Number(u.id)} value={Number(u.id)}>
                          {u.realname}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
            </Row>
          ) : (
            <div
              className={styles.nodzqmTitle}
              onClick={() => {
                dispatch(
                  traceRoute({
                    name: '产品中心',
                    path: '/productCenter',
                  })
                )
              }}
            >
              本机构未开通电子签名，
              <a
                style={{
                  borderBottom: '1px solid  #598dff',
                  fontWeight: 'bold',
                }}
              >
                去开通
              </a>
            </div>
          )}
        </div>

        <div className={styles.contentTitleMain}>
          <div className={styles.title}>内容设置</div>
          <Row style={{ marginLeft: '-270px' }}>
            <Col span={24}>
              <Form.Item label='门诊发票打印顺序' name='drugTypeSort'>
                <Radio.Group
                  style={{
                    width: '570px',
                    display: 'flex',
                    flexWrap: 'wrap',
                  }}
                >
                  <Radio value={0} style={{ marginRight: '7rem' }}>
                    药品在前
                  </Radio>
                  <Radio value={1}> 诊疗在前 </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              span={5}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              处方笺打印 :
            </Col>
            <Col span={19}>
              <Row style={{ height: '35px', marginTop: '-6px' }}>
                <Col style={{ marginLeft: '10px' }}>
                  <Form.Item name={Checks.CFJDYZZL} valuePropName='checked'>
                    <Checkbox>打印诊疗</Checkbox>
                  </Form.Item>
                </Col>
                <Col style={{ marginLeft: '10px' }}>
                  <Form.Item name={Checks.CFJDYZCL} valuePropName='checked'>
                    <Checkbox>打印材料</Checkbox>
                  </Form.Item>
                </Col>
                <Col style={{ marginLeft: '10px' }}>
                  <Form.Item name={Checks.CFJDYCFJE} valuePropName='checked'>
                    <Checkbox>打印金额</Checkbox>
                  </Form.Item>
                </Col>
                <Col style={{ marginLeft: '10px' }}>
                  <Form.Item name={Checks.CFJDYDAYS} valuePropName='checked'>
                    <Checkbox>打印天数</Checkbox>
                  </Form.Item>
                </Col>
                <Col style={{ marginLeft: '10px' }}>
                  <Form.Item name={Checks.CFJDYCJHPCH} valuePropName='checked'>
                    <Checkbox>打印厂家</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ height: '50px' }}>
                <Col style={{ marginLeft: '10px' }}>
                  <Form.Item name={Checks.CFJDYBLH} valuePropName='checked'>
                    <Checkbox>打印病历号</Checkbox>
                  </Form.Item>
                </Col>
                <Col style={{ marginLeft: '10px' }}>
                  <Form.Item name={Checks.CFJDYBZ} valuePropName='checked'>
                    <Checkbox>打印备注</Checkbox>
                  </Form.Item>
                </Col>
                <Col style={{ marginLeft: '10px' }}>
                  <Form.Item name={Checks.CFJDYZS} valuePropName='checked'>
                    <Checkbox>打印主诉</Checkbox>
                  </Form.Item>
                </Col>
                <Col style={{ marginLeft: '10px' }}>
                  <Form.Item
                    name={Checks.PRINTCABINENO}
                    valuePropName='checked'
                  >
                    <Checkbox>打印柜号</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ marginLeft: '-270px' }}>
            <Col
              span={24}
              style={{
                width: '110px',
                lineHeight: '32px',
                marginBottom: '10px',
                position: 'relative',
              }}
            >
              <div style={{ position: 'absolute', top: 0, left: 438 }}>
                超出
              </div>
              <Form.Item
                label='打印西药处方笺时'
                name='medicalWestPrintQuantity'
              >
                <Input style={{ width: '3rem', margin: '0 30px' }}></Input>
              </Form.Item>
              <div style={{ position: 'absolute', top: 0, left: 528 }}>
                <span>条项目自动换页。</span>
                <span style={{ color: '#666666' }}>
                  （不填时，则会根据纸张高度自动换页）
                </span>
              </div>
            </Col>
          </Row>
        </div>
        <div className={styles.contentTitleMains}>
          <div className={styles.title}>购药小票设置</div>
          <Row>
            <Col
              span={5}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              内容展示 :
            </Col>
            <Col span={19}>
              <Row style={{ height: '35px', marginTop: '-6px' }}>
                <Col style={{ marginLeft: '10px' }} span={4}>
                  <Form.Item name={Checks.LSDJ} valuePropName='checked'>
                    <Checkbox>零售单号</Checkbox>
                  </Form.Item>
                </Col>
                <Col style={{ marginLeft: '10px' }} span={4}>
                  <Form.Item name={Checks.SCCS} valuePropName='checked'>
                    <Checkbox>生产厂商</Checkbox>
                  </Form.Item>
                </Col>
                <Col style={{ marginLeft: '10px' }} span={5}>
                  <Form.Item name={Checks.PCHYXQ} valuePropName='checked'>
                    <Checkbox>批次号/有效期</Checkbox>
                  </Form.Item>
                </Col>
                <Col style={{ marginLeft: '10px' }} span={5}>
                  <Form.Item name={Checks.DJDW} valuePropName='checked'>
                    <Checkbox>单价/单位</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ height: '50px' }}>
                <Col style={{ marginLeft: '10px' }} span={8}>
                  <Form.Item name={Checks.FZLWTGBTH} valuePropName='checked'>
                    <Checkbox>售出药品非质量问题概不退换</Checkbox>
                  </Form.Item>
                </Col>
                <Col style={{ marginLeft: '26px' }}>
                  <Form.Item name={Checks.JGLXDH} valuePropName='checked'>
                    <Checkbox>机构联系电话</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className={styles.contentTitleMain}>
          <div className={styles.title}>其他设置</div>
          <Row style={{ marginLeft: '-270px' }}>
            <Col span={24}>
              <Form.Item label='收据展示折扣' name=''>
                <Col
                  style={{
                    width: '110px',
                    lineHeight: '32px',
                    marginBottom: '10px',
                    position: 'relative',
                  }}
                >
                  <Col style={{ marginLeft: '4px' }}>
                    <Form.Item name={Checks.CFJDYZZC} valuePropName='checked'>
                      <Checkbox>开启</Checkbox>
                    </Form.Item>
                  </Col>
                </Col>
              </Form.Item>
              <div
                style={{
                  position: 'absolute',
                  top: 5,
                  left: 506,
                  color: '#666666',
                }}
              >
                （开启后在总金额后面展示结算的折扣）
              </div>
            </Col>
          </Row>
          <Row style={{ marginLeft: '-5px' }}>
            <Col span={24}>
              <Form.Item label='超出医保支付价时，需要患者签字确认' name=''>
                <Col
                  style={{
                    width: '110px',
                    lineHeight: '32px',
                    marginBottom: '10px',
                    position: 'relative',
                  }}
                >
                  <Col style={{ marginLeft: '4px' }}>
                    <Form.Item
                      name={Checks.OVERLIMITSIGN}
                      valuePropName='checked'
                    >
                      <Checkbox>开启</Checkbox>
                    </Form.Item>
                  </Col>
                </Col>
              </Form.Item>
              <div
                style={{
                  position: 'absolute',
                  top: 5,
                  left: 400,
                  color: '#666666',
                }}
              >
                （部分票据/发票生效）
              </div>
            </Col>
          </Row>
        </div>
        <Row
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '20px',
          }}
        >
          <Button
            style={{ marginRight: '20px', color: '#666666' }}
            onClick={() => {
              onCancel()
              form.resetFields()
            }}
          >
            取消
          </Button>
          <Button
            style={{ background: '#027AFF', color: '#FFFFFF' }}
            htmlType='submit'
          >
            确定
          </Button>
        </Row>
      </Form>
    </Modal>
  )
}
