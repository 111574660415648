import {
  CaretDownFilled,
  ExclamationCircleFilled,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Space,
  Table,
} from 'antd'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectInsuranceArray } from '../../../../app/applicationSlice'
import { RootDispatch } from '../../../../app/store'
import {
  DateRangeType,
  DateSelect,
  getDateRange,
  TJFXTime,
} from '../../../../compnents/form/DateSelect'
import { EditableList } from '../../../../compnents/list/EditableList'
import { columns } from './columns'
import styles from './warehouseChangeReport.module.css'
import { ThemeContext } from '../../../../theme/ThemeContext'
import {
  getWarehouseChangeReportList,
  getWarehouseChangeReportExport,
} from './warehouseChangeReportSlice'
import { PrintOutlined } from '../../../../compnents/icons/Icons'
import { getStorage, selectStorage } from '../../../durgsystem/durgsystemSlice'
import {
  DateTimeFormatSimple,
  DateTimeFormatSimpleOnlyOne,
} from '../../../../models/datetime'
import moment from 'moment'

const { Option } = Select

export interface WarehouseChangeReportRef {
  selectTabKey?: any
  oneTabKey?: any //一级tab的key
}

interface WarehouseChangeReportProps {
  selectTabKey?: any
  oneTabKey?: any
}
const WarehouseChangeReportList = (
  { selectTabKey, oneTabKey }: WarehouseChangeReportProps,
  ref: React.Ref<WarehouseChangeReportRef>
): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const durgsystemData = useSelector(selectStorage)

  const theme = useContext(ThemeContext)

  const [form] = Form.useForm()

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  const [timeHead, timeTail] = getDateRange(DateRangeType.ThisMonth)

  const [releasingTimeFlag, setReleasingTime] = useState(false)

  const [isInTimeRange, setIsInTimeRange] = useState(false)

  const [params, setParams] = useState<any>({
    current: 1,
    size: 10,
    timeHead: timeHead,
    timeTail: timeTail,
    flag: 0,
  })

  useEffect(() => {
    setReleasingTime(false)
    dispatch(getStorage({ current: 1 }))
  }, [])

  const [page, setPage] = useState({ items: [], total: 0 })

  const getPage = () => {
    setPageLoading(true)
    dispatch(
      getWarehouseChangeReportList({
        ...params,
        current: params.current,
        size: params.size,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        setPage({
          items: res.records,
          total: res.total,
        })
      })
      .finally(() => setPageLoading(false))
  }

  const checkTimeRange = () => {
    const now = moment()
    const currentHour = now.hours()

    // 判断当前时间是否在给定的时间段内
    console.log(currentHour, now, 'currentHour')

    if (
      (currentHour >= 10 && currentHour < 12) ||
      (currentHour === 12 && now.minutes() === 0) ||
      (currentHour >= 14 && currentHour < 16) ||
      (currentHour >= 19 && currentHour <= 23) ||
      (currentHour >= 0 && currentHour < 6)
    ) {
      setIsInTimeRange(true)
    } else {
      setIsInTimeRange(false)
    }
  }

  useEffect(() => {
    checkTimeRange()
  }, [])

  const ListExport = () => {
    dispatch(
      getWarehouseChangeReportExport({
        ...params,
        timeHead: params?.timeHead
          ? moment(params?.timeHead).format(DateTimeFormatSimpleOnlyOne)
          : undefined,
        timeTail: params?.timeTail
          ? moment(params?.timeTail).format(DateTimeFormatSimpleOnlyOne)
          : undefined,
        releasingTime: releasingTimeFlag ? '99' : undefined,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        const content = res
        const blob = new Blob([content])
        const fileName = '诊疗费用统计导出数据.xlsx'
        const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL']
        const elink = document.createElement('a')
        if ('download' in elink) {
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = selfURL['createObjectURL'](blob)
          document.body.appendChild(elink)
          // 触发链接
          elink.click()
          selfURL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          navigator.msSaveBlob(blob, fileName)
        }
      })
      .then(() => {
        notification.success({
          message: '导出成功',
          duration: 3,
        })
      })
      .catch((res: any) => {
        notification.error({
          message: '导出失败：' + res,
          duration: 3,
        })
      })
  }

  useEffect(() => {
    if (selectTabKey == 0) {
      getPage()
    }
  }, [params, selectTabKey])

  useEffect(() => {
    form.setFieldsValue({
      flag: 0,
    })
  }, [])

  return (
    <Col className={styles.content}>
      <Col
        style={{
          flex: 1,
          backgroundColor: theme.pn,
          borderRadius: 10,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Form
          form={form}
          autoComplete='off'
          onFinish={(values) => {
            setParams({
              ...params,
              ...values,
              current: 1,
              timeHead: values?.timeHead ? values?.timeHead : timeHead,
              timeTail: values?.timeTail ? values?.timeTail : timeTail,
            })
          }}
        >
          <Row
            align='middle'
            justify='space-between'
            style={{ marginTop: 10, marginBottom: 20 }}
          >
            <Space>
              <Form.Item noStyle name='param'>
                <Input
                  placeholder='请输入商品名称/助记码条形码'
                  prefix={<SearchOutlined />}
                  style={{ width: 260 }}
                  onChange={form.submit}
                  autoComplete='off'
                  allowClear
                />
              </Form.Item>
              <Form.Item name='storehouseId' noStyle>
                <Select
                  placeholder='库房名称'
                  allowClear
                  style={{ width: 150 }}
                  // suffixIcon={<CaretDownFilled />}
                  onChange={() => {
                    form.submit()
                  }}
                >
                  {durgsystemData.map((v: any, i) => {
                    return (
                      <Option value={v.storehouseId} key={i}>
                        {v.storehouseName}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
              <Form.Item name='flag' noStyle>
                <Select
                  style={{ width: 150 }}
                  defaultValue={0}
                  allowClear={false}
                  placeholder='是否合并'
                  // suffixIcon={<CaretDownFilled />}
                  onChange={() => {
                    form.submit()
                  }}
                >
                  <Option value={0}>合并-否</Option>
                  <Option value={1}>合并-是</Option>
                </Select>
              </Form.Item>
              <DateSelect
                style={{ width: '8.5rem' }}
                labelPrefix=''
                allowClear={false}
                options={TJFXTime}
                namePrefix='time'
                placeholder='时间'
                initialValue={DateRangeType.ThisMonth}
                onChange={form.submit}
              />
              <Button
                type='primary'
                onClick={form.submit}
                loading={pageLoading}
              >
                查询
              </Button>
            </Space>
            <Space>
              <Button
                type='primary'
                onClick={() => {
                  ListExport()
                }}
              >
                导出
              </Button>
              {isInTimeRange ? (
                <div
                  style={{
                    opacity: 0,
                    cursor: 'pointer',
                    width: '10px',
                    height: '30px',
                  }}
                  onDoubleClick={() => {
                    alert('Service mode is open')
                    setReleasingTime(true)
                  }}
                ></div>
              ) : (
                <></>
              )}
            </Space>
          </Row>
        </Form>
        <EditableList
          bordered
          page={{
            items: page.items,
            current: params.current,
            size: params.size,
            total: page.total,
          }}
          loading={pageLoading}
          columns={columns(params.flag, params.size * (params.current - 1))}
          onChangePage={(current, size) => {
            setParams({ ...params, current, size: size || 10 })
          }}
        />
      </Col>
    </Col>
  )
}

export const WarehouseChangeReportLists = forwardRef(WarehouseChangeReportList)
