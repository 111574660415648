/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-08-18 17:44:48
 * @LastEditors: linxi
 * @LastEditTime: 2025-01-09 17:24:55
 */
import { PlusOutlined } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Form,
  Input,
  Modal,
  notification,
  Image,
  Radio,
  Row,
  Space,
  Upload,
  Spin,
  Select,
} from 'antd'
import { UploadFile } from 'antd/lib/upload/interface'
import { isNumber } from 'lodash'
import moment from 'moment'
import React, {
  forwardRef,
  ReactElement,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../../../app/store'
import FileUpload from '../../../../compnents/file/FileUpload'
import { getBase64 } from '../../../../utils/StringUtils'
import { getUrl } from '../../diagnosis/diagnosisSlice'
import styles from './RecipeItemModal.module.css'
import { User } from '../../../../models/user'
import { getDoctorList, getSFDoctorListAsync } from '../../../sales/DoctorAccont/doctorAuditSlice'
import { getbuildPDFAsync } from '../recipeListEditorSlice'

export interface outRecipeRef {
  reset: () => void
}
interface OutRecipeProps {
  treatmentId?: any
  recipeId?: string
  onOk?: (v: any) => void
  onCancel: () => void
  visible: boolean
  loading?: boolean
}

const OutRecipeModal = (
  {
    treatmentId,
    recipeId,
    onOk,
    visible,
    onCancel,
    loading = false,
  }: OutRecipeProps,
  ref: React.Ref<outRecipeRef>
): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const [isShowNo, setIsShowNo] = useState(false)

  const [uploadImgSrc, setUploadImgSrc] = useState('')

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [generateLoading, setGenerateLoading] = useState(false)

  const [base64PdfData, setBase64PdfData] = useState('')

  const [fileName, setFileName] = useState('')

  const [doctors, setDoctors] = useState<User[]>([])

  useEffect(() => {
    if(visible)
    dispatch(getSFDoctorListAsync())
      .then(unwrapResult)
      .then((doctors) => setDoctors(doctors))
  }, [visible])

  const close = () => {
    onCancel()
    setBase64PdfData('')
    setUploadImgSrc('')
    setFileName('')
  }

  const required = {
    rules: [
      {
        required: true,
      },
    ],
  }

  useImperativeHandle(ref, () => ({
    reset: () => {
      form.resetFields()
      setUploadImgSrc('')
      setBase64PdfData('')
    },
  }))

  useEffect(() => {
    if(visible){
      setBase64PdfData('')
      setUploadImgSrc('')
      setFileName('')
    }
  }, [visible])
  

  return (
    <Modal
      title={'医保外配处方设置'}
      width={800}
      visible={visible}
      onCancel={close}
      footer={null}
      bodyStyle={{ height: 450 }}
      maskClosable={false}
      keyboard={false}
      closable={false}
      className={styles.outRecipeModal}
    >
      <Spin spinning={generateLoading} tip={'文件生成中'} delay={500}>
        <Form
          form={form}
          labelAlign='right'
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 10 }}
          initialValues={{
            rxCotnFlag: 0,
          }}
          onFinish={(vs) => {
            const data = {
              ...vs,
              rxFile: uploadImgSrc.split(',')[1] || vs?.rxFile,
            }
            onOk && onOk(data)
          }}
        >
          <Form.Item label='是否续方' name='rxCotnFlag' {...required}>
            <Radio.Group
              onChange={(v) => {
                const value = v.target.value
                setIsShowNo(value == 1)
              }}
            >
              <Radio value={1}> 是 </Radio>
              <Radio value={0}> 否 </Radio>
            </Radio.Group>
          </Form.Item>
          {isShowNo && (
            <Form.Item label='续方的原处方编号' name='initRxno' {...required}>
              <Input />
            </Form.Item>
          )}
          <Form.Item
            label='处方有效天数'
            name='validDays'
            rules={[
              { required: true },
              {
                pattern: new RegExp(/^[1-9]\d*$/, 'g'),
                message: '请输入数字',
              },
            ]}
          >
            <Input
              addonAfter='天'
              onChange={(e) => {
                const reg = new RegExp(/^[1-9]\d*$/, 'g')
                if (reg.test(e.target.value)) {
                  form.setFieldsValue({
                    validEndTime: moment()
                      .add(e.target.value, 'd')
                      .format('YYYY-MM-DD'),
                  })
                }
              }}
            />
          </Form.Item>
          <Form.Item label='有效截止时间' name='validEndTime'>
            <Input disabled />
          </Form.Item>
          <Form.Item
            label='处方原件上传'
            name='rxFile'
            rules={[
              {
                required: true,
                message: '请上传文件',
              },
            ]}
          >
            <div className={styles.uploadImg} style={{ position: 'relative' }}>
              <Upload
                action={async (file: any) => {
                  setFileName(file.name)
                  setUploadImgSrc((await getBase64(file)) as string)
                  setBase64PdfData('')

                  return ''
                }}
                listType='picture'
                maxCount={1}
                showUploadList={false}
                accept={'.pdf'}
                beforeUpload={(file) => {
                  if (file.type.includes('pdf')) {
                    if (file.size / 1024 / 1024 < 1) {
                      return true
                    } else {
                      notification.info({
                        message: 'PDF文件不能大于1M,请重新选择!',
                      })
                      return false
                    }
                  }
                  notification.info({ message: '请上传PDF文件' })
                  return false
                }}
              >
                <Button icon={<PlusOutlined />}>上传附件</Button>
              </Upload>
              <span style={{ position: 'absolute', top: '4px', left: '110px' }}>
                <span style={{ marginLeft: '20px' }}>或</span>
                <Button
                  style={{ color: '#027AFF', cursor: 'pointer' }}
                  type='text'
                  loading={generateLoading}
                  onClick={() => {
                    setGenerateLoading(true)
                    dispatch(
                      getbuildPDFAsync({
                        templateCode: 'ExternalRecipe',
                        treatmentId: treatmentId,
                        recipeId: recipeId,
                      })
                    )
                      .then(unwrapResult)
                      .then((v: any) => {
                        notification.success({
                          message: '操作成功',
                        })
                        const data = new Date()
                        const time = data.getTime()
                        setFileName(time + '.pdf')
                        setBase64PdfData('data:application/pdf;base64,' + v)
                        setUploadImgSrc('')
                        form.setFieldsValue({ rxFile:  v });
                        setGenerateLoading(false)
                      })
                      .catch((err) =>{
                        setGenerateLoading(false)
                      })
                  }}
                >
                  生成系统文件
                </Button>
              </span>
              {base64PdfData && (
                <div style={{ marginTop: 15 }}>
                  <span>{fileName}</span>
                  <span
                    style={{
                      marginLeft: '20px',
                      color: '#027AFF',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setIsModalVisible(true)
                    }}
                  >
                    查看
                  </span>
                </div>
              )}
              {uploadImgSrc && <div style={{ marginTop: 15 }}>{fileName}</div>}
            </div>
          </Form.Item>
          <Form.Item label='审方医生' name='docterId'>
            <Select
              allowClear
              style={{ width: 180 }}
              placeholder='审方医生'
              // suffixIcon={
              //   <CaretDownFilled style={{ color: theme.tc3 }} />
              // }
            >
              {doctors.map((d) => (
                <Select.Option key={d.id} value={d.id}>
                  {d.realname}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
        <Row justify='end' className={styles.outRecipeFooter}>
          <Space>
            <Button onClick={close}>取消</Button>
            <Button type='primary' onClick={form.submit} loading={loading}>
              确认
            </Button>
          </Space>
        </Row>
      </Spin>
      <Modal
        title={'预览'}
        visible={isModalVisible}
        footer={null}
        width={'80%'}
        onCancel={() => {
          setIsModalVisible(false)
        }}
      >
        <div style={{ height: '650px' }}>
          <iframe
            title='PDF Viewer'
            style={{ width: '100%', height: '100%' }}
            src={base64PdfData}
          />
        </div>
      </Modal>
    </Modal>
  )
}

export default forwardRef(OutRecipeModal)
