import { unwrapResult } from '@reduxjs/toolkit'
import { Col, Form, Input, Modal, notification, Select } from 'antd'
import React, { useState, useEffect, useRef, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectUserId } from '../../app/applicationSlice'
import { RootDispatch } from '../../app/store'
import { User } from '../../models/user'
import { ThemeContext } from '../../theme/ThemeContext'
import { getAllDoctorList } from '../A-toothModule/toothHome/toothHomeSlice'
import { getDoctorsTimeStepAsync } from '../registration/register/registerSlice'
import { getDoctorList } from '../sales/DoctorAccont/doctorAuditSlice'
import {
  getAppointmentScheduleTimeAsync,
  getScheduleAdditionalAsync,
} from './appointmentSelice'

interface TemporarySchedulingModalProps {
  visible: boolean
  scheduleDate?: any
  onOk: () => void
  onCancel: () => void
  refreshList: () => void
}
export const TemporarySchedulingModal = ({
  visible,
  scheduleDate,
  onOk,
  onCancel,
  refreshList,
}: TemporarySchedulingModalProps) => {
  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const userId = useSelector(selectUserId)

  const { Option } = Select

  const [doctorList, setDoctorList] = useState<any>([]) //  医生数据

  const [doctors, setDoctors] = useState<User[]>([]) //所有医生

  const [modalBtnLoading, setModalBtnLoading] = useState(false) //  弹窗确定按钮 loading

  const [startTimeList, setStartTimeList] = useState([]) //预约时间-开始

  const [endTimeList, setEndTimeList] = useState<any>() //预约时间-结束

  console.log(scheduleDate, 'scheduleDate')

  const getAllDoctor = (id?: string) => {
    //获取医生数据
    dispatch(
      getAllDoctorList({
        size: 1000,
        state: 1,
        stationCategory: 1,
      })
    )
      .then(unwrapResult)
      .then(({ records }) => {
        setDoctorList(
          records
            .filter((v: any) => v.kssxbz != 1)
            .filter((v: any) => v.state === 1)
        ) // 筛选已注销科室的医生
      })
    dispatch(getDoctorList())
      .then(unwrapResult)
      .then((doctors: any) =>
        setDoctors(doctors.filter((v: any) => v.state == 1))
      )
  }

  useEffect(() => {
    if (visible) {
      getAllDoctor()
      form.setFieldsValue({
        operatorId: userId,
      })
    }
  }, [visible])

  return (
    <Modal
      title='临时排班'
      width={500}
      visible={visible}
      onCancel={() => {
        onCancel()
        form.resetFields()
        setModalBtnLoading(false)
      }}
      confirmLoading={modalBtnLoading}
      onOk={() => {
        form.submit()
        setModalBtnLoading(false)
      }}
      centered
    >
      <div>
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          onFinish={(values) => {
            setModalBtnLoading(true)
            const params = {
              operatorId: values?.operatorId,
              doctorId: values?.doctorId,
              startTime: values?.time?.startTime,
              endTime: values?.time?.endTime,
              deactivativeCause: values?.deactivativeCause,
              scheduleDate: scheduleDate,
            }
            dispatch(getScheduleAdditionalAsync(params))
              .then(unwrapResult)
              .then((v: any) => {
                notification.success({
                  message: '保存成功',
                })
                onOk()
                refreshList()
                form.resetFields()
                setModalBtnLoading(false)
              })
              .catch(() => {
                // onCancel()
                // form.resetFields()
                setModalBtnLoading(false)
              })
          }}
        >
          <Col span={24}>
            <Form.Item
              name='operatorId'
              label='排班人员'
              rules={[{ required: true }]}
            >
              <Select
                placeholder='请选择'
                allowClear
                onChange={(e) => {
                  console.log(e, '33333333')
                }}
              >
                {doctors?.map((d: any) => (
                  <Select.Option key={d.id} value={d.id}>
                    {d.realname}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name='doctorId'
              label='排班医生'
              rules={[{ required: true }]}
            >
              <Select
                placeholder='请选择'
                allowClear
                onChange={(e) => {
                  setStartTimeList([])
                  setEndTimeList([])
                  const params = {
                    doctorId: e,
                    scheduleDate: scheduleDate,
                  }
                  dispatch(getAppointmentScheduleTimeAsync(params))
                    .then(unwrapResult)
                    .then((res: any) => {
                      console.log(res, 'res?.[0]')

                      setEndTimeList(res?.[0]?.disableEndTimes)
                      setStartTimeList(res?.[0]?.disableStartTimes)
                    })
                }}
              >
                {doctorList?.map((d: any) => (
                  <Select.Option key={d.id} value={d.id}>
                    {d.realname}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              style={{ height: '32px' }}
              label='预约时间'
              name='time'
              rules={[{ required: true, message: ' ' }]}
            >
              <Input.Group compact>
                <Form.Item
                  name={['time', 'startTime']}
                  rules={[{ required: true, message: '请选择时间' }]}
                >
                  <Select style={{ width: '134px' }}>
                    {startTimeList?.length &&
                      startTimeList.map((v: any) => (
                        <Option key={v} value={v}>
                          {v}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
                &nbsp;&nbsp; ~ &nbsp;&nbsp;
                <Form.Item
                  name={['time', 'endTime']}
                  rules={[{ required: true, message: '请选择时间' }]}
                >
                  <Select style={{ width: '134px' }}>
                    {endTimeList?.length &&
                      endTimeList.map((v: any) => (
                        <Option key={v} value={v}>
                          {v}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
        </Form>
      </div>
    </Modal>
  )
}
