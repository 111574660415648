/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-06-29 11:13:58
 * @LastEditors: linxi
 * @LastEditTime: 2025-01-14 15:09:24
 */
import { LeftOutlined, ReloadOutlined, RightOutlined } from '@ant-design/icons'
import { Row, Space, Button, Popconfirm, Tag, Modal, DatePicker, notification, Checkbox } from 'antd'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import styles from './appointment.module.css'
import FullCalendar, { DateSelectArg, EventClickArg } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import resourceTimeGrid from '@fullcalendar/resource-timegrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
// import { INITIAL_EVENTS, INITIAL_Value } from './event-utils'
import {
  AppointmentingIcon,
  AppointmentingSureIcon,
  OutpatientedIcon,
  OverTimeIcon,
  RegistrationedIcon,
  RunOffIcon,
} from '../../compnents/icons/Icons'
import { unwrapResult } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { traceRoute } from '../../layouts/layoutSlice'
import { getGenderName } from '../../models/user'
import {
  dayWeekAsync,
  cancelAppointmentAsync,
  selectIsUpdate,
  setIsUpdate,
  sureAppointmentAsync,
} from './appointmentSelice'
import { getBgc, getSVGSrc } from '../../models/appointment'
import { useHistory } from 'react-router-dom'
import { setAppointmentIdSlice } from '../registration/register/registerSlice'
import { getAge } from '../../utils/StringUtils'
import { selectEdition } from '../../app/applicationSlice'
import { AppointmentRegister } from '../registration/register/appointmentRegister'
import { RegisteredModal } from '../A-toothModule/toothHome/modal/registeredModal'
import { AppointmentDetail } from './list/detail'
import { AddPatientModal } from '../A-toothModule/toothHome/modal/addPatientModal'
import { SubscribeModal } from '../A-toothModule/toothHome/modal/subscribeModal'
import { updatePatientTimeAsync } from '../A-toothModule/patient/patientSlice'

export const AppointmentWeek = (props: { activeKey?:any,onlyThisAccount?: any }) => {
  const edtion = useSelector(selectEdition) //区分口腔版还是普通

  const history = useHistory()

  const { RangePicker } = DatePicker

  const dispatch = useDispatch<RootDispatch>()

  const [data, setData] = useState<any>()

  const ref = useRef<any>()

  const modalRef = useRef<any>()

  const isUpdate = useSelector(selectIsUpdate)

  const [lastWeekTime, setLastWeekTime] = useState(0)

  const [weekTime, setWeekTime] = useState('')

  const [workTime, setWorkTime] = useState(['06:00:00', '21:01:00']) // 营业时间

  const [weekList, setWeekList] = useState<any>() // 营业时间

  const [timeStep, setTimeStep] = useState('00:15')

  const [allData, setAllData] = useState<any>([])

  const [cardData, setCardData] = useState<any>([])

  const [isModalVisible, setIsModalVisible] = useState(false)

  const hisVersion = useSelector(selectEdition) //his版本 0通用 1口腔

  const [modalType, setModalType] = useState(0) // 弹窗状态

  const [tableEditId, setTableEditId] = useState('') // 修改 ID

  const [registeredModal, setRegisteredModal] = useState(false) //挂号弹窗

  const [detailVisible, setDetailVisible] = useState(false) //详情弹窗

  const [addPatientModal, setAddPatientModal] = useState(false) // 新增患者弹窗

  const [appointmentRegisterVisible, setAppointmentRegisterVisible] = useState(
    false
  ) // his 版新增预约

  const [modalPatientID, setModalPatientID] = useState('') //弹窗 患者ID

  const [subscribeModal, setSubscribeModal] = useState(false) // 工作站-预约弹窗

  const [visibleTag, setVisibleTag] = useState('false')

  const [loading, setLoading] = useState(false)

  const [sureVisible, setSureVisible] = useState(false)

  const [sureData, setSureData] = useState<any>({})

  const [checked, setChecked] = useState(false)

  const [isSendMessage, setIsSendMessage] = useState<any>(false)

  useEffect(() => {
    if (detailVisible) {
      setVisibleTag('true')
    } else {
      setVisibleTag('false')
    }
  }, [detailVisible])

   useEffect(() => {
     if (props?.activeKey == '2') {
       if (props?.onlyThisAccount == true || props?.onlyThisAccount == false) {
         getpageData()
       }
     }
   }, [props?.onlyThisAccount, props?.activeKey])

  useEffect(() => {
    setIsModalVisible(false)
    setWeekTime(getLastWeek(lastWeekTime))
  }, [lastWeekTime])

  const getLastWeek = (i: number) => {
    const weekOfDay = parseInt(moment().format('E')) //计算今天是这周第几天
    const last_monday = moment()
      .subtract(weekOfDay + 7 * i - 1, 'days')
      .format('YYYY-MM-DD') //周一日期
    const last_sunday = moment()
      .subtract(weekOfDay + 7 * (i - 1), 'days')
      .format('YYYY-MM-DD') //周日日期
    return `${last_monday} 至 ${last_sunday}`
  }
  const getpageData = () => {
    const day = weekTime.split('至')
    const data = {
      type: 1,
      endTime: day[1]?.trim(),
      startTime: day[0]?.trim(),
      onlyDisplayThisAccount: props?.onlyThisAccount,
    }
    setWeekList([moment(data?.startTime), moment(data?.endTime)])
    if (data?.endTime && data?.startTime)
      dispatch(dayWeekAsync(data))
        .then(unwrapResult)
        .then((res: any) => {
          if (res && Object.keys(res).length) {
            setAllData(res.appointmentRecord)
            // setWorkTime([`${res.businessStartTime}:30`, `${res.businessEndTime}:30`])
            setWorkTime([`${res.businessStartTime}:30`, `23:30:00`])
            setData(
              res.appointmentRecord?.map((el: any) => {
                return {
                  frontContent: {
                    sex: getGenderName(el?.sex),
                    age: el?.ageYear || '1',
                    phone: el?.phone || '',
                    appointmentTime:
                      // el?.appointmentDate +
                      //   ' ' +
                      el?.appointmentStartTime + '~' + el?.appointmentEndTime ||
                      '',
                    appointmentDeptName: el?.appointmentDepartmentName || '',
                    appointmentDoctorName: el?.appointmentDoctorName || '',
                    appointmentItem: el?.appointmentItem || '',
                    appointmentComment: el?.appointmentComment || '',
                  },
                  id: el.id,
                  resourceId: el.appointmentDoctorId.toString(),
                  start:
                    el.appointmentDate + 'T' + el.appointmentStartTime + ':00',
                  end: el.appointmentDate + 'T' + el.appointmentEndTime + ':00',
                  constraint: el.appointmentState,
                  title: el.name,
                  color: getBgc(el.appointmentState),
                }
              })
            )
            setTimeStep(`00:${res.timeScale}`)
            dispatch(setIsUpdate(false))
          }
        })
    setIsModalVisible(false)
  }

  useEffect(() => {
    getpageData()
    sessionStorage.setItem(
      location.pathname,
      JSON.stringify({ weekTime: weekTime, tabBar: '2' })
    )
  }, [weekTime])

  useEffect(() => {
    if (!isUpdate) return
    setTimeout(() => {
      getpageData()
    }, 100)
    sessionStorage.setItem(
      location.pathname,
      JSON.stringify({ weekTime: weekTime, tabBar: '2' })
    )
  }, [isUpdate])

  // 点击表格的事件
  const handleDateSelect = (selectInfo: DateSelectArg) => {
    setIsModalVisible(false)
  }

  // 表格已有事件
  const handleEventClick = (clickInfo: EventClickArg) => {
    setCardData(allData.find((el: any) => el.id == clickInfo.event.id))

    setIsModalVisible(true)
    const x = clickInfo.jsEvent.pageX
    const y = clickInfo.jsEvent.pageY
    const divWidth = clickInfo.el.offsetWidth
    const left = x + 300
    let positionX = 0
    let positionY = 0
    // 若为右侧，则框弹出位置在左侧
    if (left > document.documentElement.clientWidth - 300) {
      positionX = x - modalRef.current.clientWidth - 3
    } else {
      positionX = x + 3
    }
    if (
      y + modalRef.current.clientHeight >
      document.documentElement.clientHeight
    ) {
      positionY =
        document.documentElement.clientHeight - modalRef.current.clientHeight
    } else {
      positionY = y
    }
    modalRef.current.style.top = positionY + 'px'
    modalRef.current.style.left = positionX + 'px'
  }

  const flag: any =
    localStorage.getItem("isSendMessage") == "true" ? true : false;

  useEffect(() => {
    setIsSendMessage(flag);
  }, [flag]);

  return (
    <div className={styles.main}>
      <Row justify="space-between" align="middle">
        <Space style={{ flex: 1, marginTop: "-4px" }}>
          <Button
            type="primary"
            onClick={() => {
              setLastWeekTime(0);
              ref?.current?._calendarApi.today();
            }}
          >
            回到本周
          </Button>
          <div
            className="input-group"
            style={{ display: "inline-flex" }}
            id="div_week_date"
          >
            <Button
              icon={<LeftOutlined />}
              onClick={() => {
                setLastWeekTime(lastWeekTime + 1);
                ref?.current?._calendarApi.prev();
              }}
            />
            {/* <div style={{ margin: '0 10px' }}>
              <RangePicker
                value={weekList}
                onChange={() => {
                  // setLastWeekTime(lastWeekTime - 1)
                  // ref?.current?._calendarApi.gotoDate()
                }}
              />
            </div> */}
            <div className={styles.weekTimeWrap}>{weekTime}</div>
            <Button
              icon={<RightOutlined />}
              onClick={() => {
                setLastWeekTime(lastWeekTime - 1);
                // 动态追加一行
                // ref?.current?._calendarApi.addResource({
                //   id: 'e',
                //   title: 'Room E'
                // })
                // 添加数据
                // ref?.current?._calendarApi.addEventSource(INITIAL_Value)
                ref?.current?._calendarApi.next();
              }}
            />
          </div>
        </Space>
        <ReloadOutlined
          style={{ fontSize: "20px", marginRight: 40 }}
          onClick={() => {
            getpageData();
          }}
        />
      </Row>
      <div className={styles.container}>
        {/* {renderSidebar()} */}
        <FullCalendar
          ref={ref}
          schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            resourceTimeGrid,
            interactionPlugin,
          ]}
          // headerToolbar={{
          //   left: 'prev,next today',
          //   center: 'title',
          //   right: 'dayGridMonth,timeGridWeek,timeGridDay'
          // }}
          initialView="timeGridWeek"
          events={data} // 数据源
          // editable={true}
          selectable={true}
          // selectMirror={true}
          dayMaxEvents={true}
          initialEvents={data} // alternatively, use the `events` setting to fetch from a feed
          select={handleDateSelect}
          eventClick={handleEventClick}
          // eventsSet={handleEvents} // called after events are initialized/added/changed/removed
          allDaySlot={false} // 是否展示全天
          slotDuration={`${timeStep}:00`} // 时间间隔
          slotEventOverlap={false} // 是否可叠加覆盖
          locale={"zh-cn"} // 语言模式
          stickyHeaderDates={true} // 表头固定
          weekNumbers={true} // 是否显示第几周
          firstDay={1} // 从周几开始
          buttonText={{
            today: "回到本周",
          }}
          slotLabelInterval={timeStep} //显示时段的间隔
          slotMinTime={workTime[0]} // 左侧开始时间
          slotMaxTime={workTime[1]} // 左侧结束时间
          weekText={"No."} // 左上角展示的第几周
          // dayMinWidth={200}
          // eventMinWidth={200}
          eventContent={(arg) => {
            const node = document.createElement("div");
            node.innerHTML =
              arg.backgroundColor != "#e8e8e8"
                ? `<div style="display: flex; align-items:start;justify-content: flex-start;">
              <img
                src="${getSVGSrc(Number(arg.event.constraint))}"
                width="14"
                height="14"
                style="margin-top:4px"
              />
              <span style="font-size:14px;color:#222;margin-left:4px">
                <span style="font-size:17px;color:#222;margin-left:4px">${
                  arg.event?.title
                }</span>&nbsp;&nbsp;${
                    arg.event?.extendedProps?.frontContent?.sex
                  }&nbsp;${arg.event?.extendedProps?.frontContent?.age}岁
              <span>${
                arg.event?.extendedProps?.frontContent?.appointmentItem
                  ? "</br>预约项目：" +
                    arg.event?.extendedProps?.frontContent?.appointmentItem
                  : ""
              }
              </br>预约时间：${
                arg.event?.extendedProps?.frontContent?.appointmentTime
              } 
              </span>
              </span>
                </div>`
                : "";
            const arrayOfDomNodes = [node];
            return { domNodes: arrayOfDomNodes };
          }}
        />

        {isModalVisible && (
          <div ref={modalRef} className={styles.modal}>
            <h2>
              <a
                onClick={() => {
                  if (1 === edtion) {
                    dispatch(updatePatientTimeAsync(cardData?.patientId));
                    history.push({
                      pathname: "/toothPatient",
                      state: { selectId: cardData?.patientId },
                    });
                  } else {
                    dispatch(
                      traceRoute({
                        name: "门诊统计",
                        path: "/patient/detail",
                        query: `patientId=${cardData?.patientId}`,
                      })
                    );
                  }
                }}
              >
                {cardData.name}{" "}
              </a>
              <Tag color="#FFB453">
                {cardData.receiveTag == 1 ? "复诊" : "初诊"}
              </Tag>
            </h2>
            <section>性别：{getGenderName(cardData.sex)}</section>
            <section>
              年龄：{getAge(cardData?.ageYear, cardData?.ageMonth)}
            </section>
            <section>手机号：{cardData.phone}</section>
            <section>
              预约时间：{cardData?.appointmentDate + "  "}
              {cardData.appointmentStartTime}-{cardData.appointmentEndTime}
            </section>
            <section>预约科室：{cardData.appointmentDepartmentName}</section>
            <section>预约医生：{cardData.appointmentDoctorName}</section>
            <section>预约项目：{cardData?.appointmentItem || "-"}</section>
            <section>预约备注：{cardData.appointmentComment}</section>
            <Row justify="center" style={{ marginTop: 15 }}>
              {(cardData.appointmentState == 0 ||
                cardData.appointmentState == 4 ||
                cardData.appointmentState == 9) && (
                <Space>
                  {cardData?.appointmentState == 0 && (
                    // <Popconfirm
                    //   title='确认进行此操作？'
                    //   onConfirm={() => {
                    //     dispatch(sureAppointmentAsync(cardData?.id))
                    //       .then(unwrapResult)
                    //       .then(() => {
                    //         getpageData()
                    //       })
                    //   }}
                    //   okText='确认'
                    //   cancelText='取消'
                    // >
                    <Button
                      size="small"
                      type="primary"
                      onClick={() => {
                        setSureData(cardData);
                        setSureVisible(true);
                        setChecked(false);
                      }}
                    >
                      确认
                    </Button>
                    // </Popconfirm>
                  )}
                  <Button
                    size="small"
                    type="primary"
                    onClick={() => {
                      if (hisVersion == 1) {
                        setTableEditId(cardData.id);
                        setRegisteredModal(true);
                      } else {
                        dispatch(
                          traceRoute({
                            name: '挂号',
                            path: '/registration',
                          })
                        )
                        localStorage.setItem('appointFlag','appointState') 
                        dispatch(setAppointmentIdSlice(cardData?.id));
                      }
                    }}
                  >
                    挂号
                  </Button>
                  <Button
                    size="small"
                    type="primary"
                    onClick={() => {
                      if (hisVersion == 1) {
                        setTableEditId(cardData.id);
                        setModalType(2);
                        setAppointmentRegisterVisible(true);
                      } else {
                        history.push({
                          pathname: "/addEditAppointment",
                          state: {
                            id: cardData?.id,
                          },
                        });
                      }
                    }}
                  >
                    修改
                  </Button>
                  <Button
                    size="small"
                    type="primary"
                    onClick={() => {
                      if (hisVersion == 1) {
                        setTableEditId(cardData.id);
                        setDetailVisible(true);
                      } else {
                        history.push({
                          pathname: "/appointmentDetail",
                          state: {
                            id: cardData?.id,
                            appointmentState: cardData?.appointmentState,
                            tag: '0', //专业版标志位
                          },
                        });
                      }
                    }}
                  >
                    查看详情
                  </Button>
                </Space>
              )}
              {(cardData?.appointmentState == 0 ||
                cardData?.appointmentState == 4 ||
                cardData?.appointmentState == 9) && (
                <Popconfirm
                  title={
                    <div>
                      <p>确认进行此操作？</p>
                      <p style={{ position: "relative", left: "-20px" }}>
                        <Checkbox
                          checked={isSendMessage}
                          onChange={(e) => {
                            setIsSendMessage(e.target.checked);
                            localStorage.setItem(
                              "isSendMessage",
                              JSON.stringify(e.target.checked)
                            );
                          }}
                        >
                          是否向患者发送取消预约的通知短信
                        </Checkbox>
                      </p>
                    </div>
                  }
                  onConfirm={() => {
                    dispatch(
                      cancelAppointmentAsync({
                        id: cardData?.id,
                        sendSMSFlag: Number(isSendMessage),
                      })
                    )
                      .then(unwrapResult)
                      .then(() => {
                        getpageData();
                      });
                  }}
                  okText="确认"
                  cancelText="取消"
                >
                  <Button size="small" style={{ marginLeft: "8px" }}>
                    取消预约
                  </Button>
                </Popconfirm>
              )}
              {(cardData?.appointmentState == 1 ||
                cardData?.appointmentState == 2) && (
                <Space>
                  <Button
                    size="small"
                    type="primary"
                    onClick={() => {
                      if (cardData?.invalidFlag == 1) {
                        notification.info({
                          message: "挂号已取消，不能继续就诊",
                        });
                      } else {
                        if (1 === edtion) {
                          dispatch(updatePatientTimeAsync(cardData?.patientId));
                          history.push({
                            pathname: "/toothPatient",
                            state: { selectId: cardData?.patientId },
                          });
                        } else {
                          if (cardData?.registrationId) {
                            dispatch(
                              traceRoute({
                                name: "医生门诊",
                                path: "/treatment",
                                query: `registrationId=${cardData?.registrationId}`,
                              })
                            );
                          }
                        }
                      }
                    }}
                  >
                    就诊
                  </Button>
                </Space>
              )}
            </Row>
          </div>
        )}
      </div>
      <Row justify="end" style={{ marginTop: 5 }}>
        <Space>
          <AppointmentingIcon className={styles.appointmentIcon} /> 待确认
          <AppointmentingSureIcon className={styles.appointmentIcon} /> 已确认
          <OverTimeIcon className={styles.appointmentIcon} /> 超时
          <RegistrationedIcon className={styles.appointmentIcon} /> 已挂号
          <OutpatientedIcon className={styles.appointmentIcon} /> 已就诊
          <RunOffIcon className={styles.appointmentIcon} /> 流失
        </Space>
      </Row>
      <Modal
        width={1000}
        title={modalType == 1 ? "新增预约" : "修改预约"}
        destroyOnClose
        visible={appointmentRegisterVisible}
        onCancel={() => setAppointmentRegisterVisible(false)}
        footer={null}
      >
        <AppointmentRegister
          status={modalType}
          AppointmentId={Number(tableEditId)}
          onsubmit={() => {
            getpageData();
            setAppointmentRegisterVisible(false);
            setTableEditId("");
          }}
          onCancel={() => {
            setAppointmentRegisterVisible(false);
            setTableEditId("");
          }}
          addPatient={() => {
            setAppointmentRegisterVisible(false);
            setAddPatientModal(true);
          }}
        />
      </Modal>

      {/* 工作站-挂号 */}
      <RegisteredModal
        title={0}
        appointmentId={tableEditId}
        patientId={modalPatientID} //新增患者-保存并挂号
        visible={registeredModal}
        onOk={() => {
          getpageData();
          setRegisteredModal(false);
        }}
        onCancel={() => {
          setRegisteredModal(false);
        }}
      />

      <Modal
        width={1000}
        title="查看详情"
        destroyOnClose
        visible={detailVisible}
        onCancel={() => setDetailVisible(false)}
        footer={null}
      >
        <div>
          <AppointmentDetail
            id={tableEditId}
            status={String(hisVersion)}
            visibleTag={visibleTag}
          />
        </div>
      </Modal>

      {/* 新增患者弹窗 */}
      <AddPatientModal
        visible={addPatientModal}
        onOk={(num, patientId) => {
          setModalPatientID(patientId || "");
          if (num == 2) {
            setRegisteredModal(true);
          } else if (num == 3) {
            setSubscribeModal(true);
          }
          setAddPatientModal(false);
        }}
        onCancel={() => {
          setAddPatientModal(false);
        }}
      />

      {/* 工作站-预约 */}
      <SubscribeModal
        visible={subscribeModal}
        patientId={modalPatientID} //患者 ID
        treatmentDoctorId={""} //就诊医生 ID
        onOk={() => {
          // notification.success({
          //   message: '新增成功，请刷新页面'
          // })
          setSubscribeModal(false);
        }}
        onCancel={() => setSubscribeModal(false)}
      />

      <Modal
        width={500}
        title="确认操作"
        confirmLoading={loading}
        destroyOnClose
        visible={sureVisible}
        onCancel={() => setSureVisible(false)}
        onOk={() => {
          setLoading(true);
          dispatch(
            sureAppointmentAsync({ id: sureData?.id, flag: checked ? 1 : 0 })
          )
            .then(unwrapResult)
            .then(() => {
              setLoading(false);
              setSureVisible(false);
              getpageData();
            });
        }}
      >
        <div style={{ paddingLeft: "10px" }}>
          <Checkbox
            checked={checked}
            onChange={() => {
              setChecked(!checked);
            }}
          >
            发送短信 {sureData?.tenantName && "-"} 您已成功预约 请在
            {sureData?.appointmentDate}&nbsp;&nbsp;
            {sureData?.appointmentStartTime}
            {sureData?.appointmentStartTime && sureData?.appointmentEndTime
              ? "~" + sureData?.appointmentEndTime
              : ""}
            到挂号处登记信息。地址：{sureData?.tenantAddress || "-"} 电话：
            {sureData?.tenantPhone || "-"}
          </Checkbox>
          {/* {sureData?.smsSendFlg == 1 ? (<p>该预约已发送过短信</p>) : ''} */}
        </div>
      </Modal>
    </div>
  );
}
