/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-08-29 17:21:08
 * @LastEditors: linxi
 * @LastEditTime: 2024-12-18 16:46:56
 */
import {
  Form,
  Col,
  Input,
  Modal,
  Row,
  notification,
  Checkbox,
  Button, message,
} from 'antd'
import React, {
  ReactElement,
  useContext,
  useState,
  useEffect,
  useRef,
} from 'react'
import styles from './Payments.module.css'
import { unwrapResult } from '@reduxjs/toolkit'
import { saveTraceCodesAsync } from './TraceCodeInputSlice'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import {
  drugUploadAAsync,
  drugUploadAsync,
  finishPutstorage,
  getTraceableCodeSave,
  multifunctionalUpAccountAsync,
  rectPutstorageAsync,
} from '../../putstorage/putstorageSlice'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { getInsuranceToSelfpayAsync } from '../../treatment/editor/recipeEditorSlice'
import { medicalSendHttp, strParse } from '../../../utils/MedicalUtils'
import {
  selectInsuranceItem,
  setInsurance,
} from '../../../layouts/insuranceSlice'
import { serverType } from '../../../services/commodity'
import { selectInsuranceArray } from '../../../app/applicationSlice'
import { getDeliveryList } from '../../delivery/deliverySlice'
import {numberPattern} from "../../../utils/RegExp";

interface TraceCodeInputModalProps {
  showCodeInputModal: boolean
  paymentResult: any
  onCancel: () => void
  onOk: () => void
  isShowUpload: boolean
  showUploadBtn?: boolean
  grrkFlag?: boolean
  receiptsId?: any
  recipeItemId?: any
  storehouseTag?: boolean
}

export const TraceCodeInputModal = ({
  showCodeInputModal,
  paymentResult,
  onCancel,
  onOk,
  isShowUpload,
  showUploadBtn = true,
  grrkFlag = false,
  recipeItemId,
  receiptsId,
  storehouseTag,
}: TraceCodeInputModalProps): ReactElement => {
  const [items, setItems] = useState([])
  const dispatch = useDispatch<RootDispatch>()

  const [messageApi, contextHolder] = message.useMessage()

  const [buttonLoading, setButtonLoading] = useState(false)

  const baseData = useSelector(selectInsuranceItem)

  const [trdnFlagJsn, setTrdnFlagJsn] = useState<any>({})

  const inputRefs: any = useRef(items.map(() => []))
  const [form] = Form.useForm()

  useEffect(() => {
    if (items) {
      const jsons: any = {}
      items?.map((item: any, itemIndex: number) => {
        {
          [...Array(Math.ceil(grrkFlag ? item?.count : item?.drugCount))].map(
            (_, colIndex) => {
              if (item?.traceCodeList?.[colIndex]) {
                jsons['tradeCode_' + item?.id + '_' + (Number(colIndex) + 1)] =
                  item?.traceCodeList?.[colIndex]
              }
              trdnFlagJsn[item?.id] = item?.trdnFlag
              if (localStorage.getItem('trdnFlag_' + item?.localItemIdFake) === '1'){
                trdnFlagJsn[item?.id] = 1
              }
            }
          )
        }
      })
      setTrdnFlagJsn({...trdnFlagJsn})
      if (jsons) {
        form.setFieldsValue({
          ...jsons,
        })
      }
    }
  }, [items])

  const handlePressEnter = (itemIndex: any, colIndex: any) => {
    const nextColIndex = colIndex + 1
    // 如果有下一个 Input，则聚焦
    if (inputRefs.current[itemIndex][nextColIndex]) {
      inputRefs.current[itemIndex][nextColIndex].focus()
    } else if (
      inputRefs.current[itemIndex + 1] &&
      inputRefs.current[itemIndex + 1][0]
    ) {
      // 否则如果有下一个 item，则聚焦该 item 的第一个 Input
      inputRefs.current[itemIndex + 1][0].focus()
    }
  }

  useEffect(() => {
    // 购入入库追溯码
    if (grrkFlag) {
      if (showCodeInputModal && paymentResult) {
        setItems(paymentResult)
      } else {
        setItems([])
        inputRefs.current = items?.map((item: any) =>
          Array(item?.count).fill(null)
        )
        form.resetFields()
        setTrdnFlagJsn({})
      }
    } else {
      if (showCodeInputModal && paymentResult) {
        const recipeItems = paymentResult?.materials
          ?.map((v: any) => {
            return v?.itemList
          })
          ?.flat(1)
        if (recipeItems) {
          setItems(
            recipeItems?.filter((v: any) => {
              if (
                v?.state == 3 &&
                (v?.billingCategory == '01' ||
                  v?.billingCategory == '02' ||
                  v?.billingCategory == '17')
              ) {
                return v
              }
            })
          )
        }
      } else {
        setItems([])
        inputRefs.current = items?.map((item: any) =>
          Array(item?.drugCount).fill(null)
        )
        form.resetFields()
        setTrdnFlagJsn({})
      }
    }
  }, [showCodeInputModal, grrkFlag, paymentResult])

  const saveTraceCodes = (isUpload: boolean) => {
    const treatmentId = paymentResult.treatmentId
    const paymentId = paymentResult.id

    if (!form.getFieldsValue()) {
      onCancel()
      return
    }
    // 购入入库保存
    if (grrkFlag) {
      const submitParam: any = []
      Object.entries(form.getFieldsValue()).forEach(([key, value]) => {
        // const recipeItemId = key.split('_')[1]
        const oneRow = {
          receiptsItemId: recipeItemId,
          receiptsId: receiptsId,
          traceCode: value,
        }
        submitParam.push(oneRow)
      })
      dispatch(
        getTraceableCodeSave({
          traceCodes: submitParam,
          receiptsId: receiptsId,
        })
      )
        .then(unwrapResult)
        .then((v: any) => {
          onOk()
          setButtonLoading(false)
        })
        .catch(() => {
          onCancel()
          setButtonLoading(false)
        })
    } else {
      const submitParam: any = []
      Object.entries(form.getFieldsValue()).forEach(([key, value]) => {
        const recipeItemId = key.split('_')[1]
        const oneRow = {
          treatmentId: treatmentId,
          paymentId: paymentId,
          recipeItemId: recipeItemId,
          traceCode: value,
          trdnFlag : trdnFlagJsn[recipeItemId] != 1 ? 0 : trdnFlagJsn[recipeItemId]
        }
        submitParam.push(oneRow)
      })
      dispatch(
        saveTraceCodesAsync({
          traceCodes: submitParam,
          treatmentId: treatmentId,
        })
      )
        .then(unwrapResult)
        .then((v: any) => {
          if (isUpload) {
            if (baseData?.insuranceCode === 'ZHEJIANG') {
              drugUploadA('requestString', [paymentResult.id])
            } else {
              drugUpload('requestString', treatmentId,undefined,undefined,paymentId)
            }
          } else {
            onOk()
            setButtonLoading(false)
          }
        })
        .catch(() => {
          onCancel()
          setButtonLoading(false)
        })
    }
  }

  // 发药单上传
  const drugUpload = (
    serverType: serverType,
    treatmentId: number | string,
    responseString?: string,
    recipeItemId?: string,
    paymentId?:number
  ) => {
    dispatch(
      drugUploadAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          treatmentId,
          shipmentState: 1,
          recipeItemId: recipeItemId || undefined,
          paymentId:paymentId
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          if (data?.isdone == '1') {
            notification.success({
              message: '上传完毕！',
            })
            return
          }
          const itemId = data.recipeItemId
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '发药单上传',
            },
            (call: any, err) => {
              !err &&
                drugUpload(
                  'response',
                  treatmentId,
                  JSON.stringify(call.netmessage),
                  itemId,
                  paymentId,
                )
            }
          )
        } else {
          if (res.successCode === '1') {
            drugUpload('requestString', treatmentId,undefined,undefined,paymentId)
          } else {
            setButtonLoading(false)
            onOk()
          }
        }
      })
  }

  // 发药单批量上传
  const drugUploadA = (
    serverType: serverType,
    paymentIds: number[] | string[],
    responseString?: string,
    recipeId?: number
  ) => {
    dispatch(
      drugUploadAAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          paymentIds,
          shipmentState: 1,
          recipeId: recipeId,
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          let data = null
          try {
            data = JSON.parse(res)
          } catch (e) {
            data = strParse(res)
          }
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '发药单上传',
            },
            (call: any, err) => {
              !err &&
                drugUploadA(
                  'response',
                  paymentIds,
                  JSON.stringify(call.netmessage),
                  recipeId
                )
              if (err) {
                setButtonLoading(false)
              }
            }
          )
        } else {
          setButtonLoading(false)
          onOk()
        }
      })
      .catch(() => setButtonLoading(false))
  }

  return (
      <>
        {contextHolder}
    <div>
      <Modal
        width={500}
        title='电子追溯码录入'
        destroyOnClose
        visible={showCodeInputModal}
        onCancel={() => {
          onCancel()
          form.resetFields()
          setTrdnFlagJsn({})
          setButtonLoading(false)
        }}
        footer={
          <Row>
            <Col flex={1}>
              <Button
                onClick={() => {
                  onCancel()
                  form.resetFields()
                  setTrdnFlagJsn({})
                  setButtonLoading(false)
                }}
              >
                取消
              </Button>
              {storehouseTag ? (
                <></>
              ) : (
                <Button
                  type={'primary'}
                  loading={buttonLoading}
                  onClick={() => {
                    form.validateFields().then((values) => {
                      setButtonLoading(true)
                      saveTraceCodes(false)
                    }).catch((e)=>{
                      message.destroy()
                      messageApi.open({
                        type: 'warning',
                        content: '追溯码应由数字组成，请重新输入。',
                        className: 'custom-class',
                        style: {
                          marginTop: '35vh',
                        },
                      })
                    })

                  }}
                >
                  确认
                </Button>
              )}
              {showUploadBtn ? (
                <Button
                  type={'primary'}
                  disabled={!isShowUpload}
                  loading={buttonLoading}
                  onClick={() => {
                    form.validateFields().then((values) => {
                      setButtonLoading(true)
                      Modal.confirm({
                        title: '',
                        content: (
                            <div>
                              <ExclamationCircleFilled
                                  style={{ color: '#FFAE55', fontSize: 18 }}
                              />
                              &nbsp; 是否上传发药信息至医保？
                            </div>
                        ),
                        okText: '确认',
                        cancelText: '取消',
                        icon: null,
                        onOk: () => {
                          saveTraceCodes(true)
                        },
                        onCancel: () => {
                          setButtonLoading(false)
                        },
                      })
                    }).catch((e)=>{
                      message.destroy()
                      messageApi.open({
                        type: 'warning',
                        content: '追溯码应由数字组成，请重新输入。',
                        className: 'custom-class',
                        style: {
                          marginTop: '35vh',
                        },
                      })
                    })


                  }}
                >
                  确认并上传
                </Button>
              ) : (
                ''
              )}
            </Col>
          </Row>
        }
        className={styles.traceCodeModal}
      >
        <Row className={styles.tips}>
          <Col span={24}>根据医保局最新的规定与要求：</Col>
          <Col span={24}>
            请在下方输入框中扫入每一盒药品包装上的电子追溯码。
          </Col>
          <Col span={24}>
            <span>包装</span>
            <span style={{ fontWeight: 'bold' }}>没有追溯码</span>
            <span>则不用扫入，系统会以</span>
            <span style={{ fontWeight: 'bold' }}>无码药品</span>
            <span>的方式上传。</span>
          </Col>
        </Row>

        {(!items?.length || items?.length == 0) && (
          <Row>
            <Col span={24} className={styles.errorMsg}>
              本次没有需要上传追溯码的药品。
            </Col>
          </Row>
        )}
        {items && items.length > 0 && (
          <>
            <Form form={form} autoComplete='off'>
              {items &&
                items?.map((item: any, itemIndex: number) => {
                  return (
                    <>
                      <Row key={itemIndex}>
                        <Col span={24} className={styles.traceNameCol}>
                          {item?.name} <span style={{color:'#333',fontWeight:'normal',marginRight:'6px'}}>{item?.packSpec}</span> {'  '}
                          {grrkFlag ? item?.count : item?.drugCount}
                          {item?.splitTag == 1
                            ? item?.splitUnit
                            : item?.accountUnit}
                          {!grrkFlag && (
                            <Checkbox
                              defaultChecked={item?.trdnFlag == 1 ? true : localStorage.getItem('trdnFlag_' + item?.localItemIdFake) === '1'}
                              style={{marginLeft : 10}}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  localStorage.setItem('trdnFlag_' + item?.localItemIdFake,'1')
                                  trdnFlagJsn[item?.id] = 1
                                } else {
                                  localStorage.removeItem('trdnFlag_' + item?.localItemIdFake)
                                  trdnFlagJsn[item?.id] = 0
                                }
                                setTrdnFlagJsn({...trdnFlagJsn})
                              }}
                            >
                              拆零
                            </Checkbox>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        {[
                          ...Array(
                            Math.ceil(grrkFlag ? item?.count : item?.drugCount)
                          ),
                        ].map((_, colIndex) => (
                          <Col
                            key={colIndex}
                            span={24}
                            className={styles.traceCodeCol}
                          >
                            <Form.Item
                              noStyle
                              rules={[{
                                pattern: /^\d+$/,
                                message: '请输入正确数字',
                              }]}
                              name={
                                `tradeCode_` + item?.id + `_${colIndex + 1}`
                              }
                            >
                              <Input
                                ref={(el) => {
                                  // 确保对应的数组存在
                                  if (!inputRefs.current[itemIndex]) {
                                    inputRefs.current[itemIndex] = []
                                  }
                                  inputRefs.current[itemIndex][colIndex] = el
                                }}
                                className={styles.traceCodeInput}
                                size='middle'
                                placeholder={
                                  trdnFlagJsn[item?.id] == 1 ? '承诺无追溯码' : item?.name + ` 追溯码` + `${colIndex + 1}`
                                }
                                allowClear
                                onPressEnter={() =>
                                  handlePressEnter(itemIndex, colIndex)
                                }
                              />
                            </Form.Item>
                          </Col>
                        ))}
                      </Row>
                    </>
                  )
                })}
            </Form>
          </>
        )}
      </Modal>
    </div>
      </>
  )
}
