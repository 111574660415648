/*
 * @Descripttion:
 * @version:
 * @Author: suiyue
 * @email: 1373842098@qq.com
 * @Date: 2021-12-13 16:35:36
 * @LastEditors: linxi
 * @LastEditTime: 2023-08-29 11:28:10
 */
import { Modal, message, Table, notification, Button, Row } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { ReactElement } from 'react'
import { EditableList } from '../../../../compnents/list/EditableList'

import { useDispatch, useSelector } from 'react-redux'
import { DrugStaticDetailColumns } from './columns'
import styles from '../drugStatic.module.css'
import {
  getDrugStaticDetailExoprtFnAsync,
  getDrugStaticDetailList,
  selectCurrent,
  selectDetail,
  selectLoading,
  selectTotal,
  setCurrent,
  setLoading,
} from './modalSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import { RootDispatch } from '../../../../app/store'

export const DrugStaticModal = (props: {
  drugName?: string
  timeHead?: string
  timeTail?: string
  rowId?: number
  isModalVisible: boolean
  localItemId?: number | null
  onOk: () => void
  onCancel: () => void
}): ReactElement => {
  const success = () => {
    message.success('操作成功')
  }
  const dispatch = useDispatch<RootDispatch>()

  const Current = useSelector(selectCurrent)

  const total = useSelector(selectTotal)

  const data = useSelector(selectDetail)

  const [size, setSize] = useState(10)

  const loading = useSelector(selectLoading)

  useEffect(() => {
    if (props.drugName !== '') dispatch(setLoading(true))
    dispatch(
      getDrugStaticDetailList({
        current: Current,
        size,
        timeHead: props.timeHead,
        timeTail: props.timeTail,
        drugName: props.drugName,
        localItemId: props.localItemId || null,
      })
    )
  }, [size, Current, props.drugName, props.rowId])

  useEffect(() => {
    if (props?.isModalVisible) {
      dispatch(setCurrent(1))
      setSize(10)
    } else {
      dispatch(setLoading(false))
    }
  }, [props?.isModalVisible])

  const outCluedExport = () => {
    const data = {
      current: Current,
      size,
      timeHead: props.timeHead,
      timeTail: props.timeTail,
      drugName: props.drugName,
      localItemId: props.localItemId || null,
    }
    dispatch(getDrugStaticDetailExoprtFnAsync(data))
      .then(unwrapResult)
      .then((res: any) => {
        const content = res
        const blob = new Blob([content])
        const fileName = '药品销售统计明细.xlsx'
        const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL']
        const elink = document.createElement('a')
        if ('download' in elink) {
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = selfURL['createObjectURL'](blob)
          document.body.appendChild(elink)
          // 触发链接
          elink.click()
          selfURL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          (navigator as any).msSaveBlob(blob, fileName)
        }
      })
      .then(() => {
        notification.success({
          message: '导出成功',
          duration: 3,
        })
      })
      .catch((res: any) => {
        return
      })
  }

  return (
    <Modal
      className={styles.modal}
      visible={props.isModalVisible}
      title='药品销售统计明细'
      width='1020px'
      footer={null}
      onOk={() => {
        props.onOk()
        dispatch(setLoading(false))
      }}
      onCancel={() => {
        props.onCancel()
        dispatch(setCurrent(1))
        setSize(10)
        dispatch(setLoading(false))
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          position: 'relative',
          top: '-12px',
        }}
      >
        <Button type='primary' onClick={() => outCluedExport()}>
          导出
        </Button>
      </div>

      <EditableList
        loading={loading}
        className={styles.drugStaticModal}
        rowKey={(t: any) => t.id}
        page={{
          items: data,
          current: Current,
          size: size,
          total: total,
        }}
        // summary={() => (
        //   <Table.Summary fixed>
        //     <Table.Summary.Row className={styles.total}>
        //       <Table.Summary.Cell index={0} align='center'>
        //         合计
        //       </Table.Summary.Cell>
        //       <Table.Summary.Cell index={1} align='center'></Table.Summary.Cell>
        //       <Table.Summary.Cell index={2}></Table.Summary.Cell>
        //       <Table.Summary.Cell index={3}></Table.Summary.Cell>
        //       <Table.Summary.Cell index={4}></Table.Summary.Cell>
        //       <Table.Summary.Cell index={5} align='center'></Table.Summary.Cell>
        //       <Table.Summary.Cell index={6} align='center'></Table.Summary.Cell>
        //       <Table.Summary.Cell index={7} align='right'></Table.Summary.Cell>
        //       <Table.Summary.Cell index={8} align='right'></Table.Summary.Cell>
        //       <Table.Summary.Cell index={9} align='right'></Table.Summary.Cell>
        //     </Table.Summary.Row>
        //   </Table.Summary>
        // )}
        onChangePage={(current, pageSize) => {
          setSize(pageSize as number)
          dispatch(setCurrent(current))
        }}
        columns={DrugStaticDetailColumns(size * (Current - 1))}
      />
    </Modal>
  )
}
