import { API_PREFIX } from './constant'
import { Request } from './request'
import {YBJDUploadParams} from "../features/YBJDUpload/YBJDDataUploadSlice";

/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-12-07 15:13:49
 * @LastEditors: linxi
 * @LastEditTime: 2024-10-28 09:44:12
 */

export interface MedicalRecordParams {
  state: number
  current: number
  size?: number
  param?: string
  registrationTimeHead?: string
  registrationTimeTail?: string
  treatmentDepartmentId?: string
  treatmentDepartmentName?: string
  treatmentDoctorName?: string
  payMethod?: any
}

// 列表
export function medicalRecordList(params: MedicalRecordParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/paylist`,
    method: 'GET',
    params,
  }
}

export interface AdlopuuploadParams {
  responseString?: any
  treatmentId?: any
}

export function adlopuuploadAsync(body: AdlopuuploadParams): Request {
  return {
    url: `${API_PREFIX}/blade-supervise/upload/upload`,
    method: 'post',
    body,
  }
}

export interface UploadRequestStringParams {
  serverType?: string
  responseString?: any
  treatmentId?: any
}

export function uploadRequestStringAsync(body: UploadRequestStringParams): Request {
  return {
    url: `${API_PREFIX}/blade-supervise/upload/upload_${body.serverType}`,
    method: 'post',
    body,
  }
}

export interface UploadParams {
  tenantId?: string
  treatmentIds?: any[]
}

export function uploadAsync(body: UploadParams): Request {
  return {
    url: `${API_PREFIX}/blade-supervise/supervise/quanminUpload`,
    method: 'post',
    body,
  }
}

export function YBJDUploadAsync(body: YBJDUploadParams): Request {
  return {
    url: `${API_PREFIX}/blade-supervise/YBJDUpload/localHostUpload`,
    method: 'post',
    body,
  }
}



