/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-27 15:08:32
 * @LastEditors: linxi
 * @LastEditTime: 2025-01-14 18:05:33
 */

import { LeftOutlined, RightOutlined, DeleteOutlined } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Checkbox,
  Col,
  message,
  notification,
  Popconfirm,
  Popover,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Space,
  Spin,
  Table,
  TableColumnType,
} from 'antd'
import { parseInt } from 'lodash'
import moment from 'moment'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { EditableList } from '../../compnents/list/EditableList'
import { Retail } from '../../models/retail'
import { AutomaticSchedulingModal } from './AutomaticSchedulingModal'
import styles from './composing.module.css'
import {
  getJuhePage,
  getArrangeListPage,
  addArrangeListPage,
  resetArrangeListPage,
  getDoctorInfo,
  getRankingListPage,
  deleteDoctorInfo,
  copyLatWeekDoctorInfo,
} from './composingSlice'
import { ComposingModal } from './modal'

export const Composing = () => {
  const tableRef = useRef<any>(null)
  const btnRef = useRef<any>(null)
  const tableWrapRef = useRef<any>(null)
  const { Option } = Select
  const dispatch = useDispatch<RootDispatch>()

  const [selectValue, setSelectValue] = useState(1) //医生 or 护士

  const [isModalVisible, setIsModalVisible] = useState(false) //排班弹窗是否显示

  const [
    isAutomaticSchedulingModalVisible,
    setIsAutomaticSchedulingModalVisible,
  ] = useState(false) //自动排班弹窗

  const [title, setTitle] = useState('') //排班弹窗标题

  const [modalWeekDate, setModalWeekDate] = useState<string[]>() //排班弹窗时间

  const [isEditRanking, setIsEditRanking] = useState<any>() //当前时间

  const [weekTime, setWeekTime] = useState('') // 周日期显示

  const [lastWeekTime, setLastWeekTime] = useState(0) // 周日期显示控制

  const [tableLoading, setTableLoading] = useState(false) // 表格loading控制

  const [btnLoading, setBtnLoading] = useState(false) // 按钮loading控制

  const [spinLoading, setSpinLoading] = useState(false) // 排班弹窗loading控制

  const [weekTimeList, setWeekTimeList] = useState<string[]>([]) //表格表头周日期数据

  const [weekTimeAllList, setWeekTimeAllList] = useState<string[]>([])

  const [tableCellShow, setTableCellShow] = useState(false) // 表格单元格弹窗控制

  const [rankingList, setRankingList] = useState<any>() //医生排班班次列表

  const [checkBoxValue, setCheckBoxValue] = useState<any>([]) //班次多选

  const [rankingData, setRankingData] = useState<any>() //新增编辑班次信息

  const [btnDisabled, setBtnDisabled] = useState(false) //新增编辑班次信息

  const [params, setParams] = useState<any>()

  const [tableScrollY, setTableScrollY] = useState<any>('')
  useEffect(() => {
    //固定表头
    const y = tableWrapRef.current.clientHeight - 62 + 'px'
    setTableScrollY(y)
  }, [])

  const [page, setPage] = useState<any>({
    total: 0,
    items: [],
  })

  useEffect(() => {
    params && getPage()
  }, [params])

  useEffect(() => {
    // 更新周的日期
    setWeekTime(getLastWeek(lastWeekTime))
  }, [lastWeekTime, selectValue])

  const getPage = () => {
    //获取表格列表
    setTableCellShow(false) //关闭表格单元格弹窗
    dispatch(getArrangeListPage(params))
      .then(unwrapResult)
      .then((res) => {
        setTableLoading(false)

        if (res && !Object.keys(res).length) {
          setPage({
            total: 0,
            items: [],
          })
          return
        }
        const data = {
          total: res.total,
          items: res,
        }

        setPage(data)
      })
      .catch(() => {
        setTableLoading(false)
      })
  }

  const setRanking = (obj: any, num: number, scheduleDate?: any) => {
    //设置排班
    setBtnLoading(true)
    const data = {
      doctorId: obj.doctorId,
      scheduleDate: scheduleDate,
      shiftIds: checkBoxValue,
    }

    dispatch(addArrangeListPage(data))
      .then(unwrapResult)
      .then((res) => {
        getPage()
        setBtnLoading(false)
        notification.success({
          message: '操作成功',
        })
      })
      .catch((err) => {
        setBtnLoading(false)
      })
  }

  const getDoctorInfor = async (t: any, num: number, scheduleDate: any) => {
    //获取医生排班信息
    await dispatch(getRankingListPage(params))
      .then(unwrapResult)
      .then((res) => {
        setRankingList(res)
        setSpinLoading(false)
      }) //获取排班信息
    dispatch(
      getDoctorInfo({
        doctorId: t?.doctorId,
        scheduleDate: scheduleDate,
      })
    )
      .then(unwrapResult)
      .then((res) => {
        const shiftIdList = res?.map((v: any) => v?.shiftId)
        shiftIdList?.length && setCheckBoxValue(shiftIdList)
        setSpinLoading(false)
      })
  }

  const tableCellMouseEnter = (
    t: any,
    e: any,
    index: number,
    str: string,
    scheduleDate?: any
  ) => {
    //columns render事件
    if (str == '删除') {
      setTableLoading(true)
      dispatch(
        deleteDoctorInfo({
          //删除医生排班信息
          doctorId: t?.doctorId,
          scheduleDate: scheduleDate,
        })
      )
        .then(unwrapResult)
        .then((res) => {
          getPage()
          notification.success({ message: '删除成功' })
        })
        .catch((err) => {
          setTableLoading(false)
        })
      return
    }
    setSpinLoading(true)
    setRankingData([t, index, scheduleDate]) //新增/编辑医生班次信息
    getDoctorInfor(t, index, scheduleDate) //获取医生排班信息
    setCheckBoxValue([]) //设置/编辑白班弹窗默认不选
    setTableCellShow(true) //设置/编辑白班弹窗是否显示

    // 设置弹窗位置
    const currentRef = tableRef.current
    const currentBtnRef = btnRef.current

    currentRef.style.top = ''
    currentRef.style.bottom = ''
    currentRef.style.left = ''
    // 弹窗内按钮位置
    currentBtnRef.style.top = ''
    currentBtnRef.style.bottom = ''
    currentBtnRef.style.left = ''
    // 弹窗位置
    if (document.body.clientHeight - e.clientY - 300 < 0) {
      currentRef.style.bottom = '10' + 'px'
      currentRef.style.left = e.clientX - 370 + 'px'
      currentBtnRef.style.bottom = 10 + 'px' //按钮位置
      currentBtnRef.style.left = e.clientX - 300 + 'px'
    } else {
      currentRef.style.top = e.clientY - 35 + 'px'
      currentRef.style.left = e.clientX - 150 + 'px'
      currentBtnRef.style.top = e.clientY + 276 + 'px' //按钮位置
      currentBtnRef.style.left = e.clientX - 70 + 'px'
    }
    if (document.body.clientWidth - (e.clientX - 130) - 220 < 0) {
      currentRef.style.left = e.clientX - 150 - 220 + 'px'
      currentRef.style.top = e.clientY - 35 + 'px'
    }
  }

  const columnsRenderFn = (t: any, index: number, scheduleDate?: any) => {
    // columns render
    const temp = params.dateTime[index].split('-') //判断只能编辑 修改 删除当前及以后的日期
    let forbid = false //以前日期是否置灰 parseInt(temp[2]) < parseInt(isEditRanking[2])
    if (
      moment(params.dateTime[index]) > moment().add(3, 'year') ||
      moment(params.dateTime[index]) < moment().startOf('day')
      // parseInt(temp[0]) < parseInt(isEditRanking[0]) ||
      // parseInt(temp[1]) < parseInt(isEditRanking[1]) ||
      // (parseInt(temp[1]) == parseInt(isEditRanking[1]) &&
      //   parseInt(temp[2]) < parseInt(isEditRanking[2]))
    ) {
      // notification.error({ message: `请${str}当前时间及以后的班次！` });
      forbid = true
    }
    return (
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          background:(forbid && '#f0f0f0' ) as any
        }}
        key={index}
      >
        {t.map[params.dateTime[index]]?.length ? (
          t.map[params.dateTime[index]]?.map((v: any, index: any) => {
            return v?.shiftName ? (
              <>
                <div
                  key={index}
                  className={
                    forbid
                      ? styles.tableCellTextForbidColor
                      : styles.tableCellText
                  }
                >
                  {v?.shiftName}
                </div>
                <div
                  className={
                    forbid ? styles.tableCellTextForbid : styles.editText
                  }
                >
                  {forbid ? (
                    ''
                  ) : (
                    <>
                      <div
                        className={styles.tableCellIcon}
                        onClick={(e) =>
                          tableCellMouseEnter(t, e, index, '删除', scheduleDate)
                        }
                      >
                        <DeleteOutlined />
                      </div>
                      <span
                        className={styles.tableCellWrapText}
                        onClick={(e) =>
                          tableCellMouseEnter(t, e, index, '修改', scheduleDate)
                        }
                      >
                        点击修改排班
                      </span>
                    </>
                  )}
                </div>
              </>
            ) : forbid ? (
              <div
                className={
                  styles.tableCellTextForbid
                }
              >
                {' '}
              </div>
            ) : (
              <div
                className={styles.setText}
                onClick={(e) =>
                  tableCellMouseEnter(t, e, index, '设置', scheduleDate)
                }
              >
                <span>{'点击设置排班'}</span>
              </div>
            )
          })
        ) : forbid ? (
          <div className={styles.tableCellTextForbids}> </div>
        ) : (
          <div
            className={styles.setText}
            onClick={(e) =>
              tableCellMouseEnter(t, e, index, '设置', scheduleDate)
            }
          >
            <span>{'点击设置排班'}</span>
          </div>
        )}
      </div>
    )
  }

  const columns = (): TableColumnType<any>[] => {
    return [
      {
        title: selectValue == 1 ? '医生' : '护士',
        dataIndex: 'doctorName',
        key: 'doctorName',
        align: 'center',
        width: '20rem',
        fixed: 'left',
        ellipsis: true,
        render: function doctorName(_, t) {
          return `${t.doctorName}(${t.departmentName})`
        },
      },
      {
        title: `周一(${weekTimeList[0]})`,
        dataIndex: 'shiftName',
        key: 'shiftName',
        align: 'center',
        width: '10rem',
        render: function shiftName(_, t) {
          return columnsRenderFn(t, 0, weekTimeAllList[0])
        },
      },
      {
        title: `周二(${weekTimeList[1]})`,
        dataIndex: 'shiftName',
        key: 'shiftName',
        align: 'center',
        width: '10rem',
        render: function shiftName(_, t) {
          return columnsRenderFn(t, 1, weekTimeAllList[1])
        },
      },
      {
        title: `周三(${weekTimeList[2]})`,
        dataIndex: 'shiftName',
        key: 'shiftName',
        align: 'center',
        width: '10rem',
        render: function shiftName(_, t) {
          return columnsRenderFn(t, 2, weekTimeAllList[2])
        },
      },
      {
        title: `周四(${weekTimeList[3]})`,
        key: 'shiftName',
        align: 'center',
        width: '10rem',
        render: function shiftName(_, t) {
          return columnsRenderFn(t, 3, weekTimeAllList[3])
        },
      },
      {
        title: `周五(${weekTimeList[4]})`,
        key: 'shiftName',
        align: 'center',
        width: '10rem',
        render: function shiftName(_, t) {
          return columnsRenderFn(t, 4, weekTimeAllList[4])
        },
      },
      {
        title: `周六(${weekTimeList[5]})`,
        key: 'shiftName',
        align: 'center',
        width: '10rem',
        render: function shiftName(_, t) {
          return columnsRenderFn(t, 5, weekTimeAllList[5])
        },
      },
      {
        title: `周日(${weekTimeList[6]})`,
        key: 'shiftName',
        align: 'center',
        width: '10rem',
        render: function shiftName(_, t) {
          return columnsRenderFn(t, 6, weekTimeAllList[6])
        },
      },
    ]
  }

  const getLastWeek = (i: number) => {
    // 获取周的日期
    i <= 0 ? setBtnDisabled(false) : setBtnDisabled(true) //0是当前周 复制上周排班按钮是否禁用
    setTableLoading(true)
    const weekOfDay = parseInt(moment().format('E')) //计算今天是这周第几天
    const data = []
    const paramsData = []
    for (let val = 0; val < 7; val++) {
      data.push(
        `${moment()
          .subtract(weekOfDay + 7 * i - (val + 1), 'days')
          .format('MM-DD')}`
      ) //一周的日期  月 日
      paramsData.push(
        `${moment()
          .subtract(weekOfDay + 7 * i - (val + 1), 'days')
          .format('YYYY-MM-DD')}`
      ) //一周的日期 年 月 日
    }
    setWeekTimeList(data)
    setWeekTimeAllList(paramsData)
    setParams({
      //获取列表参数
      dateTime: paramsData,
      stationCategory: selectValue,
    })

    const currentDate = moment().subtract(0, 'months').format('YYYY-MM-DD')
    setIsEditRanking(currentDate.split('-')) //用来判断之前的日期排班不能设置
    setModalWeekDate([paramsData[0], paramsData[paramsData.length - 1]])
    return `${paramsData[0]} 至 ${paramsData[paramsData.length - 1]}`
  }

  const TdCell = (props: any) => {
    //优化表格性能
    // onMouseEnter, onMouseLeave在数据量多的时候，会严重阻塞表格单元格渲染，严重影响性能
    const { rowSpan, colSpan, onMouseEnter, onMouseLeave, ...restProps } = props
    return <td {...restProps} />
  }

  return (
    <>
      <div className={styles.main}>
        {/* 新增/编辑班次 */}

        <div
          ref={tableRef}
          className={styles.raidoWrap}
          style={{
            display: tableCellShow ? 'block' : 'none',
          }}
        >
          <Spin spinning={spinLoading}>
            <Row style={{ overflow: 'hidden' }}>
              <Checkbox.Group
                onChange={(e) => {
                  setCheckBoxValue(e)
                  console.log(e, 'eeeeeeee')
                }}
                value={checkBoxValue}
              >
                <Space direction='vertical'>
                  {rankingList &&
                    rankingList.map((item: any) => {
                      return (
                        <Checkbox
                          key={item.id}
                          value={item.id}
                        >{`${item.shiftName}  ${item.shiftStartTime} ~ ${item.shiftEndTime}`}</Checkbox>
                      )
                    })}
                </Space>
              </Checkbox.Group>
            </Row>
            <Row
              ref={btnRef}
              style={{
                position: 'fixed',
                paddingLeft: '45px',
                width: '210px',
                height: '30px',
                background: '#fff',
              }}
            >
              <Space>
                <Col>
                  <Button onClick={() => setTableCellShow(false)}>取消</Button>
                </Col>
                <Col>
                  <Button
                    type='primary'
                    loading={btnLoading}
                    onClick={() => {
                      setRanking(rankingData[0], rankingData[1], rankingData[2])
                    }}
                  >
                    确认
                  </Button>
                </Col>
              </Space>
            </Row>
          </Spin>
        </div>
        <div style={{ marginTop: '10px' }}>
          <Row>
            <Space style={{ flex: 1 }}>
              <Button
                type='primary'
                onClick={() => {
                  getPage()
                  setLastWeekTime(0)
                }}
              >
                回到本周
              </Button>

              <div className='input-group' style={{ display: 'inline-flex' }}>
                {/* 左按钮 */}
                <Button
                  icon={<LeftOutlined />}
                  onClick={() => {
                    setLastWeekTime(lastWeekTime + 1)
                  }}
                />
                {/* 日期显示 */}
                <div className={styles.weekTimeWrap}>{weekTime}</div>
                {/* 右按钮 */}
                <Button
                  icon={<RightOutlined />}
                  onClick={() => {
                    setLastWeekTime(lastWeekTime - 1)
                  }}
                />
              </div>
              <Button
                type='primary'
                disabled={btnDisabled}
                onClick={() => {
                  setTitle('重置排班')
                  setIsModalVisible(true)
                }}
              >
                重置排班
              </Button>
              <Button
                type='primary'
                disabled={btnDisabled}
                onClick={() => {
                  setTitle('排班复制')
                  setIsModalVisible(true)
                }}
              >
                复制上周排班
              </Button>
              <Button
                type='primary'
                disabled={btnDisabled}
                onClick={() => {
                  setIsAutomaticSchedulingModalVisible(true)
                }}
              >
                自动排班设置
              </Button>
            </Space>
            <Space>
              <Select
                style={{ width: 160 }}
                // placeholder="医生"
                allowClear
                value={selectValue}
                onChange={(value: number) => {
                  setSelectValue(value)
                }}
              >
                <Option value={1}>医生</Option>
                <Option value={0}>护士</Option>
              </Select>
            </Space>
          </Row>
        </div>

        <div className={styles.table} ref={tableWrapRef}>
          <EditableList
            className={styles.tableGroup}
            scroll={{ y: tableScrollY }}
            style={{ height: '100%' }}
            loading={tableLoading}
            rowKey={(registration: any) => registration.tenantId}
            pagination={false}
            components={{
              body: { cell: TdCell },
            }}
            page={{
              current: 0,
              size: 10,
              total: 0,
              items: page.items,
            }}
            bordered
            columns={columns()}
          />
        </div>
        <ComposingModal
          title={title}
          visible={isModalVisible}
          onOk={(title: string) => {
            if (title == '重置排班') {
              const data = {
                startTime: modalWeekDate && modalWeekDate[0],
                endTime: modalWeekDate && modalWeekDate[1],
              }
              dispatch(resetArrangeListPage(data))
                .then(unwrapResult)
                .then((res) => {
                  getPage()
                  notification.success({ message: '重置成功' })
                })
            } else if (title == '排班复制') {
              const weekOfDay = parseInt(moment().format('E')) //计算今天是这周第几天
              const data = {
                lastWeekStartTime: moment()
                  .subtract(weekOfDay + 7 * (lastWeekTime + 1) - 1, 'days')
                  .format('YYYY-MM-DD'),
                lastWeekEndTime: moment()
                  .subtract(weekOfDay + 7 * (lastWeekTime + 1 - 1), 'days')
                  .format('YYYY-MM-DD'),
                weekEndTime: modalWeekDate && modalWeekDate[1],
                weekStartTime: modalWeekDate && modalWeekDate[0],
              }
              dispatch(copyLatWeekDoctorInfo(data))
                .then(unwrapResult)
                .then((res) => {
                  notification.success({ message: '复制成功' })
                  getPage()
                })
            }
            setIsModalVisible(false)
          }}
          onCancel={() => {
            setIsModalVisible(false)
          }}
        />
        <AutomaticSchedulingModal
          visible={isAutomaticSchedulingModalVisible}
          onOk={() => {
            setIsAutomaticSchedulingModalVisible(false)
            getPage()
          }}
          onCancel={() => {
            setIsAutomaticSchedulingModalVisible(false)
            getPage()
          }}
        ></AutomaticSchedulingModal>
      </div>
    </>
  )
}
