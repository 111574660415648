import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import { sendAsync } from '../../../app/applicationSlice'
import { RootDispatch, RootState, RootThunkApi } from '../../../app/store'
import * as R from '../../../models/recipe'
import { Recipe } from '../../../models/recipe'
import { Treatment, TreatmentNotice } from '../../../models/treatment'
import * as SR from '../../../services/recipe'
import * as ST from '../../../services/treatment'
import { cityCodeFnParams, toothParams } from '../../../services/treatment'

interface PatientInfoType {
  patientId?: string
  treatmentDoctorId?: number
  patientName?: string
  treatmentDoctorName?: string
}
interface RecipeEditorState {
  treatment?: Treatment
  recipes: Recipe[]
  patientInfo?: PatientInfoType
}

const initialState = {
  recipes: [],
  patientInfo: {},
} as RecipeEditorState

function reloadRecipeList({
  dispatch,
  getState,
}: {
  dispatch: RootDispatch
  getState: () => RootState
}) {
  const treatment = selectTreatment(getState())
  if (treatment) {
    dispatch(getRecipeList(treatment.id))
  }
}

export const createOrUpdateRecipe = createAsyncThunk<
  string,
  Recipe,
  RootThunkApi<string>
>('recipeListEditor/createOrUpdateRecipe', async (recipe, api) => {
  return sendAsync(SR.createOrUpdateRecipe(recipe), api).then((data) => {
    return data?.toString() || ''
  })
})

export const getRecipeList = createAsyncThunk<
  Recipe[],
  string,
  RootThunkApi<Recipe[]>
>('recipeListEditor/getRecipeListStatus', async (treatmentId, api) => {
  return sendAsync(SR.getRecipeList(treatmentId), api).then(
    (data: Record<string, never>) =>
      _.chain(data.records)
        .map((r) => R.fromJson(r))
        .filter((r) => r.category !== R.RecipeCategory.Extras)
        .value()
  )
})

export const removeRecipe = createAsyncThunk<void, string, RootThunkApi<void>>(
  'recipeListEditor/removeRecipeStatus',
  async (recipeId, api) => {
    return sendAsync(SR.removeRecipe(recipeId), api).then(() => {
      reloadRecipeList(api)
    })
  }
)

export const getTreatmentNotice = createAsyncThunk<
  TreatmentNotice,
  string,
  RootThunkApi<TreatmentNotice>
>('recipeListEditor/getTreatmentNoticeStatus', async (patientId, api) => {
  return sendAsync(ST.getTreatmentNotice(patientId), api)
})

export const getTab = createAsyncThunk<void, any, RootThunkApi>(
  'auth/getTab',
  async (params, api) => {
    return sendAsync(ST.getTab(params), api)
  }
)

export const getCutTab = createAsyncThunk<
  void,
  ST.tabStateParams,
  RootThunkApi
>('auth/getCutTab', async (body, api) => {
  return sendAsync(ST.getCutTab(body), api)
})

export const finishTreatment = createAsyncThunk<
  void,
  string,
  RootThunkApi<void>
>('recipeListEditor/finishTreatmentStatus', async (treatmentId, api) => {
  return sendAsync(SR.finishTreatment(treatmentId), api)
})

export const finishFirstTreatment = createAsyncThunk<
  void,
  string,
  RootThunkApi<void>
>(
  'recipeListEditor/finishFirstTreatmentStatus',
  async (registrationId, api) => {
    return sendAsync(SR.finishFirstTreatment(registrationId), api)
  }
)

export const getTreatments = createAsyncThunk<
  void,
  ST.GetTreatmentDetailParams,
  RootThunkApi<void>
>('diagnosis/copyTreatmentStatus', async (params, api) => {
  if (!params.medicalRecord && !params.registrationId && !params.treatmentId) {
    return
  }
  return sendAsync(ST.getTreatment(params), api)
})

// 获取updateFlag
export const selectTreatmentUpdateFlgsAsync = createAsyncThunk<
  void,
  string,
  RootThunkApi<void>
>('diagnosis/selectTreatmentUpdateFlgsAsync', async (id, api) => {
  return sendAsync(ST.selectTreatmentUpdateFlgs(id), api)
})

export const updateItemPayTypeAsync = createAsyncThunk<
  void,
  ST.PayTypeBody,
  RootThunkApi
>('diagnosis/updatePayType', async (body, api) => {
  return sendAsync(ST.updateItemPayType(body), api)
})

// 皮试
export const editST = createAsyncThunk<void, any, RootThunkApi>(
  'diagnosis/editST',
  async (body, api) => {
    return sendAsync(ST.editSTFn(body), api)
  }
)

// 新增治疗计划
export const addSurePlanAsync = createAsyncThunk<
  void,
  ST.addSurePlanParams,
  RootThunkApi
>('diagnosis/addSurePlanAsync', async (body, api) => {
  return sendAsync(ST.addSurePlan(body), api)
})

// 模板列表
export const templateListAsync = createAsyncThunk<void, void, RootThunkApi>(
  'diagnosis/templateListAsync',
  async (_, api) => {
    return sendAsync(ST.templateList(), api)
  }
)

// 模板数据
export const templateDetailAsync = createAsyncThunk<void, string, RootThunkApi>(
  'diagnosis/templateDetailAsync',
  async (id, api) => {
    return sendAsync(ST.templateDetail(id), api)
  }
)

// 保存牙位
export const saveToothAsync = createAsyncThunk<void, toothParams, RootThunkApi>(
  'diagnosis/saveToothAsync',
  async (body, api) => {
    return sendAsync(ST.saveTooth(body), api)
  }
)

// 同步牙位
export const syncToothAsync = createAsyncThunk<void, toothParams, RootThunkApi>(
  'diagnosis/syncToothAsync',
  async (body, api) => {
    return sendAsync(ST.syncTooth(body), api)
  }
)

// 治疗计划列表、、
export const curePlanListAsync = createAsyncThunk<
  void,
  ST.curePlanListParams,
  RootThunkApi
>('diagnosis/curePlanListAsync', async (body, api) => {
  return sendAsync(ST.curePlanList(body), api)
})

// 删除一个计划
export const removeCurePlanAsync = createAsyncThunk<
  void,
  string[],
  RootThunkApi
>('diagnosis/removeCurePlanAsync', async (body, api) => {
  return sendAsync(ST.removeCurePlan(body), api)
})

// 批量导入计划
export const importCurePlanAsync = createAsyncThunk<
  void,
  ST.importCurePlanParams,
  RootThunkApi
>('diagnosis/importCurePlanAsync', async (body, api) => {
  return sendAsync(ST.importCurePlan(body), api)
})

// 判断病历和医美病历数据是否合规
export const checkTreatmentAsync = createAsyncThunk<void, string, RootThunkApi>(
  'diagnosis/checkTreatmentAsync',
  async (body, api) => {
    return sendAsync(ST.checkTreatment(body), api)
  }
)

// 生成系统文件
export const getbuildPDFAsync = createAsyncThunk<void, ST.buildPDFAsyncParams, RootThunkApi>(
  'diagnosis/getbuildPDFAsync',
  async (params, api) => {
    return sendAsync(ST.buildPDFAsync(params), api)
  }
)
// 获取省市区地址
export const getCityList = createAsyncThunk<
  void,
  cityCodeFnParams,
  RootThunkApi
>('diagnosis/getCityCode', async (params, api) => {
  return sendAsync(ST.cityCodeFn(params), api)
})

const recipeEditorSlice = createSlice({
  name: 'recipeEditor',
  initialState,
  reducers: {
    setRecipeEditorTreatment: (
      state,
      action: PayloadAction<Treatment | undefined>
    ) => {
      state.treatment = action.payload
    },
    updateRecipe: (state, action: PayloadAction<Recipe>) => {
      state.recipes = state.recipes.map((r) =>
        r.id === action.payload.id ? action.payload : r
      )
    },
    resetRecipeList: (state) => {
      state.treatment = undefined
      state.recipes = []
    },
    setPatientInfo: (state, action) => {
      state.patientInfo = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRecipeList.fulfilled, (state, action) => {
      state.recipes = action.payload
    })
  },
})

export const selectTreatment = (state: RootState): Treatment | undefined =>
  state.recipeListEditor.treatment

export const selectRecipes = (state: RootState): Recipe[] =>
  state.recipeListEditor.recipes

export const selectPatientInfo = (
  state: RootState
): PatientInfoType | undefined => state.recipeListEditor.patientInfo

export const {
  setRecipeEditorTreatment,
  updateRecipe,
  resetRecipeList,
  setPatientInfo,
} = recipeEditorSlice.actions

export default recipeEditorSlice.reducer
