/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2024-12-04 09:44:18
 * @LastEditors: linxi
 * @LastEditTime: 2024-12-05 11:12:34
 */
import { Button, Col, Modal, Row, Space, Tabs } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { TabBar, TabPane } from '../../compnents/widgets/TabBar'
import styles from './PrintSettingAll.module.css'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { RootDispatch } from '../../app/store'
import { NavBar } from '../../compnents/nav/NavBar'
import { RecipePrintSetting } from './recipePrint/recipePrint'
import { BackFilled } from '../../compnents/icons/Icons'
import { ThemeContext } from '../../theme/ThemeContext'
import { ArrowLeftOutlined } from '@ant-design/icons'

export const PrintSettingAllTabBar = () => {
  const location: any = useLocation()

  const history = useHistory()

  const theme = useContext(ThemeContext);

  const [value, setValue] = useState<any>('2')

  const dispatch = useDispatch<RootDispatch>()

  return (
    <Col className={styles.container}>
      <TabBar style={{ margin: '10px 20px' }}>
        <TabPane key='0' tab='自定义打印模板'>
          <Tabs
            className={styles.tabs}
            style={{ marginTop: '10px', position: 'relative' }}
            onChange={(e) => {
              setValue(e)
            }}
            activeKey={value}
          >
            <TabPane key='1' tab='病历诊断书'></TabPane>
            <TabPane key='2' tab='处方'>
              <RecipePrintSetting></RecipePrintSetting>
            </TabPane>
            <TabPane key='3' tab='小票'></TabPane>
            <TabPane key='4' tab='标签'></TabPane>
            <TabPane key='5' tab='票据'></TabPane>
            <TabPane key='6' tab='核拨表'></TabPane>
            <TabPane key='7' tab='其它'></TabPane>
            <Row
              style={{
                position: 'absolute',
                right: '26px',
                top: '10px',
                display: 'flex',
                alignItems:'center',
                color:'#0273FF',
                cursor:'pointer'
              }}
              onClick={(()=>{
                history.push({
                  pathname:'/tenant/manager',
                  state: {
                    station: '2',
                  },
                })
              })}
            >
             <ArrowLeftOutlined style={{marginRight:'6px'}}/>
              返回
            </Row>
          </Tabs>
        </TabPane>
      </TabBar>
    </Col>
  )
}
