import { TableColumnType } from 'antd'
import moment from 'moment'
import React, { useRef } from 'react'
import { getStationOrderSourceNames } from '../../../models/changeWater'
import { DateTimeFormat, DateTimeFormatSimple } from '../../../models/datetime'
import { Dimen } from '../../../models/dimen'
import { getPayMethodName } from '../../../models/payment'

export const changeWaterColumns = (
  onclick: (t: string, id: any, state: number) => void,
  startIndex: number,
  data?: any
): TableColumnType<any>[] => {
  const colsTop: TableColumnType<any>[] = [
    {
      title: '序号',
      key: 'no',
      width: '4rem',
      align: 'center',
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '就诊序号',
      dataIndex: 'outpatientNo',
      width: Dimen.OutpatientNo,
      align: 'center',
      key: 'outpatientNo',
      render: (_: any, t: any) => {
        return t.outpatientNo == -1 ? ' ' : t.outpatientNo
      },
    },
    {
      title: '姓名',
      width: '6rem',
      dataIndex: 'patientName',
      align: 'center',
      key: 'patientName',
    },
    {
      title: '险种代码',
      dataIndex: 'registrationCategory',
      width: '6rem',
      align: 'center',
      key: 'registrationCategory',
      render: function registrationCategory(registrationCategory: number) {
        return registrationCategory === 0
          ? '自费'
          : registrationCategory === -1
          ? ' -'
          : '医保'
      },
    },
    {
      title: '就诊类别',
      dataIndex: 'orderSource',
      width: '6rem',
      align: 'center',
      key: 'orderSource',
      render: (_, t) => {
        return getStationOrderSourceNames(t.orderSource)
      },
    },
  ]
  const customizePayList = data?.[0]?.customizePay
    ?.map((k: any) => {
      return { ...k }
    })
    ?.sort((a: any, b: any) => a.payMethodName.localeCompare(b.payMethodName))
  if (customizePayList?.length > 0) {
    const iv = customizePayList
    for (let i = 0; i < iv.length; i++) {
      colsTop.push({
        width: '6rem',
        title: iv[i]?.payMethodName,
        dataIndex: 'otherPay_' + i,
        align: 'right',
        key: 'otherPay_' + i,
        render: (_, r: any) => {
          const value = r?.customizePay?.filter(
            (vv: any) => vv.payMethodName == iv[i]?.payMethodName
          )[0]?.payMethodAmount
          return Number(value)?.toFixed(2)
        },
      })
    }
  }
  const colsAll: TableColumnType<any>[] = [
    ...colsTop,
    {
      title: '总金额',
      dataIndex: 'totalPayAmount',
      width: '8rem',
      align: 'right',
      key: 'totalPayAmount',
      render: (_, r) => r.totalPayAmount?.toFixed(2),
    },
    // {
    //   title: '现金金额',
    //   dataIndex: 'receivePayAmount',
    //   width: Dimen.Price,
    //   align: 'right',
    //   key: 'receivePayAmount',
    //   render: (_, r) => r.receivePayAmount?.toFixed(2),
    // },
    {
      title: '应收金额',
      dataIndex: 'shouldPayAmount',
      width: '8rem',
      align: 'right',
      key: 'shouldPayAmount',
      render: (_, r) => r.shouldPayAmount?.toFixed(2),
    },
    {
      title: '实收金额',
      dataIndex: 'realPayAmount',
      width: '8rem',
      align: 'right',
      key: 'realPayAmount',
      render: (_, r) => r.realPayAmount?.toFixed(2),
    },
    // {
    //   title: '现金金额',
    //   dataIndex: 'realPayAmount',
    //   width: Dimen.Price,
    //   align: 'right',
    //   key: 'realPayAmount',
    //   render: (_, r) => r.realPayAmount?.toFixed(2),
    // },
    // {
    //   title: '医保金额',
    //   dataIndex: 'realPayAmount',
    //   width: Dimen.Price,
    //   align: 'right',
    //   key: 'realPayAmount',
    //   render: (_, r) => r.realPayAmount?.toFixed(2),
    // },

    {
      title: '发生人员',
      dataIndex: 'operateUserName',
      width: '8rem',
      align: 'center',
      key: 'operateUserName',
    },
    {
      title: '操作时间',
      dataIndex: 'createTime',
      width: '8rem',
      align: 'center',
      key: 'createTime',
      render: (_, t) => {
        const createTime = t.createTime.substring(0, 10)
        return createTime
      },
    },
    {
      title: '结算编号',
      dataIndex: 'paymentId',
      width: '8rem',
      align: 'center',
      key: 'paymentId',
    },
    {
      title: '操作',
      align: 'center',
      width: '8rem',
      render: function Element(_, t) {
        return (
          <a
            type='primary'
            onClick={(e: any) => {
              onclick(t.id, e.target.text, t.state)
            }}
          >
            查看详情
          </a>
        )
      },
    },
  ]
  return colsAll
}

export const PrintChangeWaterColumns = (
  onclick: (t: string, id: any, state: number) => void,
  startIndex: number
): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      key: 'no',
      width: '3rem',
      align: 'center',
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '就诊序号',
      dataIndex: 'outpatientNo',
      width: '4rem',
      align: 'center',
      key: 'outpatientNo',
      render: (_: any, t: any) => {
        return t.outpatientNo == -1 ? ' ' : t.outpatientNo
      },
    },
    {
      title: '姓名',
      dataIndex: 'patientName',
      align: 'center',
      key: 'patientName',
    },
    {
      title: '结算险种',
      dataIndex: 'insuranceCode',
      width: '10em',
      align: 'center',
      ellipsis: true,
      render: (_, t) => {
        return t.insuranceCode === 'ZIFEI' || !t.insuranceCode || !t
            ? '自费'
            : '智慧医保'
      },
    },
    {
      title: '就诊类别',
      dataIndex: 'orderSource',
      width: '6rem',
      align: 'center',
      key: 'orderSource',
      render: (_, t) => {
        return getStationOrderSourceNames(t.orderSource)
      },
    },
    // {
    //   title: "发生类型",
    //   dataIndex: "state",
    //   align: "center",
    //   render: (state: number) => {
    //     return state > 0 ? "已退款" : "已付款";
    //   },
    // },
    {
      title: '支付方式',
      dataIndex: 'payMethodName',
      width: Dimen.PayMethod,
      align: 'center',
      key: 'payMethodName',
      // render: (_, t) => {
      //   return getPayMethodName(t.payMethod)
      // },
    },
    {
      title: '总金额',
      dataIndex: 'totalPayAmount',
      width: Dimen.Price,
      align: 'right',
      key: 'totalPayAmount',
      render: (_, r) => r.totalPayAmount?.toFixed(2),
    },
    // {
    //   title: '现金金额',
    //   dataIndex: 'receivePayAmount',
    //   width: Dimen.Price,
    //   align: 'right',
    //   key: 'receivePayAmount',
    //   render: (_, r) => r.receivePayAmount?.toFixed(2),
    // },
    {
      title: '应收金额',
      dataIndex: 'shouldPayAmount',
      width: Dimen.Price,
      align: 'right',
      key: 'shouldPayAmount',
      render: (_, r) => r.shouldPayAmount?.toFixed(2),
    },
    {
      title: '实收金额',
      dataIndex: 'realPayAmount',
      width: Dimen.Price,
      align: 'right',
      key: 'realPayAmount',
      render: (_, r) => r.realPayAmount?.toFixed(2),
    },
    // {
    //   title: '现金金额',
    //   dataIndex: 'realPayAmount',
    //   width: Dimen.Price,
    //   align: 'right',
    //   key: 'realPayAmount',
    //   render: (_, r) => r.realPayAmount?.toFixed(2),
    // },
    // {
    //   title: '医保金额',
    //   dataIndex: 'realPayAmount',
    //   width: Dimen.Price,
    //   align: 'right',
    //   key: 'realPayAmount',
    //   render: (_, r) => r.realPayAmount?.toFixed(2),
    // },

    {
      title: '发生人员',
      dataIndex: 'operateUserName',
      width: Dimen.Name,
      align: 'center',
      key: 'operateUserName',
    },
    {
      title: '操作时间',
      dataIndex: 'createTime',
      width: Dimen.Time,
      align: 'center',
      key: 'createTime',
      render: (_, t) => {
        return t.createTime
          ? moment(t.createTime)?.format(DateTimeFormatSimple)
          : '-'
      },
    },
    {
      title: '结算编号',
      dataIndex: 'paymentId',
      width: Dimen.PaymentId,
      align: 'center',
      key: 'paymentId',
    },
  ]
}
