import { Button, Popover, Row, Space, TableColumnType, Tooltip } from 'antd'
import moment from 'moment'
import React, { ReactElement, useContext } from 'react'
import { useSelector } from 'react-redux'
import {
  AppointmentIcon,
  DeleteOutlined,
  OweMoneyIcon,
  TreatmentFilled,
} from '../../compnents/icons/Icons'
import { DateTimeFormat, DateTimeFormatSimple } from '../../models/datetime'
import { Dimen } from '../../models/dimen'
import {
  getRegistrationStateName,
  Registration,
  RegistrationState,
} from '../../models/registration'
import { getGenderName } from '../../models/user'
import { ThemeContext } from '../../theme/ThemeContext'
import { getAge } from '../../utils/StringUtils'
import { selectTenant } from '../user/setting/settingSlice'
import styles from './PatientList.module.css'
import { selectInsuranceArray } from '../../app/applicationSlice'
import { WarningOutlined } from '@ant-design/icons'
import { decrypt } from '../../models/m'

export const RegistrationColumns = (
  onAction: (
    action:
      | 'treatment'
      | 'cancel'
      | 'print'
      | 'register'
      | 'detail'
      | 'convertInsurance'
      | 'registerNoAppoint'
      | 'editPatientInfo',
    reg: Registration
  ) => void,
  startIndex: number,
  patientsMode = false,
  today = false,
  editPatientInfo?: (text?: any, v?: any) => void
): TableColumnType<Registration>[] => {
  const oweMoneyShow: any = useSelector(selectTenant)
  const theme = useContext(ThemeContext)
  const insuranceArray = useSelector(selectInsuranceArray)

  return [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: Dimen.Num,
      render: (_1, _2, i) => `${1 + i + startIndex}`,
    },
    {
      title: '门诊号',
      dataIndex: 'outpatientNo',
      width: Dimen.OutpatientNo,
      align: 'center',
      render: (_, t) => {
        return Number(t.outpatientNo) > 0 ? t.outpatientNo : '-'
      },
    },
    // {
    //   title: "病历号",
    //   dataIndex: "treatmentNo",
    //   width: Dimen.Name,

    //   align: "center",
    //   // render: (_, t) => {
    //   //   return t.treatmentNo ? t.treatmentNo : "-"
    //   // },
    // },
    {
      title: '患者姓名',
      dataIndex: 'patientName',
      width: '10em',
      align: 'center',
      render: function action(_, t) {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {location.pathname == '/registration' ? (
              <span
                style={{
                  color: '#027AFF',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  onAction('editPatientInfo', t)
                }}
              >
                {_}
              </span>
            ) : (
              <span>{_}</span>
            )}
            {t.appointmentId != -1 && (
              <AppointmentIcon
                style={{ marginLeft: '5px', width: '18px', height: '18px' }}
              />
            )}
            {t.arrearageAmountSum &&
            t.arrearageAmountSum > 0 &&
            oweMoneyShow?.openOwe == 1 ? (
              <Tooltip title={`欠费金额 ${t.arrearageAmountSum} 元`}>
                <div style={{ marginTop: '8px' }}>
                  <OweMoneyIcon
                    style={{ marginLeft: '5px', width: '18px', height: '18px' }}
                  />
                </div>
              </Tooltip>
            ) : (
              ''
            )}
            {!!t.labels.length &&
              t.labels.map((v: any) => (
                <Tooltip key={v.id} title={v.name}>
                  <span
                    className={styles.label}
                    style={{
                      backgroundColor: v.backgroundColor,
                      color: v.fontColor,
                    }}
                  >
                    {v.headName}
                  </span>
                </Tooltip>
              ))}
          </div>
        )
      },
    },
    {
      title: '病历号',
      key: 'patientId',
      width: Dimen.Sex,
      align: 'center',
      dataIndex: 'patientId',
    },
    {
      title: '结算险种',
      dataIndex: 'insuranceCode',
      width: '10em',
      align: 'center',
      render: (_, t) => {
        return !t || !t.insuranceCode || t.insuranceCode === 'ZIFEI'
          ? '自费'
          : '智慧医保'
      },
    },
    {
      title: '性别',
      key: 'gender',
      width: Dimen.Sex,
      align: 'center',
      render: (_, r) => getGenderName(r.patientSex),
    },
    {
      title: '年龄',
      dataIndex: 'patientAge',
      width: Dimen.Age,
      align: 'center',
      render: (_, r) => getAge(r.patientAge, r.patientMonth),
    },
    {
      title: '手机号',
      dataIndex: 'patientPhone',
      key: 'patientPhone',
      width: Dimen.PatientPhone,
      align: 'center',
      render: (_, r) => (r?.patientPhone ? decrypt(r?.patientPhone) : '-'),
    },
    {
      title: patientsMode ? '就诊科室' : '挂号科室',
      dataIndex: patientsMode
        ? 'treatmentDepartmentName'
        : 'registrationDepartmentName',
      width: Dimen.Department,
      align: 'center',
      render: function Actions(_, r): ReactElement | undefined {
        return patientsMode ? (
          <div
            style={{
              color:
                r?.treatmentDepartmentName == '未指定科室' ? '#2D7BFF' : '#333',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
            onClick={() => {
              if (r?.treatmentDepartmentName == '未指定科室') {
                onAction('registerNoAppoint', r)
              }
            }}
          >
            {r?.treatmentDepartmentName}
          </div>
        ) : (
          <div
            style={{
              color:
                r?.registrationDepartmentName == '未指定科室'
                  ? '#2D7BFF'
                  : '#333',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
            onClick={() => {
              if (r?.registrationDepartmentName == '未指定科室') {
                onAction('registerNoAppoint', r)
              }
            }}
          >
            {r?.registrationDepartmentName}
          </div>
        )
      },
    },
    {
      title: patientsMode ? '就诊医生' : '挂号医生',
      dataIndex: patientsMode
        ? 'treatmentDoctorName'
        : 'registrationDoctorName',
      width: Dimen.Doctor,
      align: 'center',
      render: function Actions(_, r): ReactElement | undefined {
        return patientsMode ? (
          <div
            style={{
              color:
                r?.treatmentDoctorName == '未指定医生' ? '#2D7BFF' : '#333',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
            onClick={() => {
              if (r?.treatmentDoctorName == '未指定医生') {
                onAction('registerNoAppoint', r)
              }
            }}
          >
            {r?.treatmentDoctorName}
          </div>
        ) : (
          <div
            style={{
              color:
                r?.registrationDoctorName == '未指定医生' ? '#2D7BFF' : '#333',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
            onClick={() => {
              if (r?.registrationDoctorName == '未指定医生') {
                onAction('registerNoAppoint', r)
              }
            }}
          >
            {r?.registrationDoctorName}
          </div>
        )
      },
    },
    {
      title: '就诊状态',
      key: 'state',
      width: Dimen.Status,
      align: 'center',
      render: function Element(_, r: any) {
        return (
          <>
            {r?.statusFlag == 1 && location.pathname == '/patients' ? (
              <Popover placement='top' content={<span>主诉尚未填写完整</span>}>
                <span
                  style={{
                    marginTop: '10px',
                    cursor: 'pointer',
                    color: '#e47a1f',
                  }}
                >
                  {getRegistrationStateName(r.registrationState)}
                  <WarningOutlined
                    style={{
                      color: '#e47a1f',
                      marginLeft: '2px',
                      marginTop: '4px',
                    }}
                  />
                </span>
              </Popover>
            ) : (
              getRegistrationStateName(r.registrationState)
            )}
          </>
        )
      },
    },
    {
      title: '挂号时间',
      dataIndex: 'registrationTime',
      width: Dimen.Time,
      align: 'center',
      render: (_, r) => {
        return moment(r.registrationTime).format(DateTimeFormat)
      },
    },
    {
      title: '预检分诊',
      dataIndex: 'precheckState',
      width: Dimen.Time,
      align: 'center',
      render: function Actions(_, r): ReactElement | undefined {
        return (
          <Space>
            {r.precheckState == 0 ? (
              <Row>
                <Button
                  type='text'
                  style={{
                    color: '#333',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  onClick={() => onAction('register', r)}
                >
                  登记
                </Button>
              </Row>
            ) : r.precheckState == 1 ? (
              <Row>
                <Button
                  size='small'
                  type='text'
                  style={{
                    color: '#027aff',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  onClick={() => onAction('detail', r)}
                >
                  详情
                </Button>
              </Row>
            ) : (
              <></>
            )}
          </Space>
        )
      },
    },
    {
      title: '操作',
      align: 'center',
      width: patientsMode ? '5rem' : '15rem',
      render: function Actions(_, r): ReactElement | undefined {
        if (patientsMode) {
          return r.registrationState === RegistrationState.NotTreated ||
            r.registrationState === RegistrationState.Treating ||
            today ? (
            <div>
              <Button
                size='small'
                type='primary'
                style={{ marginRight: '10px' }}
                onClick={() => {
                  if (patientsMode) {
                    if (
                      r?.treatmentDepartmentName == '未指定科室' &&
                      r?.treatmentDoctorName == '未指定医生'
                    ) {
                      onAction('registerNoAppoint', r)
                    } else {
                      onAction('treatment', r)
                    }
                  } else {
                    if (
                      r?.registrationDepartmentName == '未指定科室' &&
                      r?.registrationDoctorName == '未指定医生'
                    ) {
                      onAction('registerNoAppoint', r)
                    } else {
                      onAction('treatment', r)
                    }
                  }
                }}
              >
                就诊
              </Button>
              {insuranceArray.length !== 0 && r.insuranceCode === 'ZIFEI' && (
                <Button
                  style={{ backgroundColor: '#4dbd17', borderColor: '#4dbd17' }}
                  size='small'
                  type='primary'
                  onClick={() => {
                    onAction('convertInsurance', r)
                  }}
                >
                  自费转医保
                </Button>
              )}
              {insuranceArray.length !== 0 && r.insuranceCode != 'ZIFEI' && (
                <Button
                  style={{ backgroundColor: '#fa8714', borderColor: '#fa8714' }}
                  size='small'
                  type='primary'
                  onClick={() => {
                    onAction('convertInsurance', r)
                  }}
                >
                  医保转自费
                </Button>
              )}
            </div>
          ) : (
            <div>
              <Button
                size='small'
                type='primary'
                style={{ marginRight: '10px' }}
                onClick={() => {
                  onAction('treatment', r)
                }}
              >
                查看详情
              </Button>
              {insuranceArray.length !== 0 && r.insuranceCode === 'ZIFEI' && (
                <Button
                  style={{ backgroundColor: '#4dbd17', borderColor: '#4dbd17' }}
                  size='small'
                  type='primary'
                  onClick={() => {
                    onAction('convertInsurance', r)
                  }}
                >
                  自费转医保
                </Button>
              )}
              {insuranceArray.length !== 0 && r.insuranceCode != 'ZIFEI' && (
                <Button
                  style={{ backgroundColor: '#fa8714', borderColor: '#fa8714' }}
                  size='small'
                  type='primary'
                  onClick={() => {
                    onAction('convertInsurance', r)
                  }}
                >
                  医保转自费
                </Button>
              )}
            </div>
          )
        } else {
          const isNotTreated =
            r.registrationState === RegistrationState.NotTreated
          const isTreated = r.registrationState === RegistrationState.Treated
          const isCanceled = r.registrationState === RegistrationState.Canceled
          return (
            <Space style={{ whiteSpace: 'nowrap' }}>
              {!(isTreated || isCanceled) && (
                <a
                  onClick={() => {
                    if (!isCanceled) {
                      if (patientsMode) {
                        if (
                          r?.treatmentDepartmentName == '未指定科室' &&
                          r?.treatmentDoctorName == '未指定医生'
                        ) {
                          onAction('registerNoAppoint', r)
                        } else {
                          onAction('treatment', r)
                        }
                      } else {
                        if (
                          r?.registrationDepartmentName == '未指定科室' &&
                          r?.registrationDoctorName == '未指定医生'
                        ) {
                          onAction('registerNoAppoint', r)
                        } else {
                          onAction('treatment', r)
                        }
                      }
                      // onAction('treatment', r)
                    }
                  }}
                  style={{
                    color: isCanceled ? theme.tc3 : theme.tc2,
                    width: '14px',
                    height: '14px',
                  }}
                >
                  <Row align='middle' wrap={false}>
                    <TreatmentFilled
                      mode='small'
                      style={{
                        fill: isCanceled ? theme.tc3 : theme.s,
                        marginRight: 4,
                      }}
                    />
                    就诊
                  </Row>
                </a>
              )}
              {/* {isNotTreated && ( */}
              <a
                onClick={() => {
                  if (!isCanceled) {
                    onAction('cancel', r)
                  }
                }}
                style={{ color: isCanceled ? theme.tc3 : theme.tc2 }}
              >
                <Row align='middle' wrap={false}>
                  <DeleteOutlined
                    mode='small'
                    style={{
                      fill: isCanceled ? theme.tc3 : theme.e,
                      marginRight: 4,
                      width: '14px',
                      height: '14px',
                    }}
                  />
                  删除
                </Row>
              </a>
              {/* )} */}
              {isTreated && (
                <a onClick={() => onAction('treatment', r)}>继续就诊</a>
              )}
              {/* {!isNotTreated && ( */}
              <a
                style={isCanceled ? { color: theme.tc3 } : undefined}
                onClick={() => {
                  onAction('print', r)
                }}
              >
                打印
              </a>
              {/* )} */}
            </Space>
          )
        }
      },
    },
  ]
}
