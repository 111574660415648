/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-01-04 14:44:28
 * @LastEditors: linxi
 * @LastEditTime: 2023-07-19 09:48:33
 */

import { API_PREFIX } from './constant'
import { ContentType, MultiPartFormData, Request } from './request'

export interface WarehouseChangeReportPrams {
  current: number
  size: number
  timeHead: string
  timeTail: string
  storehouseId?: any
  flag?: any
  param?:any
  releasingTime?:any
}

// 列表
export function treatmentCostReportList(
  params: WarehouseChangeReportPrams
): Request {
  return {
    url: `${API_PREFIX}/blade-store/statistics/inventoryChange`,
    method: 'GET',
    params,
  }
}

// 导出
export const getExportFn = (params: WarehouseChangeReportPrams): Request => {
  return {
    url: `${API_PREFIX}/blade-store/statistics/inventoryChangeExportExcel`,
    method: 'GET',
    responseType: 'blob',
    params,
  }
}
