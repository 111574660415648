import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  ModalProps,
  notification,
  Row, Space,
} from 'antd'
import React, {ReactElement, useContext, useEffect, useRef, useState} from 'react'
import { useSelector } from 'react-redux'
import { GlobalKeyboardAction } from '../../../../compnents/actions/GlobalKeyboardAction'
import { Material } from '../../../../models/material'
import { isTcmRecipeCategory, RecipeCategory } from '../../../../models/recipe'
import { Settings } from '../../../../models/setting'
import { ItemCompletionType } from '../../../completion/completionSlice'
import { selectBooleanSetting } from '../../../settings/settingsSlice'
import { RecipeItemFieldInput } from '../components/RecipeItemFieldInput'
import { RecipeItemInput } from '../components/RecipeItemInput'
import styles from './RecipeItemModal.module.css'
import {Materials} from "../../../../models/commodity";
import {ThemeContext} from "../../../../theme/ThemeContext";
interface RecipeItemModalProps {
  discountFlag?: any //医嘱处方
  potionType?: number
  category?: RecipeCategory
  material?: Material
  insuranceCode?: any
  onChange?: (material: Material) => void
  onOk?: () => void
}

export const RecipeItemModal = ({
  discountFlag,
  potionType,
  category,
  material,
  insuranceCode,
  onChange,
  onCancel,
  onOk,
}: RecipeItemModalProps & ModalProps): ReactElement => {
  const [form] = Form.useForm()

  const { TextArea } = Input

  const theme = useContext(ThemeContext)

  const countPerTipRef = useRef<any>()
  const slRef = useRef<any>()
  const buttonRef = useRef<HTMLElement>(null)

  const [completionType, setCompletionType] = useState(
    ItemCompletionType.Inventory
  )
  const [loading, setLoading] = useState(false)

  const [current, setCurrent] = useState<Material | undefined>()

  const [isSplitModalVisibleMaterialsBean, setIsSplitModalVisibleMaterialsBean] = useState<any>()

  const autoChangePriceAfter = useSelector(
      selectBooleanSetting(Settings.Recipe.AutoChangePriceAfter)
  )

  const useSpecifiedBatch = useSelector(
    selectBooleanSetting(Settings.Recipe.UseSpecifiedBatch)
  )

  const ignoreStorage = useSelector(
    selectBooleanSetting(Settings.Recipe.IgnoreStorage)
  )

  const tcmUseSpecifiedBatch = useSelector(
    selectBooleanSetting(Settings.Recipe.TcmUseSpecifiedBatch)
  )

  const tcmIgnoreStorage = useSelector(
    selectBooleanSetting(Settings.Recipe.TcmIgnoreStorage)
  )

  const allowedToChangePrice = useSelector(
    selectBooleanSetting(Settings.Recipe.ChangePrice)
  )

  const [selectItems, setSelectItems] = useState<any>()

  const NumberPattern = /^(([1-9]\d*)|(0{1}))(\.\d{0,2})?$/

  const NormalDosePattern = /([0-9]\d*(\.\d*[1-9])?)|(0\.\d*[1-9])/

  const layout = {
    labelCol: {
      flex: '5rem',
    },
  }


  const handler = (e: KeyboardEvent) => {
    if (!isSplitModalVisibleMaterialsBean) {
      document.removeEventListener('keydown', handler)
      return
    }
    if (e.key === '1' || e.key === '2') {
      setIsSplitModalVisibleMaterialsBean(null)
      document.removeEventListener('keydown', handler)
      setCurrent({
        ...isSplitModalVisibleMaterialsBean,
        splitTag:e.key === '1'?1:0,
        retailPrice:e.key === '1'?isSplitModalVisibleMaterialsBean?.splitPrice:isSplitModalVisibleMaterialsBean?.retailPrice,
        accountUnit:e.key === '1'?isSplitModalVisibleMaterialsBean?.splitUnit:isSplitModalVisibleMaterialsBean?.accountUnit
      })

      setTimeout(() => {
        if (isTcmRecipeCategory(category)) {
          countPerTipRef?.current?.select()
        }else{
          slRef?.current?.select()
        }
      }, 300)

    }
  }

  useEffect(() => {
    if (isSplitModalVisibleMaterialsBean) {
      document.addEventListener('keydown', handler)
      setTimeout(() => {
        buttonRef.current?.focus()
      }, 300)
    } else {
      document.removeEventListener('keydown', handler)
    }
    return () => {
      document.removeEventListener('keydown', handler)
    }
  }, [isSplitModalVisibleMaterialsBean])

  useEffect(() => {
    setCurrent(material)
  }, [material])

  useEffect(() => {
    const flag: any = !!material
    localStorage?.setItem('editMedicalVisible', flag)
  }, [material])

  useEffect(() => {
    if (material)
      form.setFieldsValue({
        ...current,
        // countPerTip: form.getFieldValue('countPerTip')
        //   ? Number(form.getFieldValue('countPerTip'))
        //   : material?.countPerTip,
        retailPrice: current?.retailPrice
          ? Number(current?.retailPrice)?.toFixed(5)
          : '0.00000',
        retailBeforePrice:
          current?.retailPrice && current?.discount
            ? (Number(current.retailPrice) / Number(current.discount))?.toFixed(
                5
              )
            : '-',
        discount: current?.discount
          ? (Number(current?.discount) * 100)?.toFixed(0)
          : '-',
      })
  }, [current, material])

  useEffect(() => {
    if (category === RecipeCategory.Examination) {
      setCompletionType(ItemCompletionType.Treatment)
    } else {
      setCompletionType(ItemCompletionType.Auto)
    }
    form.resetFields()
  }, [category])

  const required = {
    rules: [
      {
        required: true,
        message: '请输入必填项',
      },
    ],
  }

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (process.env.node_env === 'development') {
        console.warn('Global key handler installed.')
      }
      if (e.isComposing || e.code !== 'Space') {
        return
      }
      form.submit()
    }

    document.addEventListener('keydown', onKeyDown)

    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [])

  return (
    <>
      <Modal
        title={
          material?.normalFrequency && material?.normalUsemethod
            ? '编辑药品'
            : '编辑诊疗'
        }
        width={800}
        visible={!!material}
        onCancel={(e) => {
          form.resetFields()
          onCancel && onCancel(e)
        }}
        onOk={() => {
          form.resetFields()
          onOk && onOk()
        }}
        footer={null}
      >
        <Form
          form={form}
          autoComplete='off'
          requiredMark={false}
          colon={false}
          onFinish={(values) => {
            setLoading(true)
            const tips = selectItems?.filter(
              (v: any) => v?.name == values?.name
            )
            if (isTcmRecipeCategory(category)) {
              if (values?.countPerTip == 0) {
                notification.error({
                  message: '每帖数量不可为 0',
                  duration: 2,
                })
                setLoading(false)
              } else {
                if (tips?.length || values?.name == current?.name) {
                  onChange &&
                    onChange({
                      ...current,
                      ...values,
                      id: material?.id,
                      discount: values?.discount
                        ? Number(values?.discount) / 100
                        : 1,
                      retailBeforePrice: undefined,
                    })
                  onOk && onOk()
                  setLoading(false)
                } else {
                  notification.info({
                    message: '商品名称错误，请修改后再保存',
                  })
                  setLoading(false)
                }
              }
            } else {
              if (values?.drugCount == 0) {
                notification.error({
                  message: '每帖数量不可为 0',
                  duration: 2,
                })
                setLoading(false)
              } else {
                if (tips?.length || values?.name == current?.name) {
                  onChange &&
                    onChange({
                      ...current,
                      ...values,
                      id: material?.id,
                      discount: values?.discount
                        ? Number(values?.discount) / 100
                        : 1,
                      retailBeforePrice: undefined,
                    })
                  onOk && onOk()
                  setLoading(false)
                } else {
                  notification.info({
                    message: '商品名称错误，请修改后再保存',
                  })
                  setLoading(false)
                }
              }
            }
          }}
          className={styles.form}
        >
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                {...layout}
                name='name'
                label='商品名称'
                rules={[{ required: true }]}
              >
                <RecipeItemInput
                  isRestrictUser={1} //是否限制用户
                  category={category}
                  insuranceCode={insuranceCode}
                  potionType={potionType}
                  useSpecifiedBatch={
                    isTcmRecipeCategory(category)
                      ? tcmUseSpecifiedBatch
                      : useSpecifiedBatch
                  }
                  ignoreStorage={
                    isTcmRecipeCategory(category)
                      ? tcmIgnoreStorage
                      : ignoreStorage
                  }
                  type={completionType}
                  filters={false}
                  onSelect={(m) => {
                    if (m.splitTag === 1) {
                      // 拆零
                      setIsSplitModalVisibleMaterialsBean(m);
                    } else {
                      setCurrent({
                        ...m,
                        splitTag:0,
                        groupNumber: form.getFieldValue('groupNumber'),
                      })
                      if (isTcmRecipeCategory(category)) {
                        countPerTipRef?.current?.select()
                      }else{
                        slRef?.current?.select()
                      }
                    }
                  }}
                  onItems={(v) => {
                    setSelectItems(v)
                  }}
                />
              </Form.Item>
            </Col>
            {!isTcmRecipeCategory(category) && category !== 13 && (
              <>
                <Col span={12}>
                  <Form.Item {...layout} name='groupNumber' label='组号'>
                    <Input />
                  </Form.Item>
                </Col>
                {material?.normalFrequency && material?.normalUsemethod ? (
                  <Col span={12}>
                    <Form.Item
                      {...layout}
                      name='normalFrequency'
                      label='频次'
                      rules={[
                        {
                          required: true,
                        },
                        {
                          max: 16,
                          message: '最长16位',
                        },
                      ]}
                    >
                      <RecipeItemFieldInput
                        field='normalFrequency'
                        noSize
                        recipeCategory={category}
                      />
                    </Form.Item>
                  </Col>
                ) : (
                  ''
                )}
              </>
            )}
            {material?.normalFrequency && material?.normalUsemethod ? (
              <Col span={12}>
                <Form.Item
                  {...layout}
                  name='normalUsemethod'
                  label='用法'
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <RecipeItemFieldInput
                    field='normalUsemethod'
                    noSize
                    recipeCategory={category}
                  />
                </Form.Item>
              </Col>
            ) : (
              ''
            )}
            {isTcmRecipeCategory(category) ? (
              <Col span={12}>
                <Form.Item
                  {...layout}
                  name='countPerTip'
                  label='每帖数量 '
                  rules={[{ required: true }]}
                >
                  <Input ref={countPerTipRef} />
                </Form.Item>
              </Col>
            ) : (
              <>
                {material?.normalFrequency && material?.normalUsemethod ? (
                  <Col span={12}>
                    <Form.Item
                      {...layout}
                      name='useDays'
                      label='天数'
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                ) : (
                  ''
                )}
                {
                  // material?.normalFrequency && material?.normalUsemethod 编辑诊疗时不需要
                  category !== 13 &&
                    material?.normalFrequency &&
                    material?.normalUsemethod && (
                      <Col span={12}>
                        <Form.Item
                          {...layout}
                          label='每次用量'
                          rules={[{ required: true }]}
                        >
                          <Row wrap={false} style={{ display: 'flex' }}>
                            <Form.Item name='normalDose' {...required} noStyle>
                              <Input
                                style={{ flex: 1 }}
                                onChange={(e) => {
                                  if(autoChangePriceAfter){
                                    const text = e.target.value
                                    if (
                                        material &&
                                        text &&
                                        NormalDosePattern.test(text) &&
                                        current?.frequencyCalc
                                    ) {
                                      const drugCount =
                                          Math.ceil(
                                              (Number(text) *
                                                  current?.frequencyCalc *
                                                  Number(current.useDays)) /
                                              (current.doseConvert||1)
                                          ) || 1
                                      // const useDays = Math.floor((drugCount * material.doseConvert) / (material.frequencyCalc * material.normalDose))
                                      const realDrugCount =
                                          current?.splitTag == 1
                                              ? drugCount * (current?.doseConvert||1)
                                              : drugCount
                                      form.setFieldsValue({
                                        drugCount: realDrugCount || 1,
                                      })
                                      const data: any = {
                                        ...current,
                                        normalDose: text,
                                        drugCount,
                                      }
                                      setCurrent(data)
                                    }
                                  }
                                }}
                              />
                            </Form.Item>
                            <Form.Item name='doseUnit' noStyle>
                              <RecipeItemFieldInput
                                field='doseUnit'
                                noSize
                                style={{ width: 36 }}
                              />
                            </Form.Item>
                          </Row>
                        </Form.Item>
                      </Col>
                    )
                }

                <Col span={12}>
                  <Form.Item
                    {...layout}
                    label='数量'
                    rules={[{ required: true }]}
                  >
                    <Row wrap={false} style={{ display: 'flex' }}>
                      <Form.Item
                        name='drugCount'
                        rules={[
                          {
                            required: true,
                            message: '请输入必填项',
                          },
                          {
                            pattern: /^(([1-9]\d*)|(0{1}))(\.\d{0,2})?$/,
                            message: '请输入正确的数量',
                          },
                        ]}
                        noStyle
                      >
                        <Input
                          ref={slRef}
                          style={{ flex: 1 }}
                          onChange={(e) => {
                            if(autoChangePriceAfter){
                              const text = e.target.value
                              if (
                                  current &&
                                  text &&
                                  NumberPattern.test(text) &&
                                  current.frequencyCalc
                              ) {
                                const useDays = Math.floor(
                                    (Number(text) * (current.doseConvert || 1)) /
                                    (current.frequencyCalc * current.normalDose)
                                )
                                const realUseDays =
                                    current?.splitTag == 1
                                        ? useDays / (current.doseConvert || 1)
                                        : useDays

                                const data: any = {
                                  ...current,
                                  useDays:
                                      Math.floor(
                                          realUseDays < 1 ? 1 : realUseDays
                                      ) || 1,
                                  drugCount: text,
                                }
                                setCurrent(data)
                              }
                            }
                          }}
                        />
                      </Form.Item>
                      <Form.Item name='accountUnit' noStyle>
                        <RecipeItemFieldInput
                          field='accountUnit'
                          noSize
                          style={{ width: 36 }}
                        />
                      </Form.Item>
                    </Row>
                  </Form.Item>
                </Col>
              </>
            )}
            {discountFlag ? (
              <>
                <Col span={12}>
                  <Form.Item
                    {...layout}
                    name='retailBeforePrice'
                    label='折前单价'
                    {...required}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    {...layout}
                    name='retailPrice'
                    label='折后单价'
                    rules={[
                      {
                        pattern: new RegExp(/^[0-9]+([.]{1}[0-9]+){0,1}$/),
                        message: '请输入数字',
                      },
                      { required: true, message: '请输入必填项' },
                    ]}
                  >
                    <Input
                      disabled={!allowedToChangePrice}
                      onChange={(v) => {
                        const retailPrice = v?.target?.value
                        const discount = form.getFieldValue('discount')
                        const retailBeforePrice =
                          retailPrice && discount
                            ? (
                                (retailPrice ? Number(retailPrice) : 0) /
                                (discount ? Number(discount / 100) : 0)
                              )?.toFixed(5)
                            : '-'
                        form.setFieldsValue({
                          retailBeforePrice: retailBeforePrice,
                        })
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    {...layout}
                    name='discount'
                    label='折扣%'
                    rules={[
                      {
                        pattern: new RegExp(/^[0-9]+([.]{1}[0-9]+){0,1}$/),
                        message: '请输入数字',
                      },
                      { required: true, message: '请输入必填项' },
                    ]}
                  >
                    <Input
                      disabled={!allowedToChangePrice}
                      onChange={(v) => {
                        const discount: any = v?.target?.value
                        const retailBeforePrice = form.getFieldValue('retailBeforePrice')
                        const retailPrice =
                        retailBeforePrice && discount
                            ? (
                                (retailBeforePrice ? Number(retailBeforePrice) : 0) *
                                (discount ? Number(discount / 100) : 0)
                              )?.toFixed(5)
                            : '-'
                        form.setFieldsValue({
                          retailPrice: retailPrice,
                        })
                      }}
                    />
                  </Form.Item>
                </Col>{' '}
              </>
            ) : (
              <Col span={12}>
                <Form.Item
                  {...layout}
                  name='retailPrice'
                  label='单价'
                  {...required}
                >
                  <Input disabled={!allowedToChangePrice} />
                </Form.Item>
              </Col>
            )}

            {!isTcmRecipeCategory(category) && (
              <Col span={24}>
                <Form.Item
                  {...layout}
                  name='remark'
                  label='备注'
                  rules={[
                    {
                      max: 200,
                      message: '最长200位',
                    },
                  ]}
                >
                  <TextArea showCount maxLength={200} rows={3} />
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row gutter={20}>
            <Col span={12}>
              <Button
                onClick={(e) => {
                  form.resetFields()
                  onCancel && onCancel(e)
                }}
                style={{ float: 'right' }}
              >
                取消
              </Button>
            </Col>
            <Col span={12}>
              <Button
                id='buttonId'
                type='primary'
                loading={loading}
                style={{ float: 'left' }}
                onClick={form.submit}
              >
                保存（空格）
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
          title='可选单位'
          className={styles.split}
          visible={!!isSplitModalVisibleMaterialsBean}
          onCancel={() => {
            setIsSplitModalVisibleMaterialsBean(null)
          }}
          footer={null}
      >
        <Space direction='vertical'>
          <Button
              type='primary'
              onClick={() => {
                setIsSplitModalVisibleMaterialsBean(null)
                setTimeout(() => {
                  setCurrent({
                    ...isSplitModalVisibleMaterialsBean,
                    splitTag:1,
                    retailPrice:isSplitModalVisibleMaterialsBean?.splitPrice,
                    accountUnit:isSplitModalVisibleMaterialsBean?.splitUnit
                  })
                  if (isTcmRecipeCategory(category)) {
                    countPerTipRef?.current?.select()
                  }else{
                    slRef?.current?.select()
                  }
                }, 300)
              }}
          >
            <span
                style={{
                  backgroundColor: theme.pn,
                  borderRadius: 999,
                  color: theme.c1,
                  lineHeight: '1rem',
                  marginRight: 8,
                  textAlign: 'center',
                  width: '1rem',
                }}
            >
              1
            </span>
            {`${isSplitModalVisibleMaterialsBean?.splitPrice || ''}元/${isSplitModalVisibleMaterialsBean?.splitUnit || ''}`}
          </Button>
          <Button
              ref={buttonRef}
              type='primary'
              onClick={() => {
                setIsSplitModalVisibleMaterialsBean(null)
                setTimeout(() => {
                  setCurrent({
                    ...isSplitModalVisibleMaterialsBean,
                    splitTag:0
                  })
                  if (isTcmRecipeCategory(category)) {
                    countPerTipRef?.current?.select()
                  }else{
                    slRef?.current?.select()
                  }
                }, 300)
              }}
          >
            <span
                style={{
                  backgroundColor: theme.pn,
                  borderRadius: 999,
                  color: theme.c1,
                  lineHeight: '1rem',
                  marginRight: 8,
                  textAlign: 'center',
                  width: '1rem',
                }}
            >
              2
            </span>
            {`${isSplitModalVisibleMaterialsBean?.retailPrice || ''}元/${isSplitModalVisibleMaterialsBean?.accountUnit || ''}`}
          </Button>
        </Space>
        <div className={styles.hint}>可键盘选择“1”或“2”</div>
      </Modal>
    </>
  )
}
