import { CaretDownFilled, SearchOutlined } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Form,
  Input,
  Row,
  Select,
  Space,
  Cascader as Cascaded,
} from 'antd'
import moment from 'moment'
import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { selectInsuranceArray } from '../../../app/applicationSlice'
import { RootDispatch } from '../../../app/store'
import {
  DateRangeType,
  DateSelect,
  getDateRange,
  TJFXTime,
} from '../../../compnents/form/DateSelect'
import {
  BillingCategories,
  BillingCategoryName,
} from '../../../models/billingCategory'
import { User } from '../../../models/user'
import { PaymentListParams } from '../../../services/payment'
import { ThemeContext } from '../../../theme/ThemeContext'
import { getFullUserList } from '../../completion/completionSlice'
import { getFullDoctorList } from '../../registration/list/registrationSlice'

export const Query = (props: {
  onValueChange: (params: PaymentListParams) => void
  selectedTab?: any
  alertMessage: () => void
}): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const [form] = Form.useForm()

  const inputRef = useRef<any>(null)

  const { Option } = Select

  const location: any = useLocation()

  const insuranceArray = useSelector(selectInsuranceArray)

  const [doctors, setDoctors] = useState<any>([])

  const [users, setUsers] = useState<User[]>([])

  const [isInTimeRange, setIsInTimeRange] = useState(false)

  useEffect(() => {
    dispatch(getFullDoctorList())
      .then(unwrapResult)
      .then((ds) => setDoctors(ds))
      .catch(() => {
        // do nothing
      })
  }, [])

  useEffect(() => {
    dispatch(getFullUserList({ current: 1, size: 1000, state: 1 }))
      .then(unwrapResult)
      .then((users) => setUsers(users))
      .catch(() => {
        // do nothing.
      })
  }, [])

  const checkTimeRange = () => {
    const now = moment()
    const currentHour = now.hours()

    // 判断当前时间是否在给定的时间段内
    console.log(currentHour, now, 'currentHour')

    if (
      (currentHour >= 10 && currentHour < 12) ||
      (currentHour === 12 && now.minutes() === 0) ||
      (currentHour >= 14 && currentHour < 16) ||
      (currentHour >= 19 && currentHour <= 23) ||
      (currentHour >= 0 && currentHour < 6)
    ) {
      setIsInTimeRange(true)
    } else {
      setIsInTimeRange(false)
    }
  }

  useEffect(() => {
    checkTimeRange()
  }, [])

  return (
    <Form
      form={form}
      onFinish={(values) => {
        props.onValueChange({
          ...values,
          current: 1,
        })
      }}
    >
      <Row style={{ marginTop: 10, marginBottom: 20 }} justify='space-between'>
        <Space>
          <Form.Item noStyle name='param'>
            <Input
              autoComplete='off'
              prefix={<SearchOutlined />}
              placeholder='患者姓名/药品名称/手机号/病历号'
              style={{ width: '17rem' }}
              allowClear
              onChange={() => {
                form.submit()
              }}
            />
          </Form.Item>
          {insuranceArray && insuranceArray.length !== 0 && (
            <Form.Item name='insuranceCode' noStyle>
              <Select
                placeholder='险种类型'
                style={{ width: '8rem' }}
                allowClear
                onChange={() => {
                  form.submit()
                }}
              >
                <Select.Option value={''}>全部</Select.Option>
                <Select.Option value={'ZIFEI'}>自费</Select.Option>
                <Select.Option value={'0'}>本地</Select.Option>
                <Select.Option value={'1'}>省内异地</Select.Option>
                <Select.Option value={'2'}>省外异地</Select.Option>
                <Select.Option value={'3'}>省医保</Select.Option>
                {/* {insuranceArray.map((v) => (
                  <Select.Option value={v.insuranceCode} key={v.id}>
                    {v.insuranceName}
                  </Select.Option>
                ))} */}
              </Select>
            </Form.Item>
          )}
          <Form.Item name='billingCategory' noStyle>
            <Select
              placeholder='开票项目'
              style={{ width: '8rem' }}
              allowClear
              onChange={() => form.submit()}
            >
              {BillingCategories.map((v) => {
                return (
                  <Option value={v} key={v}>
                    {BillingCategoryName(v)}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
          <Form.Item name='drugType' noStyle>
            <Select
              placeholder='项目类别'
              style={{ width: '8rem' }}
              allowClear
              onChange={() => {
                form.submit()
              }}
            >
              <Select.Option value={0}>药品</Select.Option>
              <Select.Option value={1}>诊疗</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name='treatmentDoctorId' noStyle>
            <Select
              allowClear
              placeholder='接诊医生'
              style={{
                width: '8rem',
              }}
              onChange={() => {
                form.submit()
              }}
            >
              {doctors.map((d: any) => (
                <Select.Option key={d.id} value={d.id}>
                  {d.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name='state' noStyle>
            <Select
              placeholder='结算状态'
              style={{ width: '8rem' }}
              allowClear
              // suffixIcon={<CaretDownFilled />}
              onChange={() => {
                form.submit()
              }}
            >
              <Select.Option key={3} value={3}>
                已收费
              </Select.Option>
              <Select.Option key={5} value={5}>
                已退费
              </Select.Option>
              <Select.Option key={7} value={7}>
                欠费中
              </Select.Option>
            </Select>
          </Form.Item>

          <DateSelect
            allowClear={false}
            style={{ width: '8.5rem' }}
            labelPrefix=''
            namePrefix='time'
            options={TJFXTime}
            placeholder='时间范围-时间'
            initialValue={DateRangeType.Today}
            onChange={() => {
              form.submit()
            }}
          />
          <Button
            type='primary'
            onClick={() => {
              form.submit()
            }}
          >
            查询
          </Button>
          {isInTimeRange ? (
            <div
              style={{
                opacity: 0,
                cursor: 'pointer',
                width: '10px',
                height: '30px',
              }}
              onDoubleClick={() => {
                props?.alertMessage && props?.alertMessage()
              }}
            ></div>
          ) : (
            <></>
          )}
        </Space>
      </Row>
    </Form>
  )
}
