import React, { useEffect, useRef, useState } from 'react'
import { EditableList } from '../../../../compnents/list/EditableList'
import { ChargeColumns, HomeChargeColumns } from './chargeColumns'
import styles from './chargePage.module.css'
import { ChargeQuery } from './chargeQuery'
import { SettlementModal } from '../modal/settlementModal'
import { RealPayModal } from '../../../payment/list/realPayModal'
import { ChargeDetailModal } from '../modal/chargeDetailModal'
import { Button, message, Modal, notification, Space, Table } from 'antd'
import { CutPriceModal } from '../modal/cutPriceModal'
import { InvoiveRecords } from '../../../payment/invoice/InvoiveRecord'
import { JuhePayPageRef } from '../../../payment/JuhePayPage/JuhePayPage'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../../app/store'
import {
  getBatchCharge,
  getChargePage,
  getChargePageTotalCount,
  getCutPriceAdd,
  getCutPriceDetail,
} from '../toothHomeSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  getPaymentDetail,
  getPaymentDetail2,
  getPaymentList,
  JuhePremium,
  refund as refundPayment,
} from '../../../payment/list/paymentSlice'
import { Refund } from '../../../../models/refund'
import {
  cancelSettlementAsync,
  confirmPayment,
  FeeListUpAsync,
  FeeListUpCancelAsync,
  getSingleDiscount,
  preSettlementAsync,
  selectConfirmation,
  setUpdateFlagsAsync,
} from '../../../payment/confirm/paymentConfirmSlice'
import {
  selectInsuranceArray,
  selectTenantAddress,
  selectTenantAreaCd,
  selectTenantCategory,
  selectTenantName,
  selectUserId,
  selectUserName,
} from '../../../../app/applicationSlice'
import {
  selectInsuranceItem,
  setInsurance,
} from '../../../../layouts/insuranceSlice'
import {
  getTradeName,
  medicalSendHttp,
  readCardEHttp,
  readCardSHttp,
  strParse,
} from '../../../../utils/MedicalUtils'
import moment from 'moment'
import { DateTimeFormatSimpleOnlyOne } from '../../../../models/datetime'
import { reset } from '../../../treatment/list/treatmentSlice'
import { fromJson, PaymentConfirmation } from '../../../../models/confirmation'
import { PaymentCart } from '../../../payment/cart/PaymentCart'
import {
  PaymentConfirm,
  PaymentConfirmRef,
} from '../../../payment/confirm/PaymentConfirm'
import { selectTenant } from '../../../user/setting/settingSlice'
import {
  getPatientInfoAsync,
  registerValue,
} from '../../../registration/register/registerSlice'
import { serverType } from '../../../../services/commodity'
import { traceRoute } from '../../../../layouts/layoutSlice'
import { useLocation } from 'react-router-dom'
import { getTradeCode } from '../../../../models/material'
interface ChargePageProps {
  flag?: any
  onLeftCount?: (obj: any) => void
  patientId?: string
}

export const ChargePage = ({
  flag,
  onLeftCount,
  patientId,
}: ChargePageProps) => {
  const refModal = useRef<PaymentConfirmRef>(null)

  const location = useLocation()

  const dispatch = useDispatch<RootDispatch>()

  const [pageLoading, setPageLoading] = useState(false)

  const [params, setParams] = useState<any>({
    current: 1,
    size: 10,
  })

  const [page, setPage] = useState<any>({
    total: 1,
    items: [],
  })

  const [tableCount, setTableCount] = useState<any>({}) //  表格统计数据

  const insuranceArray = useSelector(selectInsuranceArray)

  const ref = useRef<PaymentConfirmRef>(null)

  const [preSettlementModal, setPreSettlementModal] = useState(false) //  预结算弹窗

  const [tableTreatmentId, setTableTreatmentId] = useState('') //  预结算 ID

  const [settlementModal, setTettlementModal] = useState(false) //结算-结算弹窗

  const [batchModal, setBatchModal] = useState(false) //批量预结算-结算弹窗

  const [chargeDetailModal, setChargeDetailModal] = useState(false) //详情-详情弹窗

  const [refund, setRefund] = useState<Refund | undefined>() //  退费-预退费弹窗

  const [cutPriceModal, setCutPriceModal] = useState(false) //  划价弹窗

  const [invoiceModal, setInvoiceModal] = useState(false) //  发票明细弹窗

  const [juhePayModal, setJuhePayModal] = useState(false) //  聚合支付弹窗

  const [insuranceItem, setInsuranceItem] = useState<any>()

  const baseData = useSelector(selectInsuranceItem)

  const userName = useSelector(selectUserName)

  const userId = useSelector(selectUserId)

  const cycleCode = sessionStorage.getItem('cycleCode')

  const tenantCategory = useSelector(selectTenantCategory)

  const tenantAreaCd = useSelector(selectTenantAreaCd)

  const tenantName = useSelector(selectTenantName)

  const tenantAddress = useSelector(selectTenantAddress)

  const [objData, setObjData] = useState<any>({}) //预退费数据

  const [tablePaymentId, setTablePaymentId] = useState('') // 表格 ID

  const remoteConfirmation = useSelector(selectConfirmation) //结算数据

  const [batchData, setBatchData] = useState<any>({}) //批量预结算数据

  const [cutPriceDetail, setCutPriceDetail] = useState<any>([]) //划价数据

  const JuheShow: any = useSelector(selectTenant) //是否显示聚合支付按钮/type

  const [confirmation, setConfirmation] = useState<
    PaymentConfirmation | undefined
  >(undefined)

  const [registerData, setRegisterData] = useState<registerValue | undefined>()

  const [loading, setLoading] = useState(false)

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [newConfirmation, setNewConfirmation] = useState<any>({})

  const [prePay, setPrePay] = useState<any>({})

  const [buttonLoading, setButtonLoading] = useState(false)
  const [stkButtonLoading, setStkButtonLoading] = useState(false)

  const [dzpzButtonLoading, setDzpzButtonLoading] = useState(false)
  const [ybFaceButtonLoading, setYbFaceButtonLoading] = useState(false)

  useEffect(() => {
    if (remoteConfirmation && JSON.stringify(remoteConfirmation) != '{}') {
      // setTettlementModal(true)
      setNewConfirmation(remoteConfirmation)
    }
  }, [remoteConfirmation])

  const column = flag ? ChargeColumns : HomeChargeColumns

  useEffect(() => {
    sessionStorage.removeItem('toothHomeReturnTag')
  }, [])

  const getTableList = () => {
    sessionStorage.setItem(
      location.pathname + (flag ? '_shoufeiHZGL' : '_shoufei'),
      JSON.stringify({ ...params })
    )
    //获取表格数据
    setPageLoading(true)
    dispatch(
      getChargePage({
        ...params,
      })
    )
      .then(unwrapResult)
      .then(({ total, records }) => {
        setPage({ total, items: records })
      })
      .finally(() => setPageLoading(false))
    dispatch(getChargePageTotalCount(params))
      .then(unwrapResult)
      .then((res) => setTableCount(res))
      .finally(() => setPageLoading(false))
  }

  const getCutPriceDetailFn = () => {
    //获取划价信息
    dispatch(getCutPriceDetail())
      .then(unwrapResult)
      .then((res: any) => setCutPriceDetail(res))
  }
  const getBatchInfo = () => {
    //获取批量结算信息
    dispatch(getBatchCharge({ patientId: patientId || '' }))
      .then(unwrapResult)
      .then((res: any) => {
        setBatchData(fromJson(res))
      })
  }
  useEffect(() => {
    setButtonLoading(false)
    setDzpzButtonLoading(false)
    setStkButtonLoading(false)
  }, [])

  const refondSubmit = (id: any) => {
    setButtonLoading(true)
    dispatch(refundPayment(id))
      .then(unwrapResult)
      .then(() => {
        // notification.success({ message: '退费完成' })
        setButtonLoading(false)
        setStkButtonLoading(false)
        setDzpzButtonLoading(false)
        setObjData(undefined)
        dispatch(getPaymentList(params))
        setParams({
          ...params,
          current: 1,
        })
        // sibling?.reload()
      })
      .catch(() => {
        setButtonLoading(false)
        setStkButtonLoading(false)
        setDzpzButtonLoading(false)
      })
  }

  useEffect(() => {
    if (JSON.stringify(batchData) != '{}') {
      setBatchModal(true)
    }
  }, [batchData])

  useEffect(() => {
    if (cutPriceDetail.length) {
      setCutPriceModal(true)
    }
  }, [cutPriceDetail])

  useEffect(() => {
    if (confirmation && registerData) {
      // preSettlement('requestString', registerData)
      FeeListUpCancel('requestString', undefined, undefined, undefined)
    }
  }, [registerData])

  const setUpdateFlags = (insuranceFlag: string, updateFlag: string) => {
    dispatch(
      setUpdateFlagsAsync({
        treatmentId: registerData?.treatmentId,
        insuranceFlag,
        updateFlag,
      })
    ).then(unwrapResult)
    // .then(getTreatmentsDetail)
  }

  const success = (str: string) => {
    const hide = message.loading(str, 0)
    setTimeout(() => {
      hide()
    }, 3000)
  }

  const messageNotification = async (str: string) => {
    await message.destroy()
    notification.success({
      message: str,
      duration: 1,
    })
  }

  // 2204
  const FeeListUp = (
    serverType: any,
    responseString?: string,
    resolve?: any,
    reject?: any
  ) => {
    dispatch(
      FeeListUpAsync({
        serverType: serverType,
        body: {
          baseData: {
            ...insuranceItem,
            endTime: moment(insuranceItem.endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            cycleCode,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
            responseString: responseString ? responseString : null,
          },
          exp_content: '',
          registerFlag: 1,
          treatmentId: Number(registerData?.treatmentId),
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        setUpdateFlags('1', '0')
        if (serverType === 'requestString') {
          success('正在上传费用')
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
            },
            (call, err) => {
              if (err) {
                setUpdateFlags('1', '1')
                setLoading(false)
              } else {
                setUpdateFlags('1', '0'),
                  FeeListUp('response', JSON.stringify(call.netmessage))
              }
            },
            undefined,
            reject
          )
        } else {
          messageNotification('费用上传完毕')
          registerData && preSettlement('requestString', registerData)
          resolve && resolve()
        }
      })
      .catch(() => {
        resolve && resolve()
        if (serverType === 'response') {
          setUpdateFlags('1', '1')
        }
      })
  }

  // 2205
  const FeeListUpCancel = (
    serverType: any,
    responseString?: string,
    resolve?: any,
    reject?: any
  ) => {
    dispatch(
      FeeListUpCancelAsync({
        serverType: serverType,
        body: {
          baseData: {
            ...insuranceItem,
            endTime: moment(insuranceItem.endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            cycleCode,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
            responseString: responseString ? responseString : null,
          },
          exp_content: '',
          chrg_bchno: '2',
          treatmentId: Number(registerData?.treatmentId),
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          success('门诊费用明细信息撤销中')
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
            },
            (call, err) => {
              if (err) {
                // setLoading(false)
              } else {
                // setUpdateFlags('2', '0')
                messageNotification('撤销成功')
                FeeListUp('requestString')
              }

              // FeeListUpCancel('response', JSON.stringify(call.netmessage))
            },
            undefined,
            reject
          )
        } else {
          FeeListUp('requestString')
          resolve && resolve()
        }
      })
      .catch(() => {
        setLoading(false)
        if (serverType === 'requestString') {
          setUpdateFlags('1', '1')
        }
        resolve && resolve()
      })
  }

  // 挂号费收取 2206
  const preSettlement = (
    serverType: serverType,
    treatmentData: registerValue,
    responseString?: string
  ) => {
    dispatch(
      preSettlementAsync({
        serverType: serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          chrg_bchno: '2',
          exp_content: '',
          treatmentId: treatmentData?.treatmentId,
          patientId: String(treatmentData?.patientId),
          registrationId: String(treatmentData?.registrationId),
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              // message: '正在挂号费预结算',
            },
            (call, err) => {
              !err &&
                preSettlement(
                  'response',
                  treatmentData,
                  JSON.stringify(call.netmessage)
                )
            }
          )
        } else {
          registerPayment(res)
        }
      })
  }

  // 获取参保人信息
  const getPatientInfo = (
    cardType: string,
    fullCardInfo: string,
    serverType: any,
    responseString?: string
  ) => {
    dispatch(
      getPatientInfoAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          cardType: String(cardType),
          fullCardInfo,
          otherMap: {},
        },
      })
    )
      .then(unwrapResult)
      .then(async (res) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          await medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceItem.countryHospitalNb,
                insuranceItem.secretToken,
              ],
            },
            (call: any, err) => {
              if (!err) {
                message.loading('正在更新人员信息')
                getPatientInfo(
                  cardType,
                  fullCardInfo,
                  'response',
                  JSON.stringify(call.netmessage)
                )
                // }
              }
            }
          )
        } else {
          message.success('人员信息已更新')
        }
      })
      .catch((ex) => {
        notification.error({
          message: ex.message,
          duration: 3,
        })
      })
  }

  const registerPayment = (prePay?: any) => {
    setConfirmation({
      ...confirmation,
      prePay,
    } as PaymentConfirmation)

    refModal.current?.reset()
    setIsModalVisible(true)
  }

  const cancelSettlement = (
    serverType: any,
    paymentId?: string,
    cardType?: string,
    fullCardInfo?: string,
    responseString?: string
  ) => {
    const insuranceItem = insuranceArray.find(
      (v: any) => v.insuranceCode === objData?.payment?.insuranceCode
    )
    dispatch(
      cancelSettlementAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          exp_content: '',
          treatmentId: objData?.payment?.treatmentId,
          paymentId,
        },
      })
    )
      .then(unwrapResult)
      .then(async (res) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          await medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceItem.countryHospitalNb,
                insuranceItem.secretToken,
              ],
              notification: '退费',
            },
            (call: any, err) => {
              if (!err) {
                cancelSettlement(
                  'response',
                  paymentId,
                  cardType,
                  fullCardInfo,
                  JSON.stringify(call.netmessage)
                )
              }
              setStkButtonLoading(false)
              setDzpzButtonLoading(false)
            }
          )
        } else {
          //调用本地退费
          refondSubmit(objData?.payment?.id)
          //更新医保卡数据
          if (cardType == '1') {
            //实体卡
            getPatientInfo(
              '3',
              baseData?.insuranceCode == 'JIANGSU'
                ? String(fullCardInfo)
                : JSON.stringify(fullCardInfo),
              'requestString'
            )
          } else if (cardType == '2') {
            //电子凭证
            getPatientInfo('1', String(fullCardInfo), 'requestString')
          }
          // resolve()
          // submit(vs, undefined, res)
        }
      })
      .catch((v) => {
        setStkButtonLoading(false)
        setDzpzButtonLoading(false)
      })
  }

  // 保存医保结构体
  useEffect(() => {
    if (insuranceItem && JSON.stringify(insuranceItem) !== '{}') {
      dispatch(
        setInsurance({
          ...insuranceItem,
          userName,
          userId,
          cycleCode,
          tenantCategory,
          tenantAreaCd,
          name: tenantName,
          address: tenantAddress,
          areaCode: tenantAreaCd,
          endTime: moment(insuranceItem.endTime).format(
            DateTimeFormatSimpleOnlyOne
          ),
        })
      )
    }
    return () => {
      dispatch(setInsurance({}))
    }
  }, [insuranceItem])

  useEffect(() => {
    if (insuranceArray.length !== 0) {
      setInsuranceItem(insuranceArray[0])
    }
  }, [insuranceArray])

  useEffect(() => {
    if (
      (params.current &&
        params.registrationTimeHead &&
        params.registrationTimeTail) ||
      (params._dateRange == 7 && params.current)
    ) {
      sessionStorage.setItem(
        location.pathname + (flag ? '_shoufeiHZGL' : '_shoufei'),
        JSON.stringify({ ...params })
      )
      getTableList()
    }
  }, [params])

  useEffect(() => {
    setParams({
      ...params,
      param: patientId,
    })
  }, [patientId])

  const doRefund = (cardType?: string, fullCardInfo?: string) => {
    const payMethodList = objData?.payment?.payMethod?.toString()?.split(',')
    const isJuHe: any = payMethodList?.find((v: any) => {
      return v == 2
    })
    if (isJuHe) {
      //是否聚合支付退费
      dispatch(
        JuhePremium({
          treatmentId: objData?.payment?.treatmentId,
          paymentId:objData?.payment?.id
        })
      )
        .then(unwrapResult)
        .then(() => {
          setStkButtonLoading(false)
          setDzpzButtonLoading(false)
          notification.success({ message: '聚合支付退费成功' })
          setObjData(undefined)
          setParams({
            ...params,
            current: 1,
          })

          if (objData?.payment) {
            if (objData?.payment.insuranceCode !== 'ZIFEI') {
              cancelSettlement(
                'requestString',
                objData?.payment.id,
                cardType,
                fullCardInfo
              )
            } else {
              refondSubmit(objData?.payment.id)
            }
          }
        })
        .catch((v) => {
          setStkButtonLoading(false)
          setDzpzButtonLoading(false)
        })
    } else {
      if (objData?.payment) {
        if (objData?.payment.insuranceCode !== 'ZIFEI') {
          cancelSettlement(
            'requestString',
            objData?.payment.id,
            cardType,
            fullCardInfo
          )
        } else {
          refondSubmit(objData?.payment.id)
        }
      }
    }
  }

  const validCardInfoBeforeGoRefund = (type: string) => {
    //实体卡读卡校验
    if (type == '1') {
      setStkButtonLoading(true)
      //部分强制读卡的地区，跳过校验
      const tradeType = getTradeName()
      if (tradeType === 'CQ_ZHYB') {
        setLoading(false)
        doRefund()
        return
      }
      readCardSHttp(
        {
          trade_args: [getTradeCode(tenantAreaCd.slice(0, 4))],
        },
        (data, err) => {
          setLoading(false)
          if (!err) {
            //其他地区需要做二次读卡校验
            const Name =
              tradeType === 'JS_ZHYB'
                ? data?.netmessage
                    ?.split('|')[4]
                    ?.replace('\r', '')
                    .replace('\t', '')
                    .replace(' ', '')
                    .trim()
                : data?.netmessage?.Name
            if (Name != objData?.payment?.patientName) {
              Modal.error({
                title: '提示',
                content:
                  `退费信息与卡片信息不一致，请确认是否插错卡，就诊人：` +
                  objData?.payment?.patientName +
                  '，卡片姓名：' +
                  data?.netmessage.Name,
                okText: '确认',
              })
              setStkButtonLoading(false)
            } else {
              doRefund(type, data?.netmessage)
            }
          }
        }
      )
      //电子凭证读卡校验
    } else if (type == '2') {
      setDzpzButtonLoading(true)
      setTimeout(() => {
        setDzpzButtonLoading(false)
      }, 3000)
      readCardEHttp(
        {
          trade_args: [
            baseData?.countryHospitalNb,
            '01301',
            '',
            userId,
            userName,
            '',
            '',
          ],
        },
        (data, err) => {
          if (!err) {
            // {"netcode":"1","netmessage":"徐春霞|330621198804253802|01|330000ecaqm4mb0mvk0b8fab0a0000236f0d3c|330182|","QrCode":"","ChnlId":""}
            const readName = data?.netmessage?.split('|')[0]
            if (readName != objData?.payment?.patientName) {
              Modal.error({
                title: '提示',
                content:
                  `退费信息与卡片信息不一致，请确认是否插错卡，就诊人：` +
                  objData?.payment?.patientName +
                  '，电子凭证姓名：' +
                  readName,
                okText: '确认',
              })
              setDzpzButtonLoading(false)
            } else {
              doRefund(type, data?.netmessage)
            }
          }
        }
      )
      //身份证读卡
    } else if (type == '3') {
      doRefund()
      //自费直接调用退费
    } else if (type == '0') {
      doRefund()
    } else if (type == '4') {
      setYbFaceButtonLoading(true)
      setTimeout(() => {
        setYbFaceButtonLoading(false)
      }, 3000)
      readCardEHttp(
          {
            trade_args: [
              baseData?.countryHospitalNb,
              '01301',
              'cn.nhsa.ec.auth',
              userId,
              userName,
              '',
              '',
            ],
          },
          (data, err) => {
            if (!err) {
              // {"netcode":"1","netmessage":"徐春霞|330621198804253802|01|330000ecaqm4mb0mvk0b8fab0a0000236f0d3c|330182|","QrCode":"","ChnlId":""}
              const readName = data?.netmessage?.split('|')[0]
              if (readName != refund?.patientName) {
                Modal.error({
                  title: '提示',
                  content:
                      `退费信息与刷脸人员信息不一致，请确认是否插错卡，就诊人：` +
                      refund?.patientName +
                      '，扫脸姓名：' +
                      readName,
                  okText: '确认',
                })
                setYbFaceButtonLoading(false)
              } else {
                doRefund(type, data?.netmessage)
              }
            }
          }
      )
    } else {
      Modal.error({
        title: '错误',
        content: `未知的退费类型` + type + `请联系客服人员处理`,
        okText: '确认',
      })
    }
  }

  return (
    <>
      <ChargeQuery
        flag={flag}
        patientId={patientId}
        onSubmit={(obj) => {
          setParams({
            ...params,
            param: obj.nameF ? obj.nameF : patientId,
            registrationTimeHead: obj.startTimeF,
            registrationTimeTail: obj.endTimeF,
            treatmentDepartmentId: obj.departmentIdF,
            treatmentDoctorId: obj.doctorIdF,
            patientPayState: obj.stateF,
            insuranceStatus: obj.insuranceStatus,
            _dateRange: obj._dateRange,
            current: 1,
            startTimeF: undefined,
            endTimeF: undefined,
          })
          onLeftCount && onLeftCount(obj)
        }}
      />
      <EditableList
        className={styles.table}
        scroll={{ x: 1300 }}
        sticky={location.pathname == '/toothHome'}
        style={{
          overflow: 'auto',
          height: patientId ? 'calc(100% - 134px)' : 'calc(100% - 160px)',
        }}
        bordered
        loading={pageLoading}
        page={{
          current: params.current,
          size: params.size,
          total: page.total,
          items: page.items,
        }}
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row className={styles.total}>
              <Table.Summary.Cell index={0}></Table.Summary.Cell>
              <Table.Summary.Cell index={1} align='center'>
                合计
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2} align='center'></Table.Summary.Cell>
              <Table.Summary.Cell index={3} align='center'></Table.Summary.Cell>
              <Table.Summary.Cell index={4} align='center'></Table.Summary.Cell>
              <Table.Summary.Cell index={5} align='center'></Table.Summary.Cell>
              {/* 患者管理收费信息true去掉患者姓名等 */}
              {flag ? (
                <></>
              ) : (
                <>
                  <Table.Summary.Cell
                    index={6}
                    align='center'
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={7}
                    align='center'
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={8}
                    align='center'
                  ></Table.Summary.Cell>
                </>
              )}

              <Table.Summary.Cell index={9} align='center'>
                {tableCount.totalPayAmount == -1
                  ? '0'
                  : tableCount.totalPayAmount}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={10} align='center'>
                {tableCount.insurancePayAmount == -1
                  ? '0'
                  : tableCount.insurancePayAmount}
              </Table.Summary.Cell>
              <Table.Summary.Cell
                index={11}
                align='center'
                className={styles.total}
              >
                {tableCount.realPayAmount == -1
                  ? '0'
                  : tableCount.realPayAmount}
              </Table.Summary.Cell>
              <Table.Summary.Cell
                index={12}
                align='center'
                className={styles.total}
              >
                {tableCount.realerPayAmount == -1
                  ? '0'
                  : tableCount.realerPayAmount}
              </Table.Summary.Cell>
              <Table.Summary.Cell
                index={13}
                align='center'
                className={styles.total}
              >
                {tableCount.arrearageAmount == -1
                  ? '0'
                  : tableCount.arrearageAmount}
              </Table.Summary.Cell>
              {flag ? (
                <Table.Summary.Cell
                  className={styles.total}
                  index={14}
                  align='center'
                ></Table.Summary.Cell>
              ) : (
                ''
              )}

              <Table.Summary.Cell
                index={15}
                align='center'
              ></Table.Summary.Cell>
              <Table.Summary.Cell
                index={15}
                align='center'
              ></Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
        columns={column(params.size * (params.current - 1), (action, t) => {
          setTablePaymentId(t.paymentId)
          switch (action) {
            case 'settleAccounts': //结算
              setTableTreatmentId(t.treatmentId)
              if (t.settlementCategory == 1) {
                const data = {
                  patient: t.patientName,
                  amount: t.totalPayAmount,
                  // prePay,
                  ids: t.recipeId,
                  slots: [
                    {
                      name: '挂号费',
                      value: t.amount,
                      children: [
                        {
                          name: '挂号费',
                          value: t.amount,
                          children: [],
                        },
                      ],
                    },
                  ],
                  errorMaterials: [],
                }
                const preSettlementParams = {
                  treatmentId: t.treatmentId,
                  patientId: t.patientId,
                  registrationId: t.registrationId,
                  recipeId: t.recipeId,
                }
                setConfirmation(data)
                setRegisterData(preSettlementParams)
              } else {
                setPreSettlementModal(true)
              }
              break
            case 'detail': //详情
              setChargeDetailModal(true)
              break
            case 'returRremium': //退费
              dispatch(getPaymentDetail2(t?.paymentId))
                .then(unwrapResult)
                .then((res) => setObjData(res))
              break
            case 'bujiao': //补交
              dispatch(
                traceRoute({
                  name: '补交',
                  path: '/payment/oweMoney',
                  query: `paymentId=${t.paymentId}&toothPatient=${
                    patientId ? 1 : 2
                  }`,
                })
              )
              break
          }
        })}
        // columns={Columns(params.size * (params.current - 1), (action, t) => {
        //   if (action == 'detail') {
        //     return
        //   } else if (action == 'delete') {
        //     return
        //   } else if (action == 'edit') {
        //     return
        //   }
        // })}
        onChangePage={(current, size) =>
          setParams({
            ...params,
            current,
            size: size || 10,
          })
        }
        onRow={(r) => ({
          onDoubleClick: () => {
            if (r?.state == 101) {
              return
            } else {
              setTablePaymentId(r.paymentId)
              setChargeDetailModal(true)
            }
          },
        })}
      />
      {patientId && (
        <div className={styles.chargeFooter}>
          <Space>
            <Button type='primary' onClick={() => getCutPriceDetailFn()}>
              划价
            </Button>
            <Button type='primary' onClick={() => getBatchInfo()}>
              批量结算
            </Button>
            <Button type='primary' onClick={() => setInvoiceModal(true)}>
              发票明细
            </Button>
            {/* {(JuheShow?.openHubPay == 1 ||
              JuheShow?.openHubPayJiali == 1 ||
              JuheShow?.openHubPayYinlian == 1 ||
              JuheShow?.openHubPayKanglin == 1) && (
              <Button type='primary' onClick={() => setJuhePayModal(true)}>
                聚合支付记录
              </Button>
            )} */}
          </Space>
        </div>
      )}

      {/* 详情弹窗 */}
      <ChargeDetailModal
        tablePaymentId={tablePaymentId}
        visible={chargeDetailModal}
        onOk={() => setChargeDetailModal(false)}
        onCancel={() => setChargeDetailModal(false)}
      />
      {/* 预退费弹窗 */}
      <RealPayModal
        buttonLoading={buttonLoading}
        stkButtonLoading={stkButtonLoading}
        dzpzButtonLoading={dzpzButtonLoading}
        ybFaceButtonLoading={ybFaceButtonLoading}
        refund={objData?.payment}
        onOK={(type) => {
          validCardInfoBeforeGoRefund(type)
        }}
        onCancel={() => setObjData(undefined)}
        resultData={objData?.paymentDetailRecordVOS}
      />

      {/* 划价弹窗 */}
      <CutPriceModal
        visible={cutPriceModal}
        cutPriceDetail={cutPriceDetail}
        patientId={patientId || ''}
        onCancel={() => setCutPriceModal(false)}
        onOk={(arr, id, type) => {
          dispatch(
            getCutPriceAdd({
              medicalRecordType: 3,
              oneKeyPricingBean: arr,
              registrationId: id,
            })
          )
            .then(unwrapResult)
            .then((res: any) => {
              getTableList()
              setCutPriceModal(false)
              type == 2 && setBatchData(fromJson(res))
            })
        }}
      />

      {/* 发票明细弹窗 */}
      <Modal
        title='发票明细'
        width={1000}
        visible={invoiceModal}
        onCancel={() => setInvoiceModal(false)}
        footer={null}
        bodyStyle={{ padding: 0, height: 600 }}
      >
        <InvoiveRecords status={9} patientId={patientId} type={1} />
      </Modal>
      {/* 聚合支付记录弹窗 */}
      <Modal
        title='聚合支付记录'
        width={1000}
        visible={juhePayModal}
        onCancel={() => setJuhePayModal(false)}
        footer={null}
        bodyStyle={{ padding: 0, height: 600, overflow: 'auto' }}
      >
        <JuhePayPageRef status={7} patientId={patientId} type={1} />
      </Modal>
      {/* 预结算弹窗 */}
      <Modal
        title='结算'
        width={1480}
        visible={preSettlementModal}
        onCancel={() => setPreSettlementModal(false)}
        footer={null}
        destroyOnClose
        bodyStyle={{ padding: 0, height: 600, overflow: 'auto' }}
      >
        <PaymentCart
          fromToothPatient={true}
          noCheckBox={true}
          status={patientId ? 2 : 1}
          tableTreatmentId={tableTreatmentId}
        />
      </Modal>

      {/* 批量预结算弹窗 */}
      <Modal
        title='结算'
        width={1400}
        visible={batchModal}
        onCancel={() => setBatchModal(false)}
        footer={null}
        bodyStyle={{ padding: 0, height: 600, overflow: 'auto' }}
      >
        <PaymentConfirm
          forceCash={true}
          ref={ref}
          confirmation={batchData}
          status={2}
          onChargeSubmit={() => {
            setBatchModal(false)
            setParams({
              ...params,
              current: 1,
            })
          }}
          onChargeCancel={() => {
            setBatchModal(false)
          }}
          batchFlag={true}
        />
      </Modal>

      {/* 挂号费收取弹窗 */}
      <Modal
        title='预收挂号费'
        width={900}
        cancelText='取消'
        okText='确认'
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false)
          setUpdateFlags('0', '0')
        }}
        onOk={() => {
          refModal.current?.submit()
        }}
        bodyStyle={{ padding: 0 }}
      >
        <PaymentConfirm
          ref={refModal}
          confirmation={confirmation}
          YUSHOUGUAHAOFEI={true}
          YUSHOUtreatment={registerData}
          isPayPage={true}
          onPay={(callParams) => {
            setUpdateFlags('0', '0')
            if (callParams.success == '1') {
              notification.success({
                message: '挂号费支付成功',
                duration: 1.5,
              })
              setIsModalVisible(false)
              dispatch(getPaymentList(params))
              getTableList()
            } else {
              setIsModalVisible(false)
            }
            setConfirmation(undefined)
            setRegisterData(undefined)
          }}
        />
      </Modal>
    </>
  )
}
