import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Form,
  Input,
  notification,
  Row,
  Select,
  Space,
  Table,
} from 'antd'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectInsuranceArray } from '../../../../app/applicationSlice'
import { RootDispatch } from '../../../../app/store'
import {
  DateRangeType,
  DateSelect,
  getDateRange,
  TJFXTime,
} from '../../../../compnents/form/DateSelect'
import { EditableList } from '../../../../compnents/list/EditableList'
import { getDepartments } from '../../../payment/list/paymentSlice'
import { columns } from './columns'
import styles from './scheduleOfExpenses.module.css'
import { ThemeContext } from '../../../../theme/ThemeContext'
import {
  getScheduleOfExpensesList,
  getScheduleOfExpensesListExport,
  getScheduleOfExpensesTotalList,
} from './scheduleOfExpensesSlice'
import { User } from '../../../../models/user'
import { getDoctorList } from '../../../sales/DoctorAccont/doctorAuditSlice'
import moment from 'moment'
import { DateTimeFormatSimpleOnlyOne } from '../../../../models/datetime'
import { UserOutlined } from '@ant-design/icons'

export interface ScheduleOfExpensesRef {
  tabKey?: any
  oneTabKey?: any
}

interface ScheduleOfExpensesProps {
  tabKey?: any
  oneTabKey?: any
}
const ScheduleOfExpenses = (
  { tabKey, oneTabKey }: any,
  ref?: React.Ref<ScheduleOfExpensesRef>
): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const insuranceArray = useSelector(selectInsuranceArray)

  const theme = useContext(ThemeContext)

  const [form] = Form.useForm()

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  const [id, setId] = useState<string>()

  const [timeHead, timeTail] = getDateRange(DateRangeType.Today)

  const [summaryTotal, setSummaryTotal] = useState<any>()

  const [params, setParams] = useState<any>({
    current: 1,
    size: 10,
    startTime: moment(timeHead).format(DateTimeFormatSimpleOnlyOne),
    endTime: moment(timeTail).format(DateTimeFormatSimpleOnlyOne),
  })

  const [doctors, setDoctors] = useState<User[]>([])

  const [page, setPage] = useState({ items: [], total: 0 })

  const [releasingTimeFlag, setReleasingTime] = useState(false)

  const [isInTimeRange, setIsInTimeRange] = useState(false)

  useEffect(() => {
    setReleasingTime(false)
  }, [])

  const checkTimeRange = () => {
    const now = moment()
    const currentHour = now.hours()

    // 判断当前时间是否在给定的时间段内
    console.log(currentHour, now, 'currentHour')

    if (
      (currentHour >= 10 && currentHour < 12) ||
      (currentHour === 12 && now.minutes() === 0) ||
      (currentHour >= 14 && currentHour < 16) ||
      (currentHour >= 19 && currentHour <= 23) ||
      (currentHour >= 0 && currentHour < 6)
    ) {
      setIsInTimeRange(true)
    } else {
      setIsInTimeRange(false)
    }
  }

  useEffect(() => {
    checkTimeRange()
  }, [])

  useEffect(() => {
    try {
      dispatch(getDoctorList())
        .then(unwrapResult)
        .then((doctors) => setDoctors(doctors))
    } catch {
      // do nothing.
    }
    form.setFieldsValue(params)
  }, [])

  const getPage = () => {
    setPageLoading(true)
    dispatch(
      getScheduleOfExpensesList({
        ...params,
        current: params.current,
        size: params.size,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        setPage({
          items: res.records,
          total: res.total,
        })
      })
      .finally(() => setPageLoading(false))
    dispatch(
      getScheduleOfExpensesTotalList({
        ...params,
        current: params.current,
        size: params.size,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        setSummaryTotal(res)
      })
      .finally(() => setPageLoading(false))
  }

  useEffect(() => {
    if (tabKey == 2 && oneTabKey == 0) {
      getPage()
    }
  }, [params, tabKey, oneTabKey])

  const ListExport = () => {
    dispatch(
      getScheduleOfExpensesListExport({
        ...params,
        releasingTime: releasingTimeFlag ? '99' : undefined,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        const content = res
        const blob = new Blob([content])
        const fileName = '医生病人费用统计导出数据.xlsx'
        const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL']
        const elink = document.createElement('a')
        if ('download' in elink) {
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = selfURL['createObjectURL'](blob)
          document.body.appendChild(elink)
          // 触发链接
          elink.click()
          selfURL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          return (navigator as any).msSaveBlob(blob, fileName)
        }
      })
      .then(() => {
        notification.success({
          message: '导出成功',
          duration: 3,
        })
      })
      .catch((res: any) => {
        notification.error({
          message: '导出失败：' + res,
          duration: 3,
        })
      })
  }

  return (
    <Col
      style={{
        height: 'calc(100% - 30px)',
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Col
        style={{
          flex: 1,
          backgroundColor: theme.pn,
          borderRadius: 10,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Form
          form={form}
          autoComplete='off'
          onFinish={(values) => {
            setParams({
              ...params,
              ...values,
              current: 1,
              startTime: values?.timeHead
                ? moment(values?.timeHead).format(DateTimeFormatSimpleOnlyOne)
                : moment(timeHead).format(DateTimeFormatSimpleOnlyOne),
              endTime: values?.timeTail
                ? moment(values?.timeTail).format(DateTimeFormatSimpleOnlyOne)
                : moment(timeTail).format(DateTimeFormatSimpleOnlyOne),
              timeHead: undefined,
              timeTail: undefined,
            })
          }}
        >
          <Row
            align='middle'
            justify='space-between'
            style={{ marginTop: 10, marginBottom: 20 }}
          >
            <Space>
              <Form.Item name='patientName' noStyle>
                <Input
                  placeholder='请输入患者姓名'
                  allowClear
                  onChange={() => form.submit()}
                  prefix={<UserOutlined />}
                />
              </Form.Item>
              <DateSelect
                style={{ width: '8.5rem' }}
                labelPrefix=''
                allowClear={false}
                options={TJFXTime}
                namePrefix='time'
                placeholder='收费列表-时间'
                initialValue={DateRangeType.Today}
                onChange={form.submit}
              />
              <Form.Item name='doctorId' noStyle>
                <Select
                  onChange={form.submit}
                  style={{ width: 220 }}
                  placeholder='请选择医生姓名'
                  allowClear
                  showSearch
                  filterOption={(input, option) => {
                    return option?.className
                      ? ((option?.className as unknown) as string)
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      : false
                  }}
                >
                  {doctors.map((d) => (
                    <Select.Option
                      className={d.mnemonicCode}
                      key={d.id}
                      value={d.id}
                    >
                      {d.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {insuranceArray && insuranceArray.length !== 0 && (
                <Form.Item name='insuranceCode' noStyle>
                  <Select
                    placeholder='险种类型'
                    style={{ width: '8.25rem' }}
                    allowClear
                    // suffixIcon={<CaretDownFilled />}
                    onChange={form.submit}
                  >
                    <Select.Option value={''}>全部</Select.Option>
                    <Select.Option value={'ZIFEI'}>自费</Select.Option>
                    {insuranceArray.map((v) => (
                      <Select.Option value={v.insuranceCode} key={v.id}>
                        {v.insuranceName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              <Button type='primary' onClick={form.submit}>
                查询
              </Button>
            </Space>
            <Space>
              <div style={{ color: '#999999' }}>
                （最多支持导出近3个月10000条内的数据，更多请联系客服）
              </div>
              <Button
                type='primary'
                onClick={() => {
                  ListExport()
                }}
              >
                导出
              </Button>
              {isInTimeRange ? (
                <div
                  style={{
                    opacity: 0,
                    cursor: 'pointer',
                    width: '10px',
                    height: '30px',
                  }}
                  onDoubleClick={() => {
                    alert('Service mode is open')
                    setReleasingTime(true)
                  }}
                ></div>
              ) : (
                <></>
              )}
            </Space>
          </Row>
        </Form>
        <EditableList
          bordered
          page={{
            items: page.items,
            current: params.current,
            size: params.size,
            total: page.total,
          }}
          loading={pageLoading}
          scroll={{ x: 'auto' }}
          columns={columns((action: any, v: any, e: any) => {
            return
          }, params.size * (params.current - 1))}
          onChangePage={(current, size) => {
            setParams({ ...params, current, size: size || 10 })
          }}
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row className={styles.total}>
                <Table.Summary.Cell
                  className={styles.fixed}
                  index={0}
                  align='center'
                >
                  合计
                </Table.Summary.Cell>
                <Table.Summary.Cell
                  className={styles.fixed}
                  index={1}
                  align='right'
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  className={styles.fixed}
                  index={2}
                  align='right'
                ></Table.Summary.Cell>

                <Table.Summary.Cell index={3} align='center'>
                  {summaryTotal?.westernMedicineFeeSUM > 0
                    ? summaryTotal?.westernMedicineFeeSUM?.toFixed(2)
                    : '0.00'}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4} align='center'>
                  {summaryTotal?.patentChineseMedicineFeeSUM > 0
                    ? summaryTotal?.patentChineseMedicineFeeSUM?.toFixed(2)
                    : '0.00'}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5} align='center'>
                  {summaryTotal?.herbalMedicineFeeSUM > 0
                    ? summaryTotal?.herbalMedicineFeeSUM?.toFixed(2)
                    : '0.00'}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6} align='center'>
                  {summaryTotal?.otherMedicineFeeSUM > 0
                    ? summaryTotal?.otherMedicineFeeSUM?.toFixed(2)
                    : '0.00'}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7} align='center'>
                  {summaryTotal?.registrationAmountSUM > 0
                    ? summaryTotal?.registrationAmountSUM?.toFixed(2)
                    : '0.00'}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={8} align='center'>
                  {summaryTotal?.potionMoneySUM > 0
                    ? summaryTotal?.potionMoneySUM?.toFixed(2)
                    : '0.00'}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={9} align='center'>
                  {summaryTotal?.diagnosisFeeSUM > 0
                    ? summaryTotal?.diagnosisFeeSUM?.toFixed(2)
                    : '0.00'}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={10} align='center'>
                  {summaryTotal?.examinationFeeSUM > 0
                    ? summaryTotal?.examinationFeeSUM?.toFixed(2)
                    : '0.00'}
                </Table.Summary.Cell>

                <Table.Summary.Cell index={11} align='center'>
                  {summaryTotal?.totalTr05 > 0
                    ? summaryTotal?.totalTr05?.toFixed(2)
                    : '0.00'}
                </Table.Summary.Cell>

                <Table.Summary.Cell index={12} align='center'>
                  {summaryTotal?.totalTr07 > 0
                    ? summaryTotal?.totalTr07?.toFixed(2)
                    : '0.00'}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={13} align='center'>
                  {summaryTotal?.totalTr08 > 0
                    ? summaryTotal?.totalTr08?.toFixed(2)
                    : '0.00'}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={14} align='center'>
                  {summaryTotal?.totalTr09 > 0
                    ? summaryTotal?.totalTr09?.toFixed(2)
                    : '0.00'}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={15} align='center'>
                  {summaryTotal?.totalTr10 > 0
                    ? summaryTotal?.totalTr10?.toFixed(2)
                    : '0.00'}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={16} align='center'>
                  {summaryTotal?.totalTr11 > 0
                    ? summaryTotal?.totalTr11?.toFixed(2)
                    : '0.00'}
                </Table.Summary.Cell>

                <Table.Summary.Cell index={17} align='center'>
                  {summaryTotal?.totalTr23 > 0
                    ? summaryTotal?.totalTr23?.toFixed(2)
                    : '0.00'}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={18} align='center'>
                  {summaryTotal?.totalTr24 > 0
                    ? summaryTotal?.totalTr24?.toFixed(2)
                    : '0.00'}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={19} align='center'>
                  {summaryTotal?.totalTr26 > 0
                    ? summaryTotal?.totalTr26?.toFixed(2)
                    : '0.00'}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={20} align='center'>
                  {summaryTotal?.totalTr27 > 0
                    ? summaryTotal?.totalTr27?.toFixed(2)
                    : '0.00'}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={21} align='center'>
                  {summaryTotal?.totalTr28 > 0
                    ? summaryTotal?.totalTr28?.toFixed(2)
                    : '0.00'}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={22} align='center'>
                  {summaryTotal?.totalTr25 > 0
                    ? summaryTotal?.totalTr25?.toFixed(2)
                    : '0.00'}
                </Table.Summary.Cell>

                <Table.Summary.Cell index={23} align='center'>
                  {summaryTotal?.otherMedicalFeeSUM > 0
                    ? summaryTotal?.otherMedicalFeeSUM?.toFixed(2)
                    : '0.00'}
                </Table.Summary.Cell>

                <Table.Summary.Cell
                  index={24}
                  align='center'
                  className={styles.fixed}
                >
                  {summaryTotal?.totalCostSUM > 0
                    ? summaryTotal?.totalCostSUM?.toFixed(2)
                    : '0.00'}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
      </Col>
    </Col>
  )
}

export const ScheduleOfExpensesList = forwardRef(ScheduleOfExpenses)
