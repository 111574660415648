import { Button, TableColumnType } from "antd"
import moment from "moment"
import React, { ReactElement } from "react"
import { DateTimeFormat } from "../../models/datetime"
import { Dimen } from "../../models/dimen"

export const ExcuteDetailColumn = (
  onClick: (text: string, id: any, coherentVideo?: any) => void,
  startIndex: number
): TableColumnType<any>[] => {
  return [
    {
      title: "序号",
      dataIndex: "order",
      align: "center",
      width: Dimen.Num,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: "执行次数",
      dataIndex: "executeNum",
      align: "center",
      ellipsis: true,
    },
    {
      title: "治疗部位",
      dataIndex: "treatmentParts",
      align: "center",
      render: (_, t) =>
        t.treatmentParts ? t.treatmentParts : "-",
    },

    {
      title: "开始时间",
      dataIndex: "executeTime",
      align: "center",
      // sorter: (a: any, b: any) =>
      //   moment(a.executeTime).unix() - moment(b.executeTime).unix(),
      render: (_, t) =>
        t.executeTime ? moment(t.executeTime).format(DateTimeFormat) : "-",
    },
    {
      title: "结束时间",
      dataIndex: "executeEndTime",
      align: "center",
      render: (_, t) =>
        t.executeEndTime ? moment(t.executeEndTime).format(DateTimeFormat) : "-",
    },
    {
      title: "执行人",
      dataIndex: "operatorName",
      align: "center",
    },
    {
      title: "备注",
      dataIndex: "remark",
      ellipsis: true,
      align: "center",
      render: (_, t) => (t.remark ? t.remark : "-"),
    },
    {
      title: "附件",
      dataIndex: "",
      align: "center",
      render: function element(_, t) {
        return (
          <>
            {t.attachmentFile ? (
              <Button
                type='text'
                style={{ color: "#027aff" }}
                onClick={(e: any) => {
                  onClick("look", t.id, t.attachmentFile)
                }}
              >
                查看
              </Button>
            ) : (
              "-"
            )}
          </>
        )
      },
    },
  ]
}
