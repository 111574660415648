/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-25 09:17:44
 * @LastEditors: linxi
 * @LastEditTime: 2025-01-10 10:17:45
 */
import { notification, Table } from 'antd'
import { unwrapResult } from '@reduxjs/toolkit'
import React, { ReactElement, useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EditableList } from '../../../compnents/list/EditableList'
import { changeWaterParams } from '../../../services/changewater'
import { changeWaterColumns, PrintChangeWaterColumns } from './columns'
import { ChangeWaterQuerys } from './Query'
import {
  getChangeWaterDetail,
  getChangeWaterList,
  selectChargeWatertData,
  selectCurrent,
  selectDetail,
  selectTotal,
  setCurrent,
  getChangeWaterExoprt,
  selectPageLoading,
  setPageLoading,
  selectChargeWatertSummaryData,
} from './changeWaterSlice'
import { ChargeWaterModel } from './Modal'
import styles from './charge.module.css'
import { RootDispatch } from '../../../app/store'

import { PrintFrame, PrintFrameRef } from '../../print/PrintFrame'

import { DoctorSaleEntry, DoctorSalesResult } from '../../../models/doctorSales'
import { TabBar, TabPane } from '../../../compnents/widgets/TabBar'
import { selectTenantName } from '../../../app/applicationSlice'
import { changeWaterPrintBody } from '../../../models/changeWaterPrint'
import { getStationOrderSourceNames } from '../../../models/changeWater'
import moment from 'moment'
import { DateTimeFormatSimple } from '../../../models/datetime'
export const ChargeWater = (props: { type?: string }): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const [queries, setQueries] = useState<changeWaterParams>()

  const [scrollHeight, setScrollHeight] = useState<any>()

  const [SelectId, setSelectId] = useState(0)

  const Current = useSelector(selectCurrent)

  const total = useSelector(selectTotal)

  const data: any = useSelector(selectChargeWatertData)

  const summaryData = useSelector(selectChargeWatertSummaryData)

  const [size, setSize] = useState(10)

  const [isstate, setState] = useState()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [releasingTimeFlag, setReleasingTime] = useState(false)

  const printRef = useRef<PrintFrameRef>(null)

  const tenantName = useSelector(selectTenantName)

  const [result, setResult] = useState({
    headers: [],
    entries: [],
  } as DoctorSalesResult)

  const pageLoadingRX = useSelector(selectPageLoading)

  useEffect(() => {
    if (queries?.timeHead) {
      dispatch(setPageLoading(false))
      onResize() // 初始化设置高度
      window.addEventListener('resize', onResize)
      dispatch(getChangeWaterList({ ...queries, current: Current, size }))
      setTimeout(() => {
        dispatch(setPageLoading(false))
      }, 3000)
    }
  }, [Current, size, queries])

  useEffect(() => {
    setReleasingTime(false)
  }, [])
  
  const onResize = () => {
    const table = document.getElementById('tableHeightChangeWater')
    if (table) {
      const height = table.offsetHeight - 60 - 80 - 30
      setScrollHeight(height)
    } else {
      return '100%'
    }
  }

  const table = (type = false) => (
    <div id='tableHeightChangeWater'  style={{ width: '100%', height: '100%', overflowY: 'hidden' }}>
      <EditableList
        className={type ? styles.charges : styles.charge}
        scroll={{ y: Number(scrollHeight), x: '30rem' }}
        style={{
          width: '100%',
          height: '89%',
          overflow: 'hidden',
        }}
        loading={pageLoadingRX}
        rowKey={(t: any) => t.id}
        page={{
          items: data,
          current: Current, 
          size: size,
          total: total,
        }}
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row className={styles.total}>
              <Table.Summary.Cell index={0} align='center'></Table.Summary.Cell>
              <Table.Summary.Cell index={1} align='center'>
                合计
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2}></Table.Summary.Cell>
              <Table.Summary.Cell index={3}></Table.Summary.Cell>
              <Table.Summary.Cell index={4}></Table.Summary.Cell>
              {totalPageList?.map((v: any) => {
                return (
                  <>
                    <Table.Summary.Cell index={11} align='right'>
                      {Number(v?.totalValue)?.toFixed(2)}
                    </Table.Summary.Cell>
                  </>
                )
              })}
              <Table.Summary.Cell index={11} align='right'>
                {summaryData?.totalPayAmountSUM}
              </Table.Summary.Cell>
              {/* <Table.Summary.Cell index={12} align='right'>
                {summaryData?.receivePayAmountSUM}
              </Table.Summary.Cell> */}
              <Table.Summary.Cell index={5} align='right'>
                {summaryData?.shouldPayAmountSUM}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={6} align='right'>
                {summaryData?.realPayAmountSUM}
              </Table.Summary.Cell>
              {}
              <Table.Summary.Cell index={7}></Table.Summary.Cell>
              <Table.Summary.Cell index={8}></Table.Summary.Cell>
              <Table.Summary.Cell index={9}></Table.Summary.Cell>
              <Table.Summary.Cell index={10}></Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
        columns={changeWaterColumns(
          (id: any, t: any, state: any) => {
            if (t == '查看详情') {
              dispatch(getChangeWaterDetail(id))
              setIsModalVisible(true)
              setState(state)
            } else {
              return
            }
          },
          size * (Current - 1),
          data
        )}
        onRow={(d: any) => ({
          onDoubleClick: () => {
            setState(d.state)
            dispatch(getChangeWaterDetail(d.id))
            setIsModalVisible(true)
          },
        })}
        onChangePage={(current, pageSize) => {
          setSize(pageSize as number)
          dispatch(setCurrent(current))
        }}
      />
    </div>
  )
  const outCluedExport = () => {
    dispatch(
      getChangeWaterExoprt({
        ...queries,
        current: Current,
        size,
        releasingTime: releasingTimeFlag ? '99' : undefined,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        const content = res
        const blob = new Blob([content])
        const fileName = '收费流水.xlsx'
        const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL']
        const elink = document.createElement('a')
        if ('download' in elink) {
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = selfURL['createObjectURL'](blob)
          document.body.appendChild(elink)
          // 触发链接
          elink.click()
          selfURL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          (navigator as any).msSaveBlob(blob, fileName)
        }
      })
      .then(() => {
        notification.success({
          message: '导出成功',
          duration: 3,
        })
      })
      .catch((res: any) => {
        return
      })
  }

  const titleList = data?.[0]?.customizePay?.map((v: any) => {
    return { payMethodName: v?.payMethodName, payMethod: v?.payMethod }
  })

  // 动态合计行
  const groupByName = (arr: any) => {
    const groupedData: any = {}
    // 遍历原始数组
    arr?.forEach(function (item: any) {
      const name = item.payMethodName
      // 如果名称在 groupedData 中不存在，创建一个新数组
      if (!groupedData[name]) {
        groupedData[name] = []
      }
      // 将当前项添加到对应名称的数组中
      groupedData[name].push(item)
    })
    // 将结果从对象转换为数组
    const result = Object.values(groupedData)
    return result
  }

  const totalPageList: any = groupByName(
    data
      ?.map((v: any) => {
        return v?.customizePay
      })
      ?.flat(2)
  )
    ?.map((paymentMethod: any) => {
      const payMethodName = paymentMethod[0].payMethodName
      const totalValue = paymentMethod.reduce(
        (total: any, payment: any) =>
          Number(total) + Number(payment.payMethodAmount),
        0
      )
      return { payMethodName, totalValue }
    })
    ?.sort((a: any, b: any) => a.payMethodName?.localeCompare(b.payMethodName))

  const dataMainList = {
    tenantName: tenantName?.split('-')?.[0],
    dataList: data?.map((v: any) => {
      return {
        ...v,
        outpatientNo: v.outpatientNo == -1 ? '' : v.outpatientNo,
        registrationCategory:
          v?.registrationCategory == 0
            ? '自费'
            : v?.registrationCategory === -1
            ? ' -'
            : '医保',
        orderSource: getStationOrderSourceNames(v.orderSource),
        shouldPayAmount: Number(v?.shouldPayAmount)?.toFixed(2),
        realPayAmount: Number(v?.realPayAmount)?.toFixed(2),
        createTime: v.createTime
          ? moment(v.createTime)?.format(DateTimeFormatSimple)
          : '-',
      }
    }),
    // timeHead: moment(queries?.timeHead).format(DateTimeFormatSimpleOnly),
    // timeTail: moment(queries?.timeTail).format(DateTimeFormatSimpleOnly),
    titleList: titleList,
    totalPageList: totalPageList,
    summaryData: summaryData,
  }

  const content = changeWaterPrintBody(dataMainList)

  return (
    <>
      {props?.type != '1' ? (
        <TabBar style={{ margin: '10px 20px' }}>
          <TabPane key='0' tab='收费流水'>
            <div className={styles.content}>
              <ChangeWaterQuerys
                onValueChange={(v) => {
                  setQueries({ ...queries, ...v })
                  dispatch(setCurrent(1))
                }}
                print={() => {
                  // printRef.current?.print()
                  const page: any = window.open('', '_blank') // 打开一个新窗口，用于打印
                  page.document.write(content) // 写入打印页面的内容
                  page.print() // 打印
                  page?.close()
                }}
                outClued={() => {
                  outCluedExport()
                }}
                alertMessage={() => {
                  alert('Service mode is open')
                  setReleasingTime(true)
                }}
              />
              {table()}
            </div>
          </TabPane>
        </TabBar>
      ) : (
        <div className={styles.content}>
          <ChangeWaterQuerys
            onValueChange={(v) => {
              setQueries({ ...queries, ...v })
              dispatch(setCurrent(1))
            }}
            print={() => {
              // printRef.current?.print()
              const page: any = window.open('', '_blank') // 打开一个新窗口，用于打印
              page.document.write(content) // 写入打印页面的内容
              page.print() // 打印
              page?.close()
            }}
            outClued={() => {
              outCluedExport()
            }}
          />
          {table()}
        </div>
      )}
      <ChargeWaterModel
        isModalVisible={isModalVisible}
        state={isstate}
        onOk={() => {
          setIsModalVisible(false)
          setSelectId(0)
        }}
        onCancel={() => {
          setIsModalVisible(false)
          setSelectId(0)
        }}
      />
      <PrintFrame
        ref={printRef}
        title={
          JSON.parse(localStorage.getItem('credentials') as string)?.tenantName
        }
        subTitle='收费流水'
      >
        <EditableList
          className={styles.charges}
          rowKey={(t: any) => t.id}
          page={{
            items: data,
            current: Current,
            size: size,
            total: total,
          }}
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row className={styles.total}>
                <Table.Summary.Cell
                  index={0}
                  align='center'
                ></Table.Summary.Cell>
                <Table.Summary.Cell index={1} align='center'>
                  合计
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={4}></Table.Summary.Cell>
                <Table.Summary.Cell index={5} align='right'>
                  {summaryData?.shouldPayAmountSUM}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6} align='right'>
                  {summaryData?.realPayAmountSUM}
                </Table.Summary.Cell>

                <Table.Summary.Cell index={7}></Table.Summary.Cell>
                <Table.Summary.Cell index={8}></Table.Summary.Cell>
                <Table.Summary.Cell index={9}></Table.Summary.Cell>
                <Table.Summary.Cell index={10}></Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
          pagination={false}
          columns={PrintChangeWaterColumns((id: any, t: any, state: any) => {
            if (t == '查看详情') {
              dispatch(getChangeWaterDetail(id))
              setIsModalVisible(true)
              setState(state)
            } else {
              return
            }
          }, size * (Current - 1))}
          onRow={(d: any) => ({
            onDoubleClick: () => {
              setState(d.state)
              dispatch(getChangeWaterDetail(d.id))
              setIsModalVisible(true)
            },
          })}
          onChangePage={(current, pageSize) => {
            setSize(pageSize as number)
            dispatch(setCurrent(current))
          }}
        />
      </PrintFrame>
    </>
  )
}
