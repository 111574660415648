/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-25 09:17:44
 * @LastEditors: K
 * @LastEditTime: 2022-06-16 15:54:47
 */
import { Modelment } from '../models/list'
import { API_PREFIX } from './constant'
import { Request } from './request'


export interface changeWaterParams {
  id?: number
  current?: number
  pages?: number
  size?: number
  total?: number
  medicareCode?: string
  needPayAmount?: number
  operateUser?: string
  patientId?: number
  patientName?: string
  payMethod?: string
  realPayAmount?: number
  receivePayAmount?: number
  registrationCategory?: string
  returnPayAmount?: number
  settlementTypesOfInsurance?: number
  shouldPayAmount?: number
  state?: boolean
  status?: number
  tenantId?: number
  treatmentId?: number
  realPay?: string
  timeHead?: string
  timeTail?: string
  orderSource?: string
  createTime?: string
  insuranceCode?:string
  releasingTime?:any
}
export function getChangeWaterList(params: changeWaterParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/statistics/chargeWaterList`,
    method: 'GET',
    params,
  }
}
export function getChangeWaterDetail(id?: number): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/statistics/detail`,
    method: 'GET',
    params: {
      id,
    },
  }
}

export function getDoctorAuditExport(
  params: changeWaterParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/statistics/chargeWaterList/exportexcel`,
    method: 'GET',
    responseType: 'blob',
    params,
  }
}
