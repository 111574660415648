import {
  ExclamationCircleFilled,
  MinusOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Badge,
  Button,
  Checkbox,
  Col,
  Divider,
  InputNumber,
  message,
  Modal,
  notification,
  Row,
  Space,
  TableColumnType,
} from 'antd'
import moment from 'moment'
import Qrcode from 'qrcode.react'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import {
  selectAutoPrintTemplateData,
  selectCredentials,
  selectEdition,
  selectInsuranceArray,
  selectShortName,
  selectTenantAddress,
  selectTenantAreaCd,
  selectTenantCategory,
  selectTenantCityCode, selectTenantCountyCode,
  selectTenantId,
  selectTenantName,
  selectUserId,
  selectUserName,
} from '../../../app/applicationSlice'
import { RootDispatch } from '../../../app/store'
import { GlobalKeyboardAction } from '../../../compnents/actions/GlobalKeyboardAction'
import { EditableList } from '../../../compnents/list/EditableList'
import { NavBar } from '../../../compnents/nav/NavBar'
import { Label } from '../../../compnents/widgets/Label'
import {
  selectInsuranceItem,
  setInsurance,
} from '../../../layouts/insuranceSlice'
import { Breadcrumb, traceRoute } from '../../../layouts/layoutSlice'
import {
  BillingCategory,
  BillingCategoryName,
} from '../../../models/billingCategory'
import {
  DateTimeFormatSimpleOne,
  DateTimeFormatSimpleOnly,
  DateTimeFormatSimpleOnlyOne,
} from '../../../models/datetime'
import { DeliveryState } from '../../../models/delivery'
import {
  fromMaterialJson,
  getOrderCategoryName,
  getTradeCode,
  Material,
} from '../../../models/material'
import { PrintTemplate, PrintTemplateCategory} from '../../../models/print'
import { getRecipeCategoryName, RecipeStatus } from '../../../models/recipe'
import { updateStatesResponse } from '../../../services/payment'
import { ThemeContext } from '../../../theme/ThemeContext'
import { useQuery } from '../../../utils/HttpUtils'
import {
  BaoJiaQi,
  getTradeName,
  MbfeService,
  medicalSendHttp,
  readCardEHttp,
  readCardSHttp,
  strParse,
} from '../../../utils/MedicalUtils'
import {
  directDeliver,
  directDeliverSingleItem,
} from '../../delivery/deliverySlice'
import { ManualDeliveryModal } from '../../delivery/ManualDeliveryModal'
import { PrintTemplateModal } from '../../print/PrintTemplateModal'
import { RecipeListModal } from '../../recipe/modal/RecipeListModal'
import { cancelSettlementAsync } from '../confirm/paymentConfirmSlice'
import { getInsuranceCodeName } from '../list/columns'
import { JuhePremium, refund as refundPayment } from '../list/paymentSlice'
import { RealPayModal } from '../list/realPayModal'
import {
  cancelSaleUpload,
  getAllPaymentResult,
  getPaymentResult,
  getPaymentResults,
  getUpdateStates,
  isAutoUpload,
  isReadyUpload,
  selectResult,
  selectResults,
} from './paymentResultSlice'
import styles from '../Payment.module.css'
import { getBoilTypeName } from '../../../models/paymentresult'
import {
  setActiveObj,
  updatePatientTimeAsync,
} from '../../A-toothModule/patient/patientSlice'
import {
  getWebPrintDataByPaymentId, getWebPrintDataByRecipeId,
  getWebPrintDataByTreatmentId,
} from '../../recipe/recipeListSlice'
import _, { set, sum, template } from 'lodash'
import {
  PrintZXDBody,
  PrintZLZXDBody,
  PrintTYBody,
  PrintZYBody,
  PrintKQBody,
} from '../../../models/PrintTemplateModalPrint'
import { printZXDData } from '../../../services/recipe'
import {
  getPrinters,
  getPrintRecipeAsyncByPaymentId,
  getPrintTemplateSettingDetail,
  getVisibleIsPeopleFace,
  getVisibleQrCode, getWebPrintDataAsyncByRecipeId,
  initializePrintService,
  invoiceListAsync,
  invoiceListAsyncForMbfe,
  printTemplate,
  selectPrintServiceState,
  selectPrintTemplate,
  selectTemplateDefaultPrinters,
  selectTemplateOffsets,
} from '../../print/printSlice'
import { getSignatureEntityAsync } from '../../personalManagement/personalDetail/personalDetailSlice'
import { getSexData } from '../../../models/ToothHome'
import {
  PrintDiagnosisCertificateContent,
  subZYPrintPrescriptionContent,
  subPrintPrescriptionContent,
} from '../../../models/recipeEditorPrint'
import { invoicenumberconfigAsync } from '../../tenant/tenantSlice'
import { Settings } from '../../../models/setting'
import { selectBooleanSetting } from '../../settings/settingsSlice'
import { serverType } from '../../../services/commodity'
import { LoadingState } from '../../../models/common'
import { selectSettings } from '../../user/setting/settingSlice'
import { Checks } from '../../../models/setting'
import JsBarcode from 'jsbarcode'
import { getPatientInfoAsync } from '../../registration/register/registerSlice'
import { TraceCodeInputModal } from '../list/TraceCodeInputModal'
import JXImg from '../../../layouts/img/JXImg.png'
import {
  finishPutstorage,
  multifunctionalUpAccountAsync,
  rectPutstorageAsync,
} from '../../putstorage/putstorageSlice'
import {subPrintInvoiceFeeBillForWeb} from "../../../models/invoiceFeeBillForWeb";
let timer: any = null
interface paymentResultProps {
  proPaymentId?: string //口腔版 his 收费弹窗传值
  proStatus?: number //口腔版 his 收费弹窗传值
  currentPaymentId?: string
  onCancel?: () => void //口腔版 his 结束
}

export const PaymentResult = ({
  proPaymentId,
  proStatus,
  currentPaymentId,
  onCancel,
}: paymentResultProps): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const edtion = useSelector(selectEdition) //区分口腔版还是普通

  const cityCode = useSelector(selectTenantCityCode)

  const location: any = useLocation()

  const theme = useContext(ThemeContext)

  const backtrace = useLocation<Breadcrumb & { routeName: string }>().state

  const query = useQuery()

  const status = query.get('status') as string

  const selectedTab: any = query.get('selectedTab') as string

  const credentials = useSelector(selectCredentials)

  // const result = useSelector(selectResults)

  const baseData = useSelector(selectInsuranceItem)

  const hisVersion = useSelector(selectEdition) //his版本 0通用 1口腔

  const insuranceArray = useSelector(selectInsuranceArray)

  const autoPrintTemplateData: any = useSelector(selectAutoPrintTemplateData)

  const isPreview = useSelector(selectBooleanSetting(Settings.Printing.Preview))

  const userName = useSelector(selectUserName)

  const userId = useSelector(selectUserId)

  const tenantId = useSelector(selectTenantId)

  const tenantAddress = useSelector(selectTenantAddress)

  const tenantAreaCd = useSelector(selectTenantAreaCd)

  const tenantName = useSelector(selectTenantName)

  const shortName = useSelector(selectShortName)

  const tenantCategory = useSelector(selectTenantCategory)

  const initialized = useSelector(selectPrintServiceState)

  const cycleCode = sessionStorage.getItem('cycleCode')

  const defaultPrinters = useSelector(selectTemplateDefaultPrinters)

  const [result, setResult] = useState<any>()

  const [batchDeliverTarget, setBatchDeliverTarget] = useState<
    Material | undefined
  >()

  const [detail, setDetail] = useState<any>()

  const [paymentId, setPaymentId] = useState('')

  const [paymentInfo, setPaymentInfo] = useState<any>()

  const [invoiceNo, setInvoiceNo] = useState('')

  const [chargeNumber, setChargeNumber] = useState<any>({})

  const offsets = useSelector(selectTemplateOffsets)

  const [traceCodeText, setTraceCodeText] = useState('录入追溯码')

  const [isUploaded, setIsUploaded] = useState(false)
  const [btnBackgroundColor, setBtnBackgroundColor] = useState({
    backgroundColor: '#F5F5F5',
    color: '#B8B8B8',
  })

  const [isPrintModalVisible, setIsPrintModalVisible] = useState(false)

  const [isRecipeListVisible, setIsRecipeListVisible] = useState(false)

  const [loading, setLoading] = useState<'return' | 'ship' | ''>('')

  const [pjdyLoading, setPjdyLoading] = useState(false)

  const [isReplaceOrder, setIsReplaceOrder] = useState(false) // 是否为代煎订单

  const countyCode = useSelector(selectTenantCountyCode)

  const [machiningType, setmachiningType] = useState('') //  加工类型

  const [refund, setRefund] = useState(false) //  预退费

  const [refundBtn, setRefundBtn] = useState(false) // 一键退费按钮

  const [insuranceItem, setInsuranceItem] = useState<any>()

  const [buttonLoading, setButtonLoading] = useState(false)

  const [stkButtonLoading, setStkButtonLoading] = useState(false)

  const [dzpzButtonLoading, setDzpzButtonLoading] = useState(false)

  const [ybFaceButtonLoading, setYbFaceButtonLoading] = useState(false)

  const [isQrCodeVisible, setIsQrCodeVisible] = useState(false)

  const [isUploadInfoVisible, setIsUploadInfoVisible] = useState(false)

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [isQrCodeData, setIsQrCodeData] = useState<any>()

  const fromToothPatient = query.get('fromToothPatient') as string

  const zddyFlag = query.get('zddyFlag') as string //从结算页面结算成功后进入

  const settings: any = useSelector(selectSettings)

  const isOpenZDDY = settings[Checks.FPXZ]

  const [sendBjqFlag, setSendBjqFlag] = useState(false)

  const [showCodeInputModal, setShowCodeInputModal] = useState(false)
  // 保存医保结构体
  useEffect(() => {
    if (insuranceItem && JSON.stringify(insuranceItem) !== '{}') {
      dispatch(
        setInsurance({
          ...insuranceItem,
          userName,
          userId,
          cycleCode,
          tenantCategory,
          tenantAreaCd,
          name: tenantName,
          address: tenantAddress,
          areaCode: tenantAreaCd,
          endTime: moment(insuranceItem.endTime).format(
            DateTimeFormatSimpleOnlyOne
          ),
        })
      )
    }
    return () => {
      dispatch(setInsurance({}))
    }
  }, [insuranceItem])

  useEffect(() => {
    // 获取详情
    dispatch(getPrintTemplateSettingDetail())
      .then(unwrapResult)
      .then((v: any) => {
        setDetail(v)
      })
  }, [])

  useEffect(() => {
    // 获取详情
    if (!isPrintModalVisible) {
      setPjdyLoading(false)
    }
  }, [isPrintModalVisible])

  useEffect(() => {
    const paymentId = (query.get('paymentId') as string) || currentPaymentId
    setPaymentId(paymentId ? paymentId : proPaymentId || '')
  }, [query])

  useEffect(() => {
    if (result?.id != paymentId) {
      return
    }

    // 代煎未开发，暂时关闭
    if (result && location.state?.name !== '已收费') {
      const materialsData = result?.materials?.map((v: any) => {
        return { ...v, recipeId: v?.recipeId || v?.id }
      })
      const data = new Set(materialsData.map((v: any) => v.recipeId))
      const params = Array.from(data).toString()

      if (params) {
        dispatch(getUpdateStates(params))
          .then(unwrapResult)
          .then((res: updateStatesResponse) => {
            if (
              res.openRecipeAuto === 0 &&
              res.type === 0 &&
              res.potionType == 1
            ) {
              // 开启自动上传且未上传过的
              if (selectedTab != 2) {
                dispatch(isAutoUpload(params))
                  .then(unwrapResult)
                  .then((res) => {
                    notification.success({
                      message: '代煎订单自动上传成功',
                      duration: 6,
                    })
                  })
                  .catch(() => {
                    notification.error({
                      message:
                        '代煎订单自动上传失败,请尝试手动上传或联系管理员处理',
                    })
                    setIsUploadInfoVisible(true)
                  })
              }
            }
            if (res.openRecipeAuto == 1 && res.potionType == 1) {
              dispatch(isReadyUpload(params))
            }
            setIsReplaceOrder(res.potionType == 1)
            setmachiningType(res.machiningType)
          })
      }
    }
  }, [result])

  useEffect(() => {
    if (paymentId) {
      // dispatch(getPaymentResults(paymentId))
      //   .then(unwrapResult)
      //   .then((v: any) => {
      //     setResult(v)
      //   })
      dispatch(getAllPaymentResult(paymentId))
        .then(unwrapResult)
        .then((res) => {
          setPaymentInfo(res)
        })
    }
  }, [paymentId])

  useEffect(() => {
    if (
      tenantId == 8123 &&
      !sendBjqFlag &&
      paymentInfo?.payment?.realPayAmount > 0
    ) {
      const createTime = new Date(paymentInfo?.payment?.createTime)?.getTime()
      const nowTime = new Date().getTime() // 示例时间2
      const oneMinute = 60 * 1000 // 1分钟的毫秒数
      const difference = Math.abs(nowTime - createTime) // 计算两个时间的差
      //与结算时间相差大于10分钟 不播报
      if (difference > 10 * oneMinute) {
        return
      }
      const sk =
        (Number(paymentInfo?.payment?.receivePayAmount || 0) > 0
          ? paymentInfo?.payment?.receivePayAmount
          : paymentInfo?.payment?.realPayAmount) || 0
      const zl =
        (Number(paymentInfo?.payment?.receivePayAmount || 0) > 0 &&
        Number(paymentInfo?.payment?.receivePayAmount || 0) >
          Number(paymentInfo?.payment?.realPayAmount)
          ? Number(paymentInfo?.payment?.receivePayAmount) -
            Number(paymentInfo?.payment?.realPayAmount)
          : 0) || 0
      const args = []
      if (sk >= 0) {
        args.push(sk + 'Y')
      }
      if (zl > 0) {
        args.push(zl + 'Z')
      }

      if (args.length > 0) {
        BaoJiaQi({
          trade_args: args,
        })
        setSendBjqFlag(true)
        setTimeout(() => {
          setSendBjqFlag(false)
        }, 3000)
      }
    }
  }, [paymentInfo])

  useEffect(() => {
    if (isQrCodeVisible) {
      setIsQrCodeData('')
      dispatch(getVisibleQrCode())
        .then(unwrapResult)
        .then((v) => {
          setIsQrCodeData(v)
        })
      isPeopleFace()
    }
  }, [isQrCodeVisible])

  useEffect(() => {
    if (isQrCodeVisible) {
      timer = setInterval(() => {
        isPeopleFace()
      }, 20000)
    } else {
      timer && clearInterval(timer)
    }
  }, [dispatch, isQrCodeVisible])

  const isPeopleFace = () => {
    dispatch(getVisibleIsPeopleFace())
      .then(unwrapResult)
      .then((v) => {
        if (v?.sfsl == 1) {
          timer && clearInterval(timer)
          setIsQrCodeVisible(false)
        }
      })
  }

  useEffect(() => {
    if (paymentId && paymentInfo) {
      dispatch(getPaymentResults(paymentId))
        .then(unwrapResult)
        .then(async (val: any) => {
          setResult(val)
          if (isOpenZDDY) {
            JsBarcode('#barcode', val?.patientId)
            if (zddyFlag) {
              setTimeout(() => {
                 message.loading('正在准备打印...', 5)
              }, 100); // 3000毫秒后关闭加载消息，即3秒后关闭
              //批量循环打印
              Array.isArray(autoPrintTemplateData)?autoPrintTemplateData?.map((v: any) => {
                autoPrint(val,v);
              }):autoPrint(val,autoPrintTemplateData);

            }
          } else {
            if (query.has('print')) {
              setIsPrintModalVisible(true)
            }
          }
        })
    }
  }, [paymentId, isOpenZDDY, zddyFlag,paymentInfo])

  const autoPrint = async (val:any,oneTemplate:any) =>{
    if (
        oneTemplate?.templateCode &&
        (!oneTemplate?.invoiceType ||
            oneTemplate?.invoiceType == '2')
    ) {
      //网页打印
      if (
          oneTemplate?.templateCode?.indexOf('ForWeb') > 0
      ) {
        message.loading('正在准备打印...', 3)
        getWebPrintDataMain(oneTemplate?.templateCode,val)
        message.destroy()
        //lodop打印
      } else {
        if (oneTemplate?.templateCode) {
          let status = null
          message.loading('正在准备打印...', 3)
          if (oneTemplate.invoiceType == '2') {
            dispatch(invoicenumberconfigAsync())
                .then(unwrapResult)
                .then((res: any) => {
                  const data = res.records.find(
                      (v: any) => v.categoryType == 0
                  )
                  setChargeNumber(data)
                  setInvoiceNo(data?.currentNo)
                  status = {
                    insuranceCode: val?.insuranceCode,
                    invoiceCode: data.invoiceCode,
                    invoiceCoifigId: data.id,
                    invoiceNum: getZeroSupply(
                        data.currentNo,
                        data.invoiceNumberLength
                    ),
                    paymentId: paymentId,
                    templateId: oneTemplate?.id,
                    treatmentId: val?.treatmentId,
                  }
                  print(oneTemplate, status, val,defaultPrinters[oneTemplate?.id] || undefined)
                })
                .catch((v) => {
                  notification.error({
                    message: '获取发票号码失败:' + v?.message,
                  })
                })
                .finally(() => {
                  message.destroy()
                })

            // dispatch(invoicedetailsOpenAsync(data))
          } else {
            await print(oneTemplate, status, val,defaultPrinters[oneTemplate?.id] || undefined)
          }
        }
      }
    } else if (
        // 电子发票打印
        oneTemplate?.invoiceType == '3'
    ) {
      if (val?.patientId && val?.treatmentId) {
        message.loading('正在开具电子发票...', 3)
        const data: any = {
          insuranceCode: val?.insuranceCode,
          psnName: val?.patientName,
          openType: 0,
          patientId: val?.patientId,
          treatmentId: val?.treatmentId,
          recipeIds: val?.materials?.map((v: any) => {
            return v?.id
          }),
          supplierId: credentials?.dzfpSupplierId, // 只有一个供应商目前写死为1
        }
        //厂商ID为4:浙江财政票据时，使用前置机方式发起交易，其他均采用服务器模式发送交易
        if (credentials?.dzfpSupplierId == '4') {
          openDzfpForMbfe(data, 'requestString')
        } else {
          dispatch(invoiceListAsync(data))
              .then(unwrapResult)
              .then(() => {
                notification.success({
                  message: '开票完成！',
                })
              })
              .catch((v) => {
                if (String(v?.message).indexOf('人脸认证失效') != -1) {
                  setIsQrCodeVisible(true)
                }
              })
              .finally(() => {
                message.destroy()
                return
              })
        }
      }
    }
    // } else {
    //   setIsPrintModalVisible(true)
    // }
  }
  // 前置机模式的开票接口
  const openDzfpForMbfe = (
    bodyJson: any,
    serverType: serverType,
    responseString?: string
  ) => {
    dispatch(
      invoiceListAsyncForMbfe({
        serverType,
        ...bodyJson,
        responseString: encodeURIComponent(
          responseString
            ?.replace('>"', '>')
            ?.replace('"<', '<')
            .replace(/\\/g, '') || ''
        ),
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          MbfeService(
            'HX_ZJ_CZPJ',
            {
              trade_args: [
                'dzfpOpen',
                res?.url,
                res?.requestString,
                'nodata',
                'nodata',
              ],
              message: '开具电子发票',
            },
            (call: any, err) => {
              if (!err) {
                openDzfpForMbfe(
                  bodyJson,
                  'response',
                  JSON.stringify(call.netmessage.result)
                )
              } else {
                message.destroy()
              }
            }
          )
        } else {
          message.destroy()
          notification.success({
            message: '开票完成',
          })
        }
      })
      .finally(() => {
        message.destroy()
        return
      })
  }

  useEffect(() => {
    if (initialized === LoadingState.Idle) {
      dispatch(initializePrintService())
        .then(unwrapResult)
        .catch(() => {
          // do nothing.
        })
    }
    if (initialized === LoadingState.Finished) {
      dispatch(getPrinters())
    }
    if (initialized === LoadingState.Error) {
      setIsModalVisible(true)
    }
  }, [dispatch, initialized])

  const print = async (t: PrintTemplate, status?: any, val?: any,printer?:string) => {
    const offset = offsets[t.id]
    const left = offset?.left || 0
    const top = offset?.top || 0
    message.destroy()
    return dispatch(
      printTemplate({
        templateCode: t.templateCode,
        topOffset: top,
        leftOffset: left,
        treatmentId: val?.treatmentId,
        paymentId,
        preview: false,
        status,
        printer:printer
      })
    )
  }
  const getZeroSupply = (
    invoiceNumber: string,
    invoiceNumberLength: number
  ) => {
    if (
      !invoiceNumberLength ||
      invoiceNumberLength <= 0 ||
      invoiceNumberLength <= invoiceNumber.length ||
      invoiceNumberLength > 15
    ) {
      return invoiceNumber
    } else {
      const diffLength = invoiceNumberLength - invoiceNumber.length
      let Azero = ''
      for (let i = 0; i < diffLength; i++) {
        Azero += '0'
      }
      return Azero + '' + invoiceNumber
    }
  }

  const getWebPrintDataMain = (templateCategory: string,val:any) => {
    // data 打印需要的值
    // medicalRecord 1 通用 2 中医 3 口腔 (区分什么病历)
    if (templateCategory == 'RecipeExecuteForWeb') {
      //输注执行单
      getRecipeExecuteForWeb()
    } else if (templateCategory == 'RecipeExecuteZLForWeb') {
      //诊疗执行单
      getRecipeExecuteZLForWeb()
    } else if (templateCategory == 'TreatmentRecordNoRecForWeb') {
      //诊断书 无处方
      getTreatmentRecordForWeb(false)
    } else if (templateCategory == 'TreatmentRecordForWeb') {
      //诊断书 有处方
      getTreatmentRecordForWeb(true)
    } else if (templateCategory == 'ChnTradRecipeForWeb') {
      // 中药处方单打印
      getPrescriptionForWeb('', 3)
    } else if (templateCategory == 'WesternMedicinePreferenceSettingForWeb') {
      // 西药处方单打印
      getPrescriptionForWeb('', 1)
    } else if (templateCategory == 'FirstClassPsychotropicPrescriptionForWeb') {
      // 精一药品处方笺打印
      getPrescriptionForWeb('精一', 1)
    } else if (
      templateCategory == 'SecondClassPsychotropicPrescriptionForWeb'
    ) {
      // 精二药品处方笺打印
      getPrescriptionForWeb('精二', 1)
    } else if (templateCategory == 'ToxicMedicationsPrescriptionForWeb') {
      // 毒性药品
      getPrescriptionForWeb('毒', 1)
    } else if (templateCategory == 'AnesthesiaPrescriptionForWeb') {
      // 醉药品处
      getPrescriptionForWeb('麻醉', 1)
    } else if (templateCategory == 'DiagnosisCertificateForWeb') {
      // 诊断证明书
      getDiagnosisCertificateForWeb()
    }  else if (templateCategory == 'InvoiceFeeBillForWeb') {
      //80mm 小票结算
      getPrescriptionForWeb('小票', 1,val)
    } else {
      notification.error({
        message: '不支持的打印模板，请联系管理员',
      })
    }
  }

  const getRecipeExecuteForWeb = () => {
    const getWebPrintContent = getWebPrintDataByPaymentId
    if (paymentId) {
      dispatch(getWebPrintContent({ paymentId: paymentId }))
        .then(unwrapResult)
        .then((data: any) => {
          // pagePrintFlag == 'sfPrintTag'   收费标志
          // 执行单打印西药方处方明细接口获取
          const list = data?.recipeWebPrintVOList?.map((v: any) => {
            return {
              ...v,
              itemList: v?.recipeItemWebPrintVOList,
            }
          })

          //收费页面进来的，需要打印所有处方
          // 结算时执行单打印西药处方
          const printList = list
            ?.filter((v: any) => v?.category == 1)
            ?.map((val: any) => {
              return val?.itemList
            })
            ?.flat(1)
          const sortDataList =
            _.chain(printList)
              .map((m) => fromMaterialJson(m))
              .sort((a, b) => {
                if (a.groupNumber < b.groupNumber) {
                  return -1
                }
                if (a.groupNumber > b.groupNumber) {
                  return 1
                }
                return a.sort - b.sort
              })
              .value() || []
          const printZXDList = sortDataList?.filter((v: any) => {
            return (
              v?.normalUsemethod == '静滴' ||
              v?.normalUsemethod == '肌注' ||
              v?.normalUsemethod == '静滴(JDZ)' ||
              v?.normalUsemethod == '静注' ||
              v?.normalUsemethod == '穴注' ||
              v?.normalUsemethod == '雾化吸入' ||
              v?.normalUsemethod == '静推' ||
              v?.normalUsemethod == '皮下'||
              v?.normalUsemethod == '冲管'
            )
          })

          const dataMainContent = {
            tenantName: tenantName?.split('-')?.[0],
            userName: userName,
            dataMain: {
              treatment: data?.treatment,
              // 执行单打印数据
              materials: printZXDList,
              // 签名
              SignatureSrc: data?.printSettingData?.userSign,
              printAddress: data?.patientAddress,
            },
          }
          if (printZXDList?.length) {
            const page: any = window.open('', '_blank') // 打开一个新窗口，用于打印
            page?.document?.write(PrintZXDBody(dataMainContent)) // 写入打印页面的内容
            setTimeout(function () {
              page?.print() // 打印
              page?.close()
              setIsPrintModalVisible(false)
            }, 200)
          } else {
            notification.info({
              message: '暂无可打印执行单',
            })
          }
        })
    } else {
      notification.error({
        message: '缺少必要的打印参数',
      })
    }
  }

  const getRecipeExecuteZLForWeb = () => {
    const getWebPrintContent = getWebPrintDataByPaymentId

    if (paymentId) {
      dispatch(getWebPrintContent({ paymentId: paymentId, printType: 1 }))
        .then(unwrapResult)
        .then((data: any) => {
          // 执行单打印西药方处方明细接口获取
          const list = data?.recipeWebPrintVOList?.map((v: any) => {
            return {
              ...v,
              itemList: v?.recipeItemWebPrintVOList,
            }
          })
          const printList = list
            ?.map((val: any) => {
              return val?.itemList
            })
            ?.flat(1)
          let printZXDList
          //收费页面进来的，需要打印所有非药品处方
          const sortDataList =
            _.chain(printList)
              .map((m) => fromMaterialJson(m))
              .sort((a, b) => {
                if (a.groupNumber < b.groupNumber) {
                  return -1
                }
                if (a.groupNumber > b.groupNumber) {
                  return 1
                }
                return a.sort - b.sort
              })
              .value() || []
          printZXDList = sortDataList?.filter((v: any) => {
            return v?.drugType == 1
          })
          //否则，只打印当前处方
          printZXDList = sortDataList?.filter((v: any) => {
            return v?.drugType == 1
          })

          const dataMainContent = {
            tenantName: tenantName?.split('-')?.[0],
            userName: userName,
            dataMain: {
              treatment: data?.treatment,
              // 执行单打印数据
              materials: printZXDList,
              // 签名
              SignatureSrc: data?.printSettingData?.userSign,
              printAddress: data?.patientAddress,
            },
          }

          if (printZXDList?.length) {
            const page: any = window.open('', '_blank') // 打开一个新窗口，用于打印
            page?.document?.write(PrintZLZXDBody(dataMainContent)) // 写入打印页面的内容
            setTimeout(function () {
              page?.print() // 打印
              page?.close()
              setIsPrintModalVisible(false)
            }, 200)
          } else {
            notification.info({
              message: '暂无可打印执行单',
            })
          }
        })
    } else {
      notification.error({
        message: '缺少必要的打印参数',
      })
    }
  }

  const getTreatmentRecordForWeb = (haveRecipeFlag: boolean) => {
    const getWebPrintContent = getWebPrintDataByPaymentId

    if (paymentId) {
      dispatch(getWebPrintContent({ paymentId: paymentId }))
        .then(unwrapResult)
        .then((data: any) => {
          const list = data?.recipeWebPrintVOList?.map((v: any) => {
            return {
              ...v,
              itemList: v?.recipeItemWebPrintVOList,
            }
          })
          const dataMainContent = {
            tenantName: tenantName?.split('-')?.[0],
            userName: userName,
            dataMain: {
              treatment: data?.treatment,
              feeCategory: data?.feeCategory,
              insuranceArrayLength: insuranceArray.length,
              // 签名
              SignatureSrc: data?.printSettingData?.userSign,
              // 拿到当前病历下所有处方 打印诊断书时有处方就打印
              printRecipes: list,
              printAddress: data?.patientAddress,
              codeImg: document.getElementById('barcode')?.getAttribute('src'),
              JXQrCodeImg: cityCode == '330400' ? JXImg : '',
              cityCode: cityCode,
            },
          }
          if (data?.medicalRecord == 1) {
            const page: any = window.open('', '_blank') // 打开一个新窗口，用于打印
            page?.document?.write(PrintTYBody(dataMainContent, haveRecipeFlag)) // 写入打印页面的内容
            setTimeout(function () {
              page?.print() // 打印
              page?.close()
              setIsPrintModalVisible(false)
              document.getElementById('barcode')?.setAttribute('src', '')
            }, 200)
          } else if (data?.medicalRecord == 2) {
            const page: any = window.open('', '_blank') // 打开一个新窗口，用于打印
            page?.document?.write(PrintZYBody(dataMainContent, haveRecipeFlag)) // 写入打印页面的内容
            setTimeout(function () {
              page?.print() // 打印
              page?.close()
              setIsPrintModalVisible(false)
              document.getElementById('barcode')?.setAttribute('src', '')
            }, 200)
          } else if (data?.medicalRecord == 3) {
            const page: any = window.open('', '_blank') // 打开一个新窗口，用于打印
            page?.document?.write(PrintKQBody(dataMainContent, haveRecipeFlag)) // 写入打印页面的内容
            setTimeout(function () {
              page?.print() // 打印
              page?.close()
              setIsPrintModalVisible(false)
              document.getElementById('barcode')?.setAttribute('src', '')
            }, 200)
          }
        })
    } else {
      notification.error({
        message: '缺少必要的打印参数',
      })
    }
  }

  // 处方单打印
  const getPrescriptionForWeb = (
      typeName?: any,
      category?: any,
      val?:any,
      codeImg = ''
  ) => {
    let medicalWestPrintQuantity = 8 //固定一页8条
    // 是否打印厂家
    const cjFlag = detail?.functionList?.filter((v: any) => v?.type == 33)?.[0]
        ?.value
    if (cjFlag && (cjFlag == true || cjFlag == 'true')) {
      medicalWestPrintQuantity = typeName == '麻醉' ? 7 : 8
    } else {
      medicalWestPrintQuantity = typeName == '麻醉' ? 11 : 12
    }
    const getWebPrintContent = getWebPrintDataByPaymentId
    if (paymentId) {
      dispatch(
          getWebPrintContent({
            paymentId:  paymentId ,
            printCategory: category,
          })
      )
          .then(unwrapResult)
          .then((data: any) => {
            // let signBaseInfo = ''
            // if (
            //   data?.treatment?.doctorSignId &&
            //   data?.treatment?.doctorSignId != -1
            // ) {
            //   dispatch(
            //     getSignatureEntityAsync({
            //       signId: data?.treatment?.doctorSignId,
            //       tenantId: tenantId,
            //     })
            //   )
            //     .then(unwrapResult)
            //     .then((urlMain: any) => {
            //       signBaseInfo = urlMain?.signBaseInfo
            //     })
            // } else {
            //   signBaseInfo = ''
            // }
            // 西药
            if (category == 1) {
              const list = data?.recipeWebPrintVOList
                  ?.map((v: any) => {
                    return {
                      ...v,
                      itemList: v?.recipeItemWebPrintVOList,
                    }
                  })
                  ?.map((v: any) => v?.itemList)
                  ?.flat(1)

              const getWebPrintRecipeContent = getPrintRecipeAsyncByPaymentId
              if (paymentId) {
                dispatch(
                    getWebPrintRecipeContent({
                      paymentId: paymentId ,

                      printCategory: category,
                    })
                )
                    .then(unwrapResult)
                    .then((res: any) => {
                      const allPage = Math.ceil(
                          Number(res?.recipeItemGroupNumber?.length) /
                          medicalWestPrintQuantity
                      )
                      const chunkArray = (array: any, chunkSize: any) => {
                        const result = []
                        for (let i = 0; i < array.length; i += chunkSize) {
                          result.push(array.slice(i, i + chunkSize))
                        }
                        return result
                      }
                      const chunkedArray = chunkArray(
                          res?.recipeItemGroupNumber,
                          medicalWestPrintQuantity
                      )
                      const dataMainList = {
                        chunkedArray: chunkedArray,
                        allPage: allPage,
                        tenantName: tenantName?.split('-')?.[0],
                        userName: userName,
                        cityCode: cityCode,
                        doctorMessage: data?.printSettingData,
                        zlCount: res?.serviceAmount,
                        ypCount: res?.drugAmount,
                        showFlag: {
                          zsFlag: detail?.functionList?.filter(
                              (v: any) => v?.type == 37
                          )?.[0]?.value,
                          cjFlag: detail?.functionList?.filter(
                              (v: any) => v?.type == 33
                          )?.[0]?.value,
                          blhFlag: detail?.functionList?.filter(
                              (v: any) => v?.type == 32
                          )?.[0]?.value,
                          bzFlag: detail?.functionList?.filter(
                              (v: any) => v?.type == 35
                          )?.[0]?.value,
                          jeFlag: detail?.functionList?.filter(
                              (v: any) => v?.type == 28
                          )?.[0]?.value,
                          // 是否打印诊疗
                          zlFlag: detail?.functionList?.filter(
                              (v: any) => v?.type == 39
                          )?.[0]?.value,
                          // 是否打印柜号
                          ghFlag: detail?.functionList?.filter(
                              (v: any) => v?.type == 52
                          )?.[0]?.value,
                          // 是否打印天数
                          daysFlag: detail?.functionList?.filter(
                              (v: any) => v?.type == 53
                          )?.[0]?.value,
                        },
                        // SignatureSrc: signBaseInfo,
                        typeName: typeName,
                        recipeId: data?.recipeAllid,
                        detailMessage: {
                          ...data?.treatment,
                          feeCategory: data?.feeCategory,
                          insuranceArrayLength: insuranceArray.length,
                          medicalRecord: data?.medicalRecord,
                          sex: getSexData(Number(data?.treatment?.patientSex)),
                          type:
                              data?.treatment?.insuranceCode == 'ZIFEI'
                                  ? '自费'
                                  : data?.treatment?.insuranceCode != 'ZIFEI' &&
                                  data?.treatment?.insuranceCode != ''
                                      ? '医保'
                                      : '',
                          createTime: data?.treatment?.createTime
                              ? moment(data?.treatment?.createTime)?.format(
                                  DateTimeFormatSimpleOnly
                              )
                              : '-',
                          codeImg: document
                              .getElementById('barcode')
                              ?.getAttribute('src'),
                        },
                        patientMessage: {
                          ...data?.patient,
                          patientAddress: data?.patientAddress,
                        },
                      }
                      const page: any = window.open('', '_blank') // 打开一个新窗口，用于打印
                      page?.document?.write(
                          typeName == '小票'
                              ? subPrintInvoiceFeeBillForWeb({
                                ...dataMainList,
                                payment: data?.payment,
                                paymentInfo: paymentInfo,
                                countyCode: countyCode,
                                wechatQrCode: tenantId,
                                itemList: val,
                                codeImg,
                              })
                              : subPrintPrescriptionContent(dataMainList)
                      ) //JsBarcode 写入打印页面的内容
                      setTimeout(function () {
                        page.print() // 打印
                        page?.close()
                        document.getElementById('barcode')?.setAttribute('src', '')
                      }, 200)
                    })
              }
            } else {
              const list = data?.recipeWebPrintVOList
                  ?.filter((v: any) => v?.category == 3)
                  ?.map((v: any) => {
                    return {
                      ...v,
                      itemList: v?.recipeItemWebPrintVOList,
                    }
                  })
                  ?.map((v: any) => v?.itemList)
                  ?.flat(1)

              const zyList = data?.recipeWebPrintVOList?.filter(
                  (v: any) => v?.category == 3
              )

              const drugAmount = sum(
                  zyList?.[0]?.recipeItemWebPrintVOList?.map((i: any) =>
                      Number(i?.drugAmount)
                  )
              )
              const tip = zyList?.[0]?.recipeItemWebPrintVOList?.[0]?.tip
              // 打印

              const medicalWestZYPrintQuantity = 24 //固定一页24条

              const allPage = Math.ceil(
                  Number(list?.length) / medicalWestZYPrintQuantity
              )
              const chunkArray = (array: any, chunkSize: any) => {
                const result = []
                for (let i = 0; i < array.length; i += chunkSize) {
                  result.push(array.slice(i, i + chunkSize))
                }
                return result
              }
              const chunkedArray = chunkArray(list, medicalWestZYPrintQuantity)
              const dataMainList = {
                chunkedArray: chunkedArray,
                allPage: allPage,
                tenantName: tenantName?.split('-')?.[0],
                userName: userName,
                shortName: shortName,
                doctorMessage: data?.printSettingData,
                cityCode: cityCode,
                showFlag: {
                  zsFlag: detail?.functionList?.filter(
                      (v: any) => v?.type == 37
                  )?.[0]?.value,
                  cjFlag: detail?.functionList?.filter(
                      (v: any) => v?.type == 33
                  )?.[0]?.value,
                  blhFlag: detail?.functionList?.filter(
                      (v: any) => v?.type == 32
                  )?.[0]?.value,
                  bzFlag: detail?.functionList?.filter(
                      (v: any) => v?.type == 35
                  )?.[0]?.value,
                  jeFlag: detail?.functionList?.filter(
                      (v: any) => v?.type == 28
                  )?.[0]?.value,
                  // 是否打印诊疗
                  zlFlag: detail?.functionList?.filter(
                      (v: any) => v?.type == 39
                  )?.[0]?.value,
                  // 是否打印柜号
                  ghFlag: detail?.functionList?.filter(
                      (v: any) => v?.type == 52
                  )?.[0]?.value,
                  // 是否打印天数
                  daysFlag: detail?.functionList?.filter(
                      (v: any) => v?.type == 53
                  )?.[0]?.value,
                },
                // SignatureSrc: signBaseInfo,
                typeName: typeName,
                tcmMessage: {
                  tipCount: tip,
                  recipeUseMethod: zyList?.[0]?.useMethod,
                  machiningTypeMsg: zyList?.[0]?.machiningName,
                  mtCount: sum(
                      zyList?.[0]?.recipeItemWebPrintVOList?.map((i: any) =>
                          Number(i?.billingCategory == '03' ? i?.countPerTip : 0)
                      )
                  ),
                  allCount: sum(
                      zyList?.[0]?.recipeItemWebPrintVOList?.map((i: any) =>
                          Number(i?.billingCategory == '03' ? i?.drugCount : 0)
                      )
                  ),
                  costPerPatch: Number(drugAmount / Number(tip))?.toFixed(2),
                  fryingFee: Number(
                      zyList?.[0]?.recipeItemWebPrintVOList?.find(
                          (v: any) => v.billingCategory === '97'
                      )?.drugAmount
                  )?.toFixed(2),
                  amount: Number(
                      sum(
                          zyList?.[0]?.recipeItemWebPrintVOList?.map((i: any) =>
                              Number(i?.drugAmount)
                          )
                      )
                  )?.toFixed(2),
                },
                recipeId: data?.recipeAllid,
                detailMessage: {
                  ...data?.treatment,
                  feeCategory: data?.feeCategory,
                  insuranceArrayLength: insuranceArray.length,
                  sex: getSexData(Number(data?.treatment?.patientSex)),
                  type:
                      data?.treatment?.insuranceCode == 'ZIFEI'
                          ? '自费'
                          : data?.treatment?.insuranceCode != 'ZIFEI' &&
                          data?.treatment?.insuranceCode != ''
                              ? '医保'
                              : '',
                  createTime: data?.treatment?.createTime
                      ? moment(data?.treatment?.createTime)?.format(
                          DateTimeFormatSimpleOnly
                      )
                      : '-',
                  codeImg: document
                      .getElementById('barcode')
                      ?.getAttribute('src'),
                },
                payment: {
                  ...data?.payment,
                  createTime: data?.payment?.createTime
                      ? moment(data?.treatment?.createTime)?.format(
                          DateTimeFormatSimpleOnlyOne
                      )
                      : '',
                },
                patientMessage: {
                  ...data?.patient,
                  patientAddress: data?.patientAddress,
                },
                dataList: list,
              }
              const page: any = window.open('', '_blank') // 打开一个新窗口，用于打印

              page?.document?.write(subZYPrintPrescriptionContent(dataMainList)) // 写入打印页面的内容
              setTimeout(function () {
                page.print() // 打印
                page?.close()
                document.getElementById('barcode')?.setAttribute('src', '')
              }, 200)
            }
          })
    }
  }

  // 诊断证明书打印
  const getDiagnosisCertificateForWeb = () => {
    const getWebPrintContent = getWebPrintDataByPaymentId
    if (paymentId) {
      dispatch(getWebPrintContent({ paymentId: paymentId }))
        .then(unwrapResult)
        .then((data: any) => {
          const dataMainList = {
            tenantName: tenantName?.split('-')?.[0],
            userName: userName,
            // recipeCategory: recipeCategory,
            doctorMessage: data?.printSettingData,
            recipeId: data?.recipeAllid,
            detailMessage: {
              ...data?.treatment,
              createTime: data?.treatment?.createTime
                ? moment(data?.treatment?.createTime)?.format(
                    DateTimeFormatSimpleOne
                  )
                : '-',
              codeImg: document.getElementById('barcode')?.getAttribute('src'),
            },
            patientMessage: {
              ...data?.patient,
              sex: getSexData(Number(data?.patient?.sex)),
              patientAddress: data?.patientAddress,
            },
          }
          const page: any = window.open('', '_blank') // 打开一个新窗口，用于打印

          page?.document?.write(PrintDiagnosisCertificateContent(dataMainList)) // 写入打印页面的内容

          setTimeout(function () {
            page.print() // 打印
            page?.close()
            setIsPrintModalVisible(false)
            document.getElementById('barcode')?.setAttribute('src', '')
          }, 200)
        })
    }
  }

  const finish = () => {
    if (proStatus) {
      onCancel && onCancel()
      return
    }

    if (backtrace?.path === '/payment/cart') {
      // 如果从收费列表过来，则返回收费列表
      dispatch(
        traceRoute({
          name: '收费',
          path: '/payment',
        })
      )
    } else {
      dispatch(
        traceRoute({
          ...backtrace,
          name: backtrace?.routeName || backtrace?.name || '收费',
          state: {
            station: location.state?.name === '欠费管理' ? '5' : selectedTab,
          },
        })
      )
    }
  }

  const dateShow = () => {
    if (
      status == '8' ||
      status == '3' ||
      status == '5' ||
      (proStatus && proStatus == 8)
    ) {
      //欠费管理 / 已收费 / 已欠费
      return true
    }
  }

  useEffect(() => {
    if (insuranceArray.length !== 0) {
      setInsuranceItem(insuranceArray[0])
    }
  }, [insuranceArray])

  useEffect(() => {
    setButtonLoading(false)
    setDzpzButtonLoading(false)
    setStkButtonLoading(false)
  }, [])

  useEffect(() => {
    if (result && result.ybSaleUploadStatus == 1) {
      setTraceCodeText('追溯码已上传')
      setIsUploaded(true)
    } else {
      setIsUploaded(false)
    }
  }, [result])

  const refondSubmit = (id: any) => {
    //自费退费
    dispatch(refundPayment(id))
      .then(unwrapResult)
      .then(() => {
        setButtonLoading(false)
        setStkButtonLoading(false)
        setDzpzButtonLoading(false)
        setRefund(false)
        result &&
          dispatch(getPaymentResults(result.id))
            .then(unwrapResult)
            .then(async (val: any) => {
              setResult(val)
            })
      })
      .catch(() => {
        setButtonLoading(false)
        setStkButtonLoading(false)
        setDzpzButtonLoading(false)
      })
  }

  const cancelSettlement = (
    serverType: any,
    paymentId?: string,
    cardType?: string,
    fullCardInfo?: string,
    responseString?: string
  ) => {
    //医保退费
    const insuranceItem = insuranceArray.find(
      (v: any) => v.insuranceCode === paymentInfo?.payment?.insuranceCode
    )
    dispatch(
      cancelSettlementAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          exp_content: '',
          treatmentId: paymentInfo?.payment?.treatmentId,
          paymentId,
        },
      })
    )
      .then(unwrapResult)
      .then(async (res) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          await medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceItem.countryHospitalNb,
                insuranceItem.secretToken,
              ],
              notification: '退费',
            },
            (call: any, err) => {
              if (!err) {
                cancelSettlement(
                  'response',
                  paymentId,
                  cardType,
                  fullCardInfo,
                  JSON.stringify(call.netmessage)
                )
                setStkButtonLoading(false)
                setDzpzButtonLoading(false)
                setButtonLoading(false)
              } else {
                setStkButtonLoading(false)
                setDzpzButtonLoading(false)
                setButtonLoading(false)
              }
            }
          )
          result && dispatch(getPaymentResults(result.id)).then(unwrapResult)
        } else {
          refondSubmit(paymentInfo?.payment?.id)
          //更新医保卡数据
          if (cardType == '1') {
            //实体卡
            getPatientInfo(
              '3',
              baseData?.insuranceCode == 'JIANGSU'
                ? String(fullCardInfo)
                : JSON.stringify(fullCardInfo),
              'requestString'
            )
          } else if (cardType == '2') {
            //电子凭证
            getPatientInfo('1', String(fullCardInfo), 'requestString')
          }
        }
      })
      .catch((v) => {
        setStkButtonLoading(false)
        setDzpzButtonLoading(false)
        setButtonLoading(false)
      })
  }

  const success = (str: string) => {
    const hide = message.loading(str, 0)
    setTimeout(() => {
      hide()
    }, 3000)
  }

  // 获取参保人信息
  const getPatientInfo = (
    cardType: string,
    fullCardInfo: string,
    serverType: any,
    responseString?: string
  ) => {
    dispatch(
      getPatientInfoAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          cardType: String(cardType),
          fullCardInfo,
          otherMap: {},
        },
      })
    )
      .then(unwrapResult)
      .then(async (res) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          await medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceItem.countryHospitalNb,
                insuranceItem.secretToken,
              ],
            },
            (call: any, err) => {
              if (!err) {
                message.loading('正在更新人员信息')
                getPatientInfo(
                  cardType,
                  fullCardInfo,
                  'response',
                  JSON.stringify(call.netmessage)
                )
                // }
              }
            }
          )
        } else {
          message.success('人员信息已更新')
        }
      })
      .catch((ex) => {
        notification.error({
          message: ex.message,
          duration: 3,
        })
      })
  }

  const doRefund = (cardType?: string, fullCardInfo?: string) => {
    const payMethodList = paymentInfo?.payment?.payMethod
      ?.toString()
      ?.split(',')
    const isJuHe: any = payMethodList?.find((v: any) => {
      return v == 2
    })
    if (isJuHe) {
      //是否聚合支付退费
      dispatch(
        JuhePremium({
          treatmentId: paymentInfo?.payment?.treatmentId,
          paymentId: paymentId,
        })
      )
        .then(unwrapResult)
        .then(() => {
          setStkButtonLoading(false)
          setDzpzButtonLoading(false)
          if (paymentInfo?.payment) {
            if (paymentInfo?.payment.insuranceCode !== 'ZIFEI') {
              cancelSettlement(
                'requestString',
                paymentInfo?.payment.id,
                cardType,
                fullCardInfo
              )
            } else {
              refondSubmit(paymentInfo?.payment.id)
            }
          }
          result && dispatch(getPaymentResults(result.id)).then(unwrapResult)
        })
        .catch((v) => {
          setButtonLoading(false)
          setStkButtonLoading(false)
          setDzpzButtonLoading(false)
        })
        .finally(() => setRefundBtn(true))
    } else {
      if (paymentInfo?.payment) {
        if (paymentInfo?.payment.insuranceCode !== 'ZIFEI') {
          cancelSettlement(
            'requestString',
            paymentInfo?.payment.id,
            cardType,
            fullCardInfo
          )
        } else {
          refondSubmit(paymentInfo?.payment.id)
        }
      }
    }
  }

  const validCardInfoBeforeGoRefund = (type: string) => {
    //实体卡读卡校验
    if (type == '1') {
      setStkButtonLoading(true)
      //部分强制读卡的地区，跳过校验
      const tradeType = getTradeName()
      if (tradeType === 'CQ_ZHYB') {
        doRefund()
        return
      }
      readCardSHttp(
        {
          trade_args: [getTradeCode(tenantAreaCd.slice(0, 4))],
        },
        (data, err) => {
          if (!err) {
            //其他地区需要做二次读卡校验
            const Name =
              tradeType === 'JS_ZHYB'
                ? data?.netmessage
                    ?.split('|')[4]
                    ?.replace('\r', '')
                    .replace('\t', '')
                    .replace(' ', '')
                    .trim()
                : data?.netmessage?.Name
            if (Name != paymentInfo?.payment?.patientName) {
              Modal.error({
                title: '提示',
                content:
                  `退费信息与卡片信息不一致，请确认是否插错卡，就诊人：` +
                  paymentInfo?.payment?.patientName +
                  '，卡片姓名：' +
                  Name,
                okText: '确认',
              })
              setStkButtonLoading(false)
            } else {
              doRefund(type, data?.netmessage)
            }
          }
        }
      )
      //电子凭证读卡校验
    } else if (type == '2') {
      setDzpzButtonLoading(true)
      setTimeout(() => {
        setDzpzButtonLoading(false)
      }, 3000)
      readCardEHttp(
        {
          trade_args: [
            baseData?.countryHospitalNb,
            '01301',
            '',
            userId,
            userName,
            '',
            '',
          ],
        },
        (data, err) => {
          if (!err) {
            const readName = data?.netmessage?.split('|')[0]
            if (readName != paymentInfo?.payment?.patientName) {
              Modal.error({
                title: '提示',
                content:
                  `退费信息与卡片信息不一致，请确认是否插错卡，就诊人：` +
                  paymentInfo?.payment?.patientName +
                  '，电子凭证姓名：' +
                  readName,
                okText: '确认',
              })
              setDzpzButtonLoading(false)
            } else {
              doRefund(type, data?.netmessage)
            }
          }
        }
      )
      //自费直接调用退费
    } else if (type == '3') {
      setButtonLoading(true)
      doRefund()
      //自费直接调用退费
    } else if (type == '0') {
      setButtonLoading(true)
      doRefund()
    }  else if (type == '4') {
      setYbFaceButtonLoading(true)
      setTimeout(() => {
        setYbFaceButtonLoading(false)
      }, 3000)
      readCardEHttp(
          {
            trade_args: [
              baseData?.countryHospitalNb,
              '01301',
              'cn.nhsa.ec.auth',
              userId,
              userName,
              '',
              '',
            ],
          },
          (data, err) => {
            if (!err) {
              // {"netcode":"1","netmessage":"徐春霞|330621198804253802|01|330000ecaqm4mb0mvk0b8fab0a0000236f0d3c|330182|","QrCode":"","ChnlId":""}
              const readName = data?.netmessage?.split('|')[0]
              if (readName != paymentInfo?.payment?.patientName) {
                Modal.error({
                  title: '提示',
                  content:
                      `退费信息与刷脸人员信息不一致，请确认是否插错卡，就诊人：` +
                      paymentInfo?.payment?.patientName +
                      '，扫脸姓名：' +
                      readName,
                  okText: '确认',
                })
                setYbFaceButtonLoading(false)
              } else {
                doRefund(type, data?.netmessage)
              }
            }
          }
      )
    } else {
      Modal.error({
        title: '错误',
        content: `未知的退费类型` + type + `请联系客服人员处理`,
        okText: '确认',
      })
    }
  }
  const toothHomeReturnTag = sessionStorage.getItem('toothHomeReturnTag')

  const tableUpdata = (id: any, num: string) => {
    dispatch(updatePatientTimeAsync(id))
      .then(unwrapResult)
      .then(() => {
        // history.push({
        //   pathname: '/toothPatient',
        // })
        dispatch(
          traceRoute({
            name: '患者管理',
            path: '/toothPatient',
          })
        )
        dispatch(
          setActiveObj({
            tabsActiveKey: '3',
            patientId: id,
            rightTabsActiveKey: num,
          })
        )
      })
  }

  const columns: TableColumnType<any>[] = [
    {
      title: '序号',
      key: 'no',
      width: '3rem',
      align: 'center',
      render: (_1, _2, i) => `${i + 1}`,
    },
    {
      title: '名称',
      dataIndex: 'name',
      width: '20rem',
      align: 'center',
      render: (_, m: any) =>
        m.name + (m?.hilistCode ? '（' + m?.hilistCode + '）' : ''),
    },
    {
      title: '产品信息',
      align: 'center',
      dataIndex: 'productInfo',
      key: 'productInfo',
      width: '470px',
      ellipsis: true,
      render: function Element(_, t) {
        if (t.productInfo) {
          return <>{t.productInfo}</>
        } else {
          if (
            t.spec ||
            t.packSpec ||
            t.accountUnit ||
            t.doseCategory ||
            t.tenantSupplierName
          ) {
            return (
              <>
                {t.spec}/{t.packSpec}/{t.accountUnit}/{t.doseCategory}/
                {t.tenantSupplierName}
              </>
            )
          } else {
            return <></>
          }
        }
      },
    },
    {
      title: '数量',
      dataIndex: 'drugCount',
      align: 'center',
      width: '3.75rem',
      render: (_, m: any) =>
        `${m.drugCount}${m.splitTag ? m.splitUnit : m.accountUnit}`,
    },
    // {
    //   title: '规格',
    //   dataIndex: 'spec',
    //   align: 'center',
    //   width: '3.75rem',
    // },
    {
      title: '每帖数量',
      key: 'countPerTip',
      align: 'center',
      width: '5rem',
      render: (_, m: any) => (m.countPerTip <= 0 ? '-' : `${m.countPerTip}`),
    },
    {
      title: '中药帖数',
      key: 'tip',
      align: 'center',
      width: '5rem',
      render: (_, m: any) =>
        m.billingCategory !== BillingCategory.ZY || m.tip <= 0
          ? '-'
          : `${m.tip}`,
    },
    {
      title: '单价（元）',
      key: 'retailPrice',
      width: '5rem',
      align: 'right',
      render: (_, r: any) => r.retailPrice?.toFixed(5),
    },
    {
      title: '金额（元）',
      dataIndex: 'drugAmount',
      width: '5rem',
      align: 'right',
      render: (_, r: any) => r.drugAmount?.toFixed(5),
    },
    {
      title: '批次号',
      dataIndex: 'batchNo',
      width: '4rem',
      align: 'center',
    },
    {
      title: '甲乙分类',
      key: 'orderCategory',
      width: '5rem',
      align: 'center',
      render: (_, m: any) => {
        if (m?.itemPayType) {
          return getOrderCategoryName(m.orderCategory)
        } else {
          return '自费'
        }
      },
    },
    {
      title: '收费类型',
      key: 'itemPayType',
      width: '5rem',
      align: 'center',
      render: (_, m: any): string => {
        switch (m?.itemPayType) {
          case 0:
            return '自费'
          case 1:
            return '医保'
          case 2:
            return '免费'
          case 3:
            return '历年'
        }
        return ''
      },
    },
    {
      title: '发药状态',
      key: 'shipmentsState',
      width: '5rem',
      align: 'center',
      render: (_, m: any): string => {
        switch (m.shipmentsState) {
          case 0:
            return '未发药'
          case 1:
            return '已发药'
          case 8:
            return '无需发药'
          case 9:
            return '已退药'
        }
        return ''
      },
    },
    {
      title: '操作',
      key: 'actions',
      width: '10rem',
      align: 'center',
      fixed: 'right',
      render: function ShowActions(_, m: any): ReactElement | undefined {
        if (m.state === RecipeStatus.Returned) {
          // 已退费，则不显示发药退药按钮
          return undefined
        }
        return m.shipmentsState === 0 || m.shipmentsState === 9 ? (
          <Space>
            <Button
              loading={loading === 'ship'}
              type='primary'
              size='small'
              onKeyDown={(e) => e.preventDefault()}
              onClick={() => {
                setLoading('ship')
                dispatch(
                  directDeliverSingleItem({
                    recipeItemId: m.id,
                    deliveryState: DeliveryState.Unfilled,
                  })
                )
                  .then(unwrapResult)
                  .then((fyRes: any) => {
                    if (fyRes?.successful == false) {
                      const errMsg = fyRes?.errors.map((v: any) => {
                        return v.name + ' ' + v.message + ','
                      })
                      notification.error({
                        message: errMsg,
                      })
                    } else {
                      notification.success({
                        message: '操作成功',
                      })
                      if (result) {
                        dispatch(getPaymentResults(result.id))
                          .then(unwrapResult)
                          .then(async (val: any) => {
                            setResult(val)
                          })
                      }
                    }
                    setLoading('')
                  })
              }}
            >
              发药
            </Button>
            <Button
              size='small'
              style={{
                color: theme.c1,
                borderColor: theme.c1,
              }}
              onKeyDown={(e) => e.preventDefault()}
              onClick={() => {
                setBatchDeliverTarget(m)
              }}
            >
              按批次发药
            </Button>
          </Space>
        ) : m.shipmentsState === 1 ? (
          <Button
            type='primary'
            size='small'
            loading={loading === 'return'}
            onKeyDown={(e) => e.preventDefault()}
            onClick={() => {
              setLoading('return')
              dispatch(
                directDeliverSingleItem({
                  recipeItemId: m.id,
                  deliveryState: DeliveryState.Fulfilled,
                })
              ).then(() => {
                notification.success({
                  message: '操作成功',
                })
                setLoading('')
                if (result) {
                  dispatch(getPaymentResults(result.id))
                    .then(unwrapResult)
                    .then(async (val: any) => {
                      setResult(val)
                    })
                }
              })
            }}
          >
            退药
          </Button>
        ) : m.shipmentsState === 8 ? (
          <div>-</div>
        ) : undefined
      },
    },
  ]
  return (
    <Col
      style={{
        margin: '10px 20px',
        height: proStatus && proStatus == 8 ? '610px' : 'calc(100% - 20px)',
        display: proStatus && proStatus == 8 ? '' : 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        // overflow: (proStatus && proStatus == 8) ? 'auto' : 'hidden',
      }}
    >
      <GlobalKeyboardAction
        onSpacePress={() => {
          if (pjdyLoading) {
            return
          }
          setIsPrintModalVisible(true)
        }}
        onEnterPress={() => {
          if (showCodeInputModal) {
            return
          } else {
            finish()
          }
        }}
      />
      {!currentPaymentId &&
        !(proStatus && proStatus == 8) &&
        (edtion == 1 && fromToothPatient ? (
          <NavBar
            zidingyiClick={() => {
              if (fromToothPatient) {
                tableUpdata(result?.patientId, '4')
                return
              }
              dispatch(
                traceRoute({
                  ...location?.state?.backtrace,
                  state: {
                    backSelectTab: 'editor',
                  },
                })
              )
            }}
            where={['收费业务', '收费结算']}
            backtrace={
              backtrace?.path === '/payment/cart'
                ? toothHomeReturnTag
                  ? { name: '收费', path: '/toothPatient' }
                  : { name: '收费', path: '/payment' }
                : backtrace?.path == '/transactionQuery'
                ? edtion == 1
                  ? {
                      name: '交易查询',
                      path: '/toothHome',
                      state: {
                        station: selectedTab,
                      },
                    }
                  : {
                      ...backtrace,
                      name: '交易查询',
                      state: {
                        station: selectedTab,
                      },
                    }
                : {
                    ...backtrace,
                    state: {
                      station:
                        location.state?.name == '发票明细'
                          ? '3'
                          : location.state?.name === '欠费管理'
                          ? '5'
                          : location.state?.name === '已收费'
                          ? '1'
                          : '2',
                    },
                  }
            }
          />
        ) : (
          <NavBar
            where={['收费业务', '收费结算']}
            backtrace={
              backtrace?.path === '/payment/cart'
                ? toothHomeReturnTag
                  ? { name: '收费', path: '/toothPatient' }
                  : { name: '收费', path: '/payment' }
                : backtrace?.path == '/transactionQuery'
                ? edtion == 1
                  ? {
                      name: '交易查询',
                      path: '/toothHome',
                      state: {
                        station: selectedTab,
                      },
                    }
                  : {
                      ...backtrace,
                      name: '交易查询',
                      state: {
                        station: selectedTab,
                      },
                    }
                : {
                    ...backtrace,
                    state: {
                      station:
                        location.state?.name == '发票明细'
                          ? '3'
                          : location.state?.name === '欠费管理'
                          ? '5'
                          : location.state?.name === '已收费'
                          ? '1'
                          : '2',
                    },
                  }
            }
          />
        ))}
      <img id='barcode' style={{ display: 'none' }}></img>
      <Col
        style={{
          flex: 1,
          backgroundColor: theme.pn,
          borderRadius: theme.br,
          marginTop: 10,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
        }}
      >
        <Row align='bottom'>
          <Label
            label='结算完成'
            style={{ fontSize: '1.125rem', marginLeft: 20 }}
          />
          {paymentId && (
            <Col
              style={{
                color: theme.tc2,
                fontSize: '0.875rem',
                marginLeft: 20,
                paddingBottom: 8,
              }}
            >
              结算编号: {paymentId}
            </Col>
          )}
        </Row>
        <Divider style={{ borderColor: theme.c3, margin: 0 }} />
        {dateShow() && paymentInfo?.payment.insuranceCode == 'ZIFEI' ? (
          <Row style={{ marginTop: 20 }} align='bottom'>
            <Col
              span={4}
              style={{ color: theme.tc2, textAlign: 'right', fontSize: '1rem' }}
            >
              患者姓名：
            </Col>
            <Col
              span={4}
              style={{
                color: theme.tc1,
                textAlign: 'left',
                fontSize: '1.125rem',
              }}
            >
              {result?.patientName}
            </Col>
            <Col
              span={4}
              style={{ color: theme.tc2, textAlign: 'right', fontSize: '1rem' }}
            >
              总金额：
            </Col>
            <Col
              span={4}
              style={{
                color: theme.tc1,
                textAlign: 'left',
                fontSize: '1.125rem',
              }}
            >
              {Number(result?.totalPayAmount || 0).toFixed(2)}
              <span style={{ color: theme.tc2, fontSize: '1rem' }}>元</span>
              {Math.abs(paymentInfo?.payment?.discount) != 1 ? (
                <span>
                  （
                  {Number(
                    Math.abs(paymentInfo?.payment?.discount) * 10
                  )?.toFixed(2)}
                  折）
                </span>
              ) : (
                ''
              )}
            </Col>
            <Col
              span={4}
              style={{ color: theme.tc2, textAlign: 'right', fontSize: '1rem' }}
            >
              应收金额：
            </Col>
            <Col
              span={4}
              style={{
                color: theme.tc1,
                textAlign: 'left',
                fontSize: '1.125rem',
              }}
            >
              {Number(result?.totalAmount || 0).toFixed(2)}
              <span style={{ color: theme.tc2, fontSize: '1rem' }}>元</span>
            </Col>
            <Col
              span={4}
              style={{ color: theme.tc2, textAlign: 'right', fontSize: '1rem' }}
            >
              已结算金额：
            </Col>
            <Col
              span={4}
              style={{
                color: theme.tc1,
                textAlign: 'left',
                fontSize: '1.125rem',
              }}
            >
              {Number(result?.clsdamount || 0).toFixed(2)}
              <span style={{ color: theme.tc2, fontSize: '1rem' }}>元</span>
            </Col>
            <Col
              span={4}
              style={{ color: theme.tc2, textAlign: 'right', fontSize: '1rem' }}
            >
              欠费金额：
            </Col>
            <Col
              span={4}
              style={{
                color: theme.tc1,
                textAlign: 'left',
                fontSize: '1.125rem',
              }}
            >
              {Number(result?.arrearageAmount || 0).toFixed(2)}
              <span style={{ color: theme.tc2, fontSize: '1rem' }}>元</span>
            </Col>
            <Col
              span={4}
              style={{ color: theme.tc2, textAlign: 'right', fontSize: '1rem' }}
            >
              退款金额：
            </Col>
            <Col
              span={4}
              style={{
                color: theme.tc1,
                textAlign: 'left',
                fontSize: '1.125rem',
              }}
            >
              {Number(result?.refundAmount || 0).toFixed(2)}
              <span style={{ color: theme.tc2, fontSize: '1rem' }}>元</span>
            </Col>
          </Row>
        ) : paymentInfo?.payment.insuranceCode !== 'ZIFEI' ? (
          <>
            <Row style={{ marginTop: 20 }} align='bottom'>
              <Col
                span={3}
                style={{
                  color: theme.tc2,
                  textAlign: 'right',
                  fontSize: '1rem',
                }}
              >
                患者姓名：
              </Col>
              <Col
                span={3}
                style={{
                  color: theme.tc1,
                  textAlign: 'left',
                  fontSize: '1.125rem',
                }}
              >
                {result?.patientName}
              </Col>
              <Col
                span={3}
                style={{
                  color: theme.tc2,
                  textAlign: 'right',
                  fontSize: '1rem',
                }}
              >
                结算险种：
              </Col>
              <Col
                span={3}
                style={{
                  color: theme.tc1,
                  textAlign: 'left',
                  fontSize: '1.125rem',
                }}
              >
                {/* {getInsuranceCodeName(
                  paymentInfo?.insuranceTrade?.insuranceCode as string
                )} */}
                {paymentInfo?.payment?.insuranceCodemc as string}
              </Col>
              <Col
                span={3}
                style={{
                  color: theme.tc2,
                  textAlign: 'right',
                  fontSize: '1rem',
                }}
              >
                操作员：
              </Col>
              <Col
                span={2}
                style={{
                  color: theme.tc1,
                  textAlign: 'left',
                  fontSize: '1.125rem',
                }}
              >
                {result?.operateName}
              </Col>
              <Col
                span={3}
                style={{
                  color: theme.tc2,
                  textAlign: 'right',
                  fontSize: '1rem',
                }}
              >
                结算时间：
              </Col>
              <Col
                span={4}
                style={{
                  color: theme.tc1,
                  textAlign: 'left',
                  fontSize: '1.125rem',
                }}
              >
                {result?.createTime}
              </Col>
              <Col
                span={3}
                style={{
                  color: theme.tc2,
                  textAlign: 'right',
                  fontSize: '1rem',
                }}
              >
                总金额：
              </Col>
              <Col
                span={3}
                style={{
                  color: theme.tc1,
                  textAlign: 'left',
                  fontSize: '1.125rem',
                }}
              >
                {Number(paymentInfo?.payment?.totalPayAmount || 0).toFixed(2)}
                <span style={{ color: theme.tc2, fontSize: '1rem' }}>元</span>
                {Math.abs(paymentInfo?.payment?.discount) != 1 ? (
                  <span>
                    （
                    {Number(
                      Math.abs(paymentInfo?.payment?.discount) * 10
                    )?.toFixed(2)}
                    折）
                  </span>
                ) : (
                  ''
                )}
              </Col>
              <Col
                span={3}
                style={{
                  color: theme.tc2,
                  textAlign: 'right',
                  fontSize: '1rem',
                }}
              >
                应收金额：
              </Col>
              <Col
                span={3}
                style={{
                  color: theme.tc1,
                  textAlign: 'left',
                  fontSize: '1.125rem',
                }}
              >
                {Number(paymentInfo?.payment.shouldPayAmount || 0).toFixed(2)}
                <span style={{ color: theme.tc2, fontSize: '1rem' }}>元</span>
              </Col>
              <Col
                span={3}
                style={{
                  color: theme.tc2,
                  textAlign: 'right',
                  fontSize: '1rem',
                }}
              >
                实收金额：
              </Col>
              <Col
                span={3}
                style={{
                  color: theme.tc1,
                  textAlign: 'left',
                  fontSize: '1.125rem',
                }}
              >
                {Number(paymentInfo?.payment.realPayAmount || 0).toFixed(2)}
                <span style={{ color: theme.tc2, fontSize: '1rem' }}>元</span>
              </Col>
            </Row>
            <Row align='bottom'>
              <Col
                span={3}
                style={{
                  color: theme.tc2,
                  textAlign: 'right',
                  fontSize: '1rem',
                }}
              >
                医保金额：
              </Col>
              <Col
                span={3}
                style={{
                  color: theme.tc1,
                  textAlign: 'left',
                  fontSize: '1.125rem',
                }}
              >
                {Number(paymentInfo?.payment?.insurancePayAmount || 0).toFixed(
                  2
                )}
                <span style={{ color: theme.tc2, fontSize: '1rem' }}>元</span>
              </Col>
              <Col
                span={3}
                style={{
                  color: theme.tc2,
                  textAlign: 'right',
                  fontSize: '1rem',
                }}
              >
                统筹基金支付：
              </Col>
              <Col
                span={3}
                style={{
                  color: theme.tc1,
                  textAlign: 'left',
                  fontSize: '1.125rem',
                }}
              >
                {Number(
                  paymentInfo?.insuranceTrade.jibenylbxtcjjzc || 0
                ).toFixed(2)}
                <span style={{ color: theme.tc2, fontSize: '1rem' }}>元</span>
              </Col>
              <Col
                span={3}
                style={{
                  color: theme.tc2,
                  textAlign: 'right',
                  fontSize: '1rem',
                }}
              >
                个人账户支付：
              </Col>
              <Col
                span={9}
                style={{
                  color: theme.tc1,
                  textAlign: 'left',
                  fontSize: '1.125rem',
                }}
              >
                {Number(paymentInfo?.insuranceTrade.gerenzhzc || 0).toFixed(2)}
                <span style={{ color: theme.tc2, fontSize: '1rem' }}>元</span>
                {(paymentInfo?.insuranceTrade.dangnianzhzc > 0 ||
                  paymentInfo?.insuranceTrade.linianzhzc > 0) && (
                  <span style={{ color: theme.tc2, fontSize: '1rem' }}>（</span>
                )}
                {paymentInfo?.insuranceTrade.dangnianzhzc > 0 && (
                  <span style={{ color: theme.tc2, fontSize: '1rem' }}>
                    当年账户支出：
                    {Number(
                      paymentInfo?.insuranceTrade.dangnianzhzc || 0
                    ).toFixed(2)}{' '}
                    元{' '}
                  </span>
                )}
                {paymentInfo?.insuranceTrade.linianzhzc > 0 && (
                  <span style={{ color: theme.tc2, fontSize: '1rem' }}>
                    历年账户支出：
                    {Number(
                      paymentInfo?.insuranceTrade.linianzhzc || 0
                    ).toFixed(2)}{' '}
                    元{' '}
                  </span>
                )}
                {(paymentInfo?.insuranceTrade.dangnianzhzc > 0 ||
                  paymentInfo?.insuranceTrade.linianzhzc > 0) && (
                  <span style={{ color: theme.tc2, fontSize: '1rem' }}>）</span>
                )}
              </Col>
              <Col
                span={3}
                style={{
                  color: theme.tc2,
                  textAlign: 'right',
                  fontSize: '1rem',
                }}
              >
                基金支付总额：
              </Col>
              <Col
                span={3}
                style={{
                  color: theme.tc1,
                  textAlign: 'left',
                  fontSize: '1.125rem',
                }}
              >
                {Number(paymentInfo?.insuranceTrade.jijinzfze || 0).toFixed(2)}
                <span style={{ color: theme.tc2, fontSize: '1rem' }}>元</span>
              </Col>

              <Col
                span={3}
                style={{
                  color: theme.tc2,
                  textAlign: 'right',
                  fontSize: '1rem',
                }}
              >
                结算后余额：
              </Col>
              <Col
                span={3}
                style={{
                  color: theme.tc1,
                  textAlign: 'left',
                  fontSize: '1.125rem',
                }}
              >
                {Number(paymentInfo?.insuranceTrade.yue || 0).toFixed(2)}
                <span style={{ color: theme.tc2, fontSize: '1rem' }}>元</span>
              </Col>
              <Col
                span={3}
                style={{
                  color: theme.tc2,
                  textAlign: 'right',
                  fontSize: '1rem',
                }}
              >
                当年账户余额：
              </Col>
              <Col
                span={3}
                style={{
                  color: theme.tc1,
                  textAlign: 'left',
                  fontSize: '1.125rem',
                }}
              >
                {Number(paymentInfo?.insuranceTrade.dangnianye || 0).toFixed(2)}
                <span style={{ color: theme.tc2, fontSize: '1rem' }}>元</span>
              </Col>
              <Col
                span={3}
                style={{
                  color: theme.tc2,
                  textAlign: 'right',
                  fontSize: '1rem',
                }}
              >
                历年账户余额：
              </Col>
              <Col
                span={3}
                style={{
                  color: theme.tc1,
                  textAlign: 'left',
                  fontSize: '1.125rem',
                }}
              >
                {Number(paymentInfo?.insuranceTrade.linianye || 0).toFixed(2)}
                <span style={{ color: theme.tc2, fontSize: '1rem' }}>元</span>
              </Col>
            </Row>
            <Row align='bottom'>
              <Col
                span={3}
                style={{
                  color: theme.tc2,
                  textAlign: 'right',
                  fontSize: '1rem',
                }}
              >
                公务员补助：
              </Col>
              <Col
                span={3}
                style={{
                  color: theme.tc1,
                  textAlign: 'left',
                  fontSize: '1.125rem',
                }}
              >
                {Number(
                  paymentInfo?.insuranceTrade.gongwuyylbzzjzc || 0
                ).toFixed(2)}
                <span style={{ color: theme.tc2, fontSize: '1rem' }}>元</span>
              </Col>
              <Col
                span={3}
                style={{
                  color: theme.tc2,
                  textAlign: 'right',
                  fontSize: '1rem',
                }}
              >
                补充医疗：
              </Col>
              <Col
                span={3}
                style={{
                  color: theme.tc1,
                  textAlign: 'left',
                  fontSize: '1.125rem',
                }}
              >
                {Number(
                  paymentInfo?.insuranceTrade.qiyebcylbxjjzc || 0
                ).toFixed(2)}
                <span style={{ color: theme.tc2, fontSize: '1rem' }}>元</span>
              </Col>
              <Col
                span={3}
                style={{
                  color: theme.tc2,
                  textAlign: 'right',
                  fontSize: '1rem',
                }}
              >
                大病保险：
              </Col>
              <Col
                span={3}
                style={{
                  color: theme.tc1,
                  textAlign: 'left',
                  fontSize: '1.125rem',
                }}
              >
                {Number(paymentInfo?.insuranceTrade.jumindbbxjjzc || 0).toFixed(
                  2
                )}
                <span style={{ color: theme.tc2, fontSize: '1rem' }}>元</span>
              </Col>
              <Col
                span={3}
                style={{
                  color: theme.tc2,
                  textAlign: 'right',
                  fontSize: '1rem',
                }}
              >
                大额医疗补助：
              </Col>
              <Col
                span={3}
                style={{
                  color: theme.tc1,
                  textAlign: 'left',
                  fontSize: '1.125rem',
                }}
              >
                {Number(
                  paymentInfo?.insuranceTrade.zhigongdeylfybzjjzc || 0
                ).toFixed(2)}
                <span style={{ color: theme.tc2, fontSize: '1rem' }}>元</span>
              </Col>
              <Col
                span={3}
                style={{
                  color: theme.tc2,
                  textAlign: 'right',
                  fontSize: '1rem',
                }}
              >
                医疗救助：
              </Col>
              <Col
                span={3}
                style={{
                  color: theme.tc1,
                  textAlign: 'left',
                  fontSize: '1.125rem',
                }}
              >
                {Number(paymentInfo?.insuranceTrade.yiliaojzjjzc || 0).toFixed(
                  2
                )}
                <span style={{ color: theme.tc2, fontSize: '1rem' }}>元</span>
              </Col>
              <Col
                span={3}
                style={{
                  color: theme.tc2,
                  textAlign: 'right',
                  fontSize: '1rem',
                }}
              >
                超限价自费：
              </Col>
              <Col
                span={3}
                style={{
                  color: theme.tc1,
                  textAlign: 'left',
                  fontSize: '1.125rem',
                }}
              >
                {Number(paymentInfo?.insuranceTrade.chaoxianjzffy || 0).toFixed(
                  2
                )}
                <span style={{ color: theme.tc2, fontSize: '1rem' }}>元</span>
              </Col>
              <Col
                span={3}
                style={{
                  color: theme.tc2,
                  textAlign: 'right',
                  fontSize: '1rem',
                }}
              >
                实际支付起付线：
              </Col>
              <Col
                span={3}
                style={{
                  color: theme.tc1,
                  textAlign: 'left',
                  fontSize: '1.125rem',
                }}
              >
                {Number(paymentInfo?.insuranceTrade.shijizfqfx || 0).toFixed(2)}
                <span style={{ color: theme.tc2, fontSize: '1rem' }}>元</span>
              </Col>
              { paymentInfo?.payment.insuranceCode == 'CHONGQING' && (
                 <>
                   <Col
                     span={3}
                     style={{
                       color: theme.tc2,
                       textAlign: 'right',
                       fontSize: '1rem',
                     }}
                   >
                     医保钱包：
                   </Col>
                   <Col
                     span={3}
                     style={{
                       color: theme.tc1,
                       textAlign: 'left',
                       fontSize: '1.125rem',
                     }}
                   >
                     {Number(paymentInfo?.insuranceTradeSplit.find((item:any) => item.jijinzflx == '9999981') != null ? paymentInfo?.insuranceTradeSplit.find((item:any) => item.jijinzflx == '9999981').jijinzfje : '0.00').toFixed(2)}
                     <span style={{ color: theme.tc2, fontSize: '1rem' }}>元</span>
                   </Col>
                 </>
              )}
              <Col
                span={3}
                style={{
                  color: theme.tc2,
                  textAlign: 'right',
                  fontSize: '1rem',
                }}
              >
                其他支出：
              </Col>
              <Col
                span={3}
                style={{
                  color: theme.tc1,
                  textAlign: 'left',
                  fontSize: '1.125rem',
                }}
              >
                {Number(paymentInfo?.insuranceTrade.qitazc || 0).toFixed(2)}
                <span style={{ color: theme.tc2, fontSize: '1rem' }}>元</span>
              </Col>
            </Row>
          </>
        ) : (
          <Row style={{ marginTop: 20 }} align='bottom'>
            <Col
              span={4}
              style={{ color: theme.tc2, textAlign: 'right', fontSize: '1rem' }}
            >
              患者姓名：
            </Col>
            <Col
              span={4}
              style={{
                color: theme.tc1,
                textAlign: 'left',
                fontSize: '1.125rem',
              }}
            >
              {result?.patientName}
            </Col>
            <Col
              span={4}
              style={{ color: theme.tc2, textAlign: 'right', fontSize: '1rem' }}
            >
              总金额：
            </Col>
            <Col
              span={4}
              style={{
                color: theme.tc1,
                textAlign: 'left',
                fontSize: '1.125rem',
              }}
            >
              {paymentInfo?.payment?.totalPayAmount?.toFixed(2) || 0}
              <span style={{ color: theme.tc2, fontSize: '1rem' }}>元</span>
              {Math.abs(paymentInfo?.payment?.discount) != 1 ? (
                <span>
                  （
                  {Number(
                    Math.abs(paymentInfo?.payment?.discount) * 10
                  )?.toFixed(2)}
                  折）
                </span>
              ) : (
                ''
              )}
            </Col>
            <Col
              span={4}
              style={{ color: theme.tc2, textAlign: 'right', fontSize: '1rem' }}
            >
              实收金额：
            </Col>
            <Col
              span={4}
              style={{
                color: theme.tc1,
                textAlign: 'left',
                fontSize: '1.125rem',
              }}
            >
              {result?.realAmount === -1 ? '-' : result?.realAmount?.toFixed(2)}
              <span style={{ color: theme.tc2, fontSize: '1rem' }}>元</span>
            </Col>
            <Col
              span={4}
              style={{ color: theme.tc2, textAlign: 'right', fontSize: '1rem' }}
            >
              操作员：
            </Col>
            <Col
              span={4}
              style={{
                color: theme.tc1,
                textAlign: 'left',
                fontSize: '1.125rem',
              }}
            >
              {result?.operateName}
            </Col>
            <Col
              span={4}
              style={{ color: theme.tc2, textAlign: 'right', fontSize: '1rem' }}
            >
              结算时间：
            </Col>
            <Col
              span={4}
              style={{
                color: theme.tc1,
                textAlign: 'left',
                fontSize: '1.125rem',
              }}
            >
              {result?.createTime}
            </Col>
            {/* <Col
             span={4}
             style={{ color: theme.tc2, textAlign: "right", fontSize: "1rem" }}
           >
             加工方式：
           </Col>
           <Col
             span={4}
             style={{
               color: theme.tc1,
               textAlign: "left",
               fontSize: "1.125rem",
             }}
           >
             {result?.machiningTypeMsg}
           </Col> */}
          </Row>
        )}
        {dateShow() && (
          <Row justify='center' style={{ marginTop: edtion == 1 ? 14 : 50 }}>
            <EditableList
              style={{
                marginTop: 12,
                marginLeft: 20,
                marginRight: 20,
                marginBottom: 20,
                flex: 1,
                overflow: 'auto',
              }}
              pagination={false}
              dataSource={result?.paymentDetailRecordVOS}
              bordered
              size='small'
              columns={[
                {
                  title: '结算明细编号',
                  dataIndex: 'id',
                  align: 'center',
                },
                {
                  title: '实收金额',
                  dataIndex: 'realPayAmount',
                  align: 'center',
                  // render: (_, m) =>
                  //   `${m.drugCount}${m.splitTag ? m.splitUnit : m.accountUnit}`,
                },
                {
                  title: '类型',
                  dataIndex: 'state',
                  align: 'center',
                  // width: '3.75rem',
                  render: (_, m: any) => {
                    return m.state == 3
                      ? '收费'
                      : m.state == 5
                      ? '退费'
                      : m.state == 101
                      ? '补交'
                      : ''
                  },
                },
                {
                  title: '方式',
                  dataIndex: 'payMethod',
                  align: 'center',
                  // width: '5rem',
                  render: (_, m: any) => {
                    return m.payMethodName
                    // m.payMethod == 1
                    // ? '现金'
                    // : m.payMethod == 2
                    // ? '聚合支付'
                    // : m.payMethod == 3
                    // ? '支付宝'
                    // : m.payMethod == 4
                    // ? '微信'
                    // : m.payMethod == 5
                    // ? '银联'
                    // : m.payMethod == 6
                    // ? '会员卡'
                    // : m.payMethod == 99
                    // ? '医保报销'
                    // : ''
                  },
                },
                {
                  title: '操作员',
                  dataIndex: 'operateUserName',
                  align: 'center',
                  // width: '5rem',
                  // render: (_, m) =>
                  //   (m.billingCategory !== BillingCategory.ZY && m.billingCategory !== BillingCategory.DJF) || m.tip <= 0
                  //     ? '-'
                  //     : `${m.tip}`,
                },
                {
                  title: '操作时间',
                  dataIndex: 'createTime',
                  align: 'center',
                  // render: (_, r) => r.retailPrice?.toFixed(5),
                },
              ]}
            />
          </Row>
        )}

        <Row justify='center' style={{ marginTop: edtion == 1 ? 4 : 50 }}>
          <Space>
            <Button
              loading={pjdyLoading}
              disabled={!paymentId && !result?.id}
              onKeyDown={(e) => e.preventDefault()}
              onClick={() => {
                setPjdyLoading(true)
                setTimeout(() => {
                  setIsPrintModalVisible(true)
                }, 100)
              }}
              style={{ color: theme.c1, borderColor: theme.c1 }}
            >
              票据打印（空格）
            </Button>
            <Button
              style={{ color: theme.c1, borderColor: theme.c1 }}
              onKeyDown={(e) => e.preventDefault()}
              onClick={() => setIsRecipeListVisible(true)}
            >
              处方打印
            </Button>
            <Button
              type='primary'
              onKeyDown={(e) => e.preventDefault()}
              onClick={() => finish()}
            >
              结束（回车）
            </Button>
            {status == '8' && result?.arrearageFlg == 1 && (
              <Button
                type='primary'
                // onKeyDown={(e) => e.preventDefault()}
                onClick={() => {
                  const paymentId =
                    (query.get('paymentId') as string) || currentPaymentId
                  dispatch(
                    traceRoute({
                      name: '补交',
                      path: '/payment/oweMoney',
                      query: `paymentId=${paymentId}&&status=${status}`,
                    })
                  )
                }}
              >
                补交
              </Button>
            )}
          </Space>
        </Row>
        <Row
          align='middle'
          justify='space-between'
          style={{ marginTop: edtion == 1 ? 4 : 40, marginRight: 20 }}
        >
          <Label
            label='药品信息'
            style={{ fontSize: '1.125rem', marginLeft: 20 }}
          />
          {result?.state !== RecipeStatus.Returned && (
            <Space>
              <Button
                type='primary'
                disabled={refundBtn}
                danger
                onClick={() => {
                  setRefund(true)
                }}
              >
                一键退费
              </Button>
              <Button
                type='primary'
                loading={loading === 'ship'}
                onKeyDown={(e) => e.preventDefault()}
                onClick={async () => {
                  if (loading !== '') {
                    return
                  }
                  if (result) {
                    try {
                      setLoading('ship')
                      await dispatch(
                        directDeliver({
                          paymentId: result.id,
                          deliveryState: DeliveryState.Unfilled,
                        })
                      )
                        .then(unwrapResult)
                        .then((fyRes: any) => {
                          if (fyRes?.successful == false) {
                            const errMsg = fyRes?.errors.map((v: any) => {
                              return v.name + ' ' + v.message + ','
                            })
                            notification.error({
                              message: errMsg,
                            })
                          } else {
                            notification.success({
                              message: '操作成功',
                            })
                            if (result) {
                              dispatch(getPaymentResults(result.id))
                                .then(unwrapResult)
                                .then(async (val: any) => {
                                  setResult(val)
                                })
                            }
                          }
                        })
                    } catch {
                      // do nothing.
                    } finally {
                      setLoading('')
                    }
                  }
                }}
              >
                一键发药
              </Button>

              <Button
                type='primary'
                loading={loading === 'return'}
                onKeyDown={(e) => e.preventDefault()}
                onClick={async () => {
                  if (loading !== '') {
                    return
                  }
                  if (result) {
                    try {
                      setLoading('return')
                      await dispatch(
                        directDeliver({
                          paymentId: result.id,
                          deliveryState: DeliveryState.Fulfilled,
                        })
                      ).then(unwrapResult)
                      if (result) {
                        notification.success({
                          message: '操作成功',
                        })
                        dispatch(getPaymentResults(result.id))
                          .then(unwrapResult)
                          .then(async (val: any) => {
                            setResult(val)
                          })
                      }
                    } catch {
                      // do nothing.
                    } finally {
                      setLoading('')
                    }
                  }
                }}
              >
                一键退药
              </Button>

              {isUploaded && (
                <Button
                  type='ghost'
                  onClick={() => {
                    dispatch(cancelSaleUpload(result?.id))
                      .then(unwrapResult)
                      .then(async (val: any) => {
                        notification.success({
                          message: '追溯码上传撤销成功',
                        })
                        setIsUploaded(false)
                        setResult(val)
                        setBtnBackgroundColor({
                          backgroundColor: '#F5F5F5',
                          color: '#B8B8B8',
                        })
                      })
                  }}
                  onMouseEnter={() => {
                    setTraceCodeText('重新进行上传')
                    setBtnBackgroundColor({
                      backgroundColor: '#2B95FF',
                      color: '#FFFFFF',
                    })
                  }}
                  onMouseLeave={() => {
                    setTraceCodeText('追溯码已上传')
                    setBtnBackgroundColor({
                      backgroundColor: '#F5F5F5',
                      color: '#B8B8B8',
                    })
                  }}
                  style={btnBackgroundColor}
                >
                  {result?.traceCodeCnt == -1
                    ? '无需录入追溯码'
                    : traceCodeText}
                </Button>
              )}

              {insuranceItem &&
                JSON.stringify(insuranceItem) !== '{}' &&
                !isUploaded && (
                  <Badge
                    count={result?.traceCodeCnt < 0 ? 0 : result?.traceCodeCnt}
                    showZero={result?.traceCodeCnt > -1}
                  >
                    <Button
                      type='primary'
                      disabled={result?.traceCodeCnt == -1}
                      onClick={() => {
                        setShowCodeInputModal(true)
                      }}
                    >
                      {result?.traceCodeCnt == -1
                        ? '无需录入追溯码'
                        : '录入追溯码'}
                    </Button>
                  </Badge>
                )}
            </Space>
          )}

          <Divider style={{ margin: 0, borderColor: theme.c3 }} />
          <div style={{ marginBottom: '40px', width: '100%' }}>
            {result?.materials?.map((v: any) => {
              const fValue: any =
                (v?.recipeNormalFrequency
                  ? ',' + v?.recipeNormalFrequency
                  : '') +
                (v?.recipeNormalUsemethod
                  ? ',' + v?.recipeNormalUsemethod
                  : '') +
                (v?.recipeNormalDose ? ',' + v?.recipeNormalDose : '') +
                (v?.remark ? ','  : '')

                const fRemark = v?.remark ? v?.remark : ''
              return (
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}
                  >
                    <div
                      style={{
                        padding: '8px 0 8px 8px',
                        flex: 1,
                        background: '#f5f8fb',
                        margin: '20px 0 0 20px',
                        border: '1px solid #f0f0f0',
                        borderBottom: 'none',
                        fontWeight: 600,
                        fontSize: '16px',
                      }}
                    >
                      {getRecipeCategoryName(v?.category)}处方单，处方编号：
                      {v?.id}，总金额：{v?.amount?.toFixed(2)}
                    </div>
                    <EditableList
                      style={{
                        margin: '0px 0px 20px 20px',
                        flex: 1,
                        overflow: 'auto',
                      }}
                      pagination={false}
                      dataSource={v?.itemList}
                      bordered
                      size='small'
                      columns={
                        hisVersion
                          ? columns?.filter(
                              (v: any) =>
                                v.key != 'countPerTip' && v.key != 'tip'
                            )
                          : v?.category == 7 ||
                            v?.category == 13 ||
                            v?.category == 6 ||
                            v?.category == 4 ||
                            v?.category == 1 ||
                            v?.category == 14
                          ? columns?.filter(
                              (v: any) =>
                                v.key != 'countPerTip' && v.key != 'tip'
                            )
                          : columns
                      }
                    />
                    {v?.category == 3 && (
                      <Row
                        align='middle'
                        className={styles.tip}
                        style={{
                          color: theme.tc2,
                          fontSize: '0.875rem',
                          padding: '0 20px 0px 20px',
                        }}
                      >
                        总帖均：
                        <div
                          style={{
                            color: theme.tc1,
                            fontSize: '1.125rem',
                            marginRight: 10,
                          }}
                        >
                          ¥{v?.totalTipAmount?.toFixed(2)}
                        </div>
                        自费帖均：
                        <div
                          style={{
                            color: theme.tc1,
                            fontSize: '1.125rem',
                            marginRight: 10,
                          }}
                        >
                          ¥ {v?.zifeiTipAmount?.toFixed(2)}
                        </div>
                        {JSON.parse(
                          localStorage.getItem('credentials') as string
                        ).insuranceArray.length != 0 && (
                          <>
                            医保帖均：
                            <div
                              style={{
                                color: theme.tc1,
                                fontSize: '1.125rem',
                                marginRight: 10,
                              }}
                            >
                              ¥{v?.insuranceTipAmount?.toFixed(2)}
                            </div>
                          </>
                        )}
                        {v?.recipeType === 1 ? (
                          <>
                            代煎类别：
                            <div
                              style={{
                                color:
                                  v?.boilType?.toString() == '1'
                                    ? 'red'
                                    : theme.tc1,
                                marginRight: 10,
                              }}
                            >
                              {getBoilTypeName(v?.boilType?.toString())}
                            </div>
                          </>
                        ) : (
                          ''
                        )}
                        中药帖数：
                        <Button icon={<MinusOutlined />} disabled />
                        <span style={{ width: '50px', textAlign: 'center' }}>
                          {v?.tip || 7}
                        </span>
                        {/* <InputNumber
                          style={{
                            width: '3.5rem',
                            color: theme.tc2,
                            fontSize: '1rem',
                            fontWeight: 'bold',
                            textAlign: 'center',
                          }}
                          value={v?.tip || 7}
                          min={1}
                          max={9999}
                          bordered={false}
                        /> */}
                        <Button
                          icon={<PlusOutlined />}
                          style={{ marginRight: 10 }}
                          disabled
                        />
                        处方用法：
                        <Button
                          disabled
                          style={{
                            color: theme.tc2,
                            width: '500px',
                            textAlign: 'left',
                          }}
                          icon={<SearchOutlined color={theme.c3} />}
                        >
                          <span
                            style={{
                              width: '500px',
                            }}
                          >
                            {v?.useMethod + (fValue ? fValue : '')}
                            <span style={{ fontWeight: 'bold' ,color:'#000000'}}>
                              {fRemark ? fRemark : ''}
                            </span>
                          </span>
                        </Button>
                      </Row>
                    )}
                  </div>
                </>
              )
            })}
          </div>
        </Row>

        <ManualDeliveryModal
          material={batchDeliverTarget}
          visible={!!batchDeliverTarget}
          onCancel={() => setBatchDeliverTarget(undefined)}
          onOk={() => {
            setBatchDeliverTarget(undefined)
            if (result) {
              dispatch(getPaymentResults(result.id))
                .then(unwrapResult)
                .then(async (val: any) => {
                  setResult(val)
                })
            }
          }}
        />
        <TraceCodeInputModal
          showCodeInputModal={showCodeInputModal}
          paymentResult={result}
          onCancel={() => setShowCodeInputModal(false)}
          onOk={() => {
            setShowCodeInputModal(false)
            dispatch(getPaymentResults(result.id))
              .then(unwrapResult)
              .then(async (val: any) => {
                setResult(val)
              })
          }}
          isShowUpload={!isUploaded}
        />

        <PrintTemplateModal
          pagePrintFlag={'sfPrintTag'}
          templateCategory={
            result?.state == RecipeStatus.Returned
              ? PrintTemplateCategory.Return
              : undefined
          }
          treatmentId={result?.treatmentId}
          patientId={result?.patientId}
          visible={isPrintModalVisible}
          paymentId={paymentId || result?.id}
          paymentInfo={paymentInfo}
          itemList={result}
          insuranceCode={result?.insuranceCode}
          isRecipe={true}
          onCancel={() => setIsPrintModalVisible(false)}
          onOk={() => setIsPrintModalVisible(false)}
          onChange={() => {
            setIsPrintModalVisible(false)
            setIsRecipeListVisible(true)
          }}
        />
        <RecipeListModal
          patientId={result?.patientId}
          treatmentId={result?.treatmentId}
          visible={isRecipeListVisible && !!result?.treatmentId}
          onCancel={() => setIsRecipeListVisible(false)}
        />
      </Col>
      <Modal
        title='您电脑上未检测到打印软件'
        footer={null}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
      >
        <div>
          请先安装该软件：
          {/* <a href="http://demo.c-lodop.com:8000/Lodop6.226_Clodop4.127.zip"> */}
          <a href='https://hydhis-public.oss-cn-hangzhou.aliyuncs.com/setup/install_lodop32.exe?versionId=CAEQRRiBgIC9wL33mxgiIDc0MDRlNmEzMTI3YTQwNWQ5YTA1MzMyZGQ2NWI2ZWU2'>
            点击下载
          </a>
        </div>
      </Modal>
      <Modal
        title='二维码'
        footer={null}
        visible={isQrCodeVisible}
        onCancel={() => setIsQrCodeVisible(false)}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {isQrCodeData && (
            <Qrcode
              id='qrcode2'
              value={isQrCodeData?.ewm}
              style={{
                width: 240,
                height: 240,
              }}
            />
          )}
        </div>
      </Modal>
      <Modal
        visible={isUploadInfoVisible}
        title={'确认操作'}
        onCancel={() => setIsUploadInfoVisible(false)}
        footer={
          <Row>
            <Col flex={1}>
              <Button
                onClick={() => {
                  setIsUploadInfoVisible(false)
                }}
              >
                取消
              </Button>
              <Button
                type={'primary'}
                onClick={() => {
                  setIsUploadInfoVisible(false)
                }}
              >
                确认
              </Button>
            </Col>
          </Row>
        }
      >
        <div style={{ display: 'flex', lineHeight: '22px' }}>
          <div style={{ color: '#ffae55', fontSize: 24, marginRight: 10 }}>
            <ExclamationCircleFilled />
          </div>
          <p>代煎订单自动上传失败，请手动上传。</p>
        </div>
      </Modal>
      <RealPayModal
        buttonLoading={buttonLoading}
        stkButtonLoading={stkButtonLoading}
        dzpzButtonLoading={dzpzButtonLoading}
        ybFaceButtonLoading={ybFaceButtonLoading}
        refund={refund}
        onOK={(type) => {
          validCardInfoBeforeGoRefund(type)
        }}
        onCancel={() => setRefund(false)}
        resultData={result?.paymentDetailRecordVOS}
      />
    </Col>
  )
}
