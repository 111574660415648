/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-06-29 16:55:05
 * @LastEditors: linxi
 * @LastEditTime: 2025-01-14 15:37:02
 */
import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, Divider, Row, Space } from 'antd'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { log } from 'util'
import { selectEdition } from '../../../app/applicationSlice'
import { RootDispatch } from '../../../app/store'
import {
  AppointmentingIcon,
  CancelIcon,
  OutpatientedIcon,
  OverTimeIcon,
  RegistrationedIcon,
  RunOffIcon,
} from '../../../compnents/icons/Icons'
import { NavBar } from '../../../compnents/nav/NavBar'
import { traceRoute } from '../../../layouts/layoutSlice'
import {
  getAppointTypeName,
  getAppointTypeType,
} from '../../../models/appointment'
import { getSourceTypeName } from '../../../models/registration'
import { getGenderName } from '../../../models/user'
import { ThemeContext } from '../../../theme/ThemeContext'
import { getAge } from '../../../utils/StringUtils'
import {
  seeAppointmentAsync,
  setAppointmentIdSlice,
} from '../../registration/register/registerSlice'
import styles from '../appointment.module.css'

export const AppointmentDetail = (props: {
  id?: string
  status?: string
  visibleTag?: any
}) => {
  const history = useHistory()

  const dispatch = useDispatch<RootDispatch>()

  const location: any = useLocation()

  const theme = useContext(ThemeContext)

  const [data, setData] = useState<any>()

  const edition = useSelector(selectEdition)

  // 口腔版弹窗
  useEffect(() => {
    if (props?.visibleTag == 'true')
      if (location?.state?.id || props.id) {
        dispatch(seeAppointmentAsync(location?.state?.id || props.id))
          .then(unwrapResult)
          .then((res: any) => {
            setData(res)
          })
      }
  }, [location, props.id, props?.visibleTag])

  // 专业版页面
  useEffect(() => {
    if (location?.state?.tag == '0') {
      if (location?.state?.id || props.id) {
        dispatch(seeAppointmentAsync(location?.state?.id || props.id))
          .then(unwrapResult)
          .then((res: any) => {
            setData(res)
          })
      }
    }
  }, [location?.state?.tag, location, props.id])

  return (
    <Col
      style={{
        height: 'calc(100% - 20px)',
        overflow: 'hidden',
        position: 'relative',
        marginTop: 10,
        marginBottom: 10,
        padding: '0px 20px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {!props.id && (
        <NavBar
          style={{ marginBottom: '10px' }}
          where={['预约', '预约详情']}
          backtrace={{
            name: '预约',
            path: '/appointment',
            routeName: '预约',
            // state: {
            //   current: location?.state?.current,
            // },
          }}
        />
      )}
      <Col
        style={{
          flex: 1,
          overflow: 'hidden',
          backgroundColor: theme.pn,
          marginTop: 10,
          borderRadius: 10,
          padding: '30px 35px',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
        }}
      >
        <section className={styles.detail}>
          <Row>
            <Col span={8}>患者姓名：{data?.name}</Col>
            <Col span={8}>
              年龄：
              {getAge(data?.ageYear, data?.ageMonth)}
            </Col>
            <Col span={8}>性别：{getGenderName(data?.sex)}</Col>
            <Col span={8}>
              联系方式：{data?.phone || '-'}{' '}
              {edition == 1 ? <>（{data?.relationship || '-'}）</> : ''}
            </Col>
            <Col span={8}>身份证号：{data?.idCardNumber || '-'}</Col>
            <Col span={24}>工作单位：{data?.companyName || '-'}</Col>
            <Col span={24}>地址：{data?.address || '-'}</Col>
            {/* <Col span={8}>紧急联系人：{data?.emergencyContactName}</Col> */}
            <Col span={8}>
              紧急联系人方式：{data?.emergencyContactPhone || '-'}
            </Col>
            <Col span={8}>患者来源：{getSourceTypeName(data?.source)}</Col>
          </Row>
          <Divider />
          <Row>
            <Col span={8}>医生科室：{data?.appointmentDepartmentName}</Col>
            <Col span={8}>医生：{data?.appointmentDoctorName}</Col>
            <Col span={8}>
              接诊类型：{data?.furtherConsideration ? '复诊' : '初诊'}
            </Col>
            <Col span={8}>
              预约方式：
              {data?.appointmentType > 0
                ? getAppointTypeName(data?.appointmentType)
                : '-'}
            </Col>
            <Col span={8}>预约日期：{data?.appointmentDate}</Col>
            <Col span={8}>
              预约时间：{data?.appointmentStartTime}-{data?.appointmentEndTime}
            </Col>
            <Col span={8}>预约项目：{data?.appointmentItem || '-'}</Col>
            {/* <Col span={8}>预约时间：{moment(data?.appointmentStartTime, 'HH:mm')}-{moment(data?.appointmentEndTime, 'HH:mm')}</Col> */}
            <Col span={24}>预约备注：{data?.appointmentComment || '-'}</Col>
          </Row>
        </section>
        {(location?.state?.appointmentState == 0 ||
          location?.state?.appointmentState == -1) && (
          <Row justify='center' className={styles.action}>
            <Space>
              <Button
                type='primary'
                onClick={() => {
                  dispatch(
                    traceRoute({
                      name: '挂号',
                      path: '/registration',
                    })
                  )
                  localStorage.setItem('appointFlag','appointState') 
                  dispatch(
                    setAppointmentIdSlice(location?.state?.id || props.id)
                  )
                }}
              >
                挂号
              </Button>
              <Button
                type='primary'
                onClick={() => {
                  history.push({
                    pathname: '/addEditAppointment',
                    state: {
                      id: location?.state?.id || props.id,
                    },
                  })
                }}
              >
                修改
              </Button>
              <Button
                onClick={() => {
                  history.go(-1)
                }}
              >
                {' '}
                取消
              </Button>
            </Space>
          </Row>
        )}

        <div className={styles.detailFlag}>
          {data?.appointmentState == 0 && (
            <AppointmentingIcon className={styles.icon} />
          )}
          {data?.appointmentState == 1 && (
            <RegistrationedIcon className={styles.icon} />
          )}
          {data?.appointmentState == 2 && (
            <OutpatientedIcon className={styles.icon} />
          )}
          {data?.appointmentState == 3 && (
            <CancelIcon className={styles.icon} />
          )}
          {data?.appointmentState == 9 && (
            <OverTimeIcon className={styles.icon} />
          )}
          {data?.appointmentState == -1 && (
            <RunOffIcon className={styles.icon} />
          )}

          <span>{getAppointTypeType(data?.appointmentState)}</span>
        </div>
      </Col>
    </Col>
  )
}
