/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-09-29 15:43:45
 * @LastEditors: linxi
 * @LastEditTime: 2024-12-25 17:01:58
 */
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Popover,
  Radio,
  Row,
  Select,
  Space,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import _ from 'lodash'
import moment from 'moment'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import { ThemeContext } from '../../../theme/ThemeContext'
import {
  getPrinters,
  getPrintTemplateSettingUserList,
  initializePrintService,
  selectPrintServiceState,
} from '../../print/printSlice'
import styles from '../PrintSettingAll.module.css'
import txmImg from '../assets/blhIcon.png'
import { selectTenantName } from '../../../app/applicationSlice'
import { getGenderName } from '../../../models/user'
import {
  DateTimeFormatSimpleOnly,
  DateTimeFormatSimpleOnlyOne,
} from '../../../models/datetime'
import {
  getPrintSettingDetailAsync,
  getPrintSettingEditOrYLAsync,
  getPrintSettingSubmitAsync,
  getPrintSettingsyncTenantNameAsync,
} from '../PrintSettingAllSlice'
import { pinyin } from 'pinyin-pro'
import { LoadingState } from '../../../models/common'

export const RecipePrintSetting = (): ReactElement => {
  const [form] = Form.useForm()

  const dispatch = useDispatch<RootDispatch>()

  const tenantName = useSelector(selectTenantName)

  const [selectButtonId, setSelectButtonId] = useState('1')

  const [users, setUsers] = useState<any>([])

  const [elseUsers, setElseUsers] = useState<any>([])

  const [detail, setDetail] = useState<any>()

  const [changeData, setChangeData] = useState<any>()

  const [buttonLoading, setButtonLoading] = useState(false)

  const initialized = useSelector(selectPrintServiceState)

  const [showPhysicianTypeListFlag, setShowPhysicianTypeListFlag] = useState(
    false
  ) //是否展示医师的医生标志位

  const [
    showPharmaceuticalReviewerTypeListFlag,
    setShowPharmaceuticalReviewerTypeListFlag,
  ] = useState(false) //是否展示审方药师的医生标志位

  const [
    showPharmacistTypeListFlag,
    setShowPharmacistTypeListFlag,
  ] = useState<any>(false) // 是否展示配药药师的医生标志位

  const [
    showReviewerPharmacistTypeListFlag,
    setShowReviewerPharmacistTypeListFlag,
  ] = useState(false) //是否展示复核医师的医生标志位

  const [showDispenserTypeListFlag, setShowDispenserTypeListFlag] = useState(
    false
  ) //是否展示审方发药药师的医生标志位

  const [physicianSignType, setPhysicianSignType] = useState(false) //医师

  const [
    pharmaceuticalReviewerSignType,
    setPharmaceuticalReviewerSignType,
  ] = useState(false) //审方

  const [pharmacistSignType, setPharmacistSignType] = useState(false) //配药药师

  const [reviewerPharmacistSignType, setReviewerPharmacistSignType] = useState(
    false
  ) //复核医师

  const [dispenserSignType, setDispenserSignType] = useState(false) //发药医师

  const [patientData, setPatientData] = useState<any>()

  const [recipeXYList, setRecipeXYList] = useState<any>()

  const [recipeZYList, setRecipeZYList] = useState<any>()

  const zyData = [
    {
      groupNumber: '1',
      name: '桂枝',
      countPerTip: 1,
      splitUnit: 'g',
      drugCount: 9,
      normalUsemethod: '无',
      cabineNo: 'c01',
    },
    {
      groupNumber: '2',
      name: '木香',
      countPerTip: 1,
      splitUnit: 'g',
      drugCount: 10,
      normalUsemethod: '后下',
      cabineNo: 'c02',
    },
    {
      groupNumber: '3',
      name: '杏仁',
      countPerTip: 1,
      splitUnit: 'g',
      drugCount: 6,
      normalUsemethod: '煎服',
      cabineNo: 'c03',
    },
    {
      groupNumber: '4',
      name: '厚朴',
      countPerTip: 1,
      splitUnit: 'g',
      drugCount: 6,
      normalUsemethod: '煎服',
      cabineNo: 'c04',
    },
    {
      groupNumber: '5',
      name: '阿胶',
      countPerTip: 1,
      splitUnit: 'g',
      drugCount: 5,
      normalUsemethod: '烊化',
      cabineNo: 'c05',
    },
    {
      groupNumber: '6',
      name: '生姜',
      countPerTip: 1,
      splitUnit: 'g',
      drugCount: 9,
      normalUsemethod: '无',
      cabineNo: 'c06',
    },
    {
      groupNumber: '7',
      name: '白芍',
      countPerTip: 1,
      splitUnit: 'g',
      drugCount: 9,
      normalUsemethod: '无',
      cabineNo: 'c07',
    },
    {
      groupNumber: '8',
      name: '大枣 ',
      countPerTip: 1,
      splitUnit: 'g',
      drugCount: 12,
      normalUsemethod: '无',
      cabineNo: 'c08',
    },
    {
      groupNumber: '9',
      name: '炙甘草',
      countPerTip: 1,
      splitUnit: 'g',
      drugCount: 6,
      normalUsemethod: '无',
      cabineNo: 'c09',
    },
  ]

  const xyData = [
    [
      {
        groupNumber: 1,
        name: '诊查费',
        packSpec: '',
        skinResultinfo: '',
        useDays: 1,
        drugCount: '1',
        splitUnit: '次',
        drugType: 1,
        sortStr: '',
      },
    ],
    [
      {
        groupNumber: 2,
        name: '磷酸奥司他韦胶囊',
        packSpec: '6粒*1板/盒',
        skinResultinfo: '',
        useDays: 3,
        drugCount: '2',
        splitUnit: '盒',
        normalUsemethod: '口服',
        normalDose: 1,
        doseUnit: '粒',
        normalFrequency: '每天两次',
        normalFrequencySX: 'bid',
        otcRemark: '处方药',
        tenantSupplierName: '广州一品红制药有限公司',
        batchNo: '0343',
        drugType: 2,
        sortStr: '口服,1粒,每天两次',
      },
    ],
    [
      {
        groupNumber: 3,
        name: '盐酸左西替利嗪片',
        packSpec: '5mg*14/盒',
        skinResultinfo: '',
        useDays: 3,
        drugCount: '1',
        splitUnit: '盒',
        normalUsemethod: '口服',
        normalDose: 4,
        doseUnit: '片',
        normalFrequency: '每天三次',
        normalFrequencySX: 'tid',
        otcRemark: '',
        tenantSupplierName: '广州一品红制药有限公司',
        batchNo: '0382',
        drugType: 2,
        sortStr: '口服,4片,每天三次',
      },
      {
        groupNumber: 3,
        name: '通便灵胶囊',
        packSpec: '0.25g*36/盒',
        skinResultinfo: '',
        useDays: 3,
        drugCount: '3',
        splitUnit: '瓶',
        normalUsemethod: '口服',
        normalDose: 4,
        doseUnit: '片',
        normalFrequency: '每天三次',
        normalFrequencySX: 'tid',
        otcRemark: '',
        tenantSupplierName: '湖南九典制药',
        batchNo: '0382',
        drugType: 2,
        sortStr: '口服,4片,每天三次',
      },
    ],
    [
      {
        groupNumber: 4,
        name: '新清宁片',
        packSpec: '0.31g*36/盒',
        skinResultinfo: '',
        useDays: 3,
        drugCount: '1',
        splitUnit: '盒',
        normalUsemethod: '口服',
        normalDose: 1,
        doseUnit: '片',
        normalFrequency: '每天三次',
        normalFrequencySX: 'tid',
        otcRemark: '',
        tenantSupplierName: '辽宁汉草堂中',
        batchNo: '0878',
        drugType: 2,
        sortStr: '口服,1片,每天三次',
      },
    ],
    [
      {
        groupNumber: 5,
        name: '玻璃离子水门汀',
        packSpec: 'A3（15g粉+8g液）',
        skinResultinfo: 'IX',
        useDays: 1,
        drugCount: '1',
        splitUnit: '套',
        normalUsemethod: '',
        normalDose: '',
        doseUnit: '',
        normalFrequency: '',
        otcRemark: '',
        tenantSupplierName: '辽宁汉草堂中',
        batchNo: '',
        drugType: 3,
        sortStr: '',
      },
    ],
  ]

  const noDrugsXYData = [
    [
      {
        groupNumber: 1,
        name: '诊查费',
        packSpec: '',
        skinResultinfo: '',
        useDays: 1,
        drugCount: '1',
        splitUnit: '次',
        drugType: 1,
        sortStr: '',
      },
    ],
    [
      {
        groupNumber: 2,
        name: '玻璃离子水门汀',
        packSpec: 'A3（15g粉+8g液）',
        skinResultinfo: 'IX',
        useDays: 1,
        drugCount: '1',
        splitUnit: '套',
        normalUsemethod: '',
        normalDose: '',
        doseUnit: '',
        normalFrequency: '',
        otcRemark: '',
        tenantSupplierName: '辽宁汉草堂中',
        batchNo: '',
        drugType: 3,
        sortStr: '',
      },
    ],
  ]

  const noTreatmentXYData = [
    [
      {
        groupNumber: 1,
        name: '磷酸奥司他韦胶囊',
        packSpec: '6粒*1板/盒',
        skinResultinfo: '',
        useDays: 3,
        drugCount: '2',
        splitUnit: '盒',
        normalUsemethod: '口服',
        normalDose: 1,
        doseUnit: '粒',
        normalFrequency: '每天两次',
        normalFrequencySX: 'bid',
        otcRemark: '处方药',
        tenantSupplierName: '广州一品红制药有限公司',
        batchNo: '0343',
        drugType: 2,
        sortStr: '口服,1粒,每天两次',
      },
    ],
    [
      {
        groupNumber: 2,
        name: '盐酸左西替利嗪片',
        packSpec: '5mg*14/盒',
        skinResultinfo: '',
        useDays: 3,
        drugCount: '1',
        splitUnit: '盒',
        normalUsemethod: '口服',
        normalDose: 4,
        doseUnit: '片',
        normalFrequency: '每天三次',
        normalFrequencySX: 'tid',
        otcRemark: '',
        tenantSupplierName: '广州一品红制药有限公司',
        batchNo: '0382',
        drugType: 2,
        sortStr: '口服,4片,每天三次',
      },
      {
        groupNumber: 2,
        name: '通便灵胶囊',
        packSpec: '0.25g*36/盒',
        skinResultinfo: '',
        useDays: 3,
        drugCount: '3',
        splitUnit: '瓶',
        normalUsemethod: '口服',
        normalDose: 4,
        doseUnit: '片',
        normalFrequency: '每天三次',
        normalFrequencySX: 'tid',
        otcRemark: '',
        tenantSupplierName: '湖南九典制药',
        batchNo: '0382',
        drugType: 2,
        sortStr: '口服,4片,每天三次',
      },
    ],
    [
      {
        groupNumber: 3,
        name: '新清宁片',
        packSpec: '0.31g*36/盒',
        skinResultinfo: '',
        useDays: 3,
        drugCount: '1',
        splitUnit: '盒',
        normalUsemethod: '口服',
        normalDose: 1,
        doseUnit: '片',
        normalFrequency: '每天三次',
        normalFrequencySX: 'tid',
        otcRemark: '',
        tenantSupplierName: '辽宁汉草堂中',
        batchNo: '0878',
        drugType: 2,
        sortStr: '口服,1片,每天三次',
      },
    ],
    [
      {
        groupNumber: 4,
        name: '玻璃离子水门汀',
        packSpec: 'A3（15g粉+8g液）',
        skinResultinfo: 'IX',
        useDays: 1,
        drugCount: '1',
        splitUnit: '套',
        normalUsemethod: '',
        normalDose: '',
        doseUnit: '',
        normalFrequency: '',
        otcRemark: '',
        tenantSupplierName: '辽宁汉草堂中',
        batchNo: '',
        drugType: 3,
        sortStr: '',
      },
    ],
  ]

  const noMaterialsXYData = [
    [
      {
        groupNumber: 1,
        name: '诊查费',
        packSpec: '',
        skinResultinfo: '',
        useDays: 1,
        drugCount: '1',
        splitUnit: '次',
        drugType: 1,
        sortStr: '',
      },
    ],
    [
      {
        groupNumber: 2,
        name: '磷酸奥司他韦胶囊',
        packSpec: '6粒*1板/盒',
        skinResultinfo: '',
        useDays: 3,
        drugCount: '2',
        splitUnit: '盒',
        normalUsemethod: '口服',
        normalDose: 1,
        doseUnit: '粒',
        normalFrequency: '每天两次',
        normalFrequencySX: 'bid',
        otcRemark: '处方药',
        tenantSupplierName: '广州一品红制药有限公司',
        batchNo: '0343',
        drugType: 2,
        sortStr: '口服,1粒,每天两次',
      },
    ],
    [
      {
        groupNumber: 3,
        name: '盐酸左西替利嗪片',
        packSpec: '5mg*14/盒',
        skinResultinfo: '',
        useDays: 3,
        drugCount: '1',
        splitUnit: '盒',
        normalUsemethod: '口服',
        normalDose: 4,
        doseUnit: '片',
        normalFrequency: '每天三次',
        normalFrequencySX: 'tid',
        otcRemark: '',
        tenantSupplierName: '广州一品红制药有限公司',
        batchNo: '0382',
        drugType: 2,
        sortStr: '口服,4片,每天三次',
      },
      {
        groupNumber: 3,
        name: '通便灵胶囊',
        packSpec: '0.25g*36/盒',
        skinResultinfo: '',
        useDays: 3,
        drugCount: '3',
        splitUnit: '瓶',
        normalUsemethod: '口服',
        normalDose: 4,
        doseUnit: '片',
        normalFrequency: '每天三次',
        normalFrequencySX: 'tid',
        otcRemark: '',
        tenantSupplierName: '湖南九典制药',
        batchNo: '0382',
        drugType: 2,
        sortStr: '口服,4片,每天三次',
      },
    ],
    [
      {
        groupNumber: 4,
        name: '新清宁片',
        packSpec: '0.31g*36/盒',
        skinResultinfo: '',
        useDays: 3,
        drugCount: '1',
        splitUnit: '盒',
        normalUsemethod: '口服',
        normalDose: 1,
        doseUnit: '片',
        normalFrequency: '每天三次',
        normalFrequencySX: 'tid',
        otcRemark: '',
        tenantSupplierName: '辽宁汉草堂中',
        batchNo: '0878',
        drugType: 2,
        sortStr: '口服,1片,每天三次',
      },
    ],
  ]

  const noTreatmentMaterialsXYData = [
    [
      {
        groupNumber: 1,
        name: '磷酸奥司他韦胶囊',
        packSpec: '6粒*1板/盒',
        skinResultinfo: '',
        useDays: 3,
        drugCount: '2',
        splitUnit: '盒',
        normalUsemethod: '口服',
        normalDose: 1,
        doseUnit: '粒',
        normalFrequency: '每天两次',
        normalFrequencySX: 'bid',
        otcRemark: '处方药',
        tenantSupplierName: '广州一品红制药有限公司',
        batchNo: '0343',
        drugType: 2,
        sortStr: '口服,1粒,每天两次',
      },
    ],
    [
      {
        groupNumber: 2,
        name: '盐酸左西替利嗪片',
        packSpec: '5mg*14/盒',
        skinResultinfo: '',
        useDays: 3,
        drugCount: '1',
        splitUnit: '盒',
        normalUsemethod: '口服',
        normalDose: 4,
        doseUnit: '片',
        normalFrequency: '每天三次',
        normalFrequencySX: 'tid',
        otcRemark: '',
        tenantSupplierName: '广州一品红制药有限公司',
        batchNo: '0382',
        drugType: 2,
        sortStr: '口服,4片,每天三次',
      },
      {
        groupNumber: 2,
        name: '通便灵胶囊',
        packSpec: '0.25g*36/盒',
        skinResultinfo: '',
        useDays: 3,
        drugCount: '3',
        splitUnit: '瓶',
        normalUsemethod: '口服',
        normalDose: 4,
        doseUnit: '片',
        normalFrequency: '每天三次',
        normalFrequencySX: 'tid',
        otcRemark: '',
        tenantSupplierName: '湖南九典制药',
        batchNo: '0382',
        drugType: 2,
        sortStr: '口服,4片,每天三次',
      },
    ],
    [
      {
        groupNumber: 3,
        name: '新清宁片',
        packSpec: '0.31g*36/盒',
        skinResultinfo: '',
        useDays: 3,
        drugCount: '1',
        splitUnit: '盒',
        normalUsemethod: '口服',
        normalDose: 1,
        doseUnit: '片',
        normalFrequency: '每天三次',
        normalFrequencySX: 'tid',
        otcRemark: '',
        tenantSupplierName: '辽宁汉草堂中',
        batchNo: '0878',
        drugType: 2,
        sortStr: '口服,1片,每天三次',
      },
    ],
  ]

  const noDrugsMaterialsXYData = [
    [
      {
        groupNumber: 1,
        name: '诊查费',
        packSpec: '',
        skinResultinfo: '',
        useDays: 1,
        drugCount: '1',
        splitUnit: '次',
        drugType: 1,
        sortStr: '',
      },
    ],
  ]

  const noDrugsTreatmentXYData = [
    [
      {
        groupNumber: 1,
        name: '玻璃离子水门汀',
        packSpec: 'A3（15g粉+8g液）',
        skinResultinfo: 'IX',
        useDays: 1,
        drugCount: '1',
        splitUnit: '套',
        normalUsemethod: '',
        normalDose: '',
        doseUnit: '',
        normalFrequency: '',
        otcRemark: '',
        tenantSupplierName: '辽宁汉草堂中',
        batchNo: '',
        drugType: 3,
        sortStr: '',
      },
    ],
  ]

  useEffect(() => {
    setPatientData({
      name: '张云月',
      sex: 2,
      age: 22,
      patientWeight: '45.5',
      ZIFEIFeeCategory: '自费',
      treatmentDepartment: '全科',
      patientId: '130284332',
      createTime: '2024-11-15',
      payTime: '2024-11-15 14:23:32',
      patientPhone: '13788888888',
      patientAddress: '浙江省杭州市滨江区三维大厦C座603',
      chiefComplaint: '浑身无力，咳嗽2天',
      allergen: '酒精过敏',
      disease: '感冒',
    })
    form.setFieldsValue({
      patientNameSexYear: true,
      itemsPerPage: 5,
      footerRemark:
        '除主诊医师特别注明外，处方仅当日有效，按卫生部规定，药房药品一经发出，不得退换。',
    })
  }, [])

  useEffect(() => {
    if (selectButtonId == '1') {
      if (changeData?.printDrugs) {
        setRecipeZYList(zyData)
      } else {
        setRecipeZYList([])
      }
    } else {
      console.log(changeData, 'changeData')
      if (
        changeData?.printDrugs &&
        changeData?.printTreatment &&
        changeData?.printMaterials
      ) {
        setRecipeXYList(xyData)
      } else if (
        changeData?.printDrugs &&
        changeData?.printTreatment &&
        !changeData?.printMaterials
      ) {
        setRecipeXYList(noMaterialsXYData)
      } else if (
        changeData?.printDrugs &&
        !changeData?.printTreatment &&
        !changeData?.printMaterials
      ) {
        setRecipeXYList(noTreatmentMaterialsXYData)
      } else if (
        changeData?.printDrugs &&
        !changeData?.printTreatment &&
        changeData?.printMaterials
      ) {
        setRecipeXYList(noTreatmentXYData)
      } else if (
        !changeData?.printDrugs &&
        changeData?.printTreatment &&
        changeData?.printMaterials
      ) {
        setRecipeXYList(noDrugsXYData)
      } else if (
        !changeData?.printDrugs &&
        !changeData?.printTreatment &&
        changeData?.printMaterials
      ) {
        setRecipeXYList(noDrugsTreatmentXYData)
      } else if (
        !changeData?.printDrugs &&
        changeData?.printTreatment &&
        !changeData?.printMaterials
      ) {
        setRecipeXYList(noDrugsMaterialsXYData)
      } else {
        setRecipeXYList([])
      }
    }
  }, [selectButtonId, changeData])

  const recipeTypeList = [
    { name: '中药处方', id: '1' },
    { name: '西药处方', id: '2' },
    { name: '精神药品处方', id: '3' },
    { name: '医毒药品处方', id: '4' },
    { name: '输注执行单', id: '5' },
    { name: '诊疗执行单', id: '6' },
  ]

  const title = (name: any) => {
    return (
      <div className={styles.titleContent}>
        <div className={styles.title}>{name}</div>
      </div>
    )
  }

  const getRecipeTitleText = (flag: any) => {
    switch (flag) {
      case '1':
        return '中药处方'
      case '2':
        return '西药处方'
      case '3':
        return '精神药品处方'
      case '4':
        return '医毒药品处方'
      case '5':
        return '输注执行单'
      case '6':
        return '诊疗执行单'
    }
  }

  useEffect(() => {
    // 获取正常医生
    dispatch(getPrintTemplateSettingUserList({ state: 1 }))
      .then(unwrapResult)
      .then((users: any) => {
        setUsers(users)
      })
      .catch(() => {
        // do nothing.
      })

    // 获取正常医生带医师的
    dispatch(getPrintTemplateSettingUserList({ state: 1, stationType: 2 }))
      .then(unwrapResult)
      .then((users: any) => {
        setElseUsers(users)
      })
      .catch(() => {
        // do nothing.
      })
  }, [])

  useEffect(() => {
    if (initialized === LoadingState.Idle) {
      dispatch(initializePrintService())
        .then(unwrapResult)
        .catch(() => {
          // do nothing.
        })
    }
    if (initialized === LoadingState.Finished) {
      dispatch(getPrinters())
    }
  }, [dispatch, initialized])

  useEffect(() => {
    getDetail()
  }, [selectButtonId])

  const getDetail = () => {
    const id = selectButtonId == '1' ? 143 : 142
    dispatch(getPrintSettingDetailAsync({ templateId: id }))
      .then(unwrapResult)
      .then((v: any) => {
        setDetail(v)
        const titlePatientMessage: any = {
          titleName: v?.headerConfig?.titleName,
          barCode: v?.headerConfig?.barCode,
          patientNameSexYear:
            v?.headerConfig?.first_line?.patientNameSexYear || true,
          patientWeight: v?.headerConfig?.first_line?.patientWeight,
          feeCategory: v?.headerConfig?.first_line?.feeCategory,
          treatmentDepartment: v?.headerConfig?.first_line?.treatmentDepartment,
          patientId: v?.headerConfig?.second_line?.patientId,
          createTime: v?.headerConfig?.second_line?.createTime,
          payTime: v?.headerConfig?.second_line?.payTime,
          patientPhone: v?.headerConfig?.third_line?.patientPhone,
          patientAddress: v?.headerConfig?.third_line?.patientAddress,
          chiefComplaint: v?.headerConfig?.fourth_line?.chiefComplaint,
          allergen: v?.headerConfig?.fourth_line?.allergen,
          disease: v?.headerConfig?.fifth_line?.disease,
          ZIFEIFeeCategory: Number(v?.headerConfig?.ZIFEIFeeCategory),
        }
        const contentMessage: any =
          selectButtonId == '1'
            ? {
                number: v?.bodyConfig?.detail_content_setting?.number,
                name: v?.bodyConfig?.detail_content_setting?.name || true,
                everyTipCount:
                  v?.bodyConfig?.detail_content_setting?.everyTipCount,
                totalTipCount:
                  v?.bodyConfig?.detail_content_setting?.totalTipCount,
                specialUsemethod:
                  v?.bodyConfig?.detail_content_setting?.specialUsemethod,
                cabineNo: v?.bodyConfig?.detail_content_setting?.cabineNo,
                normalUsemethod:
                  v?.bodyConfig?.detail_content_setting?.normalUsemethod,
                remark: v?.bodyConfig?.detail_content_setting?.remark,
                potionType: v?.bodyConfig?.detail_content_setting?.potionType,
                tip: v?.bodyConfig?.detail_content_setting?.tip,
                perTipWeight:
                  v?.bodyConfig?.detail_content_setting?.perTipWeight,
                totalWeight: v?.bodyConfig?.detail_content_setting?.totalWeight,
                perTipMoney: v?.bodyConfig?.detail_content_setting?.perTipMoney,
                decoctMoney: v?.bodyConfig?.detail_content_setting?.decoctMoney,
                totalMoney: v?.bodyConfig?.detail_content_setting?.totalMoney,
                stamp: v?.bodyConfig?.detail_content_setting?.stamp,
                printDrugs: v?.bodyConfig?.printDrugs,
              }
            : {
                printDrugs: v?.bodyConfig?.detail_type_setting?.printDrugs,
                printTreatment:
                  v?.bodyConfig?.detail_type_setting?.printTreatment,
                printMaterials:
                  v?.bodyConfig?.detail_type_setting?.printMaterials,
                groupNumber: v?.bodyConfig?.detail_content_setting?.groupNumber,
                name: v?.bodyConfig?.detail_content_setting?.name,
                spec: v?.bodyConfig?.detail_content_setting?.spec,
                useDays: v?.bodyConfig?.detail_content_setting?.useDays,
                count: v?.bodyConfig?.detail_content_setting?.count,
                normalUsemethod:
                  v?.bodyConfig?.detail_content_setting?.normalUsemethod,
                normalDose: v?.bodyConfig?.detail_content_setting?.normalDose,
                normalFrequency:
                  v?.bodyConfig?.detail_content_setting?.normalFrequency,
                remark: v?.bodyConfig?.detail_content_setting?.remark,
                tenantSupplier:
                  v?.bodyConfig?.detail_content_setting?.tenantSupplier,
                batchNo: v?.bodyConfig?.detail_content_setting?.batchNo,
                drugAmount: v?.bodyConfig?.detail_content_setting?.drugAmount,
                treatmentAmount:
                  v?.bodyConfig?.detail_content_setting?.treatmentAmount,
                itemsPerPage:
                  v?.bodyConfig?.display_specification?.itemsPerPage,
                usemethodAbbreviation:
                  v?.bodyConfig?.display_specification?.usemethodAbbreviation,
                frequencyAbbreviation:
                  v?.bodyConfig?.display_specification?.frequencyAbbreviation,
              }

        const bottomMessage: any = {
          // 医师
          showPhysician: v?.userSignConfig?.physician?.switch,
          physicianCutId: v?.userSignConfig?.physician?.doctorId,
          physicianType: v?.userSignConfig?.physician?.signatureContent,
          // 审核
          showPharmaceuticalReviewer:
            v?.userSignConfig?.pharmaceuticalReviewer?.switch,
          pharmaceuticalReviewerCutId:
            v?.userSignConfig?.pharmaceuticalReviewer?.doctorId,
          pharmaceuticalReviewerType:
            v?.userSignConfig?.pharmaceuticalReviewer?.signatureContent,
          // 调配
          showPharmacist: v?.userSignConfig?.pharmacist?.switch,
          pharmacistCutId: v?.userSignConfig?.pharmacist?.doctorId,
          pharmacistType: v?.userSignConfig?.pharmacist?.signatureContent,
          // 复核
          showReviewerPharmacist: v?.userSignConfig?.reviewerPharmacist?.switch,
          reviewerPharmacistCutId:
            v?.userSignConfig?.reviewerPharmacist?.doctorId,
          reviewerPharmacistType:
            v?.userSignConfig?.reviewerPharmacist?.signatureContent,
          // 发药
          showDispenser: v?.userSignConfig?.shipments?.switch,
          dispenserCutId: v?.userSignConfig?.shipments?.doctorId,
          dispenserType: v?.userSignConfig?.shipments?.signatureContent,
        }
        form.setFieldsValue({
          direction: v?.globalConfig?.direction,
          paperSizeUnlimited: v?.globalConfig?.paperSizeUnlimited,
          topMargin: v?.globalConfig?.topMargin,
          leftMargin: v?.globalConfig?.leftMargin,
          ...titlePatientMessage,
          ...contentMessage,
          Arrangement: v?.bodyConfig?.Arrangement,
          ...bottomMessage,
          showfooterRemark: v?.footerConfig?.showfooterRemark,
          footerRemark: v?.footerConfig?.footerRemark,
        })
        // 医师
        setPhysicianSignType(v?.userSignConfig?.physician?.useESign)
        setShowPhysicianTypeListFlag(
          v?.userSignConfig?.physician?.signatureContent == 1 ? true : false
        )
        // 审核
        setPharmaceuticalReviewerSignType(
          v?.userSignConfig?.pharmaceuticalReviewer?.useESign
        )
        setShowPharmaceuticalReviewerTypeListFlag(
          v?.userSignConfig?.pharmaceuticalReviewer?.signatureContent == 1
            ? true
            : false
        )
        // 调配
        setPharmacistSignType(v?.userSignConfig?.pharmacist?.useESign)
        setShowPharmacistTypeListFlag(
          v?.userSignConfig?.pharmacist?.signatureContent == 1 ? true : false
        )
        // 复核
        setReviewerPharmacistSignType(
          v?.userSignConfig?.reviewerPharmacist?.useESign
        )
        setShowReviewerPharmacistTypeListFlag(
          v?.userSignConfig?.reviewerPharmacist?.signatureContent == 1
            ? true
            : false
        )
        // 发药
        setDispenserSignType(v?.userSignConfig?.shipments?.useESign)
        setShowDispenserTypeListFlag(
          v?.userSignConfig?.shipments?.signatureContent == 1 ? true : false
        )
        setChangeData({
          ...titlePatientMessage,
          ...contentMessage,
          ...bottomMessage,
          showfooterRemark: v?.footerConfig?.showfooterRemark,
          footerRemark: v?.footerConfig?.footerRemark,
        })
        setPatientData({
          name: '张云月',
          sex: 2,
          age: 22,
          patientWeight: '45.5',
          treatmentDepartment: '全科',
          patientId: '130284332',
          createTime: '2024-11-15',
          payTime: '2024-11-15 14:23:32',
          patientPhone: '13788888888',
          patientAddress: '浙江省杭州市滨江区三维大厦C座603',
          chiefComplaint: '浑身无力，咳嗽2天',
          allergen: '酒精过敏',
          disease: '感冒',
          ZIFEIFeeCategory:
            v?.headerConfig?.ZIFEIFeeCategory.toString() == '0'
              ? '自费'
              : v?.headerConfig?.ZIFEIFeeCategory?.toString() == '1'
              ? ''
              : v?.headerConfig?.ZIFEIFeeCategory?.toString() == '2'
              ? '-'
              : '',
        })
      })
  }
  return (
    <div className={styles.container}>
      <Row className={styles.recipePrintContent}>
        <Col className={styles.leftContent}>
          <Col span={24} className={styles.recipeTypeButtonMain}>
            {recipeTypeList?.map((v: any) => {
              return (
                <Button
                  key={v?.id}
                  type={v?.id == selectButtonId ? 'primary' : 'ghost'}
                  style={{ marginLeft: '8px' }}
                  onClick={() => {
                    setSelectButtonId(v?.id)
                    setChangeData({})
                  }}
                >
                  {v?.name}
                </Button>
              )
            })}
          </Col>
          <div className={styles.leftBottomFormContent}>
            <Form
              form={form}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              autoComplete='off'
              onFinish={(values: any) => {
                if (values?.physicianType == 1 && !values?.physicianCutId) {
                  notification.info({
                    message: '请选择固定医师',
                  })
                  return
                }
                if (
                  values?.pharmaceuticalReviewerType == 1 &&
                  !values?.pharmaceuticalReviewerCutId
                ) {
                  notification.info({
                    message: '请选择固定审核药师',
                  })
                  return
                }
                if (values?.pharmacistType == 1 && !values?.pharmacistCutId) {
                  notification.info({
                    message: '请选择固定调配药师',
                  })
                  return
                }
                if (
                  values?.reviewerPharmacistType == 1 &&
                  !values?.reviewerPharmacistCutId
                ) {
                  notification.info({
                    message: '请选择固定复核药师',
                  })
                  return
                }
                if (values?.dispenserType == 1 && !values?.dispenserCutId) {
                  notification.info({
                    message: '请选择固定发药药师',
                  })
                  return
                }
                const params =
                  selectButtonId == '1'
                    ? {
                        id: detail?.id,
                        templateId: 143,
                        globalConfig: {
                          direction: values?.direction,
                          topMargin: Number(values?.topMargin),
                          leftMargin: Number(values?.leftMargin),
                          paperSizeUnlimited: values?.paperSizeUnlimited,
                        },
                        headerConfig: {
                          titleName: values?.titleName,
                          barCode: values?.barCode,
                          first_line: {
                            patientNameSexYear: values?.patientNameSexYear,
                            patientWeight: values?.patientWeight,
                            feeCategory: values?.feeCategory,
                            treatmentDepartment: values?.treatmentDepartment,
                          },
                          second_line: {
                            patientId: values?.patientId,
                            createTime: values?.createTime,
                            payTime: values?.payTime,
                          },
                          third_line: {
                            patientPhone: values?.patientPhone,
                            patientAddress: values?.patientAddress,
                          },
                          fourth_line: {
                            chiefComplaint: values?.chiefComplaint,
                            allergen: values?.allergen,
                          },
                          fifth_line: {
                            disease: values?.disease,
                          },
                          ZIFEIFeeCategory: values?.ZIFEIFeeCategory,
                        },
                        bodyConfig: {
                          printDrugs: values?.printDrugs,
                          detail_content_setting: {
                            number: values?.number,
                            name: values?.name,
                            everyTipCount: values?.everyTipCount,
                            totalTipCount: values?.totalTipCount,
                            specialUsemethod: values?.specialUsemethod,
                            cabineNo: values?.cabineNo,
                            normalUsemethod: values?.normalUsemethod,
                            remark: values?.remark,
                            potionType: values?.potionType,
                            tip: values?.tip,
                            perTipWeight: values?.perTipWeight,
                            totalWeight: values?.totalWeight,
                            perTipMoney: values?.perTipMoney,
                            decoctMoney: values?.decoctMoney,
                            totalMoney: values?.totalMoney,
                            stamp: values?.stamp,
                          },
                          Arrangement: values?.Arrangement,
                        },
                        userSignConfig: {
                          physician: {
                            switch: values?.showPhysician,
                            doctorId: values?.physicianCutId,
                            signatureContent: values?.physicianType,
                            useESign: physicianSignType,
                          },
                          pharmacist: {
                            switch: values?.showPharmacist,
                            doctorId: values?.pharmacistCutId,
                            signatureContent: values?.pharmacistType,
                            useESign: pharmacistSignType,
                          },
                          pharmaceuticalReviewer: {
                            switch: values?.showPharmaceuticalReviewer,
                            doctorId: values?.pharmaceuticalReviewerCutId,
                            signatureContent:
                              values?.pharmaceuticalReviewerType,
                            useESign: pharmaceuticalReviewerSignType,
                          },
                          reviewerPharmacist: {
                            switch: values?.showReviewerPharmacist,
                            doctorId: values?.reviewerPharmacistCutId,
                            signatureContent: values?.reviewerPharmacistType,
                            useESign: reviewerPharmacistSignType,
                          },
                          shipments: {
                            switch: values?.showDispenser,
                            doctorId: values?.dispenserCutId,
                            signatureContent: values?.dispenserType,
                            useESign: dispenserSignType,
                          },
                        },
                        footerConfig: {
                          showfooterRemark: values?.showfooterRemark,
                          footerRemark: values?.footerRemark,
                        },
                      }
                    : {
                        id: detail?.id,
                        templateId: 142,
                        globalConfig: {
                          direction: values?.direction,
                          topMargin: Number(values?.topMargin),
                          leftMargin: Number(values?.leftMargin),
                          paperSizeUnlimited: values?.paperSizeUnlimited,
                        },
                        headerConfig: {
                          titleName: values?.titleName,
                          barCode: values?.barCode,
                          first_line: {
                            patientNameSexYear: values?.patientNameSexYear,
                            patientWeight: values?.patientWeight,
                            feeCategory: values?.feeCategory,
                            treatmentDepartment: values?.treatmentDepartment,
                          },
                          second_line: {
                            patientId: values?.patientId,
                            createTime: values?.createTime,
                            payTime: values?.payTime,
                          },
                          third_line: {
                            patientPhone: values?.patientPhone,
                            patientAddress: values?.patientAddress,
                          },
                          fourth_line: {
                            chiefComplaint: values?.chiefComplaint,
                            allergen: values?.allergen,
                          },
                          fifth_line: {
                            disease: values?.disease,
                          },
                          ZIFEIFeeCategory: values?.ZIFEIFeeCategory,
                        },
                        bodyConfig: {
                          detail_type_setting: {
                            printDrugs: values?.printDrugs,
                            printTreatment: values?.printTreatment,
                            printMaterials: values?.printMaterials,
                          },
                          detail_content_setting: {
                            groupNumber: values?.groupNumber,
                            name: values?.name,
                            spec: values?.spec,
                            useDays: values?.useDays,
                            count: values?.count,
                            normalUsemethod: values?.normalUsemethod,
                            normalDose: values?.normalDose,
                            normalFrequency: values?.normalFrequency,
                            remark: values?.remark,
                            tenantSupplier: values?.tenantSupplier,
                            batchNo: values?.batchNo,
                            drugAmount: values?.drugAmount,
                            treatmentAmount: values?.treatmentAmount,
                          },
                          display_specification: {
                            itemsPerPage: Number(values?.itemsPerPage),
                            usemethodAbbreviation:
                              values?.usemethodAbbreviation,
                            frequencyAbbreviation:
                              values?.frequencyAbbreviation,
                          },
                        },
                        userSignConfig: {
                          physician: {
                            switch: values?.showPhysician,
                            doctorId: values?.physicianCutId,
                            signatureContent: values?.physicianType,
                            useESign: physicianSignType,
                          },
                          pharmacist: {
                            switch: values?.showPharmacist,
                            doctorId: values?.pharmacistCutId,
                            signatureContent: values?.pharmacistType,
                            useESign: pharmacistSignType,
                          },
                          pharmaceuticalReviewer: {
                            switch: values?.showPharmaceuticalReviewer,
                            doctorId: values?.pharmaceuticalReviewerCutId,
                            signatureContent:
                              values?.pharmaceuticalReviewerType,
                            useESign: pharmaceuticalReviewerSignType,
                          },
                          reviewerPharmacist: {
                            switch: values?.showReviewerPharmacist,
                            doctorId: values?.reviewerPharmacistCutId,
                            signatureContent: values?.reviewerPharmacistType,
                            useESign: reviewerPharmacistSignType,
                          },
                          shipments: {
                            switch: values?.showDispenser,
                            doctorId: values?.dispenserCutId,
                            signatureContent: values?.dispenserType,
                            useESign: dispenserSignType,
                          },
                        },
                        footerConfig: {
                          showfooterRemark: values?.showfooterRemark,
                          footerRemark: values?.footerRemark,
                        },
                      }
                setButtonLoading(true)
                dispatch(getPrintSettingSubmitAsync(params))
                  .then(unwrapResult)
                  .then((v: any) => {
                    setButtonLoading(false)
                    notification.success({
                      message: '操作成功',
                    })
                    getDetail()
                  })
              }}
            >
              {title('全局信息')}
              <Row className={styles.rowHeight}>
                <Col span={24}>
                  <Form.Item label='纸张进入方向' name='direction'>
                    <Radio.Group
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                      }}
                    >
                      <Col span={6}>
                        <Radio value={0} style={{ marginRight: '60px' }}>
                          横板
                        </Radio>
                      </Col>
                      <Col span={6}>
                        <Radio value={1}>竖版 </Radio>
                      </Col>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row className={styles.rowHeight}>
                <Col span={24}>
                  <Form.Item
                    label={'限制纸张大小'}
                    name='paperSizeUnlimited'
                    valuePropName='checked'
                  >
                    <Checkbox></Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Row className={styles.rowHeight}>
                <Col span={4}>偏移量</Col>
                <Col span={10}>
                  <Row className={styles.alignItemsCenter}>
                    上偏移量
                    <Form.Item label='' name='topMargin'>
                      <Input
                        type='number'
                        style={{ width: '90px', marginLeft: '10px' }}
                      />
                    </Form.Item>
                    （毫米）
                  </Row>
                </Col>
                <Col span={10}>
                  <Row className={styles.alignItemsCenter}>
                    左偏移量
                    <Form.Item label='' name='leftMargin'>
                      <Input
                        type='number'
                        style={{ width: '90px', marginLeft: '10px' }}
                      />
                    </Form.Item>
                    （毫米）
                  </Row>
                </Col>
              </Row>
              {title('头部信息')}
              <Row className={styles.rowHeight}>
                <Col span={4}>抬头名称</Col>
                <Col span={20}>
                  <Row className={styles.alignItemsCenter}>
                    <Form.Item label='' name='titleName'>
                      <Input
                        style={{ width: '400px' }}
                        suffix={
                          selectButtonId == '1' ? '中药处方笺' : '西药处方笺'
                        }
                        onChange={(e) => {
                          setChangeData({
                            ...changeData,
                            titleName: e?.target?.value,
                          })
                        }}
                      />
                    </Form.Item>
                    <span
                      className={styles.nameTips}
                      onClick={() => {
                        dispatch(
                          getPrintSettingsyncTenantNameAsync({
                            tenantName: changeData?.titleName,
                          })
                        )
                          .then(unwrapResult)
                          .then((v) => {
                            notification.success({
                              message: '操作成功',
                            })
                          })
                      }}
                    >
                      运用至全部
                    </span>
                  </Row>
                </Col>
              </Row>
              <Row className={styles.rowHeight}>
                <Col span={24}>
                  <Form.Item
                    label='抬头信息'
                    name='barCode'
                    valuePropName='checked'
                  >
                    <Checkbox
                      onChange={(e) => {
                        setChangeData({
                          ...changeData,
                          barCode: e?.target?.checked,
                        })
                      }}
                    >
                      就诊条码
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Row className={styles.radioWidth6}>
                <Col span={4} style={{ marginTop: '8px' }}>
                  病人信息
                </Col>
                <Col span={20}>
                  <Row>
                    <Col span={6}>
                      <Form.Item
                        name='patientNameSexYear'
                        valuePropName='checked'
                      >
                        <Checkbox disabled>姓名(性别年龄)</Checkbox>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item name='patientWeight' valuePropName='checked'>
                        <Checkbox
                          onChange={(e) => {
                            setChangeData({
                              ...changeData,
                              patientWeight: e?.target?.checked,
                            })
                          }}
                        >
                          体重
                        </Checkbox>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item name='feeCategory' valuePropName='checked'>
                        <Checkbox
                          onChange={(e) => {
                            setChangeData({
                              ...changeData,
                              feeCategory: e?.target?.checked,
                            })
                          }}
                        >
                          费别
                        </Checkbox>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        name='treatmentDepartment'
                        valuePropName='checked'
                      >
                        <Checkbox
                          onChange={(e) => {
                            setChangeData({
                              ...changeData,
                              treatmentDepartment: e?.target?.checked,
                            })
                          }}
                        >
                          科室
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <Form.Item name='patientId' valuePropName='checked'>
                        <Checkbox
                          onChange={(e) => {
                            setChangeData({
                              ...changeData,
                              patientId: e?.target?.checked,
                            })
                          }}
                        >
                          病历号
                        </Checkbox>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item name='createTime' valuePropName='checked'>
                        <Checkbox
                          onChange={(e) => {
                            setChangeData({
                              ...changeData,
                              createTime: e?.target?.checked,
                            })
                          }}
                        >
                          开方日期
                        </Checkbox>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item name='payTime' valuePropName='checked'>
                        <Checkbox
                          onChange={(e) => {
                            setChangeData({
                              ...changeData,
                              payTime: e?.target?.checked,
                            })
                          }}
                        >
                          结算日期
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <Form.Item name='patientPhone' valuePropName='checked'>
                        <Checkbox
                          onChange={(e) => {
                            setChangeData({
                              ...changeData,
                              patientPhone: e?.target?.checked,
                            })
                          }}
                        >
                          电话
                        </Checkbox>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item name='patientAddress' valuePropName='checked'>
                        <Checkbox
                          onChange={(e) => {
                            setChangeData({
                              ...changeData,
                              patientAddress: e?.target?.checked,
                            })
                          }}
                        >
                          地址
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <Form.Item name='chiefComplaint' valuePropName='checked'>
                        <Checkbox
                          onChange={(e) => {
                            setChangeData({
                              ...changeData,
                              chiefComplaint: e?.target?.checked,
                            })
                          }}
                        >
                          主诉
                        </Checkbox>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item name='allergen' valuePropName='checked'>
                        <Checkbox
                          onChange={(e) => {
                            setChangeData({
                              ...changeData,
                              allergen: e?.target?.checked,
                            })
                          }}
                        >
                          过敏史
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <Form.Item name='disease' valuePropName='checked'>
                        <Checkbox
                          onChange={(e) => {
                            setChangeData({
                              ...changeData,
                              disease: e?.target?.checked,
                            })
                          }}
                        >
                          临床诊断
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className={styles.radioWidth6}>
                <Col span={24}>
                  <Form.Item label='费别为自费' name='ZIFEIFeeCategory'>
                    <Radio.Group
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                      }}
                      onChange={(v: any) => {
                        const value = v?.target?.value
                        if (value == 0) {
                          setPatientData({
                            ...patientData,
                            ZIFEIFeeCategory: '自费',
                          })
                        } else if (value == 1) {
                          setPatientData({
                            ...patientData,
                            ZIFEIFeeCategory: '',
                          })
                        } else if (value == 2) {
                          setPatientData({
                            ...patientData,
                            ZIFEIFeeCategory: '-',
                          })
                        }
                      }}
                    >
                      <Col span={6}>
                        <Radio value={0} style={{ marginRight: '60px' }}>
                          {'自费显示为' + '"' + '自费' + '"'}
                        </Radio>
                      </Col>
                      <Col span={6}>
                        <Radio value={1} style={{ marginRight: '60px' }}>
                          自费显示为空{' '}
                        </Radio>
                      </Col>
                      <Col span={6}>
                        <Radio value={2}>{'自费显示' + '"' + '-' + '"'}</Radio>
                      </Col>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              {title('正文')}
              {selectButtonId == '1' ? (
                <>
                  <Row className={styles.radioWidth6}>
                    <Col span={24}>
                      <Form.Item label='处方明细' name='printDrugs'>
                        <Radio.Group
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                          }}
                          onChange={(v) => {
                            const value = v?.target?.value
                            if (!value) {
                              setRecipeZYList([])
                            } else {
                              setRecipeZYList(zyData)
                            }

                            setChangeData({
                              ...changeData,
                              printDrugs: value,
                            })
                          }}
                        >
                          <Col span={6}>
                            <Radio
                              value={false}
                              style={{ marginRight: '60px' }}
                            >
                              不打印
                            </Radio>
                          </Col>
                          <Col span={6}>
                            <Radio value={true}>打印 </Radio>
                          </Col>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className={styles.radioWidth6}>
                    <Col span={4} style={{ marginTop: '8px' }}>
                      内容
                    </Col>
                    <Col span={20}>
                      <Row>
                        <Col span={6}>
                          <Form.Item name='number' valuePropName='checked'>
                            <Checkbox
                              onChange={(e: any) => {
                                setChangeData({
                                  ...changeData,
                                  number: e?.target?.checked,
                                })
                              }}
                            >
                              序号
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item name='name' valuePropName='checked'>
                            <Checkbox disabled>中药名称</Checkbox>
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            name='everyTipCount'
                            valuePropName='checked'
                          >
                            <Checkbox
                              onChange={(e: any) => {
                                setChangeData({
                                  ...changeData,
                                  everyTipCount: e?.target?.checked,
                                })
                              }}
                            >
                              每帖克数
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            name='totalTipCount'
                            valuePropName='checked'
                          >
                            <Checkbox
                              onChange={(e: any) => {
                                setChangeData({
                                  ...changeData,
                                  totalTipCount: e?.target?.checked,
                                })
                              }}
                            >
                              总帖克数
                            </Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={6}>
                          <Form.Item
                            name='specialUsemethod'
                            valuePropName='checked'
                          >
                            <Checkbox
                              onChange={(e: any) => {
                                setChangeData({
                                  ...changeData,
                                  specialUsemethod: e?.target?.checked,
                                })
                              }}
                            >
                              特殊用法
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item name='cabineNo' valuePropName='checked'>
                            <Checkbox
                              onChange={(e: any) => {
                                setChangeData({
                                  ...changeData,
                                  cabineNo: e?.target?.checked,
                                })
                              }}
                            >
                              柜号
                            </Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={6}>
                          <Form.Item
                            name='normalUsemethod'
                            valuePropName='checked'
                          >
                            <Checkbox
                              onChange={(e: any) => {
                                setChangeData({
                                  ...changeData,
                                  normalUsemethod: e?.target?.checked,
                                })
                              }}
                            >
                              用法
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item name='remark' valuePropName='checked'>
                            <Checkbox
                              onChange={(e: any) => {
                                setChangeData({
                                  ...changeData,
                                  remark: e?.target?.checked,
                                })
                              }}
                            >
                              备注
                            </Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={6}>
                          <Form.Item name='potionType' valuePropName='checked'>
                            <Checkbox
                              onChange={(e: any) => {
                                setChangeData({
                                  ...changeData,
                                  potionType: e?.target?.checked,
                                })
                              }}
                            >
                              代煎类型
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item name='tip' valuePropName='checked'>
                            <Checkbox
                              onChange={(e: any) => {
                                setChangeData({
                                  ...changeData,
                                  tip: e?.target?.checked,
                                })
                              }}
                            >
                              帖数
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            name='perTipWeight'
                            valuePropName='checked'
                          >
                            <Checkbox
                              onChange={(e: any) => {
                                setChangeData({
                                  ...changeData,
                                  perTipWeight: e?.target?.checked,
                                })
                              }}
                            >
                              每帖重量
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item name='totalWeight' valuePropName='checked'>
                            <Checkbox
                              onChange={(e: any) => {
                                setChangeData({
                                  ...changeData,
                                  totalWeight: e?.target?.checked,
                                })
                              }}
                            >
                              总重量
                            </Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={6}>
                          <Form.Item name='perTipMoney' valuePropName='checked'>
                            <Checkbox
                              onChange={(e: any) => {
                                setChangeData({
                                  ...changeData,
                                  perTipMoney: e?.target?.checked,
                                })
                              }}
                            >
                              每帖药费
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item name='decoctMoney' valuePropName='checked'>
                            <Checkbox
                              onChange={(e: any) => {
                                setChangeData({
                                  ...changeData,
                                  decoctMoney: e?.target?.checked,
                                })
                              }}
                            >
                              煎药费
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item name='totalMoney' valuePropName='checked'>
                            <Checkbox
                              onChange={(e: any) => {
                                setChangeData({
                                  ...changeData,
                                  totalMoney: e?.target?.checked,
                                })
                              }}
                            >
                              合计
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item name='stamp' valuePropName='checked'>
                            <Checkbox
                              onChange={(e: any) => {
                                setChangeData({
                                  ...changeData,
                                  stamp: e?.target?.checked,
                                })
                              }}
                            >
                              收费盖章
                            </Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className={styles.radioWidth12}>
                    <Col span={24}>
                      <Form.Item label='中药竖版排列' name='Arrangement'>
                        <Radio.Group
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                          }}
                        >
                          <Col span={12}>
                            <Radio value={0} style={{ marginRight: '60px' }}>
                              大字版（一页最多68个药品）
                            </Radio>
                          </Col>
                          <Col span={12}>
                            <Radio value={1} style={{ marginRight: '60px' }}>
                              小字版（一页最多100个药品）
                            </Radio>
                          </Col>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Row className={styles.radioWidth6}>
                    <Col span={4} style={{ marginTop: '8px' }}>
                      处方明细
                    </Col>
                    <Col span={20}>
                      <Row>
                        <Col span={4}>
                          <Form.Item name='printDrugs' valuePropName='checked'>
                            <Checkbox
                              onChange={(v: any) => {
                                setChangeData({
                                  ...changeData,
                                  printDrugs: v?.target?.checked,
                                })
                                if (v?.target?.checked) {
                                  if (
                                    changeData?.printTreatment &&
                                    changeData?.printMaterials
                                  ) {
                                    setRecipeXYList(xyData)
                                  } else if (
                                    !changeData?.printTreatment &&
                                    changeData?.printMaterials
                                  ) {
                                    setRecipeXYList(noTreatmentXYData)
                                  } else if (
                                    changeData?.printTreatment &&
                                    !changeData?.printMaterials
                                  ) {
                                    setRecipeXYList(noMaterialsXYData)
                                  } else if (
                                    !changeData?.printTreatment &&
                                    !changeData?.printMaterials
                                  ) {
                                    setRecipeXYList(noTreatmentMaterialsXYData)
                                  }
                                } else {
                                  if (
                                    changeData?.printTreatment &&
                                    changeData?.printMaterials
                                  ) {
                                    setRecipeXYList(noDrugsXYData)
                                  } else if (
                                    !changeData?.printTreatment &&
                                    changeData?.printMaterials
                                  ) {
                                    setRecipeXYList(noDrugsXYData)
                                  } else if (
                                    changeData?.printTreatment &&
                                    !changeData?.printMaterials
                                  ) {
                                    setRecipeXYList(noDrugsMaterialsXYData)
                                  } else if (
                                    !changeData?.printTreatment &&
                                    !changeData?.printMaterials
                                  ) {
                                    setRecipeXYList([])
                                  }
                                }
                              }}
                            >
                              打印药品
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item
                            name='printTreatment'
                            valuePropName='checked'
                          >
                            <Checkbox
                              onChange={(v: any) => {
                                setChangeData({
                                  ...changeData,
                                  printTreatment: v?.target?.checked,
                                })
                                if (v?.target?.checked) {
                                  if (
                                    changeData?.printDrugs &&
                                    changeData?.printMaterials
                                  ) {
                                    setRecipeXYList(xyData)
                                  } else if (
                                    !changeData?.printDrugs &&
                                    changeData?.printMaterials
                                  ) {
                                    setRecipeXYList(noDrugsXYData)
                                  } else if (
                                    changeData?.printDrugs &&
                                    !changeData?.printMaterials
                                  ) {
                                    setRecipeXYList(noMaterialsXYData)
                                  } else if (
                                    !changeData?.printDrugs &&
                                    !changeData?.printMaterials
                                  ) {
                                    setRecipeXYList(noDrugsMaterialsXYData)
                                  }
                                } else {
                                  if (
                                    changeData?.printDrugs &&
                                    changeData?.printMaterials
                                  ) {
                                    setRecipeXYList(noTreatmentXYData)
                                  } else if (
                                    !changeData?.printDrugs &&
                                    changeData?.printMaterials
                                  ) {
                                    setRecipeXYList(noDrugsTreatmentXYData)
                                  } else if (
                                    changeData?.printDrugs &&
                                    !changeData?.printMaterials
                                  ) {
                                    setRecipeXYList(noTreatmentMaterialsXYData)
                                  } else if (
                                    !changeData?.printDrugs &&
                                    !changeData?.printMaterials
                                  ) {
                                    setRecipeXYList([])
                                  }
                                }
                              }}
                            >
                              打印诊疗
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item
                            name='printMaterials'
                            valuePropName='checked'
                          >
                            <Checkbox
                              onChange={(v: any) => {
                                setChangeData({
                                  ...changeData,
                                  printMaterials: v?.target?.checked,
                                })
                                if (v?.target?.checked) {
                                  if (
                                    changeData?.printDrugs &&
                                    changeData?.printTreatment
                                  ) {
                                    setRecipeXYList(xyData)
                                  } else if (
                                    !changeData?.printDrugs &&
                                    changeData?.printTreatment
                                  ) {
                                    setRecipeXYList(noDrugsXYData)
                                  } else if (
                                    changeData?.printDrugs &&
                                    !changeData?.printTreatment
                                  ) {
                                    setRecipeXYList(noTreatmentXYData)
                                  } else if (
                                    !changeData?.printDrugs &&
                                    !changeData?.printTreatment
                                  ) {
                                    setRecipeXYList(noDrugsTreatmentXYData)
                                  }
                                } else {
                                  if (
                                    changeData?.printDrugs &&
                                    changeData?.printTreatment
                                  ) {
                                    setRecipeXYList(noMaterialsXYData)
                                  } else if (
                                    !changeData?.printDrugs &&
                                    changeData?.printTreatment
                                  ) {
                                    setRecipeXYList(noDrugsMaterialsXYData)
                                  } else if (
                                    changeData?.printDrugs &&
                                    !changeData?.printTreatment
                                  ) {
                                    setRecipeXYList(noTreatmentMaterialsXYData)
                                  } else if (
                                    !changeData?.printDrugs &&
                                    !changeData?.printTreatment
                                  ) {
                                    setRecipeXYList([])
                                  }
                                }
                              }}
                            >
                              打印材料
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        {/* <Col span={4}>
                          <Form.Item name='' valuePropName='checked'>
                            <Checkbox>打印器械</Checkbox>
                          </Form.Item>
                        </Col> */}
                      </Row>
                    </Col>
                  </Row>
                  <Row className={styles.radioWidth6}>
                    <Col span={4} style={{ marginTop: '8px' }}>
                      内容
                    </Col>
                    <Col span={20}>
                      <Row>
                        <Col span={4}>
                          <Form.Item name='groupNumber' valuePropName='checked'>
                            <Checkbox
                              onChange={(v) => {
                                setChangeData({
                                  ...changeData,
                                  groupNumber: v?.target?.checked,
                                })
                              }}
                            >
                              组号
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item name='name' valuePropName='checked'>
                            <Checkbox disabled>商品名称</Checkbox>
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item name='spec' valuePropName='checked'>
                            <Checkbox
                              onChange={(v) => {
                                setChangeData({
                                  ...changeData,
                                  spec: v?.target?.checked,
                                })
                              }}
                            >
                              规格
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item name='useDays' valuePropName='checked'>
                            <Checkbox
                              onChange={(v) => {
                                setChangeData({
                                  ...changeData,
                                  useDays: v?.target?.checked,
                                })
                              }}
                            >
                              天数
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item name='count' valuePropName='checked'>
                            <Checkbox
                              onChange={(v) => {
                                setChangeData({
                                  ...changeData,
                                  count: v?.target?.checked,
                                })
                              }}
                            >
                              数量
                            </Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={4}>
                          <Form.Item
                            name='normalUsemethod'
                            valuePropName='checked'
                          >
                            <Checkbox
                              onChange={(v) => {
                                setChangeData({
                                  ...changeData,
                                  normalUsemethod: v?.target?.checked,
                                })
                              }}
                            >
                              用法
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item name='normalDose' valuePropName='checked'>
                            <Checkbox
                              onChange={(v) => {
                                setChangeData({
                                  ...changeData,
                                  normalDose: v?.target?.checked,
                                })
                              }}
                            >
                              每次用量
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item
                            name='normalFrequency'
                            valuePropName='checked'
                          >
                            <Checkbox
                              onChange={(v) => {
                                setChangeData({
                                  ...changeData,
                                  normalFrequency: v?.target?.checked,
                                })
                              }}
                            >
                              频次
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item name='remark' valuePropName='checked'>
                            <Checkbox
                              onChange={(v) => {
                                setChangeData({
                                  ...changeData,
                                  remark: v?.target?.checked,
                                })
                              }}
                            >
                              备注
                            </Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={4}>
                          <Form.Item
                            name='tenantSupplier'
                            valuePropName='checked'
                          >
                            <Checkbox
                              onChange={(v) => {
                                setChangeData({
                                  ...changeData,
                                  tenantSupplier: v?.target?.checked,
                                })
                              }}
                            >
                              厂家
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item name='batchNo' valuePropName='checked'>
                            <Checkbox
                              onChange={(v) => {
                                setChangeData({
                                  ...changeData,
                                  batchNo: v?.target?.checked,
                                })
                              }}
                            >
                              批号
                            </Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={4}>
                          <Form.Item name='drugAmount' valuePropName='checked'>
                            <Checkbox
                              onChange={(v) => {
                                setChangeData({
                                  ...changeData,
                                  drugAmount: v?.target?.checked,
                                })
                              }}
                            >
                              药费
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item
                            name='treatmentAmount'
                            valuePropName='checked'
                          >
                            <Checkbox
                              onChange={(v) => {
                                setChangeData({
                                  ...changeData,
                                  treatmentAmount: v?.target?.checked,
                                })
                              }}
                            >
                              诊疗费
                            </Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className={styles.radioWidth6}>
                    <Col span={4} style={{ marginTop: '8px' }}>
                      展示规范
                    </Col>
                    <Col span={20}>
                      <Row>
                        <div
                          className={styles.alignItemsCenter}
                          style={{ marginRight: '10px' }}
                        >
                          药品超过
                        </div>
                        <Form.Item name='itemsPerPage'>
                          <Input
                            type='number'
                            style={{ width: '200px' }}
                          ></Input>
                        </Form.Item>
                        <div
                          className={styles.alignItemsCenter}
                          style={{ marginLeft: '10px', color: '#999999' }}
                        >
                          条项目自动分页。（不填时，则会根据纸张高度自动换页）
                        </div>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <Form.Item
                            name='usemethodAbbreviation'
                            valuePropName='checked'
                          >
                            <Checkbox
                              onChange={(v) => {
                                setChangeData({
                                  ...changeData,
                                  usemethodAbbreviation: v?.target?.checked,
                                })
                              }}
                            >
                              用法使用缩写
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name='frequencyAbbreviation'
                            valuePropName='checked'
                          >
                            <Checkbox
                              onChange={(v) => {
                                setChangeData({
                                  ...changeData,
                                  frequencyAbbreviation: v?.target?.checked,
                                })
                              }}
                            >
                              频次使用缩写
                            </Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </>
              )}

              {title('尾部信息')}
              <Row className={styles.radioWidth12}>
                <Col span={4} style={{ marginTop: '7px' }}>
                  签字
                </Col>
                <Col span={20}>
                  <Row
                    style={{ height: '40px' }}
                    className={styles.alignItemsCenter}
                  >
                    <div style={{ width: '74px' }}>
                      <Form.Item name='showPhysician' valuePropName='checked'>
                        <Checkbox
                          onChange={(v) => {
                            setChangeData({
                              ...changeData,
                              showPhysician: v?.target?.checked,
                            })
                          }}
                        >
                          医师
                        </Checkbox>
                      </Form.Item>
                    </div>
                    <div
                      style={{ width: '140px', marginRight: '20px' }}
                      className={styles.alignItemsCenter}
                    >
                      <Form.Item name='physicianType' label=''>
                        <Select
                          placeholder='医师'
                          style={{ width: '140px' }}
                          onChange={(v) => {
                            if (v == 1) {
                              setShowPhysicianTypeListFlag(true)
                            } else {
                              setShowPhysicianTypeListFlag(false)
                            }
                            setChangeData({
                              ...changeData,
                              physicianType: v,
                            })
                          }}
                        >
                          <Select.Option key={0} value={0}>
                            跟随就诊医生
                          </Select.Option>
                          <Select.Option key={1} value={1}>
                            固定医生
                          </Select.Option>
                          <Select.Option key={2} value={2}>
                            不打印
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </div>
                    {showPhysicianTypeListFlag && (
                      <div
                        style={{ width: '140px', marginRight: '20px' }}
                        className={styles.alignItemsCenter}
                      >
                        <Form.Item name='physicianCutId' label=''>
                          <Select
                            placeholder='请选择医生'
                            style={{ width: '140px' }}
                          >
                            {elseUsers?.map((u: any) => (
                              <Select.Option
                                key={Number(u.id)}
                                value={Number(u.id)}
                              >
                                {u.realname}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    )}
                    {/* {detail?.openDoctorSign && ( */}
                    <div
                      className={styles.alignItemsCenter}
                      style={{ width: '130px' }}
                    >
                      <Checkbox
                        checked={physicianSignType}
                        onChange={(v) => {
                          setPhysicianSignType(v?.target.checked)
                        }}
                      >
                        使用电子签名
                      </Checkbox>
                    </div>
                    {/* )} */}
                  </Row>
                  <Row
                    style={{ height: '40px' }}
                    className={styles.alignItemsCenter}
                  >
                    <div style={{ width: '74px' }}>
                      <Form.Item
                        name='showPharmaceuticalReviewer'
                        valuePropName='checked'
                      >
                        <Checkbox
                          onChange={(v) => {
                            setChangeData({
                              ...changeData,
                              showPharmaceuticalReviewer: v?.target?.checked,
                            })
                          }}
                        >
                          审核
                        </Checkbox>
                      </Form.Item>
                    </div>
                    <div
                      style={{ width: '140px', marginRight: '20px' }}
                      className={styles.alignItemsCenter}
                    >
                      <Form.Item name='pharmaceuticalReviewerType' label=''>
                        <Select
                          placeholder='审核'
                          style={{ width: '140px' }}
                          onChange={(v) => {
                            if (v == 1) {
                              setShowPharmaceuticalReviewerTypeListFlag(true)
                            } else {
                              setShowPharmaceuticalReviewerTypeListFlag(false)
                            }
                            setChangeData({
                              ...changeData,
                              pharmaceuticalReviewerType: v,
                            })
                          }}
                        >
                          <Select.Option key={0} value={0}>
                            跟随就诊医生
                          </Select.Option>
                          <Select.Option key={1} value={1}>
                            固定医生
                          </Select.Option>
                          <Select.Option key={2} value={2}>
                            不打印
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </div>
                    {showPharmaceuticalReviewerTypeListFlag && (
                      <div
                        style={{ width: '140px', marginRight: '20px' }}
                        className={styles.alignItemsCenter}
                      >
                        <Form.Item name='pharmaceuticalReviewerCutId' label=''>
                          <Select
                            placeholder='请选择医生'
                            style={{ width: '140px' }}
                          >
                            {elseUsers?.map((u: any) => (
                              <Select.Option
                                key={Number(u.id)}
                                value={Number(u.id)}
                              >
                                {u.realname}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    )}
                    {/* {detail?.openDoctorSign && ( */}
                    <div
                      className={styles.alignItemsCenter}
                      style={{ width: '130px' }}
                    >
                      <Checkbox
                        checked={pharmaceuticalReviewerSignType}
                        onChange={(v) => {
                          setPharmaceuticalReviewerSignType(v?.target.checked)
                        }}
                      >
                        使用电子签名
                      </Checkbox>
                    </div>
                    {/* )} */}
                  </Row>
                  <Row
                    style={{ height: '40px' }}
                    className={styles.alignItemsCenter}
                  >
                    <div style={{ width: '74px' }}>
                      <Form.Item name='showPharmacist' valuePropName='checked'>
                        <Checkbox
                          onChange={(v) => {
                            setChangeData({
                              ...changeData,
                              showPharmacist: v?.target?.checked,
                            })
                          }}
                        >
                          调配
                        </Checkbox>
                      </Form.Item>
                    </div>
                    <div
                      style={{ width: '140px', marginRight: '20px' }}
                      className={styles.alignItemsCenter}
                    >
                      <Form.Item name='pharmacistType' label=''>
                        <Select
                          placeholder='调配'
                          style={{ width: '140px' }}
                          onChange={(v) => {
                            if (v == 1) {
                              setShowPharmacistTypeListFlag(true)
                            } else {
                              setShowPharmacistTypeListFlag(false)
                            }
                            setChangeData({
                              ...changeData,
                              pharmacistType: v,
                            })
                          }}
                        >
                          <Select.Option key={0} value={0}>
                            跟随就诊医生
                          </Select.Option>
                          <Select.Option key={1} value={1}>
                            固定医生
                          </Select.Option>
                          <Select.Option key={2} value={2}>
                            不打印
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </div>
                    {showPharmacistTypeListFlag && (
                      <div
                        style={{ width: '140px', marginRight: '20px' }}
                        className={styles.alignItemsCenter}
                      >
                        <Form.Item name='pharmacistCutId' label=''>
                          <Select
                            placeholder='请选择医生'
                            style={{ width: '140px' }}
                          >
                            {elseUsers?.map((u: any) => (
                              <Select.Option
                                key={Number(u.id)}
                                value={Number(u.id)}
                              >
                                {u.realname}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    )}
                    {/* {detail?.openDoctorSign && ( */}
                    <div
                      className={styles.alignItemsCenter}
                      style={{ width: '130px' }}
                    >
                      <Checkbox
                        checked={pharmacistSignType}
                        onChange={(v) => {
                          setPharmacistSignType(v?.target.checked)
                        }}
                      >
                        使用电子签名
                      </Checkbox>
                    </div>
                    {/* )} */}
                  </Row>
                  <Row
                    style={{ height: '40px' }}
                    className={styles.alignItemsCenter}
                  >
                    <div style={{ width: '74px' }}>
                      <Form.Item
                        name='showReviewerPharmacist'
                        valuePropName='checked'
                      >
                        <Checkbox
                          onChange={(v) => {
                            setChangeData({
                              ...changeData,
                              showReviewerPharmacist: v?.target?.checked,
                            })
                          }}
                        >
                          复核
                        </Checkbox>
                      </Form.Item>
                    </div>
                    <div
                      style={{ width: '140px', marginRight: '20px' }}
                      className={styles.alignItemsCenter}
                    >
                      <Form.Item name='reviewerPharmacistType' label=''>
                        <Select
                          placeholder='复核'
                          style={{ width: '140px' }}
                          onChange={(v) => {
                            if (v == 1) {
                              setShowReviewerPharmacistTypeListFlag(true)
                            } else {
                              setShowReviewerPharmacistTypeListFlag(false)
                            }
                            setChangeData({
                              ...changeData,
                              reviewerPharmacistType: v,
                            })
                          }}
                        >
                          <Select.Option key={0} value={0}>
                            跟随就诊医生
                          </Select.Option>
                          <Select.Option key={1} value={1}>
                            固定医生
                          </Select.Option>
                          <Select.Option key={2} value={2}>
                            不打印
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </div>
                    {showReviewerPharmacistTypeListFlag && (
                      <div
                        style={{ width: '140px', marginRight: '20px' }}
                        className={styles.alignItemsCenter}
                      >
                        <Form.Item name='reviewerPharmacistCutId' label=''>
                          <Select
                            placeholder='请选择医生'
                            style={{ width: '140px' }}
                          >
                            {elseUsers?.map((u: any) => (
                              <Select.Option
                                key={Number(u.id)}
                                value={Number(u.id)}
                              >
                                {u.realname}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    )}
                    {/* {detail?.openDoctorSign && ( */}
                    <div
                      className={styles.alignItemsCenter}
                      style={{ width: '130px' }}
                    >
                      <Checkbox
                        checked={reviewerPharmacistSignType}
                        onChange={(v) => {
                          setReviewerPharmacistSignType(v?.target.checked)
                        }}
                      >
                        使用电子签名
                      </Checkbox>
                    </div>
                    {/* )} */}
                  </Row>
                  <Row
                    style={{ height: '40px' }}
                    className={styles.alignItemsCenter}
                  >
                    <div style={{ width: '74px' }}>
                      <Form.Item name='showDispenser' valuePropName='checked'>
                        <Checkbox
                          onChange={(v) => {
                            setChangeData({
                              ...changeData,
                              showDispenser: v?.target?.checked,
                            })
                          }}
                        >
                          发药
                        </Checkbox>
                      </Form.Item>
                    </div>
                    <div
                      style={{ width: '140px', marginRight: '20px' }}
                      className={styles.alignItemsCenter}
                    >
                      <Form.Item name='dispenserType' label=''>
                        <Select
                          placeholder='发药'
                          style={{ width: '140px' }}
                          onChange={(v) => {
                            if (v == 1) {
                              setShowDispenserTypeListFlag(true)
                            } else {
                              setShowDispenserTypeListFlag(false)
                            }
                            setChangeData({
                              ...changeData,
                              dispenserType: v,
                            })
                          }}
                        >
                          <Select.Option key={0} value={0}>
                            跟随就诊医生
                          </Select.Option>
                          <Select.Option key={1} value={1}>
                            固定医生
                          </Select.Option>
                          <Select.Option key={2} value={2}>
                            不打印
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </div>
                    {showDispenserTypeListFlag && (
                      <div
                        style={{ width: '140px', marginRight: '20px' }}
                        className={styles.alignItemsCenter}
                      >
                        <Form.Item name='dispenserCutId' label=''>
                          <Select
                            placeholder='请选择医生'
                            style={{ width: '140px' }}
                          >
                            {elseUsers?.map((u: any) => (
                              <Select.Option
                                key={Number(u.id)}
                                value={Number(u.id)}
                              >
                                {u.realname}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    )}
                    {/* {detail?.openDoctorSign && ( */}
                    <div
                      className={styles.alignItemsCenter}
                      style={{ width: '130px' }}
                    >
                      <Checkbox
                        checked={dispenserSignType}
                        onChange={(v) => {
                          setDispenserSignType(v?.target.checked)
                        }}
                      >
                        使用电子签名
                      </Checkbox>
                    </div>
                    {/* )} */}
                  </Row>
                </Col>
              </Row>
              <Row className={styles.radioWidth12}>
                <Col span={4} style={{ marginTop: '8px' }}>
                  尾页备注
                </Col>
                <Col span={20}>
                  <Row className={styles.alignItemsCenter}>
                    <Col span={24}>
                      <Form.Item
                        label=''
                        name='showfooterRemark'
                        valuePropName='checked'
                      >
                        <Checkbox
                          onChange={(v) => {
                            setChangeData({
                              ...changeData,
                              showfooterRemark: v?.target?.checked,
                            })
                          }}
                        >
                          展示
                        </Checkbox>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label='' name='footerRemark'>
                        <TextArea
                          maxLength={40}
                          showCount
                          onChange={(v) => {
                            setChangeData({
                              ...changeData,
                              footerRemark: v?.target?.value,
                            })
                          }}
                        ></TextArea>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '30px 0',
                  position: 'fixed',
                  bottom: 0,
                  left: '29%',
                }}
              >
                <Button
                  loading={buttonLoading}
                  style={{ background: '#027AFF', color: '#FFFFFF' }}
                  htmlType='submit'
                >
                  保存
                </Button>
              </Row>
            </Form>
          </div>
        </Col>
        <Col className={styles.rightContent}>
          <div className={styles.rightContentTitle}>
            <span>{getRecipeTitleText(selectButtonId)}</span>
            <div className={styles.titleButton}>
              <Button
                type={'primary'}
                style={{ marginRight: '8px' }}
                onClick={async () => {
                  const params =
                    selectButtonId == '1'
                      ? { templateCode: 'ChnMedicine', setupFlag: true }
                      : {
                          templateCode: 'WesternMedicine',
                          setupFlag: true,
                        }
                  await dispatch(getPrintSettingEditOrYLAsync(params))
                }}
              >
                编辑
              </Button>
              <Button
                type={'primary'}
                style={{ marginRight: '8px' }}
                onClick={async () => {
                  const params =
                    selectButtonId == '1'
                      ? { templateCode: 'ChnMedicine', setupFlag: false }
                      : {
                          templateCode: 'WesternMedicine',
                          setupFlag: false,
                        }
                  await dispatch(getPrintSettingEditOrYLAsync(params))
                }}
              >
                打印效果
              </Button>
            </div>
          </div>
          <div className={styles.rightContentMain}>
            <div className={styles.rightContentMainCenterContent}>
              <div
                className={styles.rightContentMainCenterContentTenantNameTitle}
              >
                {changeData?.titleName}
              </div>
              <div className={styles.rightContentMainCenterContentSubTitle}>
                <Col
                  span={4}
                  className={styles.rightContentMainCenterContentSubTitleLeft}
                >
                  <div
                    className={
                      styles.rightContentMainCenterContentSubTitleLeftTop
                    }
                  >
                    {changeData?.barCode ? (
                      <div style={{ height: '38px' }}>
                        <img src={txmImg}></img>
                        <div>130284332</div>
                      </div>
                    ) : (
                      <div style={{ height: '38px' }}></div>
                    )}
                  </div>
                  <div>NO: 374632</div>
                </Col>
                <Col
                  span={16}
                  className={styles.rightContentMainCenterContentSubTitleCenter}
                >
                  {getRecipeTitleText(selectButtonId)}笺
                </Col>
                <Col
                  span={4}
                  className={styles.rightContentMainCenterContentSubTitleRight}
                >
                  <div
                    className={
                      selectButtonId == '1'
                        ? styles.rightContentMainCenterContentSubTitleRightMainBorder
                        : styles.rightContentMainCenterContentSubTitleRightMain
                    }
                  >
                    {selectButtonId == '1' && (
                      <>
                        <div>委外</div>
                        <div style={{ marginTop: '-6px' }}>代煎</div>
                      </>
                    )}
                  </div>
                </Col>
              </div>
              <div className={styles.rightContentMainCenterContentRecipe}>
                <Row gutter={10} className={styles.TextStyleLine}>
                  <Col span={8} className={styles.TextStyle}>
                    <div className={styles.titleTwoTextStyle}>姓名：</div>
                    <div className={styles.valueTextStyle}>
                      <span style={{ marginRight: '4px', fontWeight: 'bold' }}>
                        {patientData?.name}
                      </span>
                      <span style={{ marginRight: '4px' }}>
                        {getGenderName(patientData?.sex)}
                      </span>
                      <span>{patientData?.age}岁</span>
                    </div>
                  </Col>
                  {changeData?.patientWeight && (
                    <Col span={5} className={styles.TextStyle}>
                      <div className={styles.titleTwoTextStyle}>体重：</div>
                      <div className={styles.valueTextStyle}>
                        {patientData?.patientWeight}KG
                      </div>
                    </Col>
                  )}
                  {changeData?.feeCategory && (
                    <Col span={5} className={styles.TextStyle}>
                      <div className={styles.titleTwoTextStyle}>费别：</div>
                      <div className={styles.valueTextStyle}>
                        {patientData?.ZIFEIFeeCategory}
                      </div>
                    </Col>
                  )}
                  {changeData?.treatmentDepartment && (
                    <Col span={6} className={styles.TextStyle}>
                      <div className={styles.titleTwoTextStyle}>科室：</div>
                      <div className={styles.valueTextStyle}>
                        {patientData?.treatmentDepartment}
                      </div>
                    </Col>
                  )}
                </Row>
                <Row
                  gutter={10}
                  className={
                    (changeData?.patientId ||
                      changeData?.createTime ||
                      changeData?.payTime) &&
                    styles.TextStyleLine
                  }
                >
                  {changeData?.patientId && (
                    <Col span={8} className={styles.TextStyle}>
                      <div className={styles.titleThreeTextStyle}>病历号：</div>
                      <div className={styles.valueTextStyle}>
                        {patientData?.patientId}
                      </div>
                    </Col>
                  )}
                  {changeData?.createTime && (
                    <Col span={7} className={styles.TextStyle}>
                      <div className={styles.titleFourTextStyle}>
                        开方日期：
                      </div>
                      <div className={styles.valueTextStyle}>
                        {patientData?.createTime
                          ? moment(patientData?.createTime).format(
                              DateTimeFormatSimpleOnly
                            )
                          : ''}
                      </div>
                    </Col>
                  )}
                  {changeData?.payTime && (
                    <Col span={9} className={styles.TextStyle}>
                      <div className={styles.titleFourTextStyle}>
                        结算日期：
                      </div>
                      <div className={styles.valueTextStyle}>
                        {patientData?.payTime
                          ? moment(patientData?.payTime).format(
                              DateTimeFormatSimpleOnlyOne
                            )
                          : ''}
                      </div>
                    </Col>
                  )}
                </Row>
                <Row
                  gutter={10}
                  className={
                    (changeData?.patientPhone || changeData?.patientAddress) &&
                    styles.TextStyleLine
                  }
                >
                  {changeData?.patientPhone && (
                    <Col span={8} className={styles.TextStyle}>
                      <div className={styles.titleTwoTextStyle}>电话：</div>
                      <div className={styles.valueTextStyle}>
                        {patientData?.patientPhone}
                      </div>
                    </Col>
                  )}
                  {changeData?.patientAddress && (
                    <Col span={16} className={styles.TextStyle}>
                      <div className={styles.titleTwoTextStyle}>地址：</div>
                      <div className={styles.valueTextStyle}>
                        {patientData?.patientAddress}
                      </div>
                    </Col>
                  )}
                </Row>
                <Row
                  gutter={10}
                  className={
                    (changeData?.chiefComplaint || changeData?.allergen) &&
                    styles.TextStyleLine
                  }
                >
                  {changeData?.chiefComplaint && (
                    <Col span={15} className={styles.TextStyle}>
                      <div className={styles.titleTwoTextStyle}>主诉：</div>
                      <div className={styles.valueTextStyle}>
                        {patientData?.chiefComplaint}
                      </div>
                    </Col>
                  )}
                  {changeData?.allergen && (
                    <Col span={9} className={styles.TextStyle}>
                      <div className={styles.titleThreeTextStyle}>过敏史：</div>
                      <div className={styles.valueTextStyle}>
                        {patientData?.allergen}
                      </div>
                    </Col>
                  )}
                </Row>
                <Row
                  gutter={16}
                  className={changeData?.disease && styles.TextStyleLine}
                >
                  {changeData?.disease && (
                    <Col span={24} className={styles.TextStyle}>
                      <div className={styles.titleFourTextStyle}>
                        临床诊断：
                      </div>
                      <div className={styles.valueTextStyle}>
                        {patientData?.disease}
                      </div>
                    </Col>
                  )}
                </Row>
              </div>
              <div className={styles.rightContentMainCenterContentRecipeList}>
                {selectButtonId == '1' ? (
                  <>
                    <div>
                      <div className={styles.rpsStyle}>Rp.</div>
                      {/* {recipeZYList?.length && changeData?.printDrugs ? (
                        <div className={styles.rpsStyle}>Rp.</div>
                      ) : (
                        ''
                      )} */}
                      <Row className={styles.recipeZYList}>
                        {recipeZYList?.map((v: any, index: any) => {
                          return (
                            <Col
                              span={
                                !changeData?.number &&
                                !changeData?.specialUsemethod &&
                                !changeData?.cabineNo &&
                                !changeData?.totalTipCount
                                  ? 6
                                  : 12
                              }
                              key={index}
                              style={{ marginBottom: '4px' }}
                            >
                              {changeData?.number && (
                                <span style={{ fontWeight: 'bold' }}>
                                  {v?.groupNumber}.
                                </span>
                              )}

                              <span
                                style={{
                                  fontWeight: 'bold',
                                  marginRight: '4px',
                                }}
                              >
                                {v?.name}
                              </span>
                              {changeData?.everyTipCount && (
                                <span>
                                  {v?.countPerTip}
                                  {v?.splitUnit}
                                </span>
                              )}
                              {changeData?.totalTipCount && (
                                <span>
                                  ({v?.drugCount}
                                  {v?.splitUnit})
                                </span>
                              )}
                              {changeData?.specialUsemethod && (
                                <span
                                  style={{
                                    fontSize: '12px',
                                    fontWeight: 'bold',
                                    marginLeft:
                                      v?.normalUsemethod == '无' ||
                                      v?.normalUsemethod == '煎服'
                                        ? '0px'
                                        : '4px',
                                  }}
                                >
                                  {v?.normalUsemethod == '无' ||
                                  v?.normalUsemethod == '煎服'
                                    ? ''
                                    : '(' + v?.normalUsemethod + ')'}
                                </span>
                              )}
                              {changeData?.cabineNo && (
                                <span
                                  style={{
                                    color: '#555555',
                                    marginLeft: '4px',
                                  }}
                                >
                                  {v?.cabineNo}
                                </span>
                              )}
                            </Col>
                          )
                        })}
                      </Row>
                    </div>
                  </>
                ) : (
                  <Col span={24}>
                    <div>
                      {/* {recipeXYList?.length ? ( */}
                      <div className={styles.rpsStyle}>Rp.</div>
                      {/* ) : (
                        ''
                      )} */}
                      {recipeXYList?.map((value: any, index: any) => {
                        return value?.map((v: any, j: any) => {
                          return (
                            <>
                              <div className={styles.recipeXYListOneLIneMain}>
                                <div
                                  className={styles.recipeXYListOneLIneMainLeft}
                                >
                                  <div
                                    style={{
                                      fontWeight: 'bold',
                                      marginRight: '4px',
                                      display: 'flex',
                                    }}
                                  >
                                    {changeData?.groupNumber &&
                                      (j == 0 ? (
                                        <div style={{ width: '14px' }}>
                                          {v?.groupNumber}.
                                        </div>
                                      ) : (
                                        <div style={{ width: '14px' }}></div>
                                      ))}
                                    <span>{v?.name}</span>
                                  </div>
                                  {changeData?.spec && (
                                    <span>{v?.packSpec}</span>
                                  )}

                                  {changeData?.remark && (
                                    <span>{v?.skinResultinfo}</span>
                                  )}
                                </div>

                                <div
                                  className={
                                    styles.recipeXYListOneLIneMainRight
                                  }
                                >
                                  {changeData?.useDays && (
                                    <span>
                                      {v?.useDays}天{changeData?.count && '/'}
                                    </span>
                                  )}
                                  {changeData?.count && (
                                    <span>
                                      {v?.drugCount}
                                      {v?.splitUnit}
                                    </span>
                                  )}
                                </div>
                              </div>
                              {((changeData?.tenantSupplier &&
                                value?.[j]?.tenantSupplierName !=
                                  value?.[j + 1]?.tenantSupplierName) ||
                                (v?.drugType != 1 &&
                                  value?.[j]?.sortStr !=
                                    value?.[j + 1]?.sortStr)) && (
                                <div
                                  className={styles?.recipeXYListTwoLIneMain}
                                >
                                  {(changeData?.normalUsemethod ||
                                    changeData?.normalDose ||
                                    changeData?.normalFrequency) &&
                                    '用法：'}
                                  {changeData?.normalUsemethod && (
                                    <span
                                      className={
                                        styles.recipeXYListTwoLIneMainLeft
                                      }
                                    >
                                      {changeData?.usemethodAbbreviation
                                        ? pinyin(v?.normalUsemethod, {
                                            pattern: 'first',
                                            toneType: 'none',
                                            separator: '',
                                          }).toUpperCase()
                                        : v?.normalUsemethod}
                                    </span>
                                  )}
                                  {changeData?.normalDose && (
                                    <span
                                      className={
                                        styles.recipeXYListTwoLIneMainCenter
                                      }
                                    >
                                      每次{v?.normalDose}
                                      {v?.doseUnit}
                                    </span>
                                  )}
                                  {changeData?.normalFrequency && (
                                    <span
                                      className={
                                        styles.recipeXYListTwoLIneMainRight
                                      }
                                    >
                                      {changeData?.frequencyAbbreviation
                                        ? v?.normalFrequencySX
                                        : v?.normalFrequency}
                                    </span>
                                  )}
                                </div>
                              )}
                              {((changeData?.tenantSupplier &&
                                value?.[j]?.tenantSupplierName !=
                                  value?.[j + 1]?.tenantSupplierName) ||
                                value?.[j]?.sortStr !=
                                  value?.[j + 1]?.sortStr) && (
                                <div
                                  className={styles?.recipeXYListThreeLIneMain}
                                >
                                  {(v?.drugType == 2 || v?.drugType == 3) &&
                                    changeData?.tenantSupplier && (
                                      <span
                                        className={
                                          styles.recipeXYListThreeLIneMainLeft
                                        }
                                      >
                                        厂家：{v?.tenantSupplierName}
                                      </span>
                                    )}
                                  {v?.drugType == 2 && changeData?.batchNo && (
                                    <span
                                      className={
                                        styles.recipeXYListThreeLIneMainRight
                                      }
                                    >
                                      批号：{v?.batchNo}
                                    </span>
                                  )}
                                </div>
                              )}
                            </>
                          )
                        })
                      })}
                    </div>
                  </Col>
                )}
                <div className={styles.footerContent}>
                  {selectButtonId == '1' ? (
                    <div>
                      {changeData?.normalUsemethod && (
                        <Row style={{ fontSize: '12px', margin: '4px 0' }}>
                          <span>
                            用法：水煮煎，内服，温服，饭后服，每天1剂，每剂1次，1次120ml
                          </span>
                        </Row>
                      )}
                      {changeData?.remark && (
                        <Row style={{ fontSize: '12px', marginBottom: '4px' }}>
                          <span>
                            备注：
                            <span style={{ fontWeight: 'bold' }}>
                              内容备注内容
                            </span>
                          </span>
                        </Row>
                      )}
                      {/* <Row
                      style={{
                        fontSize: '12px',
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: '12px',
                      }}
                    >
                      <span>------接下页------</span>
                    </Row> */}
                      {(changeData?.potionType ||
                        changeData?.tip ||
                        changeData?.perTipWeight ||
                        changeData?.totalWeight ||
                        changeData?.perTipMoney ||
                        changeData?.decoctMoney ||
                        changeData?.totalMoney ||
                        changeData?.stamp) && (
                        <Row style={{ display: 'flex' }}>
                          <Col span={19}>
                            <Row
                              style={{
                                fontSize: '14px',
                                display: 'flex',
                                color: '#101010',
                              }}
                            >
                              {changeData?.potionType && (
                                <div
                                  style={{
                                    textDecoration: 'underline',
                                    fontStyle: 'italic',
                                  }}
                                >
                                  委外代装液体代煎
                                  {changeData?.potionType && <span>，</span>}
                                </div>
                              )}
                              {changeData?.tip && (
                                <span>
                                  共7帖{changeData?.tip && <span>，</span>}
                                </span>
                              )}
                              {changeData?.perTipWeight && (
                                <span>
                                  每帖重量：18g
                                  {changeData?.perTipWeight &&
                                    changeData?.totalWeight && <span>，</span>}
                                </span>
                              )}
                              {changeData?.totalWeight && (
                                <span>总重量：156g</span>
                              )}
                            </Row>
                            <Row
                              style={{
                                fontSize: '14px',
                                display: 'flex',
                                color: '#101010',
                                marginBottom: '4px',
                                marginTop: '2px',
                              }}
                            >
                              {changeData?.perTipMoney && (
                                <span>
                                  每帖药费：43.23
                                  {changeData?.perTipMoney && <span>，</span>}
                                </span>
                              )}

                              {changeData?.decoctMoney && (
                                <span>
                                  煎药费：14
                                  {changeData?.decoctMoney &&
                                    changeData?.totalMoney && <span>，</span>}
                                </span>
                              )}
                              {changeData?.totalMoney && (
                                <span>合计：316.61</span>
                              )}
                            </Row>
                          </Col>
                          {changeData?.stamp && (
                            <Col
                              span={5}
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <div className={styles.paymentGZStyle}>
                                <>
                                  <div>收费</div>
                                  <div style={{ marginTop: '-6px' }}>盖章</div>
                                </>
                              </div>
                            </Col>
                          )}
                        </Row>
                      )}
                      <div className={styles.borderBottomStyle}></div>
                    </div>
                  ) : (
                    <div>
                      {/* <Row
                      style={{
                        fontSize: '12px',
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: '12px',
                      }}
                    >
                      <span>------接下页------</span>
                    </Row> */}
                      <Row
                        style={{
                          display: 'flex',
                          marginBottom: '4px',
                        }}
                      >
                        {changeData?.drugAmount && <span>药费：293.23，</span>}
                        {changeData?.treatmentAmount && (
                          <span>诊疗费：58.00</span>
                        )}
                      </Row>
                      <div className={styles.borderBottomStyle}></div>
                    </div>
                  )}
                  <Row className={styles.doctorBottomContent}>
                    {changeData?.showPhysician && (
                      <div className={styles.doctorContent}>
                        <span className={styles.doctorContentTitle}>
                          医师：
                        </span>
                        <span className={styles.doctorBorder}>
                          {changeData?.physicianType != 2 ? '张云舒' : ''}
                        </span>
                      </div>
                    )}
                    {changeData?.showPharmaceuticalReviewer && (
                      <div className={styles.doctorContent}>
                        <span className={styles.doctorContentTitle}>
                          审核：
                        </span>
                        <span className={styles.doctorBorder}>
                          {changeData?.pharmaceuticalReviewerType != 2
                            ? '黄今宴'
                            : ''}
                        </span>
                      </div>
                    )}
                    {changeData?.showPharmacist && (
                      <div className={styles.doctorContent}>
                        <span className={styles.doctorContentTitle}>
                          调配：
                        </span>
                        <span className={styles.doctorBorder}>
                          {changeData?.pharmacistType != 2 ? '陈儒星' : ''}
                        </span>
                      </div>
                    )}
                    {changeData?.showReviewerPharmacist && (
                      <div className={styles.doctorContent}>
                        <span className={styles.doctorContentTitle}>
                          复核：
                        </span>
                        <div className={styles.doctorBorder}>
                          {changeData?.reviewerPharmacistType != 2
                            ? '陆含韵'
                            : ''}
                        </div>
                      </div>
                    )}
                    {changeData?.showDispenser && (
                      <div className={styles.doctorContent}>
                        <span className={styles.doctorContentTitle}>
                          发药：
                        </span>
                        <span className={styles.doctorBorder}>
                          {changeData?.dispenserType != 2 ? '黄慧佳' : ''}
                        </span>
                      </div>
                    )}
                  </Row>
                  {changeData?.showfooterRemark && (
                    <Row className={styles.doctorTips}>
                      {changeData?.footerRemark}
                    </Row>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.rightContentBottom}>
            说明：上面预览仅展示不作为最终打印效果，可点击右上角的“打印效果“查看打印效果
          </div>
        </Col>
      </Row>
    </div>
  )
}
