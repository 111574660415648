/*
 * @Descripttion:
 * @version:
 * @Author: sujun
 * @email: 1373842098@qq.com
 * @Date: 2022-01-04 13:58:27
 * @LastEditors: linxi
 */
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Modal,
  ModalProps,
  Form,
  Steps,
  Col,
  Radio,
  Row,
  Space,
  Select,
  Input,
  Cascader as Cascaded,
  Button,
  notification,
  Switch,
} from 'antd'
import React, { ReactElement, useEffect, useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectCredentials } from '../../../../app/applicationSlice'
import { RootDispatch } from '../../../../app/store'
import { replaceMakeInfoParams } from '../../../../services/recipe'
import { ThemeContext } from '../../../../theme/ThemeContext'
import { stringTrim } from '../../../../utils/StringUtils'
import { pageExamineAsync } from '../../../examinetreat/examinetreatSlice'
import { getAddress } from '../../../registration/register/registerSlice'
import {
  getDecoctingPersonalSettingAsync,
  getPotionpreferencesSettingAsync,
  getReplaceMakeSettingDetail,
} from '../recipeEditorSlice'
import styles from './RecipeItemModal.module.css'
interface ReplaceMakeSettingModalProps {
  selectSupplierId?: any
  selectId?: any
  selectPotionType?: any
  recipeId?: number
  patientId?: number
  recipeCategory?: number
  isSetting?: boolean
  isOpenReplaceMake?: any
  onOk?: () => void
  onCancel?: () => void
  visible?: boolean
}

export const DecoctingPersionalSettingModal = ({
  selectSupplierId,
  selectId,
  selectPotionType,
  recipeId,
  patientId,
  recipeCategory,
  isSetting,
  isOpenReplaceMake,
  onOk,
  onCancel,
  visible,
}: ReplaceMakeSettingModalProps & ModalProps): ReactElement => {
  const { Step } = Steps

  const dispatch = useDispatch<RootDispatch>()

  const credentials = useSelector(selectCredentials)

  const theme = useContext(ThemeContext)

  const [needMoney, setNeedMoney] = useState(true)

  const [form] = Form.useForm()

  const [stepValue, setStepValue] = useState(0)

  const [
    herbalMedicineFactoryList,
    setHerbalMedicineFactoryList,
  ] = useState<any>([]) //代煎饮片厂

  const [spuLIst, setSpuLIst] = useState<any>([])

  const [machiningTypeOptions, setMachiningTypeOptions] = useState<any>([]) // 代煎方式选项

  const [potionMoneyList, setPotionMoneyList] = useState<any>() //费用类别

  const [allAreaList, setAllAreaList] = useState<any[]>()

  const [potionTypeValue, setPotionTypeValue] = useState<any>()

  const [submitValue, setSubmitValue] = useState<any>()

  const [potionMoneyId, setPotionMoneyId] = useState<any>('')

  const [autoBackFillPhoneFlag, setAutoBackFillPhoneFlag] = useState('1')

  const [supplierId, setSupplierId] = useState<any>()

  const rowProps = {
    gutter: 2,
  }

  const colProps = { span: 24 }

  const inputSelectLayout = {
    style: { width: '284px' },
  }
  const RadioLayout = {
    style: { width: '200px', lineHeight: '34px' },
  }

  useEffect(() => {
    if (visible) {
      localStorage.setItem('notUseGlobalKeyboardAction', 'true')
      setStepValue(0)
      const provinceCode = credentials?.tenantProvinceCode
        ? Number(credentials?.tenantProvinceCode + '000000')
        : ''
      const cityCode = credentials?.tenantCityCode
        ? Number(credentials?.tenantCityCode + '000000')
        : ''
      const countryCode = credentials?.tenantCountyCode
        ? Number(credentials?.tenantCountyCode + '000000')
        : ''

      const tenantAddress = credentials?.tenantAddress
        ? credentials?.tenantAddress
        : ''
      form.setFieldsValue({
        area: provinceCode
          ? [Number(provinceCode), Number(cityCode), Number(countryCode)]
          : [],
        includeDecoctionFee: false,
        addressType: 0,
        usePatientPhone: '1',
      })
      setNeedMoney(false)
    }
  }, [visible])

  useEffect(() => {
    if (visible) {
      if (!needMoney)
        form.setFieldsValue({
          potionMoney: '',
        })
    }
  }, [visible, needMoney])

  useEffect(() => {
    if (visible) {
      if (selectPotionType?.toString()) {
        setPotionTypeValue(selectPotionType)
      }
    } else {
      setPotionTypeValue('')
    }
  }, [visible, selectPotionType])

  useEffect(() => {
    if (visible) {
      const data: replaceMakeInfoParams = {
        decoctionType:
          location.pathname === '/retail' //(快速开单) && recipeCategory === 3 //(中药3)
            ? 2
            : recipeCategory === 3 //(中药3)
            ? 0
            : 1,
        patientId: patientId,
        tenantRecipeId: recipeId!,
      }
      dispatch(getReplaceMakeSettingDetail({ ...data }))
        .then(unwrapResult)
        .then((res: any) => {
          // 判断有没有代煎饮片厂
          if (res?.supplierRespVOS?.length) {
            // 获取代煎饮片厂
            setHerbalMedicineFactoryList([...res?.supplierRespVOS])
            setSpuLIst([...res?.supplierRespVOS])
          }
        })

      // 获取费用类别
      getExamineAsync()

      // 获取收货地区
      getAddressList()
    }
  }, [visible, recipeCategory, selectSupplierId])

  const getExamineAsync = () => {
    dispatch(
      // 获取费用类别
      pageExamineAsync({
        current: 1,
        size: 1000,
        state: 1,
        billingCategory: '97',
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        setPotionMoneyList(res.records)
        // setPotionMoneyId(res.records?.[0]?.id)
      })
  }

  const getAddressList = () => {
    // 获取收货地区
    dispatch(getAddress())
      .then(unwrapResult)
      .then((res: any) => {
        setAllAreaList(res)
      })
  }

  const loadData = (selectedOptions: any) => {
    const targetOption = selectedOptions[selectedOptions.length - 1]
    targetOption.loading = true
  }

  useEffect(() => {
    if (visible) {
      // 获取本地代煎数据回填信息
      if (selectId > 0) {
        getPotionpreferencesData(selectId)
      }
    }
  }, [visible, selectId])

  const getPotionpreferencesData = (v?: any) => {
    dispatch(getPotionpreferencesSettingAsync(v))
      .then(unwrapResult)
      .then((v: any) => {
        if (JSON.stringify(v) != '{}') {
          MachiningTypeData(spuLIst, v?.supplierId)
          setSupplierId(selectSupplierId)
          const flag =
            v?.treatmentServiceCatalogId && v?.treatmentServiceCatalogId != -1
          form.setFieldsValue({
            ...v,
            potionMoney: flag ? v?.treatmentServiceCatalogId : '',
            area:
              v?.province && v?.city && v?.region
                ? [Number(v?.province), Number(v?.city), Number(v?.region)]
                : [],
            // carryType: v?.carryTypeDefault,
            addressType: v?.addressType == 1 ? 1 : 0,
            usePatientPhone: v?.usePatientPhone?.toString(),
          })
          setNeedMoney(flag ? true : false)
          if (v?.usePatientPhone?.toString() == '0') {
            setAutoBackFillPhoneFlag(v?.usePatientPhone?.toString())
          }
          if (
            !v?.treatmentServiceCatalogId ||
            v?.treatmentServiceCatalogId == -1
          ) {
            form.setFieldsValue({
              potionMoney: '',
            })
          }
        }
      })
  }

  useEffect(() => {
    if (visible) {
      if (spuLIst?.length && form.getFieldValue('supplierId')) {
        MachiningTypeData(spuLIst, form.getFieldValue('supplierId'))
      }
    }
  }, [visible, spuLIst])

  const reset = () => {
    form.resetFields()
    setMachiningTypeOptions([])
    setSupplierId('')
    localStorage.setItem('notUseGlobalKeyboardAction', 'false')
  }

  // 加工方式获取方法
  const MachiningTypeData = (supplierRespVOS?: any, supplierId?: any) => {
    const TypeOptions = supplierRespVOS?.find(
      (item: any) => supplierId == item?.id
    )
    TypeOptions && setMachiningTypeOptions([...TypeOptions?.outPotionDictList])
  }
  return (
    <Modal
      visible={visible}
      title='代煎偏好设置'
      width={820}
      maskClosable={false}
      footer={null}
      className={styles.persionalSetting}
      destroyOnClose
      style={{ overflow: 'hidden', padding: '0' }}
      bodyStyle={{ padding: '10px 15px 0 15px', height: 480, overflow: 'auto' }}
      onCancel={(e) => {
        onCancel && onCancel(e)
        reset()
      }}
      onOk={(e) => {
        onOk && onOk()
        reset()
      }}
    >
      {selectPotionType == 0 ? (
        <div style={{ margin: '10px 200px 24px 200px' }}>
          <Steps size='small' current={stepValue} labelPlacement={'vertical'}>
            <Step title='代煎方式设置' />
            <Step title='代煎详情设置' />
          </Steps>
        </div>
      ) : (
        <div style={{ margin: '10px 60px 24px 60px' }}>
          <Steps size='small' current={stepValue} labelPlacement={'vertical'}>
            <Step title='代煎方式设置' />
            <Step title='代煎详情设置' />
            <Step title='配送信息设置' />
          </Steps>
        </div>
      )}

      <Form
        form={form}
        colon={false}
        autoComplete='off'
        labelAlign='left'
        onFinish={(value) => {
          const potionMoney = potionMoneyList?.find((item: any) => {
            return item?.id == (potionMoneyId || value?.potionMoney)
          })?.retailPrice
          const potionMoneys = potionMoneyList?.find((item: any) => {
            return item?.id == (potionMoneyId || submitValue?.potionMoney)
          })?.retailPrice
          const id =
            potionTypeValue?.toString() == 0
              ? potionMoneyId || value?.potionMoney
              : potionMoneyId || submitValue?.potionMoney
          const submitParam =
            potionTypeValue?.toString() == 0
              ? {
                  ...submitValue,
                  ...value,
                  potionMoney: potionMoney,
                  potionType: potionTypeValue,
                  treatmentServiceCatalogId: id || '0',
                  defaultSelected: 0,
                  includeDecoctionFee: value?.includeDecoctionFee ? 1 : 0,
                }
              : {
                  ...submitValue,
                  ...value,
                  potionType: potionTypeValue,
                  province: value?.area?.[0],
                  city: value?.area?.[1],
                  region: value?.area?.[2],
                  treatmentServiceCatalogId: id || '0',
                  defaultSelected: 0,
                  potionMoney: potionMoneys,
                  includeDecoctionFee: submitValue?.includeDecoctionFee ? 1 : 0,
                  area: null,
                  autoAddress: null,
                }
          setSubmitValue({ ...submitValue, ...value })
          dispatch(getDecoctingPersonalSettingAsync({ ...submitParam }))
            .then(unwrapResult)
            .then((v: any) => {
              notification.success({
                message: '操作成功',
              })
              onOk && onOk()
              reset()
            })
        }}
      >
        {selectPotionType == 0 ? (
          <>
            <div
              style={{
                height: '330px',
                overflowY: 'auto',
                overflowX: 'hidden',
              }}
            >
              {stepValue == 0 && (
                <>
                  <Row {...rowProps}>
                    <Col {...colProps}>
                      <Form.Item label='默认加工方式' name='machiningType'>
                        <Radio.Group>
                          <Radio value={4}>本地代煎袋装液体</Radio>
                          <Radio value={5}>本地代煎颗粒剂</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
              {stepValue == 1 && (
                <>
                  <Row {...rowProps}>
                    <Col {...colProps}>
                      <Form.Item label='代煎费' name='includeDecoctionFee'>
                        <Switch
                          checkedChildren='开启'
                          unCheckedChildren='关闭'
                          checked={needMoney}
                          onChange={(v) => {
                            setNeedMoney(v)
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row {...rowProps}>
                    <Col {...colProps}>
                      <Form.Item label='默认选中费用类别' name='potionMoney'>
                        <Select
                          {...inputSelectLayout}
                          placeholder='请选择费用类别'
                          onSelect={(v: any, s: any) => {
                            setPotionMoneyId(s.key)
                          }}
                        >
                          {potionMoneyList?.map((el: any) => (
                            <Select.Option key={el.id} value={el.id}>
                              {el.name}: {el.retailPrice} 元
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </>
        ) : (
          <div
            style={{ height: '330px', overflowY: 'auto', overflowX: 'hidden' }}
          >
            {stepValue == 0 && potionTypeValue != 2 && (
              <>
                {!isOpenReplaceMake ? (
                  <Row {...rowProps}>
                    <Col {...colProps}>
                      <Form.Item
                        label='选择委外饮片厂'
                        name='supplierId'
                        style={{
                          position: 'relative',
                        }}
                      >
                        <>
                          您尚未开通委外代煎服务，请联系客服申请开通，了解
                          <a
                            href='javascript:;'
                            style={{
                              color: '#0076FF',
                              textDecoration: 'underline',
                            }}
                            onClick={() => {
                              window.open(
                                window.location.origin + '/productCenter',
                                '_blank'
                              )
                            }}
                          >
                            更多详情
                          </a>{' '}
                          <br />
                          客服联系方式：
                          <span
                            style={{
                              color: '#0076FF',
                            }}
                          >
                            17300983625
                          </span>
                        </>
                      </Form.Item>
                    </Col>
                  </Row>
                ) : (
                  <Row {...rowProps}>
                    <Col {...colProps}>
                      <Form.Item
                        label='选择委外饮片厂'
                        name='supplierId'
                        style={{
                          position: 'relative',
                        }}
                      >
                        <Radio.Group
                          onChange={(e) => {
                            const { value } = e.target
                            MachiningTypeData(spuLIst, value)
                            setSupplierId(value)
                            form.setFieldsValue({
                              machiningType: '',
                              potionMoney: '',
                              specification: '',
                              remark: '',
                            })
                            setNeedMoney(false)
                          }}
                        >
                          {herbalMedicineFactoryList?.map((v: any) => (
                            <Radio value={v.id} key={v.id} {...RadioLayout}>
                              <Row align='middle'>{v.supplierName}</Row>
                              {v.signedType == 0 && (
                                <div
                                  style={{
                                    backgroundColor: '#DDEDFF',
                                    padding: '5px 10px',
                                  }}
                                >
                                  {v.signedMsg}
                                </div>
                              )}
                            </Radio>
                          ))}
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                )}
                <Row {...rowProps}>
                  <Col {...colProps}>
                    <Form.Item label='默认委外加工方式' name='machiningType'>
                      <Radio.Group
                        onChange={(e) => {
                          return
                        }}
                      >
                        {machiningTypeOptions?.length ? (
                          machiningTypeOptions?.map((v: any) => (
                            <Radio
                              value={v.typeVal}
                              key={v.id}
                              {...RadioLayout}
                            >
                              {v.name}
                            </Radio>
                          ))
                        ) : (
                          <div>请先选择委外饮片厂</div>
                        )}
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
            {stepValue == 1 && potionTypeValue != 2 && (
              <>
                <Row {...rowProps}>
                  <Col {...colProps}>
                    <Form.Item label='代煎费' name='includeDecoctionFee'>
                      <Switch
                        checkedChildren='开启'
                        unCheckedChildren='关闭'
                        checked={needMoney}
                        onChange={(v) => {
                          setNeedMoney(v)
                          // 代煎费关闭的情况下,费用类别置灰并且清空
                          if (v) {
                            form.setFieldsValue({
                              potionMoney: potionMoneyList?.[0]?.id,
                            })
                          } else {
                            form.setFieldsValue({
                              potionMoney: '',
                            })
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row {...rowProps}>
                  <Col {...colProps}>
                    <Form.Item label='默认选中费用类别' name='potionMoney'>
                      <Select
                        disabled={!needMoney}
                        {...inputSelectLayout}
                        placeholder='请选择费用类别'
                        onSelect={(v: any, s: any) => {
                          setPotionMoneyId(s.key)
                        }}
                      >
                        {potionMoneyList?.map((el: any) => (
                          <Select.Option key={el.id} value={el.id}>
                            {el.name}: {el.retailPrice} 元
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row {...rowProps}>
                  <Col {...colProps}>
                    <Form.Item label='默认包装规格' name='specification'>
                      <Input
                        maxLength={15}
                        placeholder='请输入包装规格'
                        {...inputSelectLayout}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row {...rowProps}>
                  <Col {...colProps}>
                    <Form.Item label='默认备注' name='remark'>
                      <Input
                        maxLength={30}
                        placeholder='请输入备注'
                        style={{ width: '600px' }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
            {stepValue == 2 && potionTypeValue != 2 && (
              <>
                <Row {...rowProps}>
                  <Col {...colProps}>
                    <Form.Item label='默认收货位置' name='addressType'>
                      <Select
                        placeholder='请选择默认收货位置'
                        {...inputSelectLayout}
                      >
                        <Select.Option key={0} value={0}>
                          患者地址
                        </Select.Option>
                        <Select.Option key={1} value={1}>
                          诊所自取
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                {/* <Row {...rowProps}>
                  <Col {...colProps}>
                    <Form.Item label='默认配送方式' name='carryTypeDefault'>
                      <Select
                        placeholder='请选择默认配送方式'
                        {...inputSelectLayout}
                      >
                        {carryTypeNumType?.map((v: any) => (
                          <Select.Option key={v} value={v}>
                            {getCarryTypeName(v)}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row> */}
                <Row {...rowProps}>
                  <Col {...colProps}>
                    <Row>
                      <Form.Item label='诊所收货地址' name='area'>
                        <Cascaded
                          {...inputSelectLayout}
                          loadData={loadData}
                          changeOnSelect
                          options={allAreaList}
                          onChange={() => {
                            return
                          }}
                          placeholder='请选择省市区'
                        />
                      </Form.Item>
                      {/* <Form.Item
                        label=''
                        name='phone'
                        rules={[
                          {
                            pattern: /^1[3|4|5|6|7|8|9]\d{9}$/,
                            message: '请输入正确的联系电话',
                          },
                        ]}
                      >
                        <Input
                          type='number'
                          allowClear
                          placeholder='请输入联系电话'
                          style={{ marginLeft: '20px', width: '294px' }}
                        /> 
                      </Form.Item>*/}
                    </Row>
                  </Col>
                </Row>
                <Row {...rowProps}>
                  <Col {...colProps}>
                    <Form.Item
                      label=' '
                      name='recipientAddress'
                      getValueFromEvent={stringTrim}
                    >
                      <Input
                        maxLength={30}
                        placeholder='请输入收货地址'
                        style={{ width: '600px' }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row {...rowProps}>
                  <Col {...colProps}>
                    <Form.Item label='患者收货地址' name='autoAddress'>
                      <Radio.Group defaultValue={0}>
                        <Radio value={0}>自动填充患者上一次的收货地址</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
                <Row {...rowProps}>
                  <Col {...colProps}>
                    <Row>
                      <Form.Item label='默认联系电话' name='usePatientPhone'>
                        <Radio.Group
                          defaultValue={'1'}
                          onChange={(v: any) => {
                            setAutoBackFillPhoneFlag(v?.target?.value)
                          }}
                        >
                          <Radio value={'1'}>自动填充患者的联系电话</Radio>
                          <Radio value={'0'}>使用诊所电话</Radio>
                        </Radio.Group>
                      </Form.Item>
                      {autoBackFillPhoneFlag == '0' && (
                        <Form.Item
                          label=''
                          name='phone'
                          rules={[
                            {
                              pattern: /^1[3|4|5|6|7|8|9]\d{9}$/,
                              message: '请输入正确的联系电话',
                            },
                          ]}
                        >
                          <Input
                            type='number'
                            allowClear
                            placeholder='请输入联系电话'
                            style={{ marginLeft: '20px', width: '262px' }}
                          />
                        </Form.Item>
                      )}
                    </Row>
                  </Col>
                </Row>
              </>
            )}
          </div>
        )}
        {selectPotionType == 0 ? (
          <Row
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              style={{ marginRight: '10px' }}
              onClick={(e) => {
                onCancel && onCancel()
                reset()
              }}
            >
              取消
            </Button>
            {stepValue == 1 && (
              <Button
                type='primary'
                style={{ marginRight: '10px' }}
                onClick={() => {
                  if (stepValue == 1) {
                    setStepValue(0)
                  }
                }}
              >
                上一步
              </Button>
            )}
            {stepValue == 0 && (
              <Button
                type='primary'
                onClick={() => {
                  const machiningTypes =
                    form.getFieldValue('machiningType') == -1
                      ? ''
                      : form.getFieldValue('machiningType')
                  if (stepValue == 0) {
                    if (!machiningTypes) {
                      notification.info({ message: '请选择默认委外加工方式' })
                    } else {
                      setStepValue(1)
                      setSubmitValue(form.getFieldsValue())
                    }
                  }
                }}
              >
                下一步
              </Button>
            )}
            {stepValue == 1 && (
              <Button type='primary' htmlType='submit'>
                确定
              </Button>
            )}
          </Row>
        ) : (
          <Row
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              style={{ marginRight: '10px' }}
              onClick={(e) => {
                onCancel && onCancel()
                reset()
              }}
            >
              取消
            </Button>
            {stepValue != 0 && (
              <Button
                type='primary'
                style={{ marginRight: '10px' }}
                onClick={() => {
                  if (stepValue == 1) {
                    setStepValue(0)
                  } else {
                    setStepValue(1)
                  }
                }}
              >
                上一步
              </Button>
            )}
            {stepValue != 2 && (
              <Button
                type='primary'
                disabled={!isOpenReplaceMake ? true : false}
                onClick={() => {
                  const machiningTypes =
                    form.getFieldValue('machiningType') == -1
                      ? ''
                      : form.getFieldValue('machiningType')
                  if (stepValue == 0) {
                    if (!machiningTypes) {
                      notification.info({ message: '请选择默认委外加工方式' })
                    } else {
                      setStepValue(1)
                      setSubmitValue(form.getFieldsValue())
                    }
                  } else {
                    setStepValue(2)
                    setSubmitValue({ ...submitValue, ...form.getFieldsValue() })
                  }
                }}
              >
                下一步
              </Button>
            )}
            {stepValue == 2 && (
              <Button type='primary' htmlType='submit'>
                确定
              </Button>
            )}
          </Row>
        )}
      </Form>
    </Modal>
  )
}
