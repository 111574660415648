/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-07-01 11:03:39
 * @LastEditors: linxi
 * @LastEditTime: 2025-01-09 10:13:50
 */
import { API_PREFIX } from './constant'
import { ContentType, MultiPartFormData, Request } from './request'

// 获取聚合支付列表

export interface getJuhePageFnParams {
  current: number
  size: number
  param: number
  insuranceCode: string
  payMethod: number
  registrationTimeHead: string
  registrationTimeTail: string
  treatmentDepartmentId: number
}
export function getJuhePageFn(params: getJuhePageFnParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/paylist`,
    method: 'GET',
    params,
  }
}

// 排班-排班列表
export interface getArrangeListPageFnParams {
  dateTime: string[]
  stationCategory: number
}
export function getArrangeListPageFn(
  params: getArrangeListPageFnParams
): Request {
  return {
    url: `${API_PREFIX}/blade-appointment/appointmentSchedule/selectSchedulingList`,
    method: 'post',
    body: params,
  }
}
// 排班-新增/编辑医生班次
export interface addArrangeListPageFnParams {
  doctorId: number
  id?: number
  scheduleDate: string
  shiftIds: number
}

export function addArrangeListPageFn(
  params: addArrangeListPageFnParams
): Request {
  return {
    url: `${API_PREFIX}/blade-appointment/appointmentSchedule/appointmentScheduleSave`,
    method: 'post',
    body: params,
  }
}
// 排班-重置排班列表
export interface resetArrangeListPageFnParams {
  startTime: string | undefined
  endTime: string | undefined
}

export function resetArrangeListPageFn(
  params: resetArrangeListPageFnParams
): Request {
  return {
    url: `${API_PREFIX}/blade-appointment/appointmentSchedule/resetScheduling`,
    method: 'post',
    body: params,
  }
}
// 排班-获取医生排班信息
export interface getDoctorInfoFnParams {
  doctorId?: number
  id?: any
  scheduleDate?:any
}

export function getDoctorInfoFn(params: getDoctorInfoFnParams): Request {
  return {
    url: `${API_PREFIX}/blade-appointment/appointmentSchedule/getAppointmentSchedule`,
    method: 'GET',
    params,
  }
}
// 排班-删除医生排班信息
export interface deleteDoctorInfoFnParams {
  doctorId: number
  scheduleDate?:any
}

export function deleteDoctorInfoFn(params: deleteDoctorInfoFnParams): Request {
  return {
    url: `${API_PREFIX}/blade-appointment/appointmentSchedule/getAppointmentSchedule`,
    method: 'DELETE',
    params,
  }
}
// 排班-copy 上周医生排班信息
export interface copyLatWeekDoctorInfoFnParams {
  lastWeekEndTime: string
  lastWeekStartTime: string
  weekEndTime: string | undefined
  weekStartTime: string | undefined
}

export function copyLatWeekDoctorInfoFn(
  params: copyLatWeekDoctorInfoFnParams
): Request {
  return {
    url: `${API_PREFIX}/blade-appointment/appointmentSchedule/copyScheduling`,
    method: 'POST',
    body: params,
  }
}
// 排班-设置-班次列表
export interface getRankingListPageParams {
  current: number
  size: number
  id: number
}

export function getRankingListPageFn(
  params: getRankingListPageParams
): Request {
  return {
    url: `${API_PREFIX}/blade-appointment/appointmentSchedule/appointmentShiftList`,
    method: 'GET',
    params,
  }
}
// 排班-设置-新增编辑班次
export interface editRankingListPageParams {
  id?: number
  shiftName: string
  shiftStartTime: string
  shiftEndTime: string
}

export function editRankingListPageFn(
  params: editRankingListPageParams
): Request {
  return {
    url: `${API_PREFIX}/blade-appointment/appointmentSchedule/appointmentShifSave`,
    method: 'POST',
    body: params,
  }
}

// 排班-设置-删除班次
export interface deleteRankingListParams {
  id?: number
}

export function deleteRankingListFn(params: deleteRankingListParams): Request {
  return {
    url: `${API_PREFIX}/blade-appointment/appointmentSchedule/appointmentShifDelete`,
    method: 'DELETE',
    params,
  }
}
// 自动排班-详情

export function automaticSchedulingDetail(): Request {
  return {
    url: `${API_PREFIX}/blade-appointment/appointmentautoschedule/detail`,
    method: 'GET',
  }
}

// 自动排班-设置

export interface AutomaticSchedulingSettingParams {
  day?: any
  switchFlag?: any
  autoSettingId?: number
}

export function automaticSchedulingSetting(
  body: AutomaticSchedulingSettingParams
): Request {
  return {
    url: `${API_PREFIX}/blade-appointment/appointmentautoschedule/autoAppointment`,
    method: 'POST',
    body,
  }
}
