/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-07-27 11:54:49
 * @LastEditors: linxi
 * @LastEditTime: 2024-03-28 15:42:01
 */
import {
  Button,
  Col,
  Row,
  Image,
  Modal,
  Space,
  Radio,
  Badge,
  notification,
  Tooltip,
  Input
} from 'antd'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { Label } from '../../compnents/widgets/Label'
import { ThemeContext } from '../../theme/ThemeContext'
import styles from './productCenter.module.css'
import Qrcode from 'qrcode.react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import {
  consultAsync,
  getProductCenterInfoYearAsync,
  getSendMessage,
  payStatusAsync,
  productCenterInfoAsync,
  QRCodeAsync,
  renewDetailsAsync,
} from './productCenterSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import { FireIcon } from '../../compnents/icons/Icons'
import {
  ArrowsAltOutlined,
  ExclamationCircleOutlined,
  RightCircleOutlined,
} from '@ant-design/icons'
import { queryDataIndex } from 'echarts/types/src/util/model'
import { useHistory, useLocation } from 'react-router-dom'
import { useQuery } from '../../utils/HttpUtils'
import { removeBreadcrumbs, traceRoute } from '../../layouts/layoutSlice'
import imageUrl from './images/back.png'
import { getMonthYear } from '../../models/productCenter'
import {
  removeCredentials,
  selectExpirationFlag,
  selectUserDepartmentId,
  selectUserId,
} from '../../app/applicationSlice'
import { dateFormat } from '../../models/appointment'
import moment from 'moment'
import { resetDiagnosis } from '../treatment/diagnosis/diagnosisSlice'

let productTimer: any = null

export const ProductCenter = (): ReactElement => {
  const location: any = useLocation()

  const history = useHistory()

  const expirationFlag = useSelector(selectExpirationFlag)

  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const departmentId = useSelector(selectUserDepartmentId)

  const userId = useSelector(selectUserId)

  const [consultVisible, setConsultVisible] = useState(false)

  const [againVisible, setAgainVisible] = useState(false)

  const [noAgainVisible, setNoAgainVisible] = useState(false)

  const [QRCodeVisible, setQRCodeVisible] = useState(false)

  const [IsExpireVisible, setIsExpireVisible] = useState(false)

  const [scanUrl, setScanUrl] = useState('')

  const [serverEndTime, setserverEndTime] = useState('')

  const [residueDay, setresidueDay] = useState('') // 剩余天数u

  const [productList, setProductList] = useState<any>([])

  const [deviceList, setDeviceList] = useState<any>([])

  const [renewDetail, setRenewDetail] = useState<any>({})

  const [renewDetailYear, setRenewDetailYear] = useState<any>()

  const [price, setPrice] = useState<any>({})

  const [value, setValue] = useState()

  const [payType, setpayType] = useState(1)

  const [id, setId] = useState(0)

  const [renewalPriceId, setRenewalPriceId] = useState<any>()

  const [durationNum, setdurationNum] = useState(1)

  const [payInfo, setPayInfo] = useState<any>({})

  const [loading, setLoading] = useState(false)

  const [email, setEmail] = useState('')

  const getPage = () => {
    dispatch(productCenterInfoAsync())
      .then(unwrapResult)
      .then((res: any) => {
        if (productList.length != res.list.length) {
          setProductList(res.list)
        }
        if (deviceList.length != res.deviceList.length) {
          setDeviceList(res.deviceList)
        }
        setserverEndTime(res.serverEndTime)
        setresidueDay(res.residueDay)
      })
  }

  useEffect(() => {
    getPage()
    return () => {
      productTimer && clearInterval(productTimer)
      productTimer = null
    }
  }, [])

  useEffect(() => {
    setdurationNum(price.year)
  }, [price])

  useEffect(() => {
    if (againVisible) {
      dispatch(renewDetailsAsync())
        .then(unwrapResult)
        .then((res: any) => {
          setRenewDetail(res)
          setEmail(res.email)
          setId(res.productId)
        })
    }
  }, [againVisible])

  useEffect(() => {
    if (expirationFlag) {
      setIsExpireVisible(true)
    } else {
      setIsExpireVisible(false)
    }
  }, [expirationFlag])

  useEffect(() => {
    if (!QRCodeVisible) return
    setLoading(true)
    productTimer && clearInterval(productTimer)
    //id = 1时，产品为服务续费，服务续费需要手动拼装
    if (id == 1) {
      getQrcode(1, durationNum, '系统续费', 0)
    } else {
      const product = productList.filter((v: any) => v.id == id)[0]
      getQrcode(
        product?.id,
        durationNum,
        product?.productName,
        product?.durationUnit
      )
    }

    // getQrcode()
    return () => {
      productTimer && clearInterval(productTimer)
      productTimer = null
    }
  }, [payType])

  const getQrcode = (
    productId = 0,
    num = 0,
    productName = '',
    durationUnit = 0
  ) => {
    const data = {
      payType,
      productId: productId || id,
      renewalPriceId: renewalPriceId,
      durationNum: num || durationNum,
    }
    dispatch(
      QRCodeAsync({
        ...data,
        email: email,
        renewalPriceId: renewalPriceId ? renewalPriceId : undefined,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        setAgainVisible(false)
        res.durationNum = durationNum
        setPayInfo(res)
        res.productName = productName
        res.durationUnit = getUnit(durationUnit)
        setQRCodeVisible(true)
        productTimer && clearInterval(productTimer)
        setLoading(false)
        productTimer = setInterval(() => {
          dispatch(payStatusAsync(res.orderId))
            .then(unwrapResult)
            .then((res: any) => {
              if (res.status == 1) {
                //如果是购买电子签名的，支付完成后直接跳转到电子签名功能页
                if (data.productId == 12) {
                  productTimer && clearInterval(productTimer)
                  productTimer = null
                  getPage()
                  setQRCodeVisible(false)
                  notification.success({
                    message: '支付成功,页面正在跳转',
                    onClose: () => {
                      setRenewalPriceId('')
                    },
                  })
                  dispatch(
                    traceRoute({
                      name: '个人管理',
                      path: '/personalManagement',
                      state: {
                        payStatus: '1',
                      },
                    })
                  )
                } else {
                  notification.success({
                    message: '支付成功',
                    onClose: () => {
                      setRenewalPriceId('')
                    },
                  })
                  productTimer && clearInterval(productTimer)
                  productTimer = null
                  getPage()
                  setQRCodeVisible(false)
                  traceRoute({
                    name: '产品中心',
                    path: '/productCenter',
                  })
                }
              }
            })
        }, 1000)
      })
      .finally(() => {
        setLoading(false)
      })
    // }
  }
  useEffect(() => {
    productTimer && clearInterval(productTimer)
    productTimer = null
    if (!QRCodeVisible) {
      setpayType(1)
      setRenewalPriceId('')
    }
  }, [QRCodeVisible])

  const againClose = () => {
    setPayInfo({})
    setAgainVisible(false)
  }

  const qrCodeClose = () => {
    setPayInfo({})
    setQRCodeVisible(false)
  }

  const getUnit = (v: number): string => {
    switch (v) {
      case 0:
        return '年'
      case 1:
        return '天'
      case 2:
        return '次'
      case 3:
        return '个'
      case 4:
        return '1个医生'
      default:
        return '年'
    }
  }
  useEffect(() => {
    if (!productList || productList.length <= 0) {
      return
    }
    // const autoClick = query.get("autoClick") as string
    const autoClick = location?.state?.autoClick
    if (autoClick == 'ysqm') {
      const product = productList.filter(
        (v: any) => v.productName == '医生处方个性签名'
      )[0]

      getQrcode(product?.id, 1, product?.productName, product?.durationUnit)
      setdurationNum(1)
      setId(product?.id)
      setdurationNum(1)
    }
  }, [productList])

  const logout = () => {
    history.push('/')
    dispatch(removeCredentials())
    dispatch(removeBreadcrumbs())
    dispatch(resetDiagnosis())
    const openYL: any = localStorage.getItem('openYL')
    const storedepartmentId =
      localStorage.getItem('registrationDepartmentId') || departmentId
    const storeuserId = localStorage.getItem('registrationDoctorId') || userId
    const session_print = localStorage?.getItem('registrationPrint') || 'false'
    const template_offsets = localStorage?.getItem('template_offsets') || ''
    const default_printers = localStorage?.getItem('default_printers') || ''
    const PRINTKEYS = localStorage?.getItem('PRINTKEYS') || ''
    // 是否组合支付的标志
    const combinationChargeFlag: any = localStorage.getItem(
      'combinationChargeFlag'
    )
    // 忽略库存等的标志
    const session_local_settings_key =
      localStorage?.getItem('local_settings_key') || ''

    const session_charge =
      localStorage?.getItem('registrationCharge') || 'false'
    // 偏好设置
    const session_preferencesSettingData: any =
      localStorage?.getItem('preferencesSettingData') || ''
    const session_tabNum: any = localStorage?.getItem('tabNum')
    localStorage.clear()
    localStorage.setItem('registrationPrint', session_print)
    localStorage.setItem('registrationCharge', session_charge)
    localStorage.setItem('registrationDepartmentId', storedepartmentId)
    localStorage.setItem('registrationDoctorId', storeuserId)
    localStorage.setItem('combinationChargeFlag', combinationChargeFlag)
    // 忽略库存等的标志
    localStorage.setItem('local_settings_key', session_local_settings_key)

    localStorage.setItem(
      'preferencesSettingData',
      session_preferencesSettingData
    )
    localStorage.setItem('template_offsets', template_offsets)
    localStorage.setItem('default_printers', default_printers)
    localStorage.setItem('openYL', openYL)
    // localStorage.setItem('PRINTKEYS', PRINTKEYS)
    sessionStorage.clear()
    if (session_tabNum && session_tabNum != 'null') {
      localStorage.setItem('tabNum', session_tabNum)
    }
    window.location.reload() // 强制页面刷新
  }

  return (
    <div style={{ padding: '0 5px', height: '100%', overflow: 'auto' }}>
      <div className={styles.card}>
        <Label label='服务信息' style={{ marginTop: 20, marginBottom: 10 }} />
        <div className={styles.cardContainer}>
          到期时间：{serverEndTime}{' '}
          <span style={{ color: '#f00' }}>{residueDay}</span>
          <Button
            type='primary'
            onClick={() => {
              dispatch(getProductCenterInfoYearAsync())
                .then(unwrapResult)
                .then((res: any) => {
                  if (res?.length) {
                    setRenewDetailYear(res)
                    setRenewalPriceId(res?.[0]?.id)
                    setValue(res?.[0]?.year)
                    setPrice(res?.[0])
                    setAgainVisible(true)
                  } else {
                    setNoAgainVisible(true)
                  }
                })
            }}
            style={{ marginLeft: 30 }}
          >
            延期续费
          </Button>
        </div>
      </div>
      <div className={styles.productCard}>
        <Label label='软件服务' style={{ marginTop: 20, marginBottom: 10 }} />
        <div className={styles.productCardContainer}>
          <Row>
            {!!productList?.length &&
              productList.map((v: any) => (
                <Col
                  className={styles.cardItem}
                  key={v.id}
                  style={{
                    cursor: v?.productName == '代煎服务' ? 'pointer' : '',
                  }}
                  onClick={() => {
                    if (v?.productName == '代煎服务') {
                      window.open(
                        'https://mp.weixin.qq.com/s?__biz=MzkxOTMyODY2Nw==&mid=2247485055&idx=1&sn=bae4976bd148f0f7352fe02bb830f2ad&chksm=c1a2848bf6d50d9da10949a1d79b83864c0f3323e025b9c79dcb42f313631104e2201f62935b#rd',
                        '_blank'
                      )
                    }
                  }}
                >
                  <Button
                    type='primary'
                    className={styles.cardAction}
                    onClick={(e) => {
                      e.stopPropagation()
                      setId(v.id)
                      setRenewalPriceId('')
                      if (v.buttonType == 0) {
                        // dispatch(consultAsync(v.id)).then(unwrapResult).then(() => {
                        setConsultVisible(true)
                        // })
                      } else {
                        setdurationNum(1)
                        getQrcode(v.id, 1, v.productName, v.durationUnit)
                      }
                    }}
                  >
                    {v.buttonType == 0 ? '点击咨询' : '立即购买'}
                  </Button>
                  <Row>
                    <Col span={4}>
                      <img style={{ width: 55, height: 55 }} src={v?.iconUrl} />
                    </Col>
                    <Col span={20}>
                      <header className={styles.header}>
                        {v?.productName} &nbsp;
                        {(v?.productName == '代煎服务' ||
                          v?.productName == '医生处方个性签名' ||
                            v?.productName == '高级医美病历') && <FireIcon />}
                      </header>
                      <section className={styles.section}>
                        <Tooltip title={v?.remark}>{v?.remark}</Tooltip>
                      </section>
                      <footer
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div>
                          {v.showPriceStatus == 1 ? (
                            <span
                              className={styles.price}
                              style={{ fontSize: 16 }}
                            >
                              价格具体咨询
                            </span>
                          ) : (
                            <>
                              <span className={styles.price}>
                                {v?.defaultPrice}
                              </span>
                              元/{getUnit(v?.durationUnit)}
                            </>
                          )}
                        </div>
                        {v?.productName == '代煎服务' && (
                          <div className={styles.seeDetail}>
                            查看详情 <RightCircleOutlined />
                          </div>
                        )}
                      </footer>
                    </Col>
                  </Row>
                </Col>
              ))}
          </Row>
        </div>
        <Label label='硬件设备' style={{ marginTop: 20, marginBottom: 10 }} />
        <div className={styles.productCardContainer}>
          <Row>
            {!!deviceList?.length &&
              deviceList.map((v: any) => (
                <Col className={styles.cardItem} key={v.id}>
                  <Button
                    type='primary'
                    className={styles.cardAction}
                    onClick={(e) => {
                      e.stopPropagation()
                      setId(v.id)
                      setRenewalPriceId('')
                      if (v.buttonType == 0) {
                        // dispatch(consultAsync(v.id)).then(unwrapResult).then(() => {
                        setConsultVisible(true)
                        // })
                      } else {
                        setdurationNum(1)
                        getQrcode(v.id, 1, v.productName, v.durationUnit)
                      }
                    }}
                  >
                    {v.buttonType == 0 ? '点击咨询' : '立即购买'}
                  </Button>
                  <Row>
                    <Col span={8}>
                      <img
                        style={{
                          width: 100,
                          height: v?.productName === '挂号立牌' ? 130 : 110,
                        }}
                        src={v?.iconUrl}
                      />
                    </Col>
                    <Col span={16}>
                      <header className={styles.header}>
                        {v?.productName} &nbsp;
                        {(v?.productName == '代煎服务' ||
                          v?.productName == '医生处方个性签名') && <FireIcon />}
                      </header>
                      <section className={styles.section}>
                        <Tooltip title={v?.remark}>{v?.remark}</Tooltip>
                      </section>
                      <footer
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div>
                          {v.showPriceStatus == 1 ? (
                            <span
                              className={styles.price}
                              style={{ fontSize: 16 }}
                            >
                              价格具体咨询
                            </span>
                          ) : (
                            <>
                              <span className={styles.price}>
                                {v?.defaultPrice}
                              </span>
                              元/{getUnit(v?.durationUnit)}
                            </>
                          )}
                        </div>
                      </footer>
                    </Col>
                  </Row>
                </Col>
              ))}
          </Row>
        </div>
      </div>

      {/* 咨询 */}
      <Modal
        width={400}
        style={{ top: 200 }}
        footer={null}
        title='您好'
        visible={consultVisible}
        onCancel={() => setConsultVisible(false)}
      >
        <p>您可以点击下方预约咨询按钮，</p>
        <p>相关服务人员会在24小时之内与您联系。</p>
        <p>如您紧急也可直接致电：17300983625</p>
        <p>咨询服务时间 : 周一至周五 09:00 - 18:00 </p>
        <Row justify='end'>
          <Button
            type='primary'
            onClick={() => {
              dispatch(consultAsync(id))
                .then(unwrapResult)
                .then(() => {
                  setConsultVisible(false)
                  notification.success({
                    message: '预约电话咨询成功',
                  })
                })
            }}
          >
            预约电话咨询
          </Button>
        </Row>
      </Modal>

      {/* 续订(没有配置价格) */}
      <Modal
        width={400}
        style={{ top: 200 }}
        footer={null}
        title='温馨提醒'
        maskClosable={false}
        visible={noAgainVisible}
        onCancel={() => {
          setNoAgainVisible(false)
          if (expirationFlag) {
            logout()
          }
        }}
      >
        <p>如需续费，请点击下方预约续费按钮，</p>
        <p>相关服务人员会在24小时之内与您联系。</p>
        <p>如您紧急也可直接致电：17300983625</p>
        <p>咨询服务时间 : 周一至周五 09:00 - 18:00 </p>
        <Row justify='end'>
          <Space>
            <Button
              type='primary'
              onClick={(v) => {
                dispatch(getSendMessage())
                  .then(unwrapResult)
                  .then((v) => {
                    notification.success({
                      message: '预约续费咨询成功',
                    })
                    setNoAgainVisible(false)
                  })
              }}
            >
              预约续费咨询
            </Button>
          </Space>
        </Row>
      </Modal>

      {/* 续订 */}
      <Modal
        width={700}
        className={styles.againModal}
        style={{ top: 200 }}
        footer={null}
        title='续订'
        visible={againVisible}
        onCancel={againClose}
      >
        <Row gutter={10} style={{ marginBottom: 20 }}>
          <Col span={5} style={{ textAlign: 'right' }}>
            机构名称：
          </Col>
          <Col span={16}>{renewDetail.tenantName}</Col>
        </Row>
        <Row gutter={10} style={{ marginBottom: 20 }}>
          <Col span={5} style={{ textAlign: 'right' }}>
            续订年限：
          </Col>
          <Col span={16}>
            <Radio.Group
              onChange={(e) => {
                const el = e.target.value
                setValue(el)
                setRenewalPriceId(
                  renewDetailYear.find((v: any) => v.year == el)?.id
                )
                setPrice(renewDetailYear.find((v: any) => v.year == el))
              }}
              value={value}
            >
              {!!renewDetailYear?.length &&
                renewDetailYear.map((v: any) => (
                  <Radio.Button
                    value={v.year}
                    key={v.price}
                    style={{ width: '82px', marginBottom: '14px' }}
                  >
                    {v?.year == 99 ? '终身' : v.year}
                    {v?.year == 99 ? '' : '年'}
                    {!!Number(v.discount) &&
                      v.discount != 1 &&
                      v.discount < 100 && (
                        <>
                          <div className={styles.discount}>
                            {Number(v.discount)}折
                          </div>
                        </>
                      )}
                  </Radio.Button>
                ))}
            </Radio.Group>
          </Col>
        </Row>
        {/* <Row gutter={10} style={{ marginBottom: 20 }}>
          <Col span={5} style={{ textAlign: 'right' }}>
            价格：
          </Col>
          <Col span={16}>￥{price.price}</Col>
        </Row> */}
        <Row gutter={10} style={{ marginBottom: 10 }}>
          <Col span={5} style={{ textAlign: 'right' }}>
            应付金额：
          </Col>
          <Col span={16}>
            <span className={styles.shouldPay}>￥{price.actuallyPrice}</span>
          </Col>
        </Row>
        <Row style={{ marginBottom: 10 }}>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Row gutter={10}>
              <Col span={5}>发票接收邮箱：</Col>
              <Col span={8}>
                <Input
                  placeholder='请输入'
                  allowClear
                  value={email}
                  onChange={(e) => setEmail(e?.target?.value)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify='end'>
          <Space>
            <Button type='primary' onClick={againClose}>
              取消
            </Button>
            <Button
              type='primary'
              onClick={() => {
                setLoading(true)
                getQrcode(1, durationNum, '系统续费', 0)
                setEmail('')
              }}
              loading={loading}
            >
              去支付
            </Button>
          </Space>
        </Row>
      </Modal>

      {/* 支付 */}
      <Modal
        width={500}
        className={styles.qrCodeModal}
        style={{ top: 200 }}
        footer={null}
        title='扫码付款'
        visible={QRCodeVisible}
        maskClosable={false}
        keyboard={false}
        onCancel={qrCodeClose}
      >
        <Row justify='center'>
          <p style={{ marginBottom: '0px', fontSize: '17px', color: 'gray' }}>
            您正在购买【{payInfo?.productName}】服务包，数量{' '}
            {payInfo?.durationNum}/{payInfo?.durationUnit}
          </p>
          <p style={{ fontSize: '15px', color: 'gray' }}>
            支付成功后，系统会自动处理您的订单。
          </p>
        </Row>
        <Row justify='center'>
          <Radio.Group
            value={payType}
            buttonStyle='solid'
            onChange={(e) => {
              setpayType(e.target.value)
            }}
          >
            <Radio.Button
              disabled={loading}
              value={1}
              style={{ width: 150, textAlign: 'center' }}
            >
              微信
            </Radio.Button>
            <Radio.Button
              disabled={loading}
              value={3}
              style={{ width: 150, textAlign: 'center' }}
            >
              支付宝
            </Radio.Button>
          </Radio.Group>
        </Row>
        <Row style={{ margin: '20px 0' }} justify='center'>
          <Qrcode
            value={payInfo?.payCode}
            color={theme.tc1}
            size={256}
            style={{
              width: 256,
              height: 256,
            }}
          />
        </Row>
        <Row justify='center'>（ 付款金额：{payInfo?.money}元 ）</Row>
      </Modal>
      <Modal
        width={500}
        style={{ top: 200 }}
        footer={null}
        title='服务到期'
        visible={IsExpireVisible}
        maskClosable={false}
        onCancel={() => {
          logout()
          setIsExpireVisible(false)
        }}
      >
        <Row>
          <span className={styles.infoIcon}>
            <ExclamationCircleOutlined />
          </span>
          <span>
            服务已在{moment(serverEndTime).format(dateFormat)}
            到期,如需继续使用,请先去续订。
          </span>
        </Row>
        <Row justify='end' style={{ marginTop: '30px' }}>
          <Space>
            <Button
              type='default'
              onClick={(v) => {
                setIsExpireVisible(false)
                logout()
              }}
            >
              取消
            </Button>
            <Button
              type='primary'
              onClick={(v) => {
                setIsExpireVisible(false)
                dispatch(getProductCenterInfoYearAsync())
                  .then(unwrapResult)
                  .then((res: any) => {
                    if (res?.length) {
                      setRenewDetailYear(res)
                      setRenewalPriceId(res?.[0]?.id)
                      setValue(res?.[0]?.year)
                      setPrice(res?.[0])
                      setAgainVisible(true)
                    } else {
                      setNoAgainVisible(true)
                    }
                  })
              }}
            >
              去续订
            </Button>
          </Space>
        </Row>
      </Modal>
    </div>
  )
}
