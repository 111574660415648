import {
    ArrowRightOutlined,
  CaretDownFilled,
  CaretUpFilled,
  ExclamationCircleFilled,
  ExclamationCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  ModalProps,
  notification,
  Pagination,
  Popover,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Table,
} from "antd";
import { TableRowSelection } from "antd/lib/table/interface";
import moment from "moment";
import React, {
  ReactElement,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootDispatch } from "../../../../app/store";
import { EditableList } from "../../../../compnents/list/EditableList";
import { Material } from "../../../../models/material";
import { Page, startPageOf } from "../../../../models/page";
import { RecipeCategory } from "../../../../models/recipe";
import { SetMeal, SetMealPageParams } from "../../../../services/setmeal";
import { ThemeContext } from "../../../../theme/ThemeContext";
import { selectEdition } from "../../../../app/applicationSlice";

interface RecipeComparisonProps {
  comparisonData: any[]
  onChange?: (materials: Material[], id?: any, category?: any) => void;
}

export const RecipeComparisonModal = ({
  comparisonData,
  onChange,
  visible,
  onCancel,
  ...others
}: RecipeComparisonProps & ModalProps): ReactElement => {

  const [infoType, setInfoType] = useState(false);

  const [sevenDaysNoInfo, setSevenDaysNoInfo] = useState(false);

  return (
    <Modal
      visible={visible}
      {...others}
      onCancel={onCancel}
      title="温馨提醒"
      width={700}
      footer={null}
      style={{ overflow: "hidden", padding: "0" }}
      bodyStyle={{ padding: "10px 30px 15px 30px" }}
      destroyOnClose
    >
      <Row
        style={{
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          marginBottom: 16,
        }}
      >
        请注意，部分项目的关键信息相比原处方有一些变化，请仔细核对
      </Row>
      <EditableList
        rowKey={(v, i) => v?.id}
        style={{ marginBottom: "10px", maxHeight: 500 }}
        pagination={false}
        size="small"
        bordered
        dataSource={comparisonData}
        columns={[
          {
            title: "序号",
            key: "no",
            align: "center",
            width: "3rem",
            render: (_1, _2, i) => `${i + 1}`,
          },
          {
            title: "项目名称",
            dataIndex: "name",
            align: "center",
            width: "12rem",
          },
          {
            title: "变更内容",
            dataIndex: "unequalFields",
            align: "center",
            ellipsis: true,
            render: function Element(_, m) {
              return (
                m.unequalFields &&
                m.unequalFields?.map((v: any) => {
                  return (
                    <div key={v.id} style={{ textAlign: "left" }}>
                      {v.field == "name"
                        ? "名称"
                        : v.field == "accountUnit"
                        ? "单位"
                        : v.field == "retailPrice"
                        ? "单价"
                        : v.field == "packSpec"
                        ? "包装规格"
                        : v.field == "spec"
                        ? "制剂规格"
                        : ""}
                      ：&nbsp;
                      {v.value2}&nbsp;
                      <ArrowRightOutlined style={{ color: "#FFA037" }} />
                      &nbsp;{v.value1};
                    </div>
                  );
                })
              );
            },
          },
        ]}
      />
      <Row justify="space-between" style={{ marginTop: 15 }}>
        <Col>
          <Radio checked={infoType} onClick={() => {
            setInfoType(!infoType)
            if(!infoType) {
              localStorage.setItem('comparisonInfoType', JSON.stringify(!infoType))
            } else {
              localStorage.removeItem('comparisonInfoType')
            }
          }}>
            弱提醒&nbsp;
            <Popover
              placement="top"
              content="勾选上弱提醒后会改为页面右上角提醒"
            >
              <ExclamationCircleOutlined
                style={{ color: "rgba(45, 123, 255, 0.51)", fontSize: 16 }}
              />
            </Popover>
          </Radio>
          <Radio
            checked={sevenDaysNoInfo}
            onClick={() => {
              setSevenDaysNoInfo(!sevenDaysNoInfo)
              if(!sevenDaysNoInfo) {
                localStorage.setItem('sevenDaysNoInfo', JSON.stringify({
                  value: !sevenDaysNoInfo,
                  expiry: Date.now() + 7 * 24 * 60 * 60 * 1000
                }))
              } else {
                localStorage.removeItem('sevenDaysNoInfo')
              }
            }}
          >
            近七天不再提示
          </Radio>
        </Col>
        <Col>
          <Button type="primary" onClick={onCancel}>
            我知道了
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};
