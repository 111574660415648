/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2024-12-19 16:18:13
 * @LastEditors: linxi
 * @LastEditTime: 2024-12-24 15:46:45
 */
import { PrintTemplate, PrintTemplateCategory } from '../models/print'
import { ADMIN_API_PREFIX, API_PREFIX } from './constant'
import { Request } from './request'

export interface PrintSettingSubmitParams {
  id: any
  templateId?:any
  globalConfig:any
  headerConfig?:any
  bodyConfig?:any
  userSignConfig?:any
  footerConfig?:any
}

export function getPrintSettingSubmit(body: PrintSettingSubmitParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/advancedprintconfig/submit`,
    method: 'POST',
    body,
  }
}


export interface PrintSettingDetailParams {
  templateId?:any
}

export function getPrintSettingDetail(params: PrintSettingDetailParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/advancedprintconfig/getConfig`,
    method: 'GET',
    params,
  }
}
export interface PrintSettingEditOrYLParams {
  templateCode?:any
  setupFlag?:any
}

export function getPrintSettingEditOrYL(params: PrintSettingEditOrYLParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/advancedprintconfig/previewEffect`,
    method: 'GET',
    params,
  }
}
export interface PrintSettingsyncTenantNameParams {
  tenantName?:any
}

export function getPrintSettingsyncTenantName(params: PrintSettingsyncTenantNameParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/advancedprintconfig/syncTenantName`,
    method: 'GET',
    params,
  }
}

